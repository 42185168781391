import { Button, Col, Modal, Row } from "antd";

const LogoutModal = ({ logoutModal, setLogoutModal, setIsLoggedOut }) => {
  const handleOk = () => {
    setLogoutModal(false);
    setIsLoggedOut(true);
  };
  const handleCancel = () => {
    setLogoutModal(false);
  };
  return (
    <Modal
      centered
      closable={false}
      className="mainLogoutModal"
      title="Logout"
      open={logoutModal}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={[
        <Row>
          <Col span={24} align={"right"} className="justify_items_end">
            <Button
              key="submit"
              className="black_color_button"
              onClick={handleOk}
            >
              Okay
            </Button>
            <Button key="submit" className="cancel_btn" onClick={handleCancel}>
              Cancel
            </Button>
            ,
          </Col>
        </Row>,
      ]}
    >
      <p className="logout_desc">Are you sure you want to Logout?</p>
    </Modal>
  );
};
export default LogoutModal;
