import SubLayout from "../../components/layout/SubLayout";
import bannerImage from "../../Assets/campagin/addCampainBanner.png";
import addUserIcon from "../../Assets/campagin/add-user-icon.svg";
import { InfoCircleOutlined } from "@ant-design/icons";

import {
  Button,
  Form,
  Input,
  Image,
  Row,
  Col,
  Select,
  Popover,
  message,
  Tooltip,
} from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import AddUserModal from "./AddUserModal/AddUserModal";
import { CloseOutlined } from "@ant-design/icons";
import API_MANAGER from "../../API";
// import LoaderGif from "../../../Assets/loader.gif";
import AlertState from "../../components/common/AlertState";
import rightArrow from "../../Assets/campagin/right_arrow.svg";
import MobileHeader from "../../components/layout/MobileHeader";

const EditCampaign = () => {
  const navigate = useNavigate();
  const propsData = useLocation();
  const dataTemp = propsData?.state;

  //state for storing user after clicking edit campaign
  const [userModalOpen, setUserModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [industries, setIndustries] = useState([]);
  const [states, setStates] = useState([]);
  const [selectedUsers, setSelectedUser] = useState([]);
  const [usersList, setUsersList] = useState([]);
  const [checkSelected, setCheckSelected] = useState([]);
  const [users, setUsers] = useState([]);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertState, setAlertState] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [mainUserData, setMainUserData] = useState({
    id: localStorage.getItem("userID"),
    name: localStorage.getItem("name"),
  });
  const [searchUsers, setSearchUsers] = useState(null);
  const [editCampaignData, setEditCampaignData] = useState(null);
  const [form] = Form.useForm();
  const props = useLocation();
  const userCreated = props.state;

  useEffect(() => {
    if (userCreated === true) {
      message.success("User successfully created.");
    }
  }, [userCreated]);

  const getData = async () => {
    setLoading(true);
    try {
      const response = await API_MANAGER.getCampaignDetail(dataTemp?._id);
      setEditCampaignData(response?.data?.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);

      message.error("Something went wrong. Please try again.");
    }
  };
  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (editCampaignData) {
      form.setFieldsValue({ name: editCampaignData?.name });
      form.setFieldsValue({
        targetHeadCount: editCampaignData?.targetHeadCount,
      });
      form.setFieldsValue({ projectCode: editCampaignData?.projectCode });
      form.setFieldsValue({ suburb: editCampaignData?.suburb });
      form.setFieldsValue({ postalCode: editCampaignData?.postalCode });
      form.setFieldsValue({
        state:
          typeof editCampaignData?.state === "object"
            ? editCampaignData?.state?._id
            : editCampaignData?.state,
      });
      form.setFieldsValue({
        industry:
          typeof editCampaignData?.industry === "object"
            ? editCampaignData?.industry?._id
            : editCampaignData?.industry,
      });
      const user = editCampaignData?.users?.filter(
        (e) => e._id !== mainUserData?.id
      );
      setSelectedUser(user);
      setUsers(user);
      const user1=[];
      editCampaignData?.users?.forEach((e) => {
        if(e?._id !== mainUserData?.id){
          user1?.push(e?._id);
        }
      });
      setCheckSelected(user1);
    }
  }, [editCampaignData]);

  const onFinish = async (values) => {
    let temp = values;
    const updatedCheckSelected = [
      ...checkSelected,
      localStorage.getItem("userID"),
    ];
    temp = {
      ...temp,
      users: updatedCheckSelected,
      ["isActive"]: true,
    };

    if (editCampaignData) {
      try {
        setLoading(true);
        const response = await API_MANAGER.updateCampaign_management(
          temp,
          editCampaignData?._id
        );
        message.success("Campaign successfully updated.");
        setLoading(false);
        navigate(-1);
        localStorage.removeItem("campaignData");
      } catch (error) {
        setLoading(false);

        message.error("Something went wrong. Please try again.");
      }
    } else {
      try {
        setLoading(true);
        const response = await API_MANAGER.createCampaign(temp);
        form.resetFields();
        setLoading(false);
        message.success("Campaign successfully created.");

        navigate(`/campaign-detail/${response?.data?.data?.id}`);
      } catch (error) {
        setLoading(false);

        message.error(
          error?.response?.data?.message?.message ||
            "Something went wrong. Please try again."
        );
      }
    }
  };
  const onFinishFailed = (errorInfo) => {};

  const options = [];
  for (let i = 10; i < 360; i++) {
    options.push({
      value: i.toString(36) + i,
      label: i.toString(36) + i,
    });
  }

  const handleAddUserModal = () => {
    setUserModalOpen(true);
  };
  const handleOk = (users) => {
    setUserModalOpen(false);
    setSelectedUser(users);
    const selectedFieldValues = users?.map((user) => user.id);
    form.setFieldsValue({ users: selectedFieldValues });
  };
  const getIndustries = async () => {
    try {
      const response = await API_MANAGER.getTags("INDUSTRY&sort=name");
      setIndustries(response?.data?.data?.tags);
    } catch (error) {
      message.error("Something went wrong. Please try again.");
    }
  };
  const getStates = async () => {
    try {
      const response = await API_MANAGER.getTagsBySearch({
        type: "STATE",
        limit: 20000,
        sort: "name",
      });
      setStates(response?.data?.data?.tags);
    } catch (error) {
      message.error("Something went wrong. Please try again.");
    }
  };
  const getUsersList = async () => {
    const params = {
      search: searchUsers,
    };
    try {
      const response = await API_MANAGER.getUsersList(params);
      const users = response?.data?.data?.filter(
        (e) => e?._id !== mainUserData?.id
      );
      setUsersList(users);
    } catch (error) {
      message.error("Something went wrong. Please try again.");
    }
  };

  useEffect(() => {
    getIndustries();
    getStates();
  }, []);
  useEffect(() => {
    getUsersList();
  }, [searchUsers]);

  return (
    <>
      <AlertState
        state={alertState}
        message={alertMessage}
        alertOpen={alertOpen}
        setAlertOpen={setAlertOpen}
      />
      <SubLayout page={"Edit Campaign"} whiteBg showBack>
        <AddUserModal
          isModalOpen={userModalOpen}
          setUserModalOpen={setUserModalOpen}
          data={usersList}
          handleOk={handleOk}
          checkSelected={checkSelected}
          setCheckSelected={setCheckSelected}
          users={users}
          setUsers={setUsers}
          setSearchUsers={setSearchUsers}
          mainUserData={mainUserData}
        />

        <div className="add_campaign_main_container">
          <Row>
            <Col xs={0} md={24} className="banner_img_col">
              <Image preview={false} src={bannerImage} />
            </Col>
          </Row>
          {/* for small screen only */}
          <Row>
            <Col xs={24} md={0}>
              <MobileHeader title={"Edit Campaign"} />
            </Col>
          </Row>
          {/* Form Start */}
          <div className="form_main_div">
            <Form
              form={form}
              name="basic"
              initialValues={{
                remember: true,
              }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
              layout="vertical"
              requiredMark={false}
            >
              <div className="form_div">
                <Row justify={"space-between"}>
                  <Col xs={24} md={11}>
                    <Form.Item
                      label="Campaign Name/Project Name*"
                      name="name"
                      rules={[
                        {
                          required: true,
                          message: "Please input campaign name/project name!",
                        },
                      ]}
                    >
                      <Input
                        className="form_input_box"
                        placeholder="Add campaign name/project name"
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={11}>
                    <Form.Item
                      label={
                        <div>
                          Target Headcount
                          <Tooltip title="The campaign's target headcount is automatically calculated from each advert's target headcount.">
                            <InfoCircleOutlined
                              className="cursor_pointer"
                              style={{ marginLeft: "5px" }}
                            />
                          </Tooltip>
                        </div>
                      }
                      name="targetHeadCount"
                    >
                      <Input
                        className="form_input_box"
                        placeholder="Target headcount"
                        disabled={true}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row justify={"space-between"}>
                  <Col xs={24} md={11}>
                    <Form.Item
                      label="Project Code"
                      name="projectCode"
                      rules={[
                        {
                          required: false,
                          message: "Please input project code!",
                        },
                      ]}
                    >
                      <Input
                        className="form_input_box"
                        placeholder="Add project code"
                        type="text"
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={11}>
                    <Form.Item
                      label="Industry*"
                      name="industry"
                      rules={[
                        {
                          required: true,
                          message: "Please input industry!",
                        },
                      ]}
                    >
                      <Select
                        className="select_input_form"
                        placeholder="Add industry"
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option?.label
                            ?.toLowerCase()
                            .includes(input?.toLowerCase())
                        }
                        filterSort={(optionA, optionB) =>
                          optionA?.label
                            ?.toLowerCase()
                            .localeCompare(optionB?.label?.toLowerCase())
                        }
                      >
                        {industries?.map((item, index) => (
                          <Select.Option
                            value={item?.id}
                            key={item?.id}
                            label={item?.name}
                          >
                            {item?.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                <Row justify={"space-between"}>
                  <Col xs={24} md={11}>
                    <Form.Item
                      label="Suburb*"
                      name="suburb"
                      rules={[
                        {
                          required: true,
                          message: "Please input suburb!",
                        },
                      ]}
                    >
                      <Input
                        className="form_input_box"
                        placeholder="Add suburb"
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={11}>
                    <Form.Item
                      label="State*"
                      name="state"
                      rules={[
                        {
                          required: true,
                          message: "Please input state!",
                        },
                      ]}
                    >
                      <Select
                        className="select_input_form"
                        placeholder="Add state"
                        showSearch
                        optionFilterProp="children"
                      >
                        {states?.map((item, index) => (
                          <Select.Option value={item?.id} key={index}>
                            {item?.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                <Row justify={"space-between"}>
                  <Col xs={24} md={11}>
                    <Form.Item
                      label="Postal Code*"
                      name="postalCode"
                      rules={[
                        {
                          required: true,
                          message: "Please input postal code!",
                        },
                      ]}
                    >
                      <Input
                        min={0}
                        className="form_input_box"
                        placeholder="Add postal code"
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={11}>
                    <label>
                      <Row justify={"space-between"}>
                        <Col span={12} style={{ marginBottom: "8px" }}>
                          Users*
                        </Col>
                      </Row>
                    </label>
                    <Form.Item
                      name="users"
                      rules={[
                        {
                          required: false,
                          message: "Please add users!",
                        },
                      ]}
                    >
                      <div
                        className="form_input_div"
                        onClick={handleAddUserModal}
                        placeholder="add user"
                      >
                        <div className="multi_select_item_block">
                          <span className="text_capitalize">
                            {mainUserData?.name}
                          </span>
                          <CloseOutlined size={4} />
                        </div>
                        {selectedUsers.slice(0, 2).map((item) => {
                          return (
                            <div className="multi_select_item_block">
                              <span className="text_capitalize">{`${item?.firstName} ${item?.lastName}`}</span>{" "}
                              <CloseOutlined size={4} />
                            </div>
                          );
                        })}
                        {selectedUsers.length > 2 && (
                          <Popover
                            className="aaa"
                            placement="topLeft"
                            content={
                              <div className="popover_contentDiv">
                                <Row>
                                  {selectedUsers.slice(2).map((item) => (
                                    <Col span={24} className="popover_item_col">
                                      <span className="greendot"></span>
                                      <span>
                                        {item?.firstName}&nbsp;{item?.lastName}
                                      </span>
                                    </Col>
                                  ))}
                                </Row>
                              </div>
                            }
                          >
                            {/* <Button>TL</Button> */}
                            <div className="count_div">
                              <span>+{selectedUsers.length - 2}</span>
                            </div>
                          </Popover>
                        )}
                      </div>
                    </Form.Item>
                  </Col>
                </Row>
              </div>
              <Row className="submit_button_row">
                <Col span={24}>
                  <Row
                    justify={"end"}
                    gutter={24}
                    className="specialBtn-parent"
                  >
                    <Button
                      className="button_no_border specialBtn_one"
                      onClick={() => navigate(-1)}
                    >
                      Cancel
                    </Button>
                    <Button
                      className="button_1 specialBtn_two"
                      // className="button_inactive specialBtn_two"
                      htmlType="submit"
                      loading={loading}
                    >
                      {"Update Campaign"}
                    </Button>
                  </Row>
                </Col>
              </Row>
            </Form>
          </div>
        </div>
      </SubLayout>
    </>
  );
};
export default EditCampaign;
