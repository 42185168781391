import { Button, Col, Input, Modal, Row, message } from "antd";
import {
  SearchOutlined,
  CheckOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import { useEffect, useState } from "react";
import API_MANAGER from "../../API";
import SearchIcon from "../../Assets/user_mangement/searchIcon.svg";
import { useParams } from "react-router-dom";
import FullScreenLoader from "../../components/common/FullScreenLoader";

const SelectCampaigns = ({
  selectCampaignsModal,
  setSelectedCampaignsModal,
  campaignList,
  setSearch,
  alreadySelected,
  setDataUpdated,
  dataUpdated,
  userId,
  loading,
}) => {
  const [selectedCampaigns, setSelectedCampaigns] = useState([]);
  const [checkSelected, setCheckSelected] = useState([]);
  const params = useParams();

  useEffect(() => {
    const idArray = [];
    if (alreadySelected?.length > 0) {
      alreadySelected.forEach((e) => {
        idArray.push(e?._id);
      });
      setCheckSelected(idArray);
      setSelectedCampaigns(alreadySelected);
    }
  }, [alreadySelected]);
  const handleOk = async () => {
    setSelectedCampaignsModal(false);
    let campaing = selectedCampaigns?.map((obj) => obj?._id);

    const payload = {
      userId: userId ? userId : params?.id,
      campaigns: campaing,
    };
    try {
      await API_MANAGER.updateCampaign(payload);
      // setLoading(false);
      message.success("Successfully updated user campaigns");
      if (dataUpdated) setDataUpdated(false);
      else setDataUpdated(true);

    } catch (error) {
      message.error("Something went wrong. Please try again.");
    }
  };
  const handleCancel = () => {
    setSelectedCampaignsModal(false);
  };
  const handleItemSelect = (item) => {
    if (checkSelected.includes(item?._id)) {
      const updatedCheckItems = checkSelected.filter(
        (check_item) => check_item !== item?._id
      );
      setCheckSelected(updatedCheckItems);
      const updatedSelectedItems = selectedCampaigns?.filter(
        (campaign_item) => campaign_item?._id !== item?._id
      );
      setSelectedCampaigns(updatedSelectedItems);
    } else {
      const updatedSelectedItems = [...selectedCampaigns, item];
      const updatedCheckItems = [...checkSelected, item?._id];
      setSelectedCampaigns(updatedSelectedItems);
      setCheckSelected(updatedCheckItems);
    }
  };

  return (
    <Modal
      className="select_campaigns_main_modal"
      title="Select Campaign:"
      open={selectCampaignsModal}
      onOk={handleOk}
      centered
      onCancel={handleCancel}
      footer={[
        <Row>
          <Col span={24} align={"right"} className="justify_items_end">
            <Button className="cancel_btn" onClick={handleCancel}>
              Cancel
            </Button>
            <Button
              key="submit"
              className={
                 "black_color_button"
              }
              onClick={handleOk}
            >
              Done
            </Button>
          </Col>
        </Row>
      ]}
    >
      <FullScreenLoader isLoading={loading} />
      <Row className="flex_direction_col">
        <Col xs={24}>
          <Input
            size="large"
            prefix={<img src={SearchIcon} />}
            placeholder="Select or search campaigns"
            onChange={(e) => setSearch(e.target.value)}
          />
        </Col>
        {/* Selected Campaigns  */}
        <Row className="mt-8 mw_overflow_x">
          <Col xs={24} className="d-flex">
            {selectedCampaigns?.slice(0, 2)?.map((item) => (
              <div className="selected_item_div text_capitalize">
                <span>{item?.campaign ? item?.campaign : item?.name}</span>
                <div style={{ marginLeft: "5px" }}>
                  {/* <kdkdkd /> */} 
                </div>
              </div>
            ))}
            {selectedCampaigns.length > 2 && (
              <div className="selected_item_div">
                <span>+{selectedCampaigns.length - 2}</span>
              </div>
            )}
          </Col>
        </Row>
        <Row className="selectCampaign_main_row">
          {campaignList &&
            campaignList?.map((item) => (
              <Col
                span={24}
                onClick={() => handleItemSelect(item)}
                className={
                  checkSelected.includes(item?._id)
                    ? "individual_main_col active justify_space"
                    : "individual_main_col inactive"
                }
              >
                <span className="text_capitalize">{item?.name}</span>
                {checkSelected.includes(item?._id) ? <CheckOutlined /> : ""}
              </Col>
            ))}
        </Row>
      </Row>
    </Modal>
  );
};
export default SelectCampaigns;
