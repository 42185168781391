import React from "react";
import { Row, Col, Form, Button } from "antd";

import { Link, useNavigate } from "react-router-dom";
import LoginImg from "../../Assets/login/welcome.png";
import Logo from "../../Assets/login/Logo.svg";

function Welcome() {
  const navigate = useNavigate();

  return (
    <>
      <div className="welcome_main_container">
        <Row className="w-100 h-100" justify={"space-between"}>
          <Col xs={24} md={15}>
            <div className="login_container">
              {/* logo sction */}
              <div className="company_logo_container">
                <Link to="/">
                  <img
                    src={Logo}
                    onClick={() => navigate("/")}
                    className="company_logo"
                  />
                </Link>
              </div>
              <div className="login_form_container">
                <Form className="w-100">
                  <div>
                    <h3 className="heading">Welcome to “Cinchy.me”</h3>
                    <p className="body_text">
                      Construction Recruitment Made Easy.
                    </p>
                  </div>

                  <Row>
                    <Col span={24}>
                      <Form.Item>
                        <Button
                          onClick={() => navigate("/signup")}
                          style={{ width: "100%" }}
                          className="signinBtn mt-42"
                        >
                          Sign Up
                        </Button>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row justify={"center"}>
                    <p className="notAMember">
                      Already have an account?{" "}
                      <span onClick={() => navigate("/login")}>Login</span>
                    </p>
                  </Row>
                </Form>
              </div>
              {/* welcome page footer sction */}
              <Row
                align={"middle"}
                justify={"space-between"}
                className="footer"
              >
                <Col xs={12} md={6} align="middle">
                  <Link to="/about-us" className="body_text">
                    About Us
                  </Link>
                </Col>
                <Col xs={12} md={6} align="middle">
                  <Link to="/contact-us" className="body_text">
                    Contact Us
                  </Link>
                </Col>
                <Col xs={12} md={6} align="middle" className="mt-21">
                  <Link to="/terms-of-use" className="body_text">
                    Terms and Conditions
                  </Link>
                </Col>
                <Col xs={12} md={6} align="middle" className="mt-21">
                  <Link to="/privacy-policy" className="body_text">
                    Privacy Policy
                  </Link>
                </Col>
              </Row>
            </div>
          </Col>
          <Col xs={0} md={9} className="loginImgContainer">
            <img src={LoginImg} alt="img" className="loginImg" />
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Welcome;
