import { createSlice } from '@reduxjs/toolkit';
const initialState = [];

const onboardSlice = createSlice({
    name:"onboarding",
    initialState,

    reducers:{
        add(state,action){
            state.push(action.payload);
        }
    }
})
export const {add} = onboardSlice.actions;
export default onboardSlice.reducer;