import React, { useState, useEffect } from "react";
import { Row, Col, Form, Input, Button, message, Progress } from "antd";
import RightLogo from "../../../Assets/login/right.svg";
import API_MANAGER from "../../../API";
import RightArrow from "../../../Assets/login/rightArrow.svg";
import AlertImg from "../../../Assets/login/alert.svg";

import { useNavigate } from "react-router-dom";
function NewPassword({ token, setSucessScreen }) {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [progressPercentage, setProgressPercentage] = useState(0);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [validate, setValidate] = useState(false);
  const [oldPasswordError, setOldPassword] = useState(false);
  const [passwordNotMatch, setPasswordNotMatch] = useState(false);
  const navigate = useNavigate();
  const validatePassword = (e) => {
    setProgressPercentage(0);
    if (e?.match(/[A-Z]/) || e?.match(/[a-z]/) || e?.match(/[1-9]/)) {
      setProgressPercentage(25);
    }
    if (
      (e?.match(/[a-z]/) && e?.match(/[A-Z]/)) ||
      (e?.match(/[a-z]/) && e?.match(/[1-9]/)) ||
      (e?.match(/[1-9]/) && e?.match(/[A-Z]/))
    ) {
      setProgressPercentage(50);
    }
    if (e?.match(/[1-9]/) && e?.match(/[a-z]/) && e?.match(/[A-Z]/)) {
      setProgressPercentage(75);
    }
    if (
      e?.length >= 8 &&
      e?.match(/[1-9]/) &&
      e?.match(/[a-z]/) &&
      e?.match(/[A-Z]/)
    ) {
      setProgressPercentage(100);
    }
  };
  const handleSubmit = async (value) => {
    if (value?.password !== value?.confirm_password) {
      message.warning("Both password should be same!");
      return;
    } else if (!value?.password.match(/[A-Z]/)) {
      message.warning("Password needs to have at least 1 capital letter");
      return;
    } else if (!value?.password.match(/[a-z]/)) {
      message.warning("Password needs to have at least 1 small letter");
      return;
    } else if (!value?.password.match(/[1-9]/)) {
      message.warning("Password needs to have at least 1 numeric character");
      return;
    } else if (!value?.password.match(/[!@#$%^&*(),.?":{}|<>]/)) {
      message.warning("Password needs to have at least 1 special character");
      return;
    } else if (!(value?.password.length >= 8)) {
      message.warning("Password needs to be at least 8 characters long");
      return;
    }
    setLoading(true);
    try {
      const response = await API_MANAGER.createNewPassword(
        {
          password: value?.password,
        },
        token
      );

      setLoading(false);
      setSucessScreen(true);
      // message.success("Your password successfully changed!");
      // navigate("/login");
    } catch (error) {
      setLoading(false);
      if (
        error?.response?.data?.message?.message === "Cannot set old password"
      ) {
        setOldPassword(true);
      }
      message.error(
        error?.response?.data?.message?.message ||
          "Something went wrong. Please try again."
      );
    }
  };
  useEffect(() => {
    if (password && confirmPassword && password === confirmPassword) {
      setValidate(true);
      setPasswordNotMatch(false);
    } else if (password && confirmPassword && password !== confirmPassword) {
      setPasswordNotMatch(true);
    } else {
      setValidate(false);
      setPasswordNotMatch(false);
    }
  }, [password, confirmPassword]);
  return (
    <div>
      <Form form={form} onFinish={handleSubmit} className="w-100">
        <Row>
          <Col span={24}>
            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: "New password is required",
                },
              ]}
              label="New Password*"
            >
              <Input.Password
                className={oldPasswordError ? "border-red" : ""}
                placeholder="Enter new password"
                type="password"
                value={password}
                onChange={(e) => {
                  setOldPassword(false);
                  validatePassword(e?.target?.value);
                  setPassword(e?.target?.value);
                }}
              />
            </Form.Item>
            {oldPasswordError ? (
              <Row align={"middle"} gutter={8} className="alertMsg">
                <Col>
                  <img src={AlertImg} alt="alert" />
                </Col>
                <Col>
                  <span>You have entered an old password</span>
                </Col>
              </Row>
            ) : progressPercentage ? (
              <Row align={"middle"} gutter={16}>
                <Col style={{ width: "209px" }}>
                  <Progress
                    percent={progressPercentage}
                    className="formProgressBar"
                    showInfo={false}
                  />
                </Col>
                <Col>
                  {progressPercentage < 100 ? (
                    <span className="weakText">Weak</span>
                  ) : (
                    <span className="strongText">Strong</span>
                  )}
                </Col>
              </Row>
            ) : (
              ""
            )}
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item
              name="confirm_password"
              rules={[
                {
                  required: true,
                  message: "Confirm password is required",
                },
              ]}
              label="Confirm Password*"
            >
              <Input.Password
                className={passwordNotMatch ? "border-red" : ""}
                placeholder="Confirm Your Password"
                type="password"
                onChange={(e) => setConfirmPassword(e?.target?.value)}
                prefix={validate ? <img src={RightLogo} alt="right" /> : ""}
              />
            </Form.Item>
            {passwordNotMatch ? (
              <Row align={"middle"} gutter={8} className="alertMsg">
                <Col>
                  <img src={AlertImg} alt="alert" />
                </Col>
                <Col>
                  <span>Password doesn’t match.</span>
                </Col>
              </Row>
            ) : (
              ""
            )}
          </Col>
        </Row>
        {progressPercentage !== 100 && (
          <Row>
            <div className="errorDescBox">
              Your password must contain at least:{" "}
              <span>
                8 Characters, 1 Upper Case Letter, 1 Lower Case Letter, 1 Number
              </span>
            </div>
          </Row>
        )}

        <Row>
          <Col span={24}>
            <Form.Item>
              <Button
                htmlType="submit"
                style={{ width: "100%" }}
                className="submitBtn mt-42"
                disabled={!validate}
              >
                Change Password
                <div className="arrowBtn">
                  <img src={RightArrow} alt="img" />
                </div>
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
}

export default NewPassword;
