import React, { useState, useEffect } from "react";
import OtpInput from "react-otp-input";
import { Row, Col, Form, Button, message } from "antd";
import API_MANAGER from "../../../API";
import RightArrow from "../../../Assets/login/rightArrow.svg";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
const OTP = ({
  email,
  setOtpVerified,
  token,
  setLoading,
  isLogin,
  handleOtpSubmit,
}) => {
  const [seconds, setSeconds] = useState(60);
  const [otp, setOtp] = useState("");
  const [otpBtnDisabled, setOtpBtnDisabled] = useState(true);
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const handleChange = (newValue) => {
    if (newValue.length == 6) {
      setOtpBtnDisabled(false);
    } else {
      setOtpBtnDisabled(true);
    }
    setOtp(newValue);
  };
  const resendOTP = async () => {
    if (isLogin) {
      try {
        await API_MANAGER.lastLoginOtp(token);
      } catch (error) {
        message.error("Something went wrong. Please try again.");
      }
    } else {
      try {
        setLoading(true);
        setSeconds(60);
        const response = await API_MANAGER.forgotPassword({ email: email });

        setLoading(false);
        message.success("OTP sent to your mail.");
      } catch (err) {
        if (err?.response?.data?.message?.message) {
          message.warning(err?.response?.data?.message?.message);
        } else {
          message.warning("Something went wrong, please try again!");
        }

        setLoading(false);
      }
    }
  };
  const handleSubmit = async (values) => {
    if (isLogin) {
      handleOtpSubmit(otp);
    } else {
      try {
        setLoading(true);
        const response = await API_MANAGER.verifyEmailOtp(otp, token);
        setLoading(false);
        setOtpVerified(true);
      } catch (err) {
        message.error("Please enter valid otp!");
        setLoading(false);
      }
    }
  };
  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [seconds]);

  return (
    <div>
      <Form form={form} onFinish={(values) => handleSubmit(values)}>
        <Row>
          <Col span={24}>
            <Form.Item name="otp">
              <OtpInput
                value={otp}
                onChange={handleChange}
                numInputs={6}
                shouldAutoFocus={true}
                isInputNum={true}
                inputStyle="otpInput"
                containerStyle="otpContainer"
                renderSeparator={<span>-</span>}
                renderInput={(props) => <input {...props} />}
                inputType="tel"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col className="justify_space" span={24}>
            <span
              onClick={seconds === 0 ? resendOTP : null}
              className={
                seconds === 0 ? "resendText" : "resendText cursor_wait"
              }
            >
              Resend Code
            </span>
            {seconds > 0 && (
              <span style={{ color: "white" }}>in {seconds} seconds</span>
            )}
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item>
              <Button
                className="submitBtn w-100 mt-60"
                htmlType="submit"
                disabled={otpBtnDisabled}
              >
                Verify
                <div className="arrowBtn">
                  <img src={RightArrow} alt="img" />
                </div>
              </Button>
            </Form.Item>
          </Col>
        </Row>
        <Row justify={"center"}>
          <Col>
            <p className="spamText">
              Did not receive the mail? Check your <span>Spam folder.</span>
            </p>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default OTP;
