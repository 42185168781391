import React, { useState, useEffect } from "react";
import SubLayout from "../../components/layout/SubLayout";
import { Col, Row, message } from "antd";
import newsbanner from "../../Assets/news/newsDetailsbanner.jpeg";
import TrendingNewsCard from "../../components/common/news/TrendingNewsCard";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import API_MANAGER from "../../API";
import moment from "moment";
import FullScreenLoader from "../../components/common/FullScreenLoader";
import ShareNews from "../../components/common/news/ShareNews";
import AlertState from "../../components/common/AlertState";
const NewsDetails = () => {
  const { id } = useParams();
  const [data, setData] = useState(null);
  const [trendingData, setTrendingData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertState, setAlertState] = useState("");
  const [isLiked, setIsLiked] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const BASE_URL = "https://cinchy.me";

  const getData = async (liked) => {
    if (!liked) setLoading(true);
    try {
      const response = await API_MANAGER.getNewsDetail(id);
      setData(response?.data?.data);
      setIsLiked(response?.data?.data?.hasUserLiked);
      if (!liked) setLoading(false);
    } catch (error) {
      if (!liked) setLoading(false);
      message.error("something went wrong");
    }
  };
  const getTrendingData = async () => {
    try {
      const response = await API_MANAGER.getNewsFeed();
      const temp = response?.data?.data?.weekly.filter((e) => e?.id !== id);

      setTrendingData(temp);
    } catch (error) {
      message.error("Something went wrong. Please try again.");
    }
  };
  const likeNews = async () => {
    setIsLiked(!isLiked);
    try {
      await API_MANAGER.likeNews(id);
      getData(true);
    } catch (error) {
      message.error("Something went wrong. Please try again.");
    }
  };
  useEffect(() => {
    getData();
    getTrendingData();
  }, [id]);
  /*share news modal function*/

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const shareNews = async (users) => {
    const body = {
      newsUrl: `${BASE_URL + location?.pathname}`,
      users,
    };
    try {
      await API_MANAGER.shareNews(body);
      setIsModalOpen(false);
      message.success("Article successfully shared.");
    } catch (error) {
      message.error("Something went wrong. Please try again.");
    }
  };
  return (
    <>
      <SubLayout
        padding={"padding"}
        page={"News detail"}
        showBack
        whiteBg
        showSearch
      >
        <FullScreenLoader isLoading={loading} />
        <AlertState
          state={alertState}
          message={alertMessage}
          alertOpen={alertOpen}
          setAlertOpen={setAlertOpen}
        />
        <Col className="newsDetailsMainContainer">
          <Row>
            <Col
              md={0}
              xs={24}
              className="sm-back-heading"
              onClick={() => navigate(-1)}
            >
              {"< Latest News & updates"}
            </Col>
          </Row>
          {data?.image && (
            <Row className="newsBanner">
              <img
                src={data?.image}
                alt="banner-image"
                className="newsDetails-banner"
              />
            </Row>
          )}

          <Col span={24} className="newsHeading">
            {data?.heading}
          </Col>
          <Row align={"middle"} className="newsDateDetail">
            {/* date will be here */}
            <span>
              &nbsp; &#x2022; &nbsp;
              {moment(data?.createdAt).format("DD MMMM YYYY").toUpperCase()}
              &nbsp; &nbsp;
            </span>
            {/* like button svg */}
            <span onClick={() => likeNews()} className="cursor-pointer">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill={isLiked ? "#F9CF66" : "#fff"}
              >
                <path
                  d="M7 22V11M2 13V20C2 21.1046 2.89543 22 4 22H17.4262C18.907 22 20.1662 20.9197 20.3914 19.4562L21.4683 12.4562C21.7479 10.6389 20.3418 9 18.5032 9H15C14.4477 9 14 8.55228 14 8V4.46584C14 3.10399 12.896 2 11.5342 2C11.2093 2 10.915 2.1913 10.7831 2.48812L7.26394 10.4061C7.10344 10.7673 6.74532 11 6.35013 11H4C2.89543 11 2 11.8954 2 13Z"
                  stroke={data?.hasUserLiked ? "#F9CF66" : "#091647"}
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </span>
            {/* share svg icon */}
            <span onClick={showModal} className="cursor-pointer">
              &nbsp; &nbsp;
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M21.2914 12.6062C21.5355 12.397 21.6575 12.2924 21.7023 12.1679C21.7415 12.0586 21.7415 11.9391 21.7023 11.8298C21.6575 11.7053 21.5355 11.6007 21.2914 11.3914L12.8206 4.1308C12.4004 3.7706 12.1903 3.5905 12.0124 3.58609C11.8578 3.58226 11.7101 3.65018 11.6124 3.77006C11.5 3.90799 11.5 4.18473 11.5 4.7382V9.03346C9.36532 9.40691 7.41159 10.4886 5.95971 12.1127C4.37682 13.8833 3.50123 16.1748 3.5 18.5498V19.1618C4.54934 17.8977 5.85951 16.8753 7.34076 16.1647C8.6467 15.5383 10.0584 15.1671 11.5 15.0694V19.2595C11.5 19.8129 11.5 20.0897 11.6124 20.2276C11.7101 20.3475 11.8578 20.4154 12.0124 20.4116C12.1903 20.4072 12.4004 20.2271 12.8206 19.8669L21.2914 12.6062Z"
                  stroke="#091647"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </span>
          </Row>
          <Row justify={"space-between"}>
            <Col xs={24} md={15} lg={17} className="backendData">
              <div dangerouslySetInnerHTML={{ __html: data?.summary }} />
            </Col>
            {/* trending news section */}
            <Col xs={0} md={8} lg={6}>
              <div className="trending-news-container">
                <h3 className="heading-h3">Trending News</h3>
                {trendingData?.map((item, index) => (
                  <TrendingNewsCard item={item} />
                ))}
              </div>
            </Col>
          </Row>
          <Row>
            <Col style={{ padding: "30px 0px 20px 0px" }} xs={24} md={0}>
              <h3 className="heading-h3">Trending </h3>
            </Col>
          </Row>
          <Col
            xs={24}
            md={0}
            className="newsMainContainer"
            style={{ width: "95vh" }}
          >
            {/* trending news carousel for mobile screen */}

            <Carousel
              additionalTransfrom={0}
              arrows={true}
              autoPlaySpeed={3000}
              centerMode={false}
              className=""
              containerClass="container-padding-bottom"
              dotListClass=""
              draggable
              focusOnSelect={false}
              infinite={false}
              itemClass=""
              keyBoardControl
              minimumTouchDrag={80}
              pauseOnHover
              renderArrowsWhenDisabled={false}
              renderButtonGroupOutside={false}
              renderDotsOutside={false}
              responsive={{
                desktop: {
                  breakpoint: {
                    max: 3000,
                    min: 1024,
                  },
                  items: 3,
                  partialVisibilityGutter: 40,
                },
                mobile: {
                  breakpoint: {
                    max: 464,
                    min: 0,
                  },
                  items: 1,
                  partialVisibilityGutter: 30,
                },
                tablet: {
                  breakpoint: {
                    max: 1024,
                    min: 464,
                  },
                  items: 2,
                  partialVisibilityGutter: 30,
                },
              }}
              rewind={false}
              rewindWithAnimation={false}
              rtl={false}
              shouldResetAutoplay
              showDots={false}
              sliderClass=""
              slidesToSlide={1}
              swipeable
            >
              {trendingData?.map((item, index) => (
                <TrendingNewsCard item={item} />
              ))}
            </Carousel>
          </Col>
        </Col>
        {/* share news modal */}
        <ShareNews
          shareNews={shareNews}
          showModal={showModal}
          handleCancel={handleCancel}
          isModalOpen={isModalOpen}
        />
      </SubLayout>
    </>
  );
};

export default NewsDetails;
