import React, { useEffect, useState } from "react";
import { Row, Col, Form, Input, Button, message, Checkbox } from "antd";
import RightArrow from "../../Assets/login/rightArrow.svg";
import { Link, useNavigate } from "react-router-dom";
import API_MANAGER from "../../API";
import Cookies from "js-cookie";
import LoaderGif from "../../Assets/loader.gif";
import LoginImg from "../../Assets/login/welcome.png";
import Logo from "../../Assets/login/Logo.svg";
import OTP from "../../components/common/verifyEmailOtp/OTP";
function Login() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const [email, setEmail] = useState("");
  const [isOtp, setIsOtp] = useState(false);
  const [token, setToken] = useState(null);
  const [response, setResponse] = useState();
  const [form] = Form.useForm();
  const lastLoginOtp = async (token) => {
    try {
      await API_MANAGER.lastLoginOtp(token);
      setIsOtp(true);
      setLoading(false);
    } catch (error) {
      message.error("Something went wrong. Please try again.");
    }
  };
  const handleOtpSubmit = async (otp) => {
    try {
      const res = await API_MANAGER.verifyLoginOtp(
        {
          otp: otp,
        },
        token
      );
      let expireTime = new Date().getTime() + 3 * 3600 * 1000;
      Cookies.set("access_token", token, {
        expires: expireTime,
      });
      localStorage.setItem("expireTime", expireTime);
      if (
        response?.data?.data?.user?.subscription &&
        response?.data?.data?.user?.subscription?.length > 0
      ) {
        if (response?.data?.data?.user?.recruiterUserType === "Standard") {
          if (response?.data?.data?.user?.permissions?.includes(0)) {
            navigate(`/campaign-management`);
          } else if (response?.data?.data?.user?.permissions?.includes(3)) {
            navigate(`/user-management`);
          }
        } else if (
          response?.data?.data?.user?.recruiterUserType === "Associate"
        ) {
          navigate(`/job-advert`);
        } else if (
          response?.data?.data?.user?.recruiterUserType === " " ||
          response?.data?.data?.user?.recruiterUserType === "Admin" ||
          response?.data?.data?.user?.recruiter
        ) {
          navigate(`/campaign-management`);
        }
      } else {
        navigate("/onboarding");
      }
    } catch (error) {
      message.error(
        error?.response?.data?.message?.message ||
        "Something went wrong. Please try again."
      );
    }
  };
  const LoginUser = async (values) => {
    setEmail(values?.email);
    if (rememberMe) {
      localStorage.setItem("email", values?.email);
      localStorage.setItem("password", values?.password);
    } else {
      localStorage.removeItem("email");
      localStorage.removeItem("password");
    }
    try {
      setLoading(true);
      const response = await API_MANAGER.login(values);
      setResponse(response);
      if (response) {
        localStorage.setItem(
          "name",
          response?.data?.data?.user?.firstName +
          " " +
          response?.data?.data?.user?.lastName
        );
      }
      let expireTime = new Date().getTime() + 3 * 3600 * 1000;
      localStorage.setItem("expireTime", expireTime);
      if (response?.data?.data?.user?.subscription?.[0]?.sp?.plusSubscription) {
        localStorage.setItem(
          "isPlusSubscription",
          response?.data?.data?.user?.subscription?.[0]?.sp?.plusSubscription
        );
      } else {
        localStorage.setItem("isPlusSubscription", false);
      }
      localStorage.setItem(
        "clientId",
        response?.data?.data?.user?.recruiter?._id
      );
      localStorage.setItem("userID", response?.data?.data?.user?.id);
      localStorage.setItem("userEmail", response?.data?.data?.user?.email);
      localStorage.setItem("createdAt", response?.data?.data?.user?.createdAt);
      const userType = response?.data?.data?.user?.recruiterUserType
        ? response?.data?.data?.user?.recruiterUserType !== "Standard"
          ? response?.data?.data?.user?.recruiterUserType
          : "Account"
        : "Root";
      localStorage.setItem("user", userType);

      if (
        response?.data?.data?.user?.subscription &&
        response?.data?.data?.user?.subscription?.length > 0
      ) {
        localStorage.setItem(
          "subscription",
          JSON.stringify(response?.data?.data?.user?.subscription[0])
        );
      } else {
        localStorage.setItem("subscription", JSON.stringify({}));
      }
      if (
        response?.data?.data?.user?.permissions &&
        response?.data?.data?.user?.permissions?.length > 0
      ) {
        localStorage.setItem(
          "permissions",
          JSON.stringify(response?.data?.data?.user?.permissions)
        );
      } else {
        localStorage.setItem("permissions", JSON.stringify([0, 1, 2, 3, 4, 5]));
      }

      if (response?.data?.data?.isOtp) {
        setToken(response?.data?.data?.accessToken);
        lastLoginOtp(response?.data?.data?.accessToken);
      } else {
        localStorage.setItem("expireTime", expireTime);
        Cookies.set("access_token", response?.data?.data?.accessToken, {
          expires: expireTime,
        });
        if (
          response?.data?.data?.user?.subscription &&
          response?.data?.data?.user?.subscription?.length > 0 &&
          response?.data?.data?.user?.subscription[0]?.active
        ) {
          if (response?.data?.data?.user?.recruiterUserType === "Standard") {
            if (response?.data?.data?.user?.permissions?.includes(0)) {
              navigate(`/campaign-management`);
            } else if (response?.data?.data?.user?.permissions?.includes(3)) {
              navigate(`/user-management`);
            }
          } else if (
            response?.data?.data?.user?.recruiterUserType === "Associate"
          ) {
            navigate(`/job-advert`);
          } else if (
            response?.data?.data?.user?.recruiterUserType === " " ||
            response?.data?.data?.user?.recruiterUserType === "Admin" ||
            response?.data?.data?.user?.recruiter
          ) {
            navigate(`/campaign-management`);
          }
        } else {
          navigate("/onboarding");
        }
        setLoading(false);
      }
    } catch (err) {
      if (err?.response?.data?.message?.message) {
        message.warning(err?.response?.data?.message?.message);
      } else {
        message.error("Something went wrong. Please try again.");
      }
      setLoading(false);
    }
  };
  useEffect(() => {
    const Email = localStorage.getItem("email");
    const Password = localStorage.getItem("password");
    if (Email && Password) {
      form.setFieldsValue({
        email: Email,
        password: Password,
      });
    }
  }, []);
  return (
    <>
      {loading ? (
        <div className="loaderContainer">
          <img src={LoaderGif} alt="loader image" />
        </div>
      ) : (
        <div className="login_main_container">
          <Row className=" w-100 h-100" justify={"space-between"}>
            {/* Login content section */}

            <Col xs={24} md={15}>
              <div className="login_container">
                <div className="logo_container">
                  <Link to="/">
                    <img
                      src={Logo}
                      onClick={() => navigate("/")}
                      className="company_logo"
                    />
                  </Link>
                </div>
                <div className="login_form_container">
                  {isOtp ? (
                    <div>
                      <div className="w-100">
                        <div className="forgotHeader">
                          <h3 className="forgotHeading">Verify Account</h3>
                          {email && (
                            <p className="desc">
                              We have sent you a code on <b>{email}</b>.
                            </p>
                          )}
                        </div>
                      </div>
                      <OTP
                        email={email}
                        token={token}
                        isLogin={true}
                        handleOtpSubmit={handleOtpSubmit}
                      />
                    </div>
                  ) : (
                    <Form onFinish={LoginUser} form={form} className="w-100">
                      <div>
                        <h3 className="heading">Login</h3>
                      </div>
                      <Row>
                        <Col span={24}>
                          <Form.Item
                            label="Email*"
                            name="email"
                            rules={[
                              {
                                required: true,
                                message: "Please input your email!",
                              },
                              {
                                type: "email",
                                message: "Please enter correct email!",
                              },
                            ]}
                          >
                            <Input
                              type="email"
                              className="w-100 emailInput text-sm"
                              placeholder="Johndoe@abc.com"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={24} className="text-sm">
                          <Form.Item
                            name="password"
                            label="Password*"
                            rules={[
                              {
                                required: true,
                                message: "Please input your password!",
                              },
                            ]}
                          >
                            <Input.Password
                              placeholder="Abc@1234"
                              type="password"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row justify={"space-between"} align={"middle"}>
                        <Col>
                          <Checkbox
                            className="text-sm remember-me"
                            onChange={(e) => setRememberMe(e?.target?.checked)}
                          >
                            Remember me
                          </Checkbox>
                        </Col>
                        <Col
                          className="forgotPassword cursor-pointer text-sm"
                          onClick={() => navigate("/forgot-password")}
                        >
                          Forgot password?
                        </Col>
                      </Row>
                      <Row>
                        <Col span={24}>
                          <Form.Item>
                            <Button
                              htmlType="submit"
                              style={{ width: "100%" }}
                              className="submitBtn mt-60"
                            >
                              Login
                              <div className="arrowBtn">
                                <img src={RightArrow} alt="img" />
                              </div>
                            </Button>
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row justify={"center"}>
                        <p className="notAMember">
                          Not a member?{" "}
                          <span onClick={() => navigate("/signup")}>
                            Sign Up
                          </span>
                        </p>
                      </Row>
                    </Form>
                  )}
                </div>
              </div>
            </Col>
            {/* login image section */}
            <Col xs={0} md={9} className="loginImgContainer">
              <img src={LoginImg} alt="img" className="loginImg" />
            </Col>
          </Row>
        </div>
      )}
    </>
  );
}

export default Login;
