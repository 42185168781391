import "antd/dist/reset.css";
import "./styles/index.scss";
import CustomRoutes from "./Routes/Routes";
import ProtectedRoute from "./Routes/ProtectedRoute";
import Login from "./pages/login";
import ForgotPassword from "./pages/forgotpassword";
import SignUp from "./pages/signUp";
import Welcome from "./pages/Welcome/index";
import React, { useEffect, useState } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import OnBoardingForm from "./pages/OnBoardingForm/OnBoardingForm";
import Aboutus from "./pages/AboutUs/Aboutus";
import TermsOfUse from "./pages/TermsOfUse/TermsOfUse";
import ErrorPage from "./components/common/ErrorPage";
import Contactus from "./pages/ContactUs/Contactus";
import VerifyOtp from "./pages/veryOtp/VerifyOtp";
import { Provider } from "react-redux";
import store from "./store/store";
import PrivacyPolicy from "./pages/PrivacyPolicy/PrivacyPolicy";
import SetYourPassword from "./pages/setYourPassword";
import UserInvite from "./pages/userInvite";
import API_MANAGER from "./API";
import { IsTokenValid } from "./utils/middleware";
import { message } from "antd";
import NoInternetError from "./components/common/NoInternetError";
import SessionExpired from "./components/common/SessionExpired";

function App() {
  const [seconds, setSeconds] = useState(10800);
  const navigate = useNavigate();
  const getUserData = async () => {
    try {
      const response = await API_MANAGER.getLocalData();
      setSeconds(response?.data?.data?.remainingTime);
      if (response?.data?.data?.user && response?.data?.data?.recruiter) {
        localStorage.setItem(
          "name",
          response?.data?.data?.user?.firstName +
            " " +
            response?.data?.data?.user?.lastName
        );
        localStorage.setItem("userEmail", response?.data?.data?.user?.email);
        localStorage.setItem("userID", response?.data?.data?.user?.id);
        localStorage.setItem(
          "createdAt",
          response?.data?.data?.user?.createdAt
        );
        const userType = response?.data?.data?.user?.recruiterUserType
          ? response?.data?.data?.user?.recruiterUserType !== "Standard"
            ? response?.data?.data?.user?.recruiterUserType
            : "Account"
          : "Root";
        localStorage.setItem("user", userType);
        localStorage.setItem("clientId", response?.data?.data?.recruiter?._id);
        if (
          response?.data?.data?.recruiter?.subscription?.sp?.plusSubscription
        ) {
          localStorage.setItem(
            "isPlusSubscription",
            response?.data?.data?.recruiter?.subscription?.sp?.plusSubscription
          );
        } else {
          localStorage.setItem("isPlusSubscription", false);
        }
        console.log(
          response?.data?.data?.recruiter?.subscription,
          "response?.data?.data?.recruiter"
        );
        if (response?.data?.data?.recruiter?.subscription) {
          localStorage.setItem(
            "subscription",
            JSON.stringify(response?.data?.data?.recruiter?.subscription)
          );
        } else {
          localStorage.setItem("subscription", JSON.stringify({}));
        }
      }
      if (
        response?.data?.data?.user?.permissions &&
        response?.data?.data?.user?.permissions?.length > 0
      ) {
        localStorage.setItem(
          "permissions",
          JSON.stringify(response?.data?.data?.user?.permissions)
        );
      }
    } catch (error) {
      console.log("Something went wrong!");
    }
  };
  useEffect(() => {
    localStorage.setItem("moreOption", "close");
  }, []);
  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds < 1) {
        clearInterval(interval);
        const Email = localStorage.getItem("email");
        const Password = localStorage.getItem("password");
        localStorage.clear();
        if (Email && Password) {
          localStorage.setItem("email", Email);
          localStorage.setItem("password", Password);
        }
        localStorage.setItem("session", "Yes");
        // navigate(`/login`);
        return;
      } else {
        setSeconds((prevSeconds) => prevSeconds - 1);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [seconds]);
  useEffect(() => {
    const isValidToken = IsTokenValid();
    let subscription = localStorage.getItem("subscription");
    if (subscription) {
      subscription = JSON.parse(subscription);
    }
    if (isValidToken && subscription?.active) getUserData();
  }, []);
  // const minutes = Math.floor(seconds / 60);
  // const remainingSeconds = seconds % 60;
  const networkError = localStorage.getItem("network");
  const sessionExpired = localStorage.getItem("session");
  return (
    <Provider store={store}>
      <div
        className={`App ${(networkError || sessionExpired) && "app_container"}`}
      >
        {networkError && networkError === "Yes" ? <NoInternetError /> : ""}
        {sessionExpired && sessionExpired === "Yes" ? <SessionExpired /> : ""}
        <Routes>
          <Route path="/" element={<Welcome />} />
          <Route path="/about-us" element={<Aboutus />} />
          <Route path="/terms-of-use" element={<TermsOfUse />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/contact-us" element={<Contactus />} />
          <Route path="/verifyOtp" element={<VerifyOtp />} />

          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/set-your-password" element={<SetYourPassword />} />
          <Route path="/user-invite" element={<UserInvite />} />
          <Route element={<ProtectedRoute />}>
            <Route path="/onboarding" element={<OnBoardingForm />} />
            <Route path="/*" element={<CustomRoutes />} />
          </Route>
          <Route path="/404" element={<ErrorPage />} />
          <Route path="*" element={<ErrorPage />} />
        </Routes>
      </div>
    </Provider>
  );
}

export default App;
