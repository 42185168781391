import { Col, Row, Tabs } from "antd";
import { useEffect, useState } from "react";
import HardRequirementSection from "./HardRequirementSection";
import SoftRequirementSection from "./SoftRequirementSection";
import SubLayout from "../../../components/layout/SubLayout";
import { pickList_phone } from "../../../utils/constant";

const AddRequirementPhone = ({ onSubmit }) => {
  const [defaultTab, setDefaultTab] = useState(0);
  const [finalData, setFinalData] = useState();
  const [hardData, setHardData] = useState({});
  const [softData, setSoftData] = useState({});
  const [selectedTagsData, setSelectedTagsData] = useState([]);
  const [checkSelectedTags, setCheckSelectedTags] = useState([]);
  const [tagData, setTagData] = useState();
  const [loading, setLoading] = useState(false);
  const [checkTagItem, setCheckTagItem] = useState([]);
  const [tempHardData, setTempHardData] = useState({});
  //SOFT REQUIREMENT
  const [softSelectedTagsData, setSoftSelectedTagsData] = useState([]);
  const [softCheckSelectedTags, setSoftCheckSelectedTags] = useState([]);
  const [softTagData, setSoftTagData] = useState();
  const [softCheckTagItem, setSoftCheckTagItem] = useState([]);
  const [tempSoftData, setTempSoftData] = useState({});
  const handlePostAdvert = () => {
    let hardTemp = selectedTagsData.filter((item) => item?.mode === "");
    let softTemp = softSelectedTagsData.filter((item) => item?.mode === "");

    if (hardTemp.length !== 0) {
      hardTemp.forEach((obj) => (hardData[obj.key] = []));
      // hardData[hardTemp] = [];
    }
    if (softTemp.length !== 0) {
      softTemp.forEach((obj) => (softData[obj.key] = []));
    }
    let finalobject = {};
    finalobject["hardRequirements"] = hardData;
    finalobject["softRequirements"] = softData;

    setFinalData(finalobject);

    onSubmit(finalobject);
  };
  const tabs = [
    {
      key: 0,
      title: "Hard Requirement",
    },
    {
      key: 1,
      title: "Soft Requirement",
    },
  ];
  const handleTabClick = (key) => {
    setDefaultTab(key);
  };

  return (
    <div className="HardSoftRequirement_container">
      {/* Tabs Section */}
      <div className="tab_main_div">
        <Row style={{ padding: "8px" }}>
          {tabs.map((item, index) => (
            <Col
              xs={12}
              onClick={() => handleTabClick(item?.key)}
              className={
                index === defaultTab ? "selectedTab" : "nonSelectedTab"
              }
            >
              <span>{item?.title}</span>
            </Col>
          ))}
        </Row>
      </div>

      {/* Content Main Section */}
      {defaultTab === 0 ? (
        <HardRequirementSection
          pickList={pickList_phone}
          hardData={hardData}
          setHardData={setHardData}
          softData={softData}
          handlePostAdvert={handlePostAdvert}
          selectedTagsData={selectedTagsData}
          setSelectedTagsData={setSelectedTagsData}
          checkSelectedTags={checkSelectedTags}
          setCheckSelectedTags={setCheckSelectedTags}
          tagData={tagData}
          setTagData={setTagData}
          loading={loading}
          setLoading={setLoading}
          checkTagItem={checkTagItem}
          setCheckTagItem={setCheckTagItem}
          tempHardData={tempHardData}
          setTempHardData={setTempHardData}
          softSelectedTagsData={softSelectedTagsData}
        />
      ) : (
        <SoftRequirementSection
          pickList={pickList_phone}
          softData={softData}
          setSoftData={setSoftData}
          handlePostAdvert={handlePostAdvert}
          hardData={hardData}
          softSelectedTagsData={softSelectedTagsData}
          setSoftSelectedTagsData={setSoftSelectedTagsData}
          softCheckSelectedTags={softCheckSelectedTags}
          setSoftCheckSelectedTags={setSoftCheckSelectedTags}
          softTagData={softTagData}
          setSoftTagData={setSoftTagData}
          softCheckTagItem={softCheckTagItem}
          setSoftCheckTagItem={setSoftCheckTagItem}
          tempSoftData={tempSoftData}
          setTempSoftData={setTempSoftData}
          loading={loading}
          setLoading={setLoading}
          selectedTagsData={selectedTagsData}
        />
      )}
    </div>
  );
};
export default AddRequirementPhone;
