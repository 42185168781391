import React, { useState, useEffect } from "react";
import { Row, Col, Input, message } from "antd";
import { useNavigate } from "react-router-dom";
import LeftArrow from "../../Assets/leftArrow.svg";
import CancelIcon from "../../Assets/cancelIcon.svg";
import SearchModal from "../common/search/SearchModal";
import SearchIcon from "../../Assets/user_mangement/searchIcon.svg";

import API_MANAGER from "../../API";
function MobileHeader({ showSearch, title }) {
  const navigate = useNavigate();
  const [searchedData, setSearchedData] = useState({});
  const [searchText, setSearchText] = useState(null);
  const [isSearchModalOpen, setIsSearchModalOpen] = useState(false);
  const [searchType, setSearchType] = useState("all");
  const GetGlobalSearch = async (value) => {
    setSearchedData({});
    try {
      const param = {
        search: searchText,
        limit: 10,
        type: searchType,
      };
      const response = await API_MANAGER.getGlobalSearch(param);
      setSearchedData(response?.data?.data);
    } catch (err) {
      message.warning("Something went wrong");
    }
  };
  useEffect(() => {
    if (searchText) {
      GetGlobalSearch();
    }
  }, [searchType]);
  return (
    <div className="mobileHeaderContainer">
      <Row align={"middle"} className="mb-20">
        <Col>
          <img src={LeftArrow} alt="<" onClick={() => navigate(-1)} />
        </Col>
        <Col className="mobileTitle">{title}</Col>
      </Row>
      {showSearch && (
        <Row className="mb-20">
          <Col span={24}>
            <Input
              className="header_input_box_search mobileSearchHeader"
              placeholder="Search Name, Project or Discipline"
              onChange={(e) => setSearchText(e.target.value)}
              onPressEnter={(e) => {
                GetGlobalSearch();
                setIsSearchModalOpen(true);
              }}
              value={searchText}
              prefix={<img src={SearchIcon} className="search_icon" />}
              suffix={
                searchText?.length !== 0 && (
                  <img
                    className="cursor_pointer"
                    src={CancelIcon}
                    onClick={() => setSearchText("")}
                  />
                )
              }
            />
          </Col>
        </Row>
      )}
      <SearchModal
        searchType={searchType}
        setSearchType={setSearchType}
        isSearchModalOpen={isSearchModalOpen}
        setIsSearchModalOpen={setIsSearchModalOpen}
        searchedData={searchedData}
        searchText={searchText}
        centered={true}
      />
    </div>
  );
}

export default MobileHeader;
