import React from "react";
import { DragablleIcon } from "../../../Assets/campagin/sectionProgress/svgs";

function RequirementItem({ title, dragging }) {
  return (
    <div className={`${dragging == true ? "requirement-common-item active_border" : "requirement-common-item"}`}>
      <DragablleIcon />
      <p className={`${dragging == true ? "active" : "inactive"}`}>{title}</p>
    </div>
  );
}

export default RequirementItem;
