import { Button, Col, Row, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import ContactIcon from "../../../Assets/susbcription_mangement/user-icons-black-color.svg";
import plusIcon from "../../../Assets/susbcription_mangement/plus.svg";
import minusIcon from "../../../Assets/susbcription_mangement/minus.svg";
import { InfoCircleOutlined } from "@ant-design/icons";
import API_MANAGER from "../../../API";
import { numberWithCommas } from "../../../utils/constant";
function Adduser({
  setcurrentForm,
  setprogress,
  setStatus,
  setAddUserData,
  addUserData,
  data,
  userData,
}) {
  const [selectedItem, setselectedItem] = useState(addUserData["account"]);
  const [selectedItemAssociation, setselectedItemAssociation] = useState(
    addUserData["association"]
  );
  useEffect(() => {
    addUserData["account"] = selectedItem;
  }, [selectedItem]);
  useEffect(() => {
    addUserData["association"] = selectedItemAssociation;
  }, [selectedItemAssociation]);

  const handleNext = async () => {
    try {
      const params = {
        accountUser: selectedItem,
        associateUser: selectedItemAssociation,
      };
      const response = await API_MANAGER.postAdditionalUsers(params);
      setcurrentForm("pay");
      setStatus((prev) => ({
        ...prev,
        user: "sucess",
        pay: "sucess",
      }));
      setprogress((prev) => ({
        ...prev,
        third: 100,
      }));
    } catch (error) {}
  };
  return (
    <div className="choose_plan_container">
      <Row gutter={[0, 16]}>
        <Col span={24} className="justify-center d-flex addUserPlanContainer">
          <h2 className="h2">Add additional Users</h2>
        </Col>
        <Col span={24} className="justify-center d-flex">
          <p className="heading para-desc">
            Select addition users, if you require more users than your chosen
            subscription plan.
          </p>
        </Col>
      </Row>
      {data?.standard_users > 0 && (
        <Row
          className="subscription_card mt-24"
          align={"middle"}
          gutter={[0, 10]}
        >
          <Col md={14} xs={24} className="gap-10 align-center">
            <img
              className="contact_icon"
              src={ContactIcon}
              alt="contact icon"
            />

            <p>Additional Account Users</p>
            <Tooltip
              className="cursor_pointer"
              title="Account Users can set up recruitment campaigns, post job adverts, manage your subscription and other users, depending on the permissions you assign them."
            >
              <InfoCircleOutlined />
            </Tooltip>
          </Col>
          <Col md={5} xs={24} className="card_margin_left">
            <h4>
              $
              {numberWithCommas(
                data?.additional_standard_user_price
                  ? data?.additional_standard_user_price
                  : 0
              )}
            </h4>
            <span>/user</span>
          </Col>
          <Col md={5} xs={24} className="item_adder card_margin_left">
            <img
              onClick={() =>
                selectedItem !== 0 ? setselectedItem((prev) => prev - 1) : ""
              }
              className={`${selectedItem != 0 ? "pointer" : ""}`}
              alt="minus icon"
              src={minusIcon}
            />
            <h5>{selectedItem}</h5>
            <img
              onClick={() => setselectedItem((prev) => prev + 1)}
              className="pointer"
              alt="plus icon"
              src={plusIcon}
            />
          </Col>
        </Row>
      )}
      {data?.association_users > 0 && (
        <Row
          className="subscription_card mt-24"
          align={"middle"}
          gutter={[0, 10]}
        >
          <Col md={14} xs={24} className="gap-10 align-center">
            <img
              className="contact_icon"
              src={ContactIcon}
              alt="contact icon"
            />
            <p>Additional Association Users</p>
            <Tooltip
              className="cursor_pointer"
              title="Association User is assigned to external partners and clients. The Association User has permission to view candidates and to approve candidates for specific adverts. The Association User does NOT have full access to the platform and only has permission see the campaigns and adverts assigned to them by either Standard Users or Admin Users."
            >
              <InfoCircleOutlined />
            </Tooltip>
          </Col>
          <Col md={5} xs={24} className="card_margin_left">
            <h4>
              $
              {numberWithCommas(
                data?.additional_association_user_price
                  ? data?.additional_association_user_price
                  : 0
              )}
            </h4>
            <span>/user</span>
          </Col>
          <Col md={5} xs={24} className="item_adder card_margin_left">
            <img
              onClick={() =>
                selectedItemAssociation > 0
                  ? setselectedItemAssociation((prev) => prev - 1)
                  : ""
              }
              alt="minus icon"
              className={`${selectedItemAssociation > 0 ? "pointer" : ""}`}
              src={minusIcon}
            />
            <h5>{selectedItemAssociation}</h5>

            <img
              onClick={() => setselectedItemAssociation((prev) => prev + 1)}
              className="pointer"
              alt="plus icon"
              src={plusIcon}
            />
          </Col>
        </Row>
      )}

      <Row className="mt-24" gutter={[0, 24]}>
        <Col span={24} className="justify-center d-flex">
          <Button
            onClick={() => {
              handleNext();
            }}
            className="standardBtn"
          >
            Next
          </Button>
        </Col>
      </Row>
    </div>
  );
}

export default Adduser;
