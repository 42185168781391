import { Col, Image, Row } from "antd";
import targetIcon from "../../../Assets/campagin/target.svg";
import Icon1 from "../../../Assets/campagin/cards/icon1.svg";

const CountCards = ({ data }) => {
  return (
    <div className="countCardsMainDiv">
      <Row justify={"space-between"}>
        <Col xs={24} lg={9} className="countCard campaignCardOne lg-mb-20">
          <Row justify={"space-between"} align={"middle"} className="p-20 h-100">
            <Col xs={12}>
              <Row>
                <Col span={24} className="mb-16">
                  <Row align={"middle"} gutter={8}>
                    <Col>
                      <Image
                        src={targetIcon}
                        preview={false}
                        height={34}
                        width={34}
                      />
                    </Col>
                    <Col xs={0} md={20}>
                      <span className="heading_title">Target Headcount</span>
                    </Col>
                    <Col xs={16} md={0}>
                      <p className="amount">{data?.targetHeadcount}</p>

                      {/* <p className="heading_title">Target Headcount</p> */}
                    </Col>
                  </Row>
                </Col>
                <Col span={24}>
                  <Col xs={0} md={20}>
                    <p className="amount">{data?.targetHeadcount}</p>
                  </Col>{" "}
                  <Col xs={20} md={0}>
                    <span className="heading_title">Target Headcount</span>
                  </Col>
                </Col>
              </Row>
            </Col>
            <Col xs={11} className="total_accepted_col align-center-justify h-100">
              <Row>
                <Col xs={24} align={"center"}>
                  <span className="main_amount">
                    {data?.accepted}/{data?.targetHeadcount}
                  </span>
                </Col>
                <Col xs={24} align={"center"}>
                  <span className="heading_title_inner">Total Accepted</span>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col xs={24} lg={14} className="campaignCardOne countCardSecond">
          <Row className="p-20 h-100" justify={"space-between"}>
            <Col
              xs={7}
              className="second_section_inner_card align-center-justify"
            >
              <Row>
                <Col xs={24} align={"center"}>
                  <Image preview={false} height={32} width={32} src={Icon1} />
                </Col>
                <Col xs={24} align={"center"}>
                  <span className="main_amount">{data?.applied}</span>
                </Col>
                <Col xs={24} align={"center"}>
                  <span className="heading_title_inner">Applied</span>
                </Col>
              </Row>
            </Col>
            <Col
              xs={7}
              className="second_section_inner_card align-center-justify"
            >
              <Row>
                <Col xs={24} align={"center"}>
                  <Image preview={false} height={32} width={32} src={Icon1} />
                </Col>

                <Col xs={24} align={"center"}>
                  <span className="main_amount">{data?.hardReview}</span>
                </Col>
                <Col xs={24} align={"center"}>
                  <span className="heading_title_inner">Hard Review</span>
                </Col>
              </Row>
            </Col>
            <Col
              xs={7}
              className="second_section_inner_card align-center-justify"
            >
              <Row>
                <Col xs={24} align={"center"}>
                  <Image preview={false} height={32} width={32} src={Icon1} />
                </Col>

                <Col xs={24} align={"center"}>
                  <span className="main_amount">{data?.roleOffered}</span>
                </Col>
                <Col xs={24} align={"center"}>
                  <span className="heading_title_inner">Role Offered</span>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};
export default CountCards;
