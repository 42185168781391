import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Form,
  Input,
  Button,
  message,
  Checkbox,
  Progress,
} from "antd";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import LoginImg from "../../Assets/login/welcome.png";
import Cookies from "js-cookie";
import Logo from "../../Assets/login/Logo.svg";
import RightLogo from "../../Assets/login/right.svg";
import AlertImg from "../../Assets/login/alert.svg";
import RightArrow from "../../Assets/login/rightArrow.svg";
import API_MANAGER from "../../API";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
function SetYourPassword() {
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [progressPercentage, setProgressPercentage] = useState(0);
  const [value, setValue] = useState("+61");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [validate, setValidate] = useState(false);
  const [passwordNotMatch, setPasswordNotMatch] = useState(false);
  const [form] = Form.useForm();
  const [isOtp, setIsOtp] = useState(false);
  const [token, setToken] = useState(null);
  const [response, setResponse] = useState();
  const [data, setData] = useState({});
  /************  Password Validation Logic===============*/
  const validatePassword = (e) => {
    setProgressPercentage(0);
    if (e?.match(/[A-Z]/) || e?.match(/[a-z]/) || e?.match(/[1-9]/)) {
      setProgressPercentage(25);
    }
    if (
      (e?.match(/[a-z]/) && e?.match(/[A-Z]/)) ||
      (e?.match(/[a-z]/) && e?.match(/[1-9]/)) ||
      (e?.match(/[1-9]/) && e?.match(/[A-Z]/))
    ) {
      setProgressPercentage(50);
    }
    if (e?.match(/[1-9]/) && e?.match(/[a-z]/) && e?.match(/[A-Z]/)) {
      setProgressPercentage(75);
    }
    if (
      e?.length >= 8 &&
      e?.match(/[1-9]/) &&
      e?.match(/[a-z]/) &&
      e?.match(/[A-Z]/)
    ) {
      setProgressPercentage(100);
    }
  };
  const lastLoginOtp = async (token) => {
    try {
      await API_MANAGER.lastLoginOtp(token);
      setIsOtp(true);
      setLoading(false);
    } catch (error) {
      message.error("Something went wrong. Please try again.");
    }
  };
  /************ to confirm both password are same or not ===============*/
  useEffect(() => {
    if (password && confirmPassword && password === confirmPassword) {
      setValidate(true);
      setPasswordNotMatch(false);
    } else if (password && confirmPassword && password !== confirmPassword) {
      setPasswordNotMatch(true);
      setValidate(false);
    } else {
      setValidate(false);
      setPasswordNotMatch(false);
    }
  }, [password, confirmPassword]);
  /************ calling api for signup ===============*/
  const handelSignUp = async (e) => {
    const params = {
      password: e?.password,
      confirmPassword: e?.confirmPassword,
    };
    if (password === confirmPassword) {
      try {
        setLoading(true);
        const resp = await API_MANAGER.setYourPassword(
          params,
          location.search.split("?token=")[1]
        );
        setLoading(false);
        message.success("Password successfully generated.");
        const response = await API_MANAGER.login({
          email: form.getFieldValue("email"),
          password: e?.password,
        });
        if (response) {
          localStorage.setItem(
            "name",
            response?.data?.data?.user?.firstName +
              " " +
              response?.data?.data?.user?.lastName
          );
        }
        let expireTime = new Date().getTime() + 3 * 3600 * 1000;
        localStorage.setItem("expireTime", expireTime);
        if (
          response?.data?.data?.user?.subscription?.[0]?.sp?.plusSubscription
        ) {
          localStorage.setItem(
            "isPlusSubscription",
            response?.data?.data?.user?.subscription?.[0]?.sp?.plusSubscription
          );
        } else {
          localStorage.setItem("isPlusSubscription", false);
        }
        localStorage.setItem(
          "clientId",
          response?.data?.data?.user?.recruiter?._id
        );
        localStorage.setItem("userID", response?.data?.data?.user?.id);
        localStorage.setItem("userEmail", response?.data?.data?.user?.email);
        localStorage.setItem(
          "createdAt",
          response?.data?.data?.user?.createdAt
        );
        const userType = response?.data?.data?.user?.recruiterUserType
          ? response?.data?.data?.user?.recruiterUserType !== "Standard"
            ? response?.data?.data?.user?.recruiterUserType
            : "Account"
          : "Root";
        localStorage.setItem("user", userType);

        if (
          response?.data?.data?.user?.subscription &&
          response?.data?.data?.user?.subscription?.length > 0
        ) {
          localStorage.setItem(
            "subscription",
            JSON.stringify(response?.data?.data?.user?.subscription[0])
          );
        } else {
          localStorage.setItem("subscription", JSON.stringify({}));
        }
        if (
          response?.data?.data?.user?.permissions &&
          response?.data?.data?.user?.permissions?.length > 0
        ) {
          localStorage.setItem(
            "permissions",
            JSON.stringify(response?.data?.data?.user?.permissions)
          );
        } else {
          localStorage.setItem(
            "permissions",
            JSON.stringify([0, 1, 2, 3, 4, 5])
          );
        }

        localStorage.setItem("expireTime", expireTime);
        Cookies.set("access_token", response?.data?.data?.accessToken, {
          expires: expireTime,
        });
        if (
          response?.data?.data?.user?.subscription &&
          response?.data?.data?.user?.subscription?.length > 0
        ) {
          if (response?.data?.data?.user?.recruiterUserType === "Standard") {
            if (response?.data?.data?.user?.permissions?.includes(0)) {
              navigate(`/campaign-management`);
            } else if (response?.data?.data?.user?.permissions?.includes(3)) {
              navigate(`/user-management`);
            }
          } else if (
            response?.data?.data?.user?.recruiterUserType === "Associate"
          ) {
            navigate(`/job-advert`);
          } else if (
            response?.data?.data?.user?.recruiterUserType === " " ||
            response?.data?.data?.user?.recruiterUserType === "Admin" ||
            response?.data?.data?.user?.recruiter
          ) {
            navigate(`/campaign-management`);
          }
        } else {
          navigate("/onboarding");
        }
        setLoading(false);
      } catch (err) {
        setLoading(false);
        message.error(
          err?.response?.data?.message?.message ||
            "Something went wrong. Please try again."
        );
      }
    } else {
    }
  };

  const getUserData = async () => {
    try {
      const response = await API_MANAGER.getUserDetail(
        location.search.split("?token=")[1]
      );
      const tempData = response?.data?.data;
      setData(response?.data?.data);
      form.setFieldsValue({
        firstName: tempData?.firstName,
        lastName: tempData?.lastName,
        email: tempData?.email,
        phoneNumber: tempData?.phoneNumber,
        countryCode: `${tempData?.countryCode}`,
      });
    } catch (error) {
      message.error("Something went wrong. Please try again.");
    }
  };
  useEffect(() => {
    getUserData();
  }, [location.search.split("?token=")[1]]);
  return (
    <>
      <div className="signup_main_container">
        <Row className=" w-100" justify={"space-between"}>
          <Col xs={24} md={15}>
            <div className="login_container">
              <div className="logo_container">
                <Link to="/campaign-management">
                  <img
                    src={Logo}
                    onClick={() => navigate("/campaign-management")}
                    className="company_logo"
                  />
                </Link>
              </div>
              <div className="login_form_container">
                <Form
                  form={form}
                  onFinish={(e) => handelSignUp(e)}
                  className="w-100"
                >
                  <div>
                    <h3 className="heading">Set your Password</h3>
                  </div>
                  {/* first name & last name section */}
                  <Row gutter={{ xs: 0, md: 24 }}>
                    <Col xs={24} md={12}>
                      <Form.Item
                        label="First Name*"
                        name="firstName"
                        rules={[
                          {
                            required: true,
                            message: "Please input your first name!",
                          },
                        ]}
                      >
                        <Input
                          className="w-100 emailInput"
                          placeholder="John"
                          disabled
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} md={12}>
                      <Form.Item
                        label="Last Name*"
                        name="lastName"
                        rules={[
                          {
                            required: true,
                            message: "Please input your last name!",
                          },
                        ]}
                      >
                        <Input
                          className="w-100 emailInput"
                          disabled
                          placeholder="Doe"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  {/* user email */}
                  <Row>
                    <Col span={24}>
                      <Form.Item
                        label="Email*"
                        name="email"
                        rules={[
                          {
                            required: true,
                            message: "Please input your email!",
                          },
                          {
                            type: "email",
                            message: "Please enter correct email!",
                          },
                        ]}
                      >
                        <Input
                          autoComplete="off"
                          type="email"
                          className="w-100 emailInput"
                          placeholder="abc@gmail.com"
                          disabled
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  {/* user mobile number */}
                  <Row>
                    <label className="custome_label">Contact Number*</label>

                    <Col xs={7} sm={6}>
                      <Form.Item
                        name="countryCode"
                        rules={[
                          {
                            required: true,
                            message: "Please input your country code!",
                          },
                        ]}
                      >
                        {/* components from other library */}
                        <PhoneInput
                          international
                          countryCallingCodeEditable={false}
                          className="w-100 emailInput PhoneInput"
                          defaultCountry="AU"
                          value={value}
                          disabled
                          onChange={setValue}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={17} sm={18}>
                      <Form.Item
                        name="phoneNumber"
                        rules={[
                          {
                            required: true,
                            message: "Please input your phone number!",
                          },
                        ]}
                      >
                        <Input
                          placeholder="phone number"
                          type="phone"
                          disabled
                          className="w-100 emailInput phoneNumberInput"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  {/* user password */}
                  <Row>
                    <Col span={24}>
                      <Form.Item
                        name="password"
                        label="Password*"
                        rules={[
                          {
                            required: true,
                            message: "Please input your password!",
                          },
                        ]}
                      >
                        <Input.Password
                          autoComplete="off"
                          placeholder="Password"
                          type="password"
                          value={password}
                          onChange={(e) => {
                            validatePassword(e?.target?.value);
                            setPassword(e?.target?.value);
                          }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  {/*=============== progress bar and password verification message ====================*/}
                  <Row>
                    {progressPercentage ? (
                      <Row align={"middle"} gutter={16}>
                        <Col
                          className="progressbar_margin"
                          style={{
                            width: "209px",
                          }}
                        >
                          <Progress
                            percent={progressPercentage}
                            className="formProgressBar"
                            showInfo={false}
                          />
                        </Col>
                        <Col className="progressbar_margin">
                          {progressPercentage < 100 ? (
                            <span className="weakText">Weak</span>
                          ) : (
                            <span className="strongText">Strong</span>
                          )}
                        </Col>
                      </Row>
                    ) : (
                      ""
                    )}
                  </Row>
                  <div>
                    {progressPercentage > 0 && progressPercentage !== 100 && (
                      <Row>
                        <div className="errorDescBox">
                          Your password must contain at least:{" "}
                          <span>
                            8 Characters, 1 Upper Case Letter, 1 Lower Case
                            Letter, 1 Number
                          </span>
                        </div>
                      </Row>
                    )}
                  </div>

                  {/* confirm password */}
                  <Row>
                    <Col span={24}>
                      <Form.Item
                        name="confirmPassword"
                        label="Confirm Password*"
                        // dependencies={["password"]}
                        // hasFeedback
                        rules={[
                          {
                            required: true,
                            message: "Please confirm your password!",
                          },
                        ]}
                      >
                        <Input.Password
                          autoComplete="off"
                          placeholder="Confirm Password"
                          type="password"
                          onChange={(e) => setConfirmPassword(e?.target?.value)}
                          prefix={
                            validate ? <img src={RightLogo} alt="right" /> : ""
                          }
                        />
                      </Form.Item>
                      {passwordNotMatch ? (
                        <Row align={"middle"} gutter={8} className="alertMsg">
                          <Col>
                            <img src={AlertImg} alt="alert" />
                          </Col>
                          <Col>
                            <span>Password doesn’t match.</span>
                          </Col>
                        </Row>
                      ) : (
                        ""
                      )}
                    </Col>
                  </Row>
                  <Row className="mb-20">
                    <Col>
                      <Form.Item
                        name="isTermsAccepted"
                        valuePropName="checked"
                        rules={[
                          {
                            required: true,
                            message:
                              "Please check terms and conditions and privacy policy to proceed!",
                          },
                        ]}
                      >
                        <Checkbox checked={true} className="termsCheckBox">
                          I accept the{" "}
                          <Link to="/terms-of-use" target="_blank">
                            <span className="span-text">
                              Terms and Conditions
                            </span>
                          </Link>{" "}
                          and <br></br>
                          <Link to="/privacy-policy" target="_blank">
                            <span className="span-text">Privacy Policy</span>{" "}
                          </Link>
                          of Cinchy.me.
                        </Checkbox>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <Form.Item>
                        <Button
                          htmlType="submit"
                          style={{ width: "100%" }}
                          className="submitBtn mt-30"
                          loading={loading}
                        >
                          Sign Up
                          <div className="arrowBtn">
                            <img src={RightArrow} alt="img" />
                          </div>
                        </Button>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row justify={"center"}>
                    <p className="notAMember">
                      Already a member?{" "}
                      <span onClick={() => navigate("/login")}>Login</span>
                    </p>
                  </Row>
                </Form>
              </div>
            </div>
          </Col>
          <Col xs={0} md={9} className="loginImgContainer">
            <img src={LoginImg} alt="img" className="loginImg" />
          </Col>
        </Row>
      </div>
    </>
  );
}

export default SetYourPassword;
