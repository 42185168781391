import React, { useState, useEffect } from "react";
import OtpInput from "react-otp-input";
import { Row, Col, Form, Button, message } from "antd";
import API_MANAGER from "../../../API";
import RightArrow from "../../../Assets/login/rightArrow.svg";
const OTP = ({ email, setOtpVerified, setToken }) => {
  const [createPassword, setCreatePassword] = useState(false);
  const [seconds, setSeconds] = useState(60);
  const [otp, setOtp] = React.useState("");
  const [loading, setLoading] = useState(false);
  const [otpBtnDisabled, setOtpBtnDisabled] = useState(true);
  const [form] = Form.useForm();
  const handleChange = (newValue) => {
    if (newValue.length == 6) {
      setOtpBtnDisabled(false);
    } else {
      setOtpBtnDisabled(true);
    }
    setOtp(newValue);
  };
  const resendOTP = async () => {
    setSeconds(60);
    try {
      setLoading(true);
      const response = await API_MANAGER.forgotPassword({ email: email });

      setLoading(false);
      message.success("OTP sent to your mail.");
    } catch (err) {
      if (err?.response?.data?.message?.message) {
        message.warning(err?.response?.data?.message?.message);
      } else {
        message.warning("Something went wrong, please try again!");
      }

      setLoading(false);
    }
  };
  const handleSubmit = async (values) => {
    try {
      const response = await API_MANAGER.verifyOtp({ email: email, otp: otp });
      setOtpVerified(true);
      setToken(response?.data?.data);
    } catch (err) {
      message.error("Please enter valid otp!");
    }
  };
  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [seconds]);
  return (
    <div>
      <Form form={form} onFinish={(values) => handleSubmit(values)}>
        <Row justify={"center"}>
          <Col span={24}>
            <Form.Item name="otp">
              {/* type="tel" pattern="[0-9]{10}" */}
              <OtpInput
                value={otp}
                onChange={handleChange}
                numInputs={6}
                shouldAutoFocus={true}
                isInputNum={true}
                inputStyle="otpInput"
                inputType="tel"
                type=""
                containerStyle="otpContainer"
                renderSeparator={<span>-</span>}
                renderInput={(props) => <input {...props} />}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <span
              onClick={seconds === 0 ? resendOTP : null}
              className={
                seconds === 0 ? "resendText " : "resendText cursor_wait"
              }
            >
              Resend code
            </span>
          </Col>
          {seconds > 0 && (
            <Col span={12} align={"right"}>
              <span style={{ color: "white" }}>{seconds} Seconds</span>
            </Col>
          )}
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item>
              <Button
                className="submitBtn w-100 mt-60"
                htmlType="submit"
                disabled={otpBtnDisabled}
              >
                Verify
                <div className="arrowBtn">
                  <img src={RightArrow} alt="img" />
                </div>
              </Button>
            </Form.Item>
          </Col>
        </Row>
        <Row justify={"center"}>
          <Col>
            <p className="spamText">
              Did not receive the mail? Check your <span>Spam folder.</span>
            </p>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default OTP;
