import { Avatar, Col, Modal, Row } from "antd";
import YellowImage from "../../Assets/campagin/yellow_circle.svg";
import YellowImageHalf from "../../Assets/campagin/yellow_circle_half.svg";
import LeftIcon from "../../Assets/campagin/leftIcon.svg";
import SaveIcon from "../../Assets/campagin/save.svg";
import ShareIcon from "../../Assets/campagin/share.svg";
import LocationIcon from "../../Assets/campagin/location.svg";
import EmploymentIcon from "../../Assets/campagin/EmploymentIcon.svg";
import HoursIcon from "../../Assets/campagin/HoursIcon.svg";
import IndustryIcon from "../../Assets/campagin/IndustryIcon.svg";
import JobDurationIcon from "../../Assets/campagin/JobDurationIcon.svg";
import Roster from "../../Assets/campagin/Roster.svg";
import Salary from "../../Assets/campagin/Salary.svg";
import Discipline from "../../Assets/campagin/Discipline.svg";
import CloseTimerIcon from "../../Assets/campagin/CloseIcon.svg";
import { numberWithCommas } from "../../utils/constant";
import { UserOutlined } from "@ant-design/icons";

import dayjs from "dayjs";

const PhoneViewModal = ({
  phoneViewData,
  phoneModal,
  setPhoneModal,
  campaignName,
}) => {
  const handleOk = () => {
    setPhoneModal(false);
  };
  const handleCancel = () => {
    setPhoneModal(false);
  };
  return (
    <Modal
      centered
      footer={false}
      className="PhoneViewModalMainContainer"
      open={phoneModal}
      onOk={handleOk}
      closable={false}
      onCancel={handleCancel}
    >
      <div className="phone_modal_main_content_row">
        <img alt="icon" src={YellowImage} className="yellow_icon" />
        <img alt="icon" src={YellowImageHalf} className="yellow_icon_half" />

        <Row className="titleMainRow">
          <Col xs={1}>
            <img alt="icon" src={LeftIcon} />
          </Col>
          <Col xs={23} align={"center"}>
            <span>Job Description</span>
          </Col>
        </Row>
        <div className="content_main_div">
          <Row className="content_row">
            <Col xs={20}>
              <Row justify={"space-between"}>
                <Col xs={4} align={"center"} className="align-center-justify ">
                  {" "}
                  <Avatar size={"large"} className="campaign-avatar">
                    {phoneViewData?.campaignName?.charAt(0)?.toUpperCase()}
                  </Avatar>
                </Col>
                <Col xs={19}>
                  <p className="Advert_title">
                    {phoneViewData?.jobTitle?.label || "---"}
                  </p>
                  <p className="Advert_Desc">{campaignName || "---"}</p>
                </Col>
              </Row>
            </Col>
            <Col xs={4}>
              <img alt="icon" src={SaveIcon} />
              <img alt="icon" src={ShareIcon} />
            </Col>
          </Row>
          <Row
            className="location_row align-center-justify "
            justify={"space-between"}
          >
            <Col xs={2}>
              <img alt="icon" src={LocationIcon} />
            </Col>
            <Col xs={21}>
              <span className="location_text">
                {phoneViewData?.suburb +
                  ", " +
                  phoneViewData?.state?.label +
                  ", " +
                  phoneViewData?.postalCode}
              </span>
            </Col>
          </Row>

          {/* Status Section */}
          <div className="status_main_div">
            <div className="status_inner_div">
              <Row>
                <Col span={12}>
                  <Row justify={"space-between"}>
                    <Col xs={4}>
                      <img alt="icon" src={EmploymentIcon} />
                    </Col>
                    <Col xs={18}>
                      <p className="title_heading">Employment Status</p>
                      <p className="title_desc">
                        {phoneViewData?.employmentType
                          ? phoneViewData?.employmentType?.label
                          : "---"}
                      </p>
                    </Col>
                  </Row>
                </Col>
                <Col span={12}>
                  <Row justify={"space-between"}>
                    <Col xs={5}>
                      <img alt="icon" src={Salary} />
                    </Col>
                    <Col xs={18}>
                      <p className="title_heading">Salary/Rate</p>
                      <p className="title_desc">
                        <p className="detail align-text-end-sm">
                          {phoneViewData?.maxSalary &&
                          phoneViewData?.minSalary !== phoneViewData?.maxSalary
                            ? `$${
                                phoneViewData?.minSalary
                                  ? numberWithCommas(
                                      phoneViewData?.minSalary
                                        ?.toString()
                                        ?.includes(".")
                                        ? parseFloat(
                                            phoneViewData?.minSalary
                                          )?.toFixed(2)
                                        : phoneViewData?.minSalary
                                    )
                                  : 0
                              }-$${
                                phoneViewData?.maxSalary
                                  ? numberWithCommas(
                                      phoneViewData?.maxSalary
                                        ?.toString()
                                        ?.includes(".")
                                        ? parseFloat(
                                            phoneViewData?.maxSalary
                                          )?.toFixed(2)
                                        : phoneViewData?.maxSalary
                                    )
                                  : 0
                              }`
                            : `$${
                                phoneViewData?.minSalary
                                  ? numberWithCommas(
                                      phoneViewData?.minSalary
                                        ?.toString()
                                        ?.includes(".")
                                        ? parseFloat(
                                            phoneViewData?.minSalary
                                          )?.toFixed(2)
                                        : phoneViewData?.minSalary
                                    )
                                  : 0
                              }`}
                        </p>
                      </p>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row className="mt-20">
                <Col span={12}>
                  <Row justify={"space-between"}>
                    <Col xs={5}>
                      <img alt="icon" src={Roster} />
                    </Col>
                    <Col xs={18}>
                      <p className="title_heading">Roster</p>
                      <p className="title_desc">
                        {phoneViewData?.roster?.label || "---"}
                      </p>
                    </Col>
                  </Row>
                </Col>
                <Col span={12}>
                  <Row justify={"space-between"}>
                    <Col xs={5}>
                      <img alt="icon" src={HoursIcon} />
                    </Col>
                    <Col xs={18}>
                      <p className="title_heading">Hours</p>
                      <p className="title_desc">
                        {phoneViewData?.hours || "---"}
                      </p>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row className="mt-20">
                <Col span={12}>
                  <Row justify={"space-between"}>
                    <Col xs={5}>
                      <img alt="icon" src={IndustryIcon} />
                    </Col>
                    <Col xs={18}>
                      <p className="title_heading">Industry</p>
                      <p className="title_desc">
                        {phoneViewData?.industry?.label || "---"}
                      </p>
                    </Col>
                  </Row>
                </Col>
                <Col span={12}>
                  <Row justify={"space-between"}>
                    <Col xs={5}>
                      <img alt="icon" src={Discipline} />
                    </Col>
                    <Col xs={18}>
                      <p className="title_heading">Discipline</p>
                      <p className="title_desc">
                        {phoneViewData?.discipline?.label || "---"}
                      </p>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row className="mt-20">
                <Col span={12}>
                  <Row justify={"space-between"}>
                    <Col xs={5}>
                      <img alt="icon" src={JobDurationIcon} />
                    </Col>
                    <Col xs={18}>
                      <p className="title_heading">Job Duration</p>
                      <p className="title_desc">
                        {phoneViewData?.jobDuration?.label || "---"}
                      </p>
                    </Col>
                  </Row>
                </Col>
                <Col span={12}>
                  <Row justify={"space-between"}>
                    <Col xs={5}>
                      <img alt="icon" src={CloseTimerIcon} />
                    </Col>
                    <Col xs={18}>
                      <p className="title_heading">Close date</p>
                      <p className="title_desc">
                        {phoneViewData?.endDate
                          ? dayjs(phoneViewData?.endDate).format("DD MMM YYYY")
                          : "---"}
                      </p>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </div>
          {/* Job Description Section */}
          <Row className="mt-20 job_description_main_row">
            <Col xs={24} className="job_Desc_title">
              Job Description
            </Col>
            <Col xs={24} className="job_Desc_content">
              {phoneViewData?.jobDescription || "---"}
            </Col>
          </Row>
        </div>
      </div>

      {/* Content Section */}
    </Modal>
  );
};
export default PhoneViewModal;
