import React, { useEffect, useState } from "react";
import SubLayout from "../../components/layout/SubLayout";
import {
  Button,
  Col,
  Dropdown,
  Input,
  Radio,
  Row,
  Switch,
  Table,
  Tooltip,
  message,
} from "antd";
import SearchIcon from "../../Assets/campagin/searchIcon.svg";
import FilterIcon from "../../Assets/filter.svg";
import actionIcon from "../../Assets/user_mangement/action.svg";
import { useLocation, useNavigate } from "react-router-dom";
import DeleteIcon from "../../Assets/advert_detail/action-delete.svg";
import edit_icon from "../../Assets/user_mangement/edit_icon.svg";
import edit from "../../Assets/user_mangement/edit.svg";

import mail from "../../Assets/user_mangement/mail.svg";
import NewsFeedCarousel from "../../components/common/NewsFeedCarousel";
import userIcon from "../../Assets/user_double.svg";
import WarningIcon from "../../Assets/campagin/warning.svg";
import ResendMail from "./ResendMail";
import API_MANAGER from "../../API";
import AlertState from "../../components/common/AlertState";
import FullScreenLoader from "../../components/common/FullScreenLoader";
import CustomPagination from "../../components/common/CustomPagination";
import UsersExhaust from "./UsersExhaustModal";

function UserManagement() {
  const user = localStorage.getItem("user");
  const navigate = useNavigate();
  //Two types of subscription Normal -> standard  and Plus -> Recruitment
  const [selectedUser, setSelectedUser] = useState();
  const [ResendModal, SetResendModal] = useState(false);
  const [remainingUserCount, setRemainingUserCount] = useState();
  const [totalRemaining, setTotalRemaining] = useState(0);
  const [currentSubscription, setCurrentSubscription] = useState("Plus");
  const [userCount, setUserCount] = useState({});
  const [tableData, setTableData] = useState([]);
  const [alertState, setAlertState] = useState("");
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [subsData, setSubsData] = useState();
  const [subsUserCount, setSubsUserCount] = useState();
  const [accountUserCounter, setAccountUserCounter] = useState(0);
  const [associateUsersCounter, setAssociateCounter] = useState(0);
  const [searchUser, setSearchUser] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [userExhaustModal, setUserExhaustModal] = useState(false);
  const [sortType, setSortType] = useState("-createdAt");
  const location = useLocation();
  const newUserAdded = location?.state?.newUser;
  const profileUpdated = location?.state?.profileUpdated;
  const permissionsUpdated = location?.state?.permissionsUpdated;
  const getData = async () => {
    const param = {
      search: searchUser ? searchUser : "",
      sort: sortType ? sortType : "-isActive",
      page: currentPage,
    };
    setLoading(true);
    try {
      const response = await API_MANAGER.getRecruiterUserList(param);
      setTableData(response?.data?.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      message.error("Something went wrong. Please try again.");
    }
  };

  const activeUserData = async () => {
    try {
      const response = await API_MANAGER.activeUser();

      setRemainingUserCount(response?.data?.data?.remaining);
    } catch (error) {
      message.error("Something went wrong. Please try again.");
    }
  };

  useEffect(() => {
    let id;
    if (id) {
      clearTimeout(id);
    }
    id = setTimeout(() => {
      getData();
    }, 500);
    return () => {
      clearTimeout(id);
    };
  }, [searchUser, sortType]);
  useEffect(() => {
    activeUserData();
  }, []);
  const customLocale = {
    emptyText: "No Users", // Customize the "No Data" text here
  };
  const handleActionDropdown = (rowData) => {
    setSelectedUser(rowData);
  };
  const handleDeleteUser = () => {
    SetResendModal(true);
  };
  const handleSwitch = async (event, data, index) => {
    const body = {
      isActive: event,
    };
    try {
      await API_MANAGER.userStatusToggle(data?.user?.id, body);

      message.success("Status successfully updated.");
      getData();
      activeUserData();
    } catch (error) {
      // setAlertState("error");
      // setAlertMessage(
      //   "You don’t have any available user licence’s for this action. Please purchase an additional licence to activate this profile."
      // );
      // setAlertOpen(true);
      setUserExhaustModal(true);
    }
  };
  const handleSorting = async (e) => {
    if (e === "az") {
      let tempData = await tableData?.sort(function (a, b) {
        if (a?.user?.firstName < b?.user?.firstName) {
          return -1;
        }
        if (a?.user?.firstName > b?.user?.firstName) {
          return 1;
        }
        return 0;
      });
      setTableData([...tempData]);
    } else if (e === "za") {
      let tempData = await tableData?.sort(function (a, b) {
        if (a?.user?.firstName > b?.user?.firstName) {
          return -1;
        }
        if (a?.user?.firstName < b?.user?.firstName) {
          return 1;
        }
        return 0;
      });
      setTableData([...tempData]);
    } else if (e === "LF") {
      let tempData = await tableData?.sort(function (a, b) {
        const x = new Date(a?.user?.updatedAt);
        const y = new Date(b?.user?.updatedAt);
        return y - x;
      });
      setTableData([...tempData]);
    } else if (e === "OF") {
      let tempData = await tableData?.sort(function (a, b) {
        const x = new Date(a?.user?.updatedAt);
        const y = new Date(b?.user?.updatedAt);
        return x - y;
      });
      setTableData([...tempData]);
    }
  };
  const items = [
    {
      key: "1",
      label: (
        <Radio.Group
          // value={sortType}
          onChange={(e) => handleSorting(e?.target?.value)}
          className="dropDown_radio_group"
        >
          <div className="">
            <Row className="filter_item_row">
              <Col span={24} className="">
                <Radio value={"LF"} className="radio_text">
                  Latest First
                </Radio>
              </Col>
            </Row>
            <Row className="filter_item_row">
              <Col span={24} className="">
                <Radio value={"OF"} className="radio_text">
                  Oldest First
                </Radio>
              </Col>
            </Row>
            <Row className="filter_item_row">
              <Col span={24} className="">
                <Radio value={"az"} className="radio_text">
                  Sort by A-Z
                </Radio>
              </Col>
            </Row>
            <Row className="filter_item_row">
              <Col span={24} className="">
                <Radio value={"za"} className="radio_text">
                  Sort by Z-A
                </Radio>
              </Col>
            </Row>
          </div>
        </Radio.Group>
      ),
    },
  ];
  const tableDropdown = [
    {
      key: "1",
      label: (
        <div className="table-dropdown">
          <Row>
            <Col
              span={24}
              className="column_hover padding_on_top "
              onClick={() =>
                navigate(`/user-management/${selectedUser?.id}`, {
                  state: selectedUser,
                })
              }
            >
              <div style={{ padding: "5px" }}>
                <img src={edit_icon} style={{ marginRight: "6px" }} />
                <span>Edit</span>
              </div>
            </Col>
            <Col
              span={24}
              className="column_hover padding_on_bottom"
              onClick={handleDeleteUser}
            >
              <div style={{ padding: "5px" }}>
                <img src={mail} style={{ marginRight: "6px" }} />
                <span>Resend Mail</span>
              </div>
            </Col>
          </Row>
        </div>
      ),
    },
  ];
  const columns = [
    {
      title: (
        <Row
          gutter={16}
          align={"middle"}
          className="d-flex wrap-unset align_center_start"
        >
          <Col className="campaign-table-heading gray_text">Name</Col>
          <Col>
            <Dropdown
              menu={{ items }}
              overlayClassName="notificationFilterDropdown"
            >
              <img src={FilterIcon} alt="filter" />
            </Dropdown>
          </Col>
        </Row>
      ),
      dataIndex: "firstName",
      width: "256px ",
      render: (item, row, index) => {
        return row?.user?.isVerified ? (
          <Row>
            <Col
              xs={24}
              className="cursor-pointer text_capitalize"
              style={{ wordBreak: "break-all" }}
              onClick={() =>
                navigate(`/user-management/${row.user?.id}`, { state: row })
              }
            >
              {row?.user?.firstName} {row?.user?.lastName}
            </Col>
            {/* <Col xs={4} style={{ justifyContent: "flex-end" }}>
              <Switch
                checked={row?.user?.isActive}
                onChange={(event) => handleSwitch(event, row, index)}
              />
            </Col> */}
          </Row>
        ) : (
          <Row>
            <Col
              xs={20}
              style={{ display: "flex" }}
              className="cursor-pointer"
              onClick={() =>
                navigate(`/user-management/${row?.user?.id}`, { state: row })
              }
            >
              <Tooltip
                title="Not signed up"
                className="cursor_pointer"
                placement="topLeft"
              >
                {" "}
                <img src={WarningIcon} />
              </Tooltip>
              <span style={{ marginLeft: "10px", overflow: "auto" }}>
                {row?.user?.firstName} {row?.user?.lastName}
              </span>
            </Col>
            {/* <Col xs={4} style={{ justifyContent: "flex-end" }}>
              <Switch
                checked={row?.user?.isActive}
                onChange={(event) => handleSwitch(event, row, index)}
              />
            </Col> */}
          </Row>
        );
      },
      fixed: "left",
    },
    {
      title: <div className="gray_text">Status</div>,
      dataIndex: "isActive",
      width: "126px",
      align: "center",
      render: (item, row) => (
        <Switch
          checked={row?.user?.isActive}
          onChange={(event) => handleSwitch(event, row)}
        />
      ),
    },
    // {
    //   title: <div className="gray_text">Status</div>,
    //   dataIndex: "kkkk",
    //   render: (item, rawData, index) => {
    //     return (
    //       <Switch
    //         checked={rawData?.user?.isActive}
    //         onChange={(event) => handleSwitch(event, rawData, index)}
    //       />
    //     );
    //   },
    //   align: "center",
    // },
    {
      title: (
        <div className="gray_text">
          User<br></br>Type
        </div>
      ),
      dataIndex: "recruiterUserType",
      width: "126px",
      render: (data, rawData) => {
        return (
          <span>
            {rawData?.user?.recruiterUserType === null
              ? "Admin"
              : rawData?.user?.recruiterUserType === "Standard"
                ? "Account"
                : rawData?.user?.recruiterUserType === "Associate"
                  ? "Association"
                  : rawData?.user?.recruiterUserType}
          </span>
        );
      },
      align: "center",
    },
    {
      title: (
        <div className="gray_text">
          Campaigns<br></br> Managing
        </div>
      ),
      width: "126px",
      dataIndex: "campaignCount",
      align: "center",
    },
    {
      title: (
        <div className="gray_text">
          Job Advert<br></br> managing
        </div>
      ),
      width: "126px",
      dataIndex: "advertCount",
      align: "center",
      render: (item) => <span>{item || 0}</span>,
    },
    {
      title: (
        <div className="gray_text">
          Candidates <br></br>Targeted
        </div>
      ),
      width: "126px",
      dataIndex: "target",
      align: "center",
      render: (item) => <span>{item || 0}</span>,
    },
    {
      title: (
        <div className="gray_text">
          Candidates<br></br> Shortlisted
        </div>
      ),
      width: "126px",
      dataIndex: "shortlisted",
      align: "center",
      render: (item) => <span>{item || 0}</span>,
    },
    {
      title: (
        <div className="gray_text">
          Candidates<br></br>Accepted
        </div>
      ),
      width: "126px",
      dataIndex: "accepted",
      align: "center",
      render: (item) => <span>{item || 0}</span>,
    },
    {
      title: "",
      dataIndex: "action",
      width: "126px",
      render: (item, row) => {
        if (row?.user?.isVerified) {
          return (
            <div className="cursor_pointer">
              <span
                style={{
                  padding: "8px",
                  backgroundColor: "#E3E9FC",
                  borderRadius: "8px",
                }}
              >
                <img
                  src={edit_icon}
                  style={{ width: "16px" }}
                  onClick={(e) =>
                    navigate(`/user-management/${row?.user?.id}`, {
                      state: row,
                    })
                  }
                />
              </span>
            </div>
          );
        } else {
          return (
            <div className="cursor_pointer">
              <Dropdown
                placement="bottomRight"
                trigger={["click"]}
                className="actionItems_dropdown"
                menu={{ items: tableDropdown }}
              >
                <img
                  src={actionIcon}
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    handleActionDropdown(row?.user);
                  }}
                />
              </Dropdown>
            </div>
          );
        }
      },
      width: 100,
    },
  ];

  //HANDLING SUBSCRIPTION DATA FOR USER COUNT
  useEffect(() => {
    const subscriptionData = JSON.parse(localStorage.getItem("subscription"));
    setSubsData(subscriptionData);
    let associateUsersCount = subscriptionData?.associateUser;
    let accountUsersCount = subscriptionData?.accountUser;
    const totalSubsUsers = accountUsersCount + associateUsersCount;
    setAccountUserCounter(accountUsersCount);
    setAssociateCounter(associateUsersCount);
    setSubsUserCount(totalSubsUsers);
  }, []);
  useEffect(() => {
    if (localStorage.getItem("isPlusSubscription")) {
      setUserCount({
        "Association User": associateUsersCounter,
        "Account Users": accountUserCounter,
        subs: "Plus",
        total: subsUserCount,
      });
    } else {
      setUserCount({
        "Account Users": accountUserCounter,
        subs: "Normal",
        total: subsUserCount,
      });
    }
  }, [
    currentSubscription,
    subsUserCount,
    associateUsersCounter,
    accountUserCounter,
  ]);
  useEffect(() => {
    if (remainingUserCount) {
      let total = 0;
      const keys = Object.keys(remainingUserCount);
      for (let i = 0; i < keys.length; i++) {
        const key = keys[i];
        const value = remainingUserCount[key];
        total += value;
      }
      setTotalRemaining(total);
    }
  }, [remainingUserCount]);
  return (
    <div className="user_management_container">
      <UsersExhaust
        userExhaustModal={userExhaustModal}
        setUserExhaustModal={setUserExhaustModal}
      />
      <AlertState
        state={alertState}
        message={alertMessage}
        alertOpen={alertOpen}
        setAlertOpen={setAlertOpen}
      />
      <SubLayout page={"User Management"} whiteBg showSearch>
        <FullScreenLoader isLoading={loading} />
        <ResendMail
          data={selectedUser}
          SetResendModal={SetResendModal}
          ResendModal={ResendModal}
        />

        <div className="landing_campaign_page">
          <NewsFeedCarousel />
        </div>
        <Row>
          <div className="user_management_table_box">
            <Table
              dataSource={tableData.slice((currentPage - 1) * 10, currentPage * 10)}
              columns={columns}
              // bordered
              locale={customLocale}
              pagination={false}
              scroll={{ x: "calc(30%)" }}
              title={() => (
                <Row gutter={16} align={"middle"} justify={"space-between"}>
                  <Col xs={24} md={12}>
                    <Row className="justify_space_between_resp">
                      <Col
                        xs={user === "Root" || user === "Admin" ? 17 : 24}
                        md={24}
                      >
                        <Input.Search
                          className="searchBox"
                          allowClear
                          placeholder="Search user name"
                          onChange={(e) => { setSearchUser(e?.target?.value); setCurrentPage(1) }}
                          prefix={<img src={SearchIcon} alt="search" />}
                        />
                      </Col>
                      {(user === "Root" || user === "Admin") && (
                        <Col xs={6} className="d-none">
                          <Tooltip
                            title={
                              <Row className="tooltip_user_management_row">
                                {/* <Col xs={24}>
                              <span>Title</span>
                            </Col> */}
                                <Col
                                  xs={24}
                                  className="align_center_start flex-col"
                                >
                                  {currentSubscription === "Plus" && (
                                    <div className="align_center_start">
                                      <div className="green_dot"></div>
                                      <span className="count_left">
                                        {userCount["Association User"]}{" "}
                                        Association user
                                      </span>
                                    </div>
                                  )}
                                  <div className="align_center_start">
                                    <div className="green_dot"></div>
                                    <span className="count_left">
                                      {userCount["Account Users"]} Account/Admin
                                      user
                                    </span>
                                  </div>
                                </Col>
                              </Row>
                            }
                          >
                            <div className="numberOfUsersDiv  cursor_pointer">
                              <img src={userIcon} />
                              <span>+{userCount?.total}</span>
                            </div>
                          </Tooltip>
                        </Col>
                      )}
                    </Row>
                  </Col>
                  {(user === "Root" ||
                    user === "Admin" ||
                    user === "Account") && (
                      <Col xs={24} md={12} align={"end"}>
                        <Row justify={"end"}>
                          <Col className="d_none_phone">
                            <Tooltip
                              title={
                                <Row className="tooltip_user_management_row">
                                  <Col xs={24}>
                                    <span>Remaining Users</span>
                                  </Col>
                                  <Col xs={24} className=" flex-col mt-8">
                                    {currentSubscription === "Plus" && (
                                      <div className="align_center_start">
                                        <div className="green_dot"></div>
                                        <span className="count_left">
                                          {remainingUserCount?.associate}{" "}
                                          Association user(s)
                                        </span>
                                      </div>
                                    )}
                                    <div className="align_center_start">
                                      <div className="green_dot"></div>
                                      <span className="count_left">
                                        {remainingUserCount?.account} Account
                                        user(s)
                                      </span>
                                    </div>
                                  </Col>
                                </Row>
                              }
                            >
                              <div className="numberOfUsersDiv cursor_pointer">
                                <img src={userIcon} />
                                <span>+{totalRemaining}</span>
                              </div>
                            </Tooltip>
                          </Col>
                          <Col className="d_none_phone">
                            {totalRemaining > 0 ? (
                              <Button
                                onClick={() =>
                                  navigate(`/user-management/add-user`, {
                                    state: remainingUserCount,
                                  })
                                }
                                className="createBtn"
                              >
                                + Add User
                              </Button>
                            ) : (
                              <Button
                                onClick={() =>
                                  navigate("/subscription-management")
                                }
                                className="createBtn"
                              >
                                + Buy User
                              </Button>
                            )}
                          </Col>
                        </Row>
                      </Col>
                    )}
                </Row>
              )}
            // onRow={(record, rowIndex) => {
            //   return {
            //     onClick: (event) => {
            //       navigate(`/user-management/${record.user?.id}`, {
            //         state: record,
            //       });
            //     }, // click row
            //     style: { cursor: "pointer" },
            //   };
            // }}
            />
            <CustomPagination
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              total={tableData?.length}
              itemPerPage={10}
            />
            <Row>
              <Col xs={24} className="d-none">
                {userCount?.total > 0 ? (
                  <Button
                    onClick={() => navigate("add-user")}
                    className="createBtn createBtn-sm"
                  >
                    + Add User
                  </Button>
                ) : (
                  <Button
                    onClick={() => navigate("/subscription-management")}
                    className="createBtn createBtn-sm"
                  >
                    + Buy User
                  </Button>
                )}
              </Col>
            </Row>
          </div>
        </Row>
      </SubLayout>
    </div>
  );
}

export default UserManagement;
