export const getFileNameFromUrl = (url) => {
  const splittedUrl = url?.split("/");
  if (splittedUrl.length >= 2) {
    return splittedUrl[splittedUrl.length - 2]?.split("_%").join(" ");
  } else {
    return splittedUrl.pop();
  }
};
export const getFileSizeFromUrl = async (url) => {
  try {
    const resp = await fetch(url);
    const docBody = await resp.blob();
    return `${(docBody?.size / (1024 * 1024)).toFixed(2)}`;
  } catch (e) {
    return "0";
  }
};
export const jobStatusEnum = [

  {
    title: "APPLIED",
    label: "APPLIED",
    key: 0,
  },
  {
    title: "ACCEPTED",
    label: "ACCEPTED",
    key: 1,
  },
  {
    title: "SHORTLIST_OFFERED",
    label: "SHORTLIST OFFERED",
    key: 2,
  },
  {
    title: "HARD_REVIEW",
    label: "HARD REVIEW",
    key: 3,
  },
  {
    title: "ROLE_OFFERED",
    label: "ROLE OFFERED",
    key: 4,
  },
  {
    title: "ROLE_ACCEPTED",
    label: "ROLE ACCEPTED",
    key: 5,
  },
  {
    title: "INVITED",
    label: "INVITED",
    key: -1,
  },
];
export function capitalizeWords(str) {
  // Split the input string into words
  const words = str.split(" ");

  // Iterate through the words and capitalize the first letter of each word
  const capitalizedWords = words.map((word) => {
    if (word.length > 0) {
      return word.charAt(0).toUpperCase() + word.slice(1);
    } else {
      return ""; // Handle empty words (e.g., multiple spaces)
    }
  });

  // Join the capitalized words back into a string
  return capitalizedWords.join(" ");
}

export function checkPermissions(array,userType) {
  let sum = 0;
  for (let i = 0; i < array?.length; i++) {
    sum += array[i];
  }
  const textArray = [];
  if (sum === 15) {
    textArray.push("Add/Edit/Delete Campaigns");
    textArray.push("Add/Edit/Delete Users");
    textArray.push("Subscription Management");
  } else if (sum === 13 && array.includes(0)) {
    textArray.push("Add/Edit/Delete Campaigns");
    textArray.push("Add/Edit/Delete Users");
  } else if (sum === 10 && userType !== 'Associate') {
    textArray.push("Add/Edit/Delete Campaigns");
  } else if (sum === 13) {
    textArray.push("Add/Edit/Delete Users");
  } else if (sum === 9 || userType === 'Associate') {
    textArray.push("Accept/Reject Candidate");
    textArray.push("Leave notes/comments");
  }
  return textArray;
}
