export const ValidRoutes = {
  Root: [
    "campaign-management",
    "campaign-detail",
    "create-advert",
    "advert-detail",
    "advert-tracker",
    "applicant-profile",
    "user-management",
    "subscription-management",
    "news-feed",
    "archive",
    "search",
    "myProfile",
    "edit-profile",
    "onboarding",
    "404",
  ],
  Admin: [
    "campaign-management",
    "campaign-detail",
    "create-advert",
    "advert-detail",
    "advert-tracker",
    "applicant-profile",
    "user-management",
    "subscription-management",
    "news-feed",
    "archive",
    "search",
    "myProfile",
    "edit-profile",
    "404",
  ],
  Associate: [
    "job-advert",
    "create-advert",
    "advert-detail",
    "advert-tracker",
    "applicant-profile",
    "news-feed",
    "search",
    "myProfile",
    "edit-profile",
    "404",
  ],
  Account: [
    "campaign-management",
    "campaign-detail",
    "create-advert",
    "advert-detail",
    "advert-tracker",
    "applicant-profile",
    "user-management",
    "news-feed",
    "archive",
    "search",
    "myProfile",
    "edit-profile",
    "404",
  ],
  AccountCampaign: [
    "campaign-management",
    "campaign-detail",
    "create-advert",
    "advert-detail",
    "applicant-profile",
    "advert-tracker",
    "news-feed",
    "archive",
    "search",
    "myProfile",
    "edit-profile",
    "404",
  ],
  AccountUser: [
    "user-management",
    "applicant-profile",
    "news-feed",
    "archive",
    "search",
    "myProfile",
    "edit-profile",
    "404",
  ],
};
