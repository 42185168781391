import axios from "axios";
// import fileDownload from "js-file-download";
import Cookies from "js-cookie";
import { message } from "antd";
import { navigate, useNavigate } from "react-router-dom";
import API_MANAGER from "../API";

const baseURL = "https://staging-recruiter.cinchy.me/";

let HELPERS = {
  log: (...args) => {
    if (process.env.NODE_ENV !== "production") {
    }
  },
  error: (...args) => {
    if (process.env.NODE_ENV !== "production") {
    }
  },
  getCookie: (name) => {
    let cookieValue = null;
    if (document.cookie && document.cookie !== "") {
      const cookies = document.cookie.split(";");
      for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim();
        // Does this cookie string begin with the name we want?
        if (cookie.substring(0, name.length + 1) === name + "=") {
          cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
          break;
        }
      }
    }
    return cookieValue;
  },

  request: (config) => {
    config.headers = config.headers ? config.headers : {};
    const csrfTokenEl = document.querySelector("[name=csrfmiddlewaretoken]");
    const csrfToken =
      HELPERS.getCookie("csrftoken") || (csrfTokenEl && csrfTokenEl.value);
    // if (csrfToken) {
    //   config.headers["X-CSRFToken"] = csrfToken;
    // }

    return axios
      .request(config)
      .then((response) => {
        localStorage.removeItem("session");
        localStorage.removeItem("network");
        return response;
      })
      .catch((err) => {
        console.log(err, "--------------------------------");
        if (err?.response?.status === 401) {
          const Email = localStorage.getItem("email");
          const Password = localStorage.getItem("password");
          localStorage.clear();
          if (Email && Password) {
            localStorage.setItem("email", Email);
            localStorage.setItem("password", Password);
          }
          // window.location.replace(`/login`, "_self");
          localStorage.removeItem("network");
          localStorage.setItem("session", "Yes");
        } else if (err?.code === "ERR_NETWORK") {
          console.log(err);
          localStorage.setItem("network", "Yes");
          localStorage.removeItem("session");
        } else {
          localStorage.removeItem("network");
          localStorage.removeItem("session");
          throw err;
        }
      });
  },
  secureRequest: async (config) => {
    config.headers = config.headers ? config.headers : {};
    let accessToken = Cookies.get("access_token");

    if (accessToken === undefined) {
      // const refreshToken = Cookies.get("refresh_token");
      // if (refreshToken === undefined) {
      //   window.open(`${baseURL}login`, "_self");
      //   return;
      // }
      // await HELPERS.getAccessToken(refreshToken);

      accessToken = Cookies.get("access_token");
    }
    config.headers["Authorization"] = `Bearer ${accessToken} `;
    const clientId = localStorage.getItem("clientId");
    if (clientId) config.headers["clientId"] = clientId;
    return HELPERS.request(config);
  },
  converToFormData: (obj, rootName, ignoreList) => {
    var formData = new FormData();
    function appendFormData(data, root) {
      if (!ignore(root)) {
        root = root || "";
        if (data instanceof File) {
          formData.append(root, data);
        } else if (Array.isArray(data)) {
          for (var i = 0; i < data.length; i++) {
            appendFormData(data[i], root + "[" + i + "]");
          }
        } else if (typeof data === "object" && data) {
          for (var key in data) {
            if (data.hasOwnProperty(key)) {
              if (root === "") {
                appendFormData(data[key], key);
              } else {
                appendFormData(data[key], root + "." + key);
              }
            }
          }
        } else {
          if (data !== null && typeof data !== "undefined") {
            formData.append(root, data);
          }
        }
      }
    }
    function ignore(root) {
      return (
        Array.isArray(ignoreList) &&
        ignoreList.some(function (x) {
          return x === root;
        })
      );
    }
    appendFormData(obj, rootName);
    return formData;
  },
  //   handleDownload: (url, filename) => {
  //     let type = url.split("?")[0];
  //     type = type.split(".");
  //     const name = filename + "." + type[type.length - 1];
  //     axios
  //       .get(url, {
  //         headers: {
  //           "Access-Control-Allow-Origin": "*",
  //           "Access-Control-Allow-Methods": "GET",
  //           "Content-Type": "application/json",
  //         },
  //         responseType: "blob",
  //       })
  //       .then((res) => {
  //         fileDownload(res.data, name);
  //       })
  //       .catch((err) => {
  //         message.warn("Something went wrong");
  //       });
  //   },
  debounce: (fn, wait) => {
    let timeout;
    return function () {
      const context = this,
        args = arguments;
      clearTimeout(timeout);
      timeout = setTimeout(function () {
        fn.apply(context, args);
      }, wait);
    };
  },
  //   downloadFile: (response) => {
  //     try {
  //       let fileName = null;
  //       const fieldToFind = "filename";
  //       const headerValues = response.headers["content-disposition"];
  //       if (headerValues && headerValues !== "") {
  //         const headerValuesArray = headerValues.split(";");
  //         for (let i = 0; i < headerValuesArray.length; i++) {
  //           const headerValue = headerValuesArray[i].trim();
  //           // Does this header value string begin with the name we want?
  //           if (
  //             headerValue.substring(0, fieldToFind.length + 1) ===
  //             fieldToFind + "="
  //           ) {
  //             fileName = headerValue.substring(fieldToFind.length + 1);
  //             break;
  //           }
  //         }
  //       }
  //       var blob = new Blob([response?.data]);
  //       fileDownload(blob, `${fileName}`);
  //     } catch (err) {
  //       message.warn("Something went wrong");
  //     }
  //   },
  deleteCookie: (name) => {
    document.cookie = name + "=; expires=Thu, 01 Jan 1970 00:00:01 GMT;";
  },
  getAccessToken: async (token) => {
    try {
      const parsedData = {
        refresh: token,
      };
      const response = await API_MANAGER.getAccessToken(parsedData);
      Cookies.set("access_token", response?.data?.access_token, {
        expires: 1 / 24,
      });
      Cookies.set("refresh_token", response?.data?.refresh_token, {
        expires: 4 / 24,
      });
    } catch (err) {
      message.error("Something went wrong. Please try again.");
    }
  },
};

export default HELPERS;
