import { Button, Col, Input, Row, message } from "antd";
import React, { useEffect, useState } from "react";
import calenderIcon from "../../../Assets/susbcription_mangement/calender.svg";
import contactIcon from "../../../Assets/susbcription_mangement/contact_pay.svg";
import deleteIcon from "../../../Assets/susbcription_mangement/delete.svg";
import plusIcon from "../../../Assets/susbcription_mangement/plus.svg";
import minusIcon from "../../../Assets/susbcription_mangement/minus.svg";
import { useNavigate } from "react-router-dom";
import { numberWithCommas } from "../../../utils/constant";
import API_MANAGER from "../../../API";
function ReviewAndPay({
  handleSubmit,
  addUserData,
  data,
  setAddUserData,
  handleDelete,
  loading,
  setLoading,
  discountCode,
  setDiscountCode,
}) {
  const [selectedItem, setselectedItem] = useState(
    addUserData?.account ? addUserData?.account : 0
  );
  const [selectedAssociationUser, setSeletedAssociationUser] = useState(
    addUserData?.association ? addUserData?.association : 0
  );
  const [discountResponse, setDiscountResponse] = useState(null);
  const [totalAmount, setTotalAmount] = useState(0);
  const navigate = useNavigate();
  useEffect(() => {
    setAddUserData({
      account: selectedItem,
      association: selectedAssociationUser,
    });
  }, [selectedItem, selectedAssociationUser]);

  useEffect(() => {
    let tempTotal =
      data?.default_price +
      selectedItem * data?.additional_standard_user_price +
      selectedAssociationUser * data?.additional_association_user_price;
    if (discountResponse)
      tempTotal = tempTotal - (tempTotal * discountResponse?.percent_off) / 100;
    setTotalAmount(tempTotal);
  }, [
    data,
    selectedItem,
    selectedAssociationUser,
    selectedItem,
    discountResponse,
  ]);

  const checkDiscountCode = async () => {
    setDiscountResponse(null);
    if (discountCode) {
      try {
        const response = await API_MANAGER.discountCode({
          discount: discountCode,
        });
        console.log("Discount Code: " + response);
        setDiscountResponse(response?.data?.data);
      } catch (error) {
        message.error(
          error?.response?.data?.message?.message ||
            "Something went wrong. Please try again."
        );
      }
    }
  };
  return (
    <div className="choose_plan_container">
      <Row gutter={[0, 16]}>
        <Col span={24} className="justify-center d-flex reviewAndPayContainer">
          <h2 className="h2">Review and Pay</h2>
        </Col>
        <Col span={24} className="onboard-center">
          <p className="heading mb-16 para-desc">
            Unlock the benefits of "Cinchy.me" and enjoy fast, targeted access
            to our digital ‘tradie’ database.
          </p>
        </Col>
      </Row>
      <div className="pay_modal_holder">
        <div className="type_heading orderSummaryHeading mb-20 mt-14">
          Order Summary
        </div>
        {/* gutter={{ xs: 0, lg: 40 }} */}
        <Row className="w-100 review_pay_parent">
          <Col xs={24} lg={12} className="item_holder">
            <div className="reviewPayCard">
              <Row className="item">
                <Col span={18} className="gap-23">
                  <img src={calenderIcon} alt="calender icon" />
                  <div>
                    <p>{data?.name}</p>
                    {`(${
                      data?.standard_users > 0
                        ? `${data?.standard_users} Account Users`
                        : ""
                    }${
                      data?.standard_users && data?.association_users
                        ? ` & `
                        : ""
                    }${
                      data?.association_users > 0
                        ? `${data?.association_users} Association Users`
                        : ""
                    })`}
                  </div>
                </Col>
                <Col
                  span={6}
                  className="gap-10 align-center justify-content-between"
                >
                  <p>
                    $
                    {numberWithCommas(
                      (
                        data?.default_price -
                        (discountResponse?.percent_off
                          ? (data?.default_price *
                              discountResponse?.percent_off) /
                            100
                          : 0)
                      ).toFixed(2)
                    )}
                  </p>
                  <div>
                    <img
                      src={deleteIcon}
                      alt="delete icon"
                      className="cursor-pointer"
                      onClick={() => {
                        setSeletedAssociationUser(0);
                        setselectedItem(0);
                        handleDelete();
                      }}
                    />
                  </div>
                </Col>
              </Row>

              {data?.standard_users > 0 && (
                <div>
                  <Row className="item">
                    <Col span={18} className="gap-23">
                      <img src={contactIcon} alt="contact icon" />
                      <div className="d-flex justify-center direction-col">
                        <p>Additional Account User</p>

                        <div>
                          <div className="item_adder ">
                            <img
                              onClick={() =>
                                selectedItem != 0
                                  ? setselectedItem((prev) => prev - 1)
                                  : ""
                              }
                              className={`${
                                selectedItem != 0 ? "pointer" : ""
                              }`}
                              alt="minus icon"
                              src={minusIcon}
                              style={{ height: "10px", width: "10px" }}
                            />
                            <p>{selectedItem}</p>
                            <img
                              onClick={() =>
                                setselectedItem((prev) => prev + 1)
                              }
                              className="pointer"
                              alt="plus icon"
                              src={plusIcon}
                              style={{ height: "10px", width: "10px" }}
                            />
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col
                      span={6}
                      className="gap-10 align-center justify-content-between"
                    >
                      <p>
                      ${numberWithCommas(
                      (
                        (selectedItem * data?.additional_standard_user_price) -
                        (discountResponse?.percent_off
                          ? ((selectedItem * data?.additional_standard_user_price) *
                              discountResponse?.percent_off) /
                            100
                          : 0)
                      ).toFixed(2)
                    )}
                        {/* $
                        {numberWithCommas(
                          selectedItem * data?.additional_standard_user_price
                        ) + ".00"} */}
                      </p>
                      <div>
                        <img
                          src={deleteIcon}
                          alt="delete icon"
                          className="cursor-pointer"
                          onClick={() => setselectedItem(0)}
                        />
                      </div>
                    </Col>
                  </Row>
                </div>
              )}
              {data?.association_users > 0 && (
                <div>
                  <Row className="item">
                    <Col span={18} className="gap-23">
                      <img src={contactIcon} alt="contact icon" />
                      <div className="d-flex justify-center direction-col">
                        <p>Additional Association User</p>

                        <div>
                          <div className="item_adder ">
                            <img
                              onClick={() =>
                                selectedAssociationUser != 0
                                  ? setSeletedAssociationUser(
                                      (prev) => prev - 1
                                    )
                                  : ""
                              }
                              className={`${
                                selectedItem != 0 ? "pointer" : ""
                              }`}
                              alt="minus icon"
                              src={minusIcon}
                              style={{ height: "10px", width: "10px" }}
                            />
                            <p>{selectedAssociationUser}</p>
                            <img
                              onClick={() =>
                                setSeletedAssociationUser((prev) => prev + 1)
                              }
                              className="pointer"
                              alt="plus icon"
                              src={plusIcon}
                              style={{ height: "10px", width: "10px" }}
                            />
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col
                      span={6}
                      className="gap-10 align-center justify-content-between"
                    >
                      <p>
                        ${numberWithCommas(
                          (
                            (selectedAssociationUser * data?.additional_association_user_price) -
                            (discountResponse?.percent_off
                              ? ((selectedAssociationUser * data?.additional_association_user_price) *
                              discountResponse?.percent_off) /
                              100
                              : 0)
                              ).toFixed(2)
                              )}
                          {/* ${numberWithCommas(
                          selectedAssociationUser *
                            data?.additional_association_user_price
                        ) + ".00"} */}
                      </p>
                      <div>
                        <img
                          src={deleteIcon}
                          className="cursor-pointer"
                          alt="delete icon"
                          onClick={() => setSeletedAssociationUser(0)}
                        />
                      </div>
                    </Col>
                  </Row>
                </div>
              )}

              <Row className="mt-20 checkout-amount">
                <Col className="checkout-amount" span={18}>
                  <p> Total Amount</p>
                </Col>

                <Col span={6} className="checkout-amount">
                  <p>
                    $
                    {numberWithCommas(
                      (
                        (data?.default_price -
                        (discountResponse?.percent_off
                          ? (data?.default_price *
                              discountResponse?.percent_off) /
                            100
                          : 0)) +
                          ((selectedItem * data?.additional_standard_user_price) -
                          (discountResponse?.percent_off
                            ? ((selectedItem * data?.additional_standard_user_price) *
                                discountResponse?.percent_off) /
                              100
                            : 0)) +
                        ((selectedAssociationUser * data?.additional_association_user_price) -
                        (discountResponse?.percent_off
                          ? ((selectedAssociationUser * data?.additional_association_user_price) *
                          discountResponse?.percent_off) /
                          100
                          : 0))
                      ).toFixed(2)
                    )}
                  </p>
                </Col>
              </Row>
            </div>
          </Col>
          <Col xs={24} lg={11}>
            <div className="reviewPayCard">
              <p className="Discount_code">Discount code</p>
              <div className="mt-10">
                <Input
                  value={discountCode}
                  onChange={(e) => setDiscountCode(e?.target?.value)}
                  suffix={
                    <div
                      className="apply_text cursor-pointer"
                      onClick={() => checkDiscountCode()}
                    >
                      Apply
                    </div>
                  }
                  placeholder="Apply discount code"
                />
              </div>
              {discountResponse && (
                <div>
                  {/* <p className="discountMsg">
                    This code entitles you to a{" "}
                    {`${discountResponse?.percent_off}%`} discount on your core
                    subscription. The discount does not apply to additional
                    users.
                  </p> */}
                  <p className="discountMsg">
                    The discount will apply to the total cost (excluding taxes).
                    Please note, the discount does not apply to renewals.
                  </p>
                </div>
              )}
              <div className="mt-28">
                <p>
                  Note: You will be redirected to Stripe for payment. We will
                  not store your credit card information.
                </p>
              </div>
              <div className="mt-28">
                <Button
                  className="primaryBtn black_color"
                  onClick={() => handleSubmit()}
                  loading={loading}
                >
                  Pay
                </Button>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default ReviewAndPay;
