import SubLayout from "../../components/layout/SubLayout";
import bannerImage from "../../Assets/campagin/addCampainBanner.png";
import addUserIcon from "../../Assets/campagin/add-user-icon.svg";
import { InfoCircleOutlined } from "@ant-design/icons";

import {
  Button,
  Checkbox,
  Form,
  Input,
  Image,
  Row,
  Col,
  Select,
  Popover,
  message,
  Tooltip,
} from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import AddUserModal from "./AddUserModal/AddUserModal";
import { CloseOutlined } from "@ant-design/icons";
import API_MANAGER from "../../API";
// import LoaderGif from "../../../Assets/loader.gif";
import AlertState from "../../components/common/AlertState";
import rightArrow from "../../Assets/campagin/right_arrow.svg";
import { capitalizeWords } from "../../utils";
import MobileHeader from "../../components/layout/MobileHeader";

const AddCampign = () => {
  const navigate = useNavigate();
  //state for storing user after clicking edit campaign
  const [userModalOpen, setUserModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [industries, setIndustries] = useState([]);
  const [states, setStates] = useState([]);
  const [selectedUsers, setSelectedUser] = useState([]);
  const [usersList, setUsersList] = useState([]);
  const [checkSelected, setCheckSelected] = useState([]);
  const [users, setUsers] = useState([]);
  const [alertOpen, setAlertOpen] = useState(false);
  const [searchUsers, setSearchUsers] = useState("");
  const [alertState, setAlertState] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [storedCampaignData, setStoredCampaignData] = useState();
  const [permissionsSum, setPermissionSum] = useState(0);
  const [form] = Form.useForm();
  const [mainUserData, setMainUserData] = useState({
    id: localStorage.getItem("userID"),
    name: localStorage.getItem("name"),
  });
  //storing users which are preselected on clicking edit campaign

  const onFinish = async (values) => {
    const updatedCheckSelected = [
      ...checkSelected,
      localStorage.getItem("userID"),
    ];
    let temp = {
      ...values,
      users: updatedCheckSelected,
      ["isActive"]: true,
    };
    try {
      setLoading(true);
      let response;
      if (localStorage.getItem("user") === "Root") {
        response = await API_MANAGER.createCampaignRoot(temp);
      } else {
        response = await API_MANAGER.createCampaign(temp);
      }
      form.resetFields();
      setLoading(false);
      message.success("Campaign successfully created.");
      localStorage.removeItem("campaignData");
      // navigate("/campaign-management");
      navigate(`/campaign-detail/${response?.data?.data?.id}`);
    } catch (error) {
      setLoading(false);
      message?.error("Something went wrong. Please try again.");
    }
  };
  const onFinishFailed = (errorInfo) => {};
  const handleGoBack = () => {
    navigate(-1);
  };
  const options = [];
  for (let i = 10; i < 360; i++) {
    options.push({
      value: i.toString(36) + i,
      label: i.toString(36) + i,
    });
  }

  const handleAddUserModal = () => {
    setUserModalOpen(true);
  };
  const handleOk = (users) => {
    setUserModalOpen(false);
    setSelectedUser(users);
    const selectedFieldValues = users?.map((user) => user.id);
    form.setFieldsValue({ users: selectedFieldValues });
  };
  const getIndustries = async () => {
    try {
      const response = await API_MANAGER.getTags("INDUSTRY&sort=name");
      setIndustries(response?.data?.data?.tags);
    } catch (error) {
      message.error("Something went wrong. Please try again.");
    }
  };
  const getStates = async () => {
    try {
      const response = await API_MANAGER.getTagsBySearch({
        type: "STATE",
        limit: 20000,
        sort: "name",
      });
      setStates(response?.data?.data?.tags);
    } catch (error) {
      message.error("Something went wrong. Please try again.");
    }
  };
  const getUsersList = async () => {
    const param = {
      search: searchUsers ? searchUsers : "",
      sort: "-createdAt",
    };
    try {
      const response = await API_MANAGER.getUsersList(param);
      const users = response?.data?.data?.filter(
        (e) => e?._id !== mainUserData?.id
      );
      setUsersList(users);
    } catch (error) {
      message.error("Something went wrong. Please try again.");
    }
  };
  useEffect(() => {
    getIndustries();
    getStates();
  }, []);
  useEffect(() => {
    getUsersList();
  }, [searchUsers]);

  const initialState = {
    name: storedCampaignData?.name || "",
    postalCode: storedCampaignData?.postalCode || "",
    projectCode: storedCampaignData?.projectCode || "",
    state: storedCampaignData?.state || "",
    industry: storedCampaignData?.industry || "",
    suburb: storedCampaignData?.suburb || "",
    state: storedCampaignData?.state || "",
  };
  const handleInputChange = (text, value) => {
    initialState[value] = text;

    localStorage.setItem("campaignData", JSON.stringify(initialState));
  };

  //user save to LS
  useEffect(() => {
    const usersSelected = JSON.parse(localStorage.getItem("usersData"));
    if (usersSelected === null)
      localStorage.setItem("usersData", JSON.stringify(selectedUsers));
    else {
      const updatedUsers = [...usersSelected];
      setSelectedUser(updatedUsers);
    }
  }, []);
  useEffect(() => {
    const campaignData = JSON.parse(localStorage.getItem("campaignData"));
    setStoredCampaignData(campaignData);
    form.setFieldsValue({ name: campaignData?.name });
    form.setFieldsValue({
      targetHeadCount: campaignData?.targetHeadCount,
    });
    form.setFieldsValue({ projectCode: campaignData?.projectCode });
    form.setFieldsValue({ suburb: campaignData?.suburb });
    form.setFieldsValue({ postalCode: campaignData?.postalCode });
    form.setFieldsValue({ state: campaignData?.state });
    form.setFieldsValue({ industry: campaignData?.industry });
  }, []);

  const handleCrossIcon = (event, item) => {
    event.stopPropagation();
    if (checkSelected.includes(item?._id)) {
      const updateUserList = users.filter(
        (itemSelected) => itemSelected._id !== item?._id
      );
      setUsers(updateUserList);
      const updatedCheckSelected = checkSelected.filter(
        (itemSelected) => itemSelected !== item?._id
      );
      setCheckSelected(updatedCheckSelected);
      setSelectedUser(updateUserList);
    }
  };
  useEffect(() => {
    const permissions = localStorage.getItem("permissions");
    let sum = 0;
    for (let i = 0; i < permissions?.length; i++) {
      sum += permissions[i];
    }
    setPermissionSum(sum);
  });

  return (
    <>
      <AlertState
        state={alertState}
        message={alertMessage}
        alertOpen={alertOpen}
        setAlertOpen={setAlertOpen}
      />
      <SubLayout page={"Create Campaign"} whiteBg showBack>
        <AddUserModal
          isModalOpen={userModalOpen}
          setUserModalOpen={setUserModalOpen}
          data={usersList}
          handleOk={handleOk}
          checkSelected={checkSelected}
          setCheckSelected={setCheckSelected}
          users={users}
          setUsers={setUsers}
          searchUsers={searchUsers}
          setSearchUsers={setSearchUsers}
        />

        <div className="add_campaign_main_container">
          <Row>
            <Col xs={0} md={24} className="banner_img_col">
              <Image preview={false} src={bannerImage} />
            </Col>
          </Row>
          {/* for small screen only */}
          <Row>
            <Col xs={24} md={0}>
              <MobileHeader title={"Add Campaign"} />
            </Col>
          </Row>
          {/* Form Start */}
          <div className="form_main_div">
            <Form
              form={form}
              name="basic"
              initialValues={{
                remember: true,
              }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
              layout="vertical"
              requiredMark={false}
            >
              <div className="form_div">
                <Row justify={"space-between"}>
                  <Col xs={24} md={11}>
                    <Form.Item
                      label="Campaign Name/Project Name*"
                      name="name"
                      rules={[
                        {
                          required: true,
                          message: "Please input campaign name/project name!",
                        },
                      ]}
                    >
                      <Input
                        className="form_input_box"
                        placeholder="Add campaign name/project name"
                        onChange={(e) =>
                          handleInputChange(e.target.value, "name")
                        }
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={11}>
                    <Form.Item
                      label={
                        <div>
                          Target Headcount
                          <Tooltip title="The campaign's target headcount is automatically calculated from each advert's target headcount.">
                            <InfoCircleOutlined
                              className="cursor_pointer"
                              style={{ marginLeft: "5px" }}
                            />
                          </Tooltip>
                        </div>
                      }
                      name="targetHeadCount"
                    >
                      <Input
                        className="form_input_box"
                        placeholder="Target headcount"
                        disabled={true}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row justify={"space-between"}>
                  <Col xs={24} md={11}>
                    <Form.Item
                      label="Project Code"
                      name="projectCode"
                      rules={[
                        {
                          required: false,
                          message: "Please input project code!",
                        },
                      ]}
                    >
                      <Input
                        className="form_input_box"
                        placeholder="Add project code"
                        type="text"
                        onChange={(e) =>
                          handleInputChange(e.target.value, "projectCode")
                        }
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={11}>
                    <Form.Item
                      label="Industry*"
                      name="industry"
                      rules={[
                        {
                          required: true,
                          message: "Please input industry!",
                        },
                      ]}
                    >
                      <Select
                        className="select_input_form"
                        placeholder="Add industry"
                        showSearch
                        optionFilterProp="children"
                        onChange={(e) => handleInputChange(e, "industry")}
                        filterOption={(input, option) =>
                          option?.label
                            ?.toLowerCase()
                            .includes(input?.toLowerCase())
                        }
                        filterSort={(optionA, optionB) =>
                          optionA?.label
                            ?.toLowerCase()
                            .localeCompare(optionB?.label?.toLowerCase())
                        }
                      >
                        {industries?.map((item, index) => (
                          <Select.Option
                            value={item?.id}
                            key={index}
                            label={item?.name}
                          >
                            {item?.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                <Row justify={"space-between"}>
                  <Col xs={24} md={11}>
                    <Form.Item
                      label="Suburb*"
                      name="suburb"
                      rules={[
                        {
                          required: true,
                          message: "Please input suburb!",
                        },
                      ]}
                    >
                      <Input
                        className="form_input_box"
                        placeholder="Add suburb"
                        onChange={(e) =>
                          handleInputChange(e.target.value, "suburb")
                        }
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={11}>
                    <Form.Item
                      label="State*"
                      name="state"
                      rules={[
                        {
                          required: true,
                          message: "Please input state!",
                        },
                      ]}
                    >
                      <Select
                        className="select_input_form"
                        showSearch
                        optionFilterProp="children"
                        placeholder="Add state"
                        onChange={(e) => handleInputChange(e, "state")}
                      >
                        {states?.map((item, index) => (
                          <Select.Option
                            value={item?.id}
                            key={index}
                            label={item?.name}
                          >
                            {item?.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                <Row justify={"space-between"}>
                  <Col xs={24} md={11}>
                    <Form.Item
                      label="Postal Code*"
                      name="postalCode"
                      rules={[
                        {
                          required: true,
                          message: "Please input postal code!",
                        },
                      ]}
                    >
                      <Input
                        className="form_input_box"
                        placeholder="Add postal code"
                        onChange={(e) =>
                          handleInputChange(e.target.value, "postalCode")
                        }
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={11}>
                    <label>
                      <Row justify={"space-between"}>
                        <Col span={12} style={{ marginBottom: "8px" }}>
                          Users*
                        </Col>
                      </Row>
                    </label>
                    <Form.Item
                      name="users"
                      rules={[
                        {
                          required: false,
                          message: "Please add users!",
                        },
                      ]}
                    >
                      <div
                        className="form_input_div"
                        onClick={handleAddUserModal}
                        placeholder="add user"
                      >
                        <div className="multi_select_item_block">
                          <span className="text_capitalize">{mainUserData?.name}</span>
                        </div>

                        {selectedUsers.slice(0, 2).map((item) => {
                          return (
                            <div className="multi_select_item_block text_capitalize">
                              <span>{`${item?.firstName} ${item?.lastName}`}</span>
                              {/* <CloseOutlined
                                size={4}
                                onClick={(e) => handleCrossIcon(e, item)}
                              /> */}
                            </div>
                          );
                        })}
                        {selectedUsers.length > 2 && (
                          <Popover
                            className="aaa"
                            placement="topLeft"
                            content={
                              <div className="popover_contentDiv">
                                <Row>
                                  {selectedUsers.slice(2).map((item) => (
                                    <Col span={24} className="popover_item_col">
                                      <span className="greendot"></span>
                                      <span>
                                        {item?.firstName}&nbsp;{item?.lastName}
                                      </span>
                                    </Col>
                                  ))}
                                </Row>
                              </div>
                            }
                          >
                            {/* <Button>TL</Button> */}
                            <div className="count_div">
                              <span>+{selectedUsers.length - 2}</span>
                            </div>
                          </Popover>
                        )}
                      </div>
                    </Form.Item>
                  </Col>
                </Row>
              </div>
              <Row className="submit_button_row ">
                <Col span={24}>
                  <Row
                    justify={"end"}
                    gutter={24}
                    className="specialBtn-parent"
                  >
                    <Col>
                      <Button
                        className="button_no_border specialBtn_one"
                        onClick={() => {
                          localStorage.removeItem("campaignData");
                          navigate("/campaign-management");
                        }}
                      >
                        Cancel
                      </Button>
                    </Col>
                    <Col>
                      <Button
                        className="button_1 specialBtn_two"
                        htmlType="submit"
                        loading={loading}
                      >
                        {"Create Campaign"}
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Form>
          </div>
        </div>
      </SubLayout>
    </>
  );
};
export default AddCampign;
