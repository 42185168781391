import React, { useEffect, useState } from "react";
import { Row, Col, Avatar, Radio, Divider, message, Modal, Input } from "antd";
import SubLayout from "../../components/layout/SubLayout";
import { UserOutlined } from "@ant-design/icons";
import MailICon from "../../Assets/profile/mail.svg";
import CheckMark from "../../Assets/profile/checkMark.svg";
import PhoneIcon from "../../Assets/profile/phone.svg";
import AddressIcon from "../../Assets/profile/address.svg";
import DocumentBox from "../../components/common/DocumentBox";
import JobPositionCard from "../../components/common/campaignManagement/JobPositionCard";
import { UpdateApplicantProfileStatus } from "../../components/common/Popup";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import API_MANAGER from "../../API";
import moment from "moment";
import { jobStatusEnum } from "../../utils";
import FullScreenLoader from "../../components/common/FullScreenLoader";
import InviteCampaignDropdown from "../../components/common/search/InviteCampaignDropdown";
import { UpdatestatusPopup } from "../../components/common/AdvertDetail/Reportpopup";
function ApplicantProfile() {
  const navigate = useNavigate();
  const [type, setType] = useState("PE");
  const [citizen, setCitizen] = useState("");
  const [loading, setLoading] = useState(false);
  const [updateStatusModal, setUpdateStatusModal] = useState(false);
  const [candidateData, setCandidateData] = useState();
  const [soaOpen, setSoaOpen] = useState(false);
  const [vocOpen, setVocOpen] = useState(false);
  const [candidateProgress, setCandidateProgress] = useState("");
  const [isHidden, setIsHidden] = useState(true);
  const [inviteModal, setInviteModal] = useState(false);
  const [inviteLoading, setInviteLoading] = useState(false);
  const [campaignSearch, setCampaignSearch] = useState(null);
  const [campaignList, setCampaignList] = useState([]);
  const [inviteIds, setInviteIds] = useState({});
  const [updatePopup, setUpdatePopup] = useState(false);
  const [candidateSelectedData, setCandidateSelectedData] = useState({});
  const userType = localStorage.getItem("user");

  const { id } = useParams();
  const { state } = useLocation();
  const props = state;
  const withDrawlCandidate = state.withDrawlCandidate;

  //CHECKING JOB STATE FROM ADVERT ID

  const singleUserCampaignDetails = async () => {
    const param = {
      profileId: id,
      search: campaignSearch ? campaignSearch : "",
      sort: "-createdAt",
      limit: 20000,
      page: 1,
    };
    try {
      const response = await API_MANAGER.getSingleUserCampaignDetails(
        param,
        localStorage.getItem("userID")
      );
      setCampaignList(response?.data?.data);
    } catch (error) {
      message.error("Something went wrong. Please try again.");
    }
  };

  useEffect(() => {
    if (props?.id || id) {
      const appliedJobData = candidateData?.candidate?.appliedJobs.find(
        (jobs) => jobs?.advertId?.id === props?.id
      );

      setCandidateSelectedData(appliedJobData);
      const applicationState = appliedJobData
        ? appliedJobData?.progress[appliedJobData?.progress?.length - 1]?.state
        : -1;
      if (applicationState > 2) {
        setIsHidden(false);
      }
      const applicantStatus = jobStatusEnum[applicationState];
      console.log("ppppp", applicationState, applicantStatus);
      setCandidateProgress(
        applicationState !== -1 ? applicantStatus?.label : "INVITED"
      );
    }
  }, [props, candidateData]);

  useEffect(() => {
    if ((props?.search || withDrawlCandidate === "yes") && candidateData) {
      singleUserCampaignDetails();
    }
  }, [props, candidateData, campaignSearch]);
  useEffect(() => {
    if (candidateData?.candidate?.candidateVisa?.length !== 0) {
      if (!candidateData?.candidate?.candidateVisa[0]?.isAustralian)
        setCitizen("Non - Australian Citizen");
      else setCitizen("australian");
    } else {
      setCitizen("australian");
    }
  }, [candidateData]);

  //GETTING CANDIDATE DATA
  const getCandidateDetails = async () => {
    setLoading(true);
    try {
      const response = await API_MANAGER.getAppliedCandidateDetails(id);
      // setCandidatesDetails(response?.data?.data?.result);
      setCandidateData(response?.data?.data);
      setLoading(false);
    } catch (error) {
      message.error("Something went wrong. Please try again.");
      setLoading(false);
    }
  };
  useEffect(() => {
    getCandidateDetails();
  }, [id]);
  const handleStatementOfAttainment = () => {
    if (soaOpen) setSoaOpen(false);
    else setSoaOpen(true);
  };
  const handleVOC = () => {
    if (vocOpen) setVocOpen(false);
    else setVocOpen(true);
  };
  const replaceString = (string) => {
    if (typeof string === "number") {
      string = string?.toString();
    }
    return string?.replace(/[^\s]/g, "x");
  };
  const handleOk = async () => {
    setInviteLoading(true);
    let tempInvites = Object.entries(inviteIds);
    let adverts = [];
    tempInvites?.map((item) => {
      item[1]?.map((e) => {
        adverts.push({
          advertId: e,
          campaignId: item[0],
        });
      });
    });
    const params = {
      adverts: adverts,
      profileId: candidateData?.candidate?._id,
    };
    try {
      const response = await API_MANAGER.inviteUsers(params);
      setInviteLoading(false);
      message.success("Successfully invited.");
      setInviteModal(false);
      // GetGlobalSearch();
      // navigate(`/applicant-profile/${candidateData?._id}`, {
      //   state: { isInvited: true, search: true },
      // });
      navigate(-1);
    } catch (error) {
      setInviteLoading(false);
      message.error(
        error?.response?.data?.message ||
          "Something went wrong. Please try again."
      );
    }
  };
  console.log(state?.filter,'state?.filter')
  const backUrlFunction = () => {
    navigate(`/search?search=${state?.searchText}&page=${state?.page}`, {
      state: {
        page: state?.page,
        filter: state?.filter,
        search: state?.searchText,
        path: state?.path,
      },
    });
  };
  const updateCandidateJobStatus = async () => {
    try {
      if (
        candidateSelectedData?.progress[
          candidateSelectedData?.progress?.length - 1
        ]?.state === 0
      ) {
        await API_MANAGER.updateCandidateJobStatus(candidateSelectedData?._id, {
          status: "SHORTLIST_OFFERED",
        });
      } else if (
        candidateSelectedData?.progress[
          candidateSelectedData?.progress?.length - 1
        ]?.state === 2
      ) {
        await API_MANAGER.acceptShortlistOffer({
          applicationId: candidateSelectedData?._id,
          acceptance: true,
        });
      } else if (
        candidateSelectedData?.progress[
          candidateSelectedData?.progress?.length - 1
        ]?.state === 3
      ) {
        await API_MANAGER.updateCandidateJobStatus(candidateSelectedData?._id, {
          status: "ROLE_OFFERED",
        });
      }

      message.success("Status successfully updated.");
      setUpdatePopup(false);
      getCandidateDetails();
    } catch (error) {
      // message.error("Something went wrong. Please try again.");
      // setUpdateAlert(true);
      message.error(
        "A Candidate must accept your previous request, before you can push them to the next stage of the recruitment process."
      );
      setUpdatePopup(false);
    }
  };

  return (
    <SubLayout
      page="Applicant Profile"
      whiteBg
      showBack
      showSearch
      backUrlFunction={backUrlFunction}
      isBackFunc={state?.isBackFunc}
    >
      <FullScreenLoader isLoading={loading} />{" "}
      {/* {candidateSelectedData?.progress?.[
        candidateSelectedData?.progress?.length - 1
      ]?.state <= 4 ? ( */}
      <UpdatestatusPopup
        updatePopup={updatePopup}
        setUpdatePopUp={setUpdatePopup}
        setUpdatePopup={setUpdatePopup}
        candidateSelectedData={candidateSelectedData}
        onSubmit={updateCandidateJobStatus}
        name={candidateData?.candidate?.name}
      />
      {/* ) : null} */}
      <Modal
        title={`Invite ${props?.name || candidateData?.candidate?.name} to:`}
        open={inviteModal}
        onOk={handleOk}
        onCancel={() => setInviteModal(false)}
        okText={"Invite"}
        wrapClassName="invite-modal"
        centered
        confirmLoading={inviteLoading}
      >
        <Input
          className="search-input mb-20"
          size="large"
          placeholder="Select or search adverts"
          value={campaignSearch}
          onChange={(e) => setCampaignSearch(e?.target?.value)}
          prefix={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="21"
              height="20"
              viewBox="0 0 21 20"
              fill="none"
            >
              <path
                d="M19.8947 19.0002L15.5517 14.6572M15.5517 14.6572C16.2946 13.9143 16.8839 13.0324 17.286 12.0618C17.688 11.0911 17.8949 10.0508 17.8949 9.00021C17.8949 7.9496 17.688 6.90929 17.286 5.93866C16.8839 4.96803 16.2946 4.08609 15.5517 3.34321C14.8088 2.60032 13.9269 2.01103 12.9563 1.60898C11.9857 1.20693 10.9453 1 9.89474 1C8.84414 1 7.80382 1.20693 6.83319 1.60898C5.86256 2.01103 4.98062 2.60032 4.23774 3.34321C2.73741 4.84354 1.89453 6.87842 1.89453 9.00021C1.89453 11.122 2.73741 13.1569 4.23774 14.6572C5.73807 16.1575 7.77295 17.0004 9.89474 17.0004C12.0165 17.0004 14.0514 16.1575 15.5517 14.6572Z"
                stroke="#1638B1"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          }
        />
        <div className="campaignListContainer">
          {campaignList &&
            campaignList?.map((item, index) => (
              <InviteCampaignDropdown
                setInviteIds={setInviteIds}
                inviteIds={inviteIds}
                item={item}
                key={index}
              />
            ))}
        </div>
      </Modal>
      <div className="applicant_profile_main_container">
        {/* for small screen only */}
        <Row>
          <Col xs={24} md={0}>
            <p className="profile-goback" onClick={() => navigate(-1)}>
              {"< Applicant Profile"}
            </p>
          </Col>
        </Row>
        <Row gutter={[20, 20]}>
          <Col xs={24} md={7}>
            <div className="personal_detail_container">
              <Row justify={"space-between"}>
                <Col
                  xs={7}
                  md={24}
                  className="align-center-justify avatar-icon "
                >
                  {!isHidden ? (
                    <Avatar
                      icon={<UserOutlined />}
                      size={window.innerWidth > 767 ? 122 : 84}
                      className="avatar-icon"
                      src={candidateData?.candidate?.profileImage}
                    />
                  ) : (
                    <Avatar
                      icon={<UserOutlined />}
                      size={window.innerWidth > 767 ? 122 : 84}
                      src={candidateData?.candidate?.profileImage}
                      className="avatar-icon text_blur"
                    />
                  )}
                </Col>
                <Col xs={16} md={24}>
                  <p className="name">{candidateData?.candidate?.name}</p>
                  {props?.search || withDrawlCandidate === "yes" ? (
                    <>
                      <div
                        className="tagBox cursor-pointer"
                        onClick={() => {
                          setInviteModal(true);
                        }}
                      >
                        <Row gutter={8} justify={"center"} align={"middle"}>
                          <Col>
                            <svg
                              width="8"
                              height="7"
                              viewBox="0 0 8 7"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M6.18342 5.28999C4.97579 6.49159 3.02421 6.49159 1.81658 5.28999C-0.141075 3.34213 1.23838 0 4 0C6.76162 0 8.14108 3.34213 6.18342 5.28999Z"
                                fill="#071135"
                              />
                            </svg>
                          </Col>
                          <Col>INVITE</Col>
                        </Row>
                      </div>
                    </>
                  ) : (
                    <div
                      className={`tagBox ${
                        userType !== "Associate" && "cursor-pointer"
                      }`}
                      onClick={() => {
                        userType !== "Associate" && setUpdatePopup(true);
                      }}
                    >
                      <Row gutter={8} justify={"center"} align={"middle"}>
                        <Col>
                          <svg
                            width="8"
                            height="7"
                            viewBox="0 0 8 7"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M6.18342 5.28999C4.97579 6.49159 3.02421 6.49159 1.81658 5.28999C-0.141075 3.34213 1.23838 0 4 0C6.76162 0 8.14108 3.34213 6.18342 5.28999Z"
                              fill="#071135"
                            />
                          </svg>
                        </Col>
                        <Col>{candidateProgress}</Col>
                      </Row>
                    </div>
                  )}
                </Col>
              </Row>

              {/* ******************************** */}
              <Row justify={"space-between"}>
                <Col xs={11} md={24}>
                  <div className="detail_box">
                    <Row gutter={8} align={"middle"} justify={"start"}>
                      <Col>
                        <img src={MailICon} />
                      </Col>
                      <Col>
                        <p className="item">Email</p>
                      </Col>
                    </Row>
                    <Row>
                      {!isHidden ? (
                        <p className="value">{candidateData?.email}</p>
                      ) : (
                        <p className="value text_blur">
                          {replaceString(candidateData?.email)}
                        </p>
                      )}
                    </Row>
                  </div>
                </Col>
                <Col xs={11} md={24}>
                  <div className="detail_box">
                    <Row gutter={8} align={"middle"}>
                      <Col>
                        <img src={PhoneIcon} />
                      </Col>
                      <Col>
                        <p className="item">Phone Number</p>
                      </Col>
                    </Row>
                    <Row>
                      {!isHidden ? (
                        <p className="value">
                          +{candidateData?.candidate?.countryCode}{" "}
                          {candidateData?.candidate?.phoneNumber}
                        </p>
                      ) : (
                        <p className="value text_blur">
                          {replaceString(candidateData?.candidate?.phoneNumber)}
                        </p>
                      )}
                    </Row>
                  </div>
                </Col>
                <Col xs={11} md={24}>
                  <div className="detail_box">
                    <Row gutter={8} align={"middle"}>
                      <Col>
                        <img src={AddressIcon} />
                      </Col>
                      <Col>
                        <p className="item">Address</p>
                      </Col>
                    </Row>
                    <Row>
                      {!isHidden ? (
                        <p className="value">
                          {candidateData?.candidate?.street
                            ?.charAt(0)
                            ?.toUpperCase() +
                            candidateData?.candidate?.street?.substring(1) +
                            ", " +
                            candidateData?.candidate?.city +
                            ", " +
                            candidateData?.candidate?.state +
                            ", " +
                            candidateData?.candidate?.postalCode +
                            ", " +
                            candidateData?.candidate?.country || ""}
                        </p>
                      ) : (
                        <p>
                          <span className="value text_blur">
                            {replaceString(candidateData?.candidate?.street)}
                          </span>
                          <span className="value ">
                            {", " +
                              candidateData?.candidate?.city +
                              ", " +
                              candidateData?.candidate?.state +
                              ", " +
                              candidateData?.candidate?.postalCode +
                              ", " +
                              candidateData?.candidate?.country || ""}
                          </span>
                        </p>
                      )}
                    </Row>
                  </div>
                </Col>
                <Col xs={11} md={24}>
                  <div className="detail_box">
                    <p className="item">D.O.B</p>
                    {!isHidden ? (
                      <p className="value">
                        {moment(candidateData?.candidate?.dob).format(
                          "DD MMM YYYY"
                        )}
                      </p>
                    ) : (
                      <p className="value text_blur">
                        {replaceString(
                          moment(candidateData?.candidate?.dob).format(
                            "DD MMM YYYY"
                          )
                        )}
                      </p>
                    )}
                  </div>
                </Col>
                <Col xs={11} md={24}>
                  <div className="detail_box">
                    <p className="item">Gender</p>

                    {!isHidden ? (
                      <p className="value">
                        {candidateData?.candidate?.gender
                          ?.charAt(0)
                          ?.toUpperCase() +
                          candidateData?.candidate?.gender?.substring(1)}
                      </p>
                    ) : (
                      <p className="value text_blur">
                        {replaceString(candidateData?.candidate?.gender)}
                      </p>
                    )}
                  </div>
                </Col>
                <Col xs={11} md={24}>
                  <div className="detail_box">
                    <p className="item">Ethnicity</p>

                    <p className="value"></p>
                    {!isHidden ? (
                      <p className="value">
                        {candidateData?.candidate?.ethnicity?.name}
                      </p>
                    ) : (
                      <p className="value text_blur">
                        {replaceString(
                          candidateData?.candidate?.ethnicity?.name
                        )}
                      </p>
                    )}
                  </div>
                </Col>
                <Col xs={11} md={24}>
                  <div className="detail_box">
                    <p className="item">Availability</p>
                    {candidateData?.candidate?.isAvailable ? (
                      <p className="value">I’m available for work</p>
                    ) : (
                      <p className="value">I’m not available for work</p>
                    )}
                  </div>
                </Col>
              </Row>
            </div>
          </Col>

          <Col xs={24} md={17}>
            <div className="professional_detail_container">
              <div className="radioButtons">
                <Radio.Group
                  defaultValue={type}
                  onChange={(e) => setType(e?.target?.value)}
                >
                  <Radio.Button value="PE">
                    Professional Experience
                  </Radio.Button>
                  <Radio.Button value="QS">Qualifications</Radio.Button>
                  <Radio.Button value="OD">Other Details</Radio.Button>
                </Radio.Group>
              </div>
              <div className="professional_detail_content">
                {type === "PE" && (
                  <div className="professional_experience_container">
                    <div className="professional_summary_box">
                      <p className="title">Profile Summary Description</p>
                      <p className="desc">
                        {candidateData?.candidate?.profileSummary}
                      </p>
                      <div>
                        <p className="title">Resume</p>
                        <Row>
                          <Col xs={24} md={12}>
                            <DocumentBox
                              docLink={
                                candidateData?.candidate?.resume[0]?.resume
                              }
                              updatedDate={
                                candidateData?.candidate?.resume[0]?.updatedAt
                              }
                              isHidden={isHidden}
                            />
                          </Col>
                        </Row>
                      </div>
                    </div>
                    <div>
                      <Row gutter={[18, 18]}>
                        {candidateData?.candidate?.experiences.map(
                          (expData) => {
                            return (
                              <Col xs={24} md={24}>
                                <JobPositionCard
                                  data={expData}
                                  isHidden={isHidden}
                                />
                              </Col>
                            );
                          }
                        )}
                      </Row>
                    </div>
                  </div>
                )}
                {type === "QS" && (
                  <>
                    {candidateData?.candidate?.qualifications.map((qual) => (
                      <div className="qualification_container mb-20">
                        <div className="qualification_summary_box">
                          <Row gutter={8}>
                            <Col>
                              <p className="title">
                                {qual?.qualificationType?.name}
                                {qual?.isValidate && <img src={CheckMark} />}
                              </p>
                            </Col>
                          </Row>
                          <Row
                            gutter={24}
                            className="civil-certificate"
                            style={{ marginTop: "10px" }}
                          >
                            <Col xs={24} md={14}>
                              <DocumentBox
                                docLink={qual?.uploadedDocument}
                                updatedDate={qual?.issueDate}
                                isHidden={isHidden}
                              />
                            </Col>
                            <Col
                              xs={24}
                              md={10}
                              style={{ marginBottom: "10px" }}
                            >
                              <Row justify={"space-between"}>
                                <Col span={12}>
                                  <p className="item">Issue Date</p>
                                  <p className="value">
                                    {qual?.issueDate
                                      ? moment(qual?.issueDate).format(
                                          "DD MMM YYYY"
                                        )
                                      : "-"}
                                  </p>
                                </Col>
                                <Col span={12}>
                                  <p className="item">Expiry Date</p>
                                  <p className="value">
                                    {qual?.expiryDate
                                      ? moment(qual?.expiryDate).format(
                                          "DD MMM YYYY"
                                        )
                                      : "-"}
                                  </p>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                          <Row gutter={15} style={{ marginTop: "20px" }}>
                            <Col xs={8}>
                              <p className="item">Member No./ID</p>
                              <p className="value">{qual?.memberId}</p>
                            </Col>
                            <Col xs={8}>
                              <p className="item">State/Country</p>
                              <p className="value">{qual?.stateOrCountry}</p>
                            </Col>
                            <Col xs={8}>
                              <p className="item">
                                Licence Type/Class/Category
                              </p>
                              {qual?.licenceType?.map((item) => (
                                <p className="value">{item?.name},</p>
                              ))}
                            </Col>
                          </Row>
                        </div>

                        {(qual?.hasSOA || qual?.hasVOC) && (
                          <div className="qualification_detail_box ">
                            {qual?.hasSOA && (
                              <div>
                                <p
                                  className="title cursor_pointer justify_space"
                                  onClick={handleStatementOfAttainment}
                                >
                                  Statement of Attainment{" "}
                                  {!soaOpen ? <DownOutlined /> : <UpOutlined />}
                                </p>

                                {soaOpen && (
                                  <Row gutter={24} className="sm-row-reverse">
                                    <Col xs={24} md={14} className="sm-mt-16">
                                      <DocumentBox
                                        docLink={qual?.SOADocument}
                                        isHidden={isHidden}
                                      />
                                    </Col>
                                    <Col xs={24} md={10}>
                                      <p className="item">Issue Date</p>
                                      <p className="value">
                                        {moment(qual?.SOAIssueDate).format(
                                          "DD MMM YYYY"
                                        )}
                                      </p>
                                    </Col>
                                  </Row>
                                )}
                              </div>
                            )}
                            {qual?.hasSOA && (
                              <Divider className="horizontalDivider" />
                            )}
                            {qual?.hasVOC && (
                              <div>
                                <p
                                  className="title cursor_pointer justify_space"
                                  onClick={handleVOC}
                                >
                                  Verification of Competency{" "}
                                  {!vocOpen ? <DownOutlined /> : <UpOutlined />}
                                </p>
                                {vocOpen && (
                                  <Row gutter={24} className="sm-row-reverse">
                                    <Col xs={24} md={14} className="sm-mt-16">
                                      <DocumentBox
                                        docLink={qual?.VOCDocument}
                                        isHidden={isHidden}
                                      />
                                    </Col>
                                    <Col xs={24} md={10}>
                                      <Row justify={"space-between"}>
                                        <Col span={12}>
                                          <p className="item">
                                            {" "}
                                            Verification Type
                                          </p>
                                          <p className="value">
                                            {qual?.VOCType?.name}
                                          </p>
                                        </Col>
                                        <Col span={12}>
                                          <p className="item">Issue Date </p>
                                          <p className="value">
                                            {moment(qual?.VOCIssueDate).format(
                                              "DD MMM YYYY"
                                            )}
                                          </p>
                                        </Col>
                                      </Row>
                                    </Col>
                                  </Row>
                                )}
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    ))}
                  </>
                )}
                {type === "OD" && (
                  <div className="other_detail_container">
                    <div className="citizen_container">
                      <div className="titleBox">
                        {citizen === "australian"
                          ? "Australian Citizen"
                          : "Non - Australian Citizen"}
                      </div>
                      <div className="detail-container">
                        <>
                          {candidateData?.candidate?.documents?.map((doc) => (
                            <div style={{ padding: "20px 0" }}>
                              <p className="title mt-30">
                                {doc?.documentType?.name}
                                {doc?.isVerfied && <img src={CheckMark} />}
                              </p>
                              <Row gutter={24}>
                                <Col
                                  xs={24}
                                  sm={14}
                                  style={{ marginBottom: "10px" }}
                                >
                                  <DocumentBox
                                    docLink={doc?.document}
                                    isHidden={isHidden}
                                  />
                                </Col>
                                <Col xs={24} sm={10}>
                                  <Row gutter={15}>
                                    <Col xs={12}>
                                      <p className="item">
                                        {doc.documentType.name === "Passport"
                                          ? "Nationality"
                                          : doc.documentType.name ===
                                            "Proof of Age/Photo Card"
                                          ? "State"
                                          : doc.documentType.name === "VISA"
                                          ? "Visa Type Number"
                                          : doc.documentType.name ===
                                            "Driver Licence"
                                          ? "State"
                                          : "ID Number"}
                                      </p>
                                      <p
                                        className="value"
                                        style={{
                                          overflow: "hidden",
                                          textOverflow: "ellipsis",
                                        }}
                                      >
                                        {doc.documentType.name === "Passport"
                                          ? citizen
                                              .substring(0, 1)
                                              .toUpperCase() +
                                            citizen.substring(1)
                                          : doc.documentType.name ===
                                            "Proof of Age/Photo Card"
                                          ? doc?.documentData?.state
                                          : doc.documentType.name === "VISA"
                                          ? doc?.documentData?.visaTypeNo?.name
                                          : doc?.documentType?.name ===
                                            "Driver Licence"
                                          ? doc?.documentData?.state || "-"
                                          : doc?.documentData?.idNumber || "-"}
                                      </p>
                                    </Col>
                                    <Col xs={12}>
                                      <p className="item">
                                        {doc.documentType.name === "Passport"
                                          ? "Passport Number"
                                          : doc.documentType.name ===
                                            "Driver Licence"
                                          ? "Licence Number"
                                          : doc.documentType.name === "VISA"
                                          ? "Grant Number"
                                          : "ID Number"}
                                      </p>
                                      <p className="value">
                                        {doc.documentType.name === "Passport"
                                          ? doc?.documentData?.passportNumber
                                          : doc?.documentType?.name ===
                                            "Driver Licence"
                                          ? doc?.documentData?.licenceNumber ||
                                            doc?.documentData?.licenseNumber
                                          : doc.documentType.name === "VISA"
                                          ? doc?.documentData.grantNo
                                          : doc?.documentData?.idNumber}
                                      </p>
                                    </Col>
                                  </Row>
                                  <Row gutter={15}>
                                    <Col span={12}>
                                      {doc?.documentType?.name ===
                                        "Driver Licence" && (
                                        <div className="mt-16">
                                          <p className="item">Class</p>
                                          <p className="value">
                                            {doc?.documentData?.class?.length >
                                            0
                                              ? doc?.documentData?.class?.map(
                                                  (e) => {
                                                    return `${e} `;
                                                  }
                                                )
                                              : "-"}
                                          </p>
                                        </div>
                                      )}
                                      {doc?.documentType?.name === "VISA" ? (
                                        <div className="mt-16">
                                          <p className="item">Issue Date</p>
                                          <p className="value">
                                            {moment(
                                              doc?.documentData?.issueDate
                                            ).format("DD MMM YYYY")}
                                          </p>
                                        </div>
                                      ) : (
                                        <div className="mt-16">
                                          <p className="item">Expiry Date</p>
                                          <p className="value">
                                            {console.log(
                                              doc,
                                              "doc?.documentData"
                                            )}
                                            {moment(
                                              doc?.documentData?.expiryDate
                                            ).format("DD MMM YYYY")}
                                          </p>
                                        </div>
                                      )}
                                    </Col>
                                    <Col span={12}>
                                      {doc?.documentType?.name ===
                                        "Driver Licence" && (
                                        <div className="mt-16">
                                          <p className="item">Conditions</p>
                                          <p className="value">
                                            {doc?.documentData?.conditions
                                              ?.length > 0
                                              ? doc?.documentData?.conditions?.map(
                                                  (e) => {
                                                    return `${e} `;
                                                  }
                                                )
                                              : "-"}
                                          </p>
                                        </div>
                                      )}
                                      {doc?.documentType?.name === "VISA" && (
                                        <div className="mt-16">
                                          <p className="item">Expiry Date</p>
                                          <p className="value">
                                            {moment(
                                              doc?.documentData?.expiryDate
                                            ).format("DD MMM YYYY")}
                                          </p>
                                        </div>
                                      )}
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                            </div>
                          ))}
                        </>
                        {/* )}  */}
                      </div>
                    </div>

                    <div className="additional_detail_container">
                      <div className="titleBox">
                        Additional Personal Details
                      </div>
                      <div className="detail-container">
                        <Row gutter={16}>
                          <Col span={8}>
                            <p className="item">Tax File Number (TFN)</p>
                            {!isHidden ? (
                              <p className="value">
                                {
                                  candidateData?.candidate?.otherDetails[0]
                                    ?.tfnNo
                                }
                              </p>
                            ) : (
                              <p className="value text_blur">
                                {replaceString(
                                  candidateData?.candidate?.otherDetails[0]
                                    ?.tfnNo
                                )}
                              </p>
                            )}
                          </Col>
                          <Col span={8}>
                            <p className="item">USI No.</p>
                            {!isHidden ? (
                              <p className="value">
                                {
                                  candidateData?.candidate?.otherDetails[0]
                                    ?.usiNo
                                }
                              </p>
                            ) : (
                              <p className="value text_blur">
                                {replaceString(
                                  candidateData?.candidate?.otherDetails[0]
                                    ?.usiNo
                                )}
                              </p>
                            )}
                          </Col>
                        </Row>
                        <Divider className="horizontalDivider" />
                        <div>
                          <p className="title">Superannuation</p>
                          <Row gutter={16}>
                            <Col span={8}>
                              <p className="item">Fund Name</p>
                              {!isHidden ? (
                                <p className="value">
                                  {
                                    candidateData?.candidate?.otherDetails[0]
                                      ?.superannuationDetails?.fundName?.name
                                  }
                                </p>
                              ) : (
                                <p className="value text_blur">
                                  {replaceString(
                                    candidateData?.candidate?.otherDetails[0]
                                      ?.superannuationDetails?.fundName?.name
                                  )}
                                </p>
                              )}
                            </Col>
                            <Col span={8}>
                              <p className="item">Fund USI/ABN</p>
                              {!isHidden ? (
                                <p className="value">
                                  {" "}
                                  {
                                    candidateData?.candidate?.otherDetails[0]
                                      ?.superannuationDetails?.fundUsiAbn?.name
                                  }
                                </p>
                              ) : (
                                <p className="value text_blur">
                                  {" "}
                                  {replaceString(
                                    candidateData?.candidate?.otherDetails[0]
                                      ?.superannuationDetails?.fundUsiAbn?.name
                                  )}
                                </p>
                              )}
                            </Col>
                            <Col span={8}>
                              <p className="item">Member Number</p>
                              {!isHidden ? (
                                <p className="value">
                                  {
                                    candidateData?.candidate?.otherDetails[0]
                                      ?.superannuationDetails?.memberNumber
                                  }
                                </p>
                              ) : (
                                <p className="value text_blur">
                                  {replaceString(
                                    candidateData?.candidate?.otherDetails[0]
                                      ?.superannuationDetails?.memberNumber
                                  )}
                                </p>
                              )}
                            </Col>
                          </Row>
                        </div>
                        <Divider className="horizontalDivider" />
                        <div>
                          <p className="title">Long Service Leave</p>
                          <Row gutter={16}>
                            <Col xs={12} md={8}>
                              <p className="item">State</p>
                              {!isHidden ? (
                                <p className="value">
                                  {
                                    candidateData?.candidate?.otherDetails[0]
                                      ?.lslDetails?.state?.name
                                  }
                                </p>
                              ) : (
                                <p className="value text_blur">
                                  {replaceString(
                                    candidateData?.candidate?.otherDetails[0]
                                      ?.lslDetails?.state?.name
                                  )}
                                </p>
                              )}
                            </Col>

                            <Col xs={12} md={8}>
                              <p className="item">Member Number</p>
                              {!isHidden ? (
                                <p className="value">
                                  {" "}
                                  {
                                    candidateData?.candidate?.otherDetails[0]
                                      ?.lslDetails?.memberNumber
                                  }
                                </p>
                              ) : (
                                <p className="value text_blur">
                                  {" "}
                                  {replaceString(
                                    candidateData?.candidate?.otherDetails[0]
                                      ?.lslDetails?.memberNumber
                                  )}
                                </p>
                              )}
                            </Col>
                          </Row>
                        </div>
                        {candidateData?.candidate?.otherDetails[0]?.isPPE ? (
                          <>
                            <Divider className="horizontalDivider" />
                            <div>
                              <p className="title">PPE Details</p>
                              <Row gutter={16}>
                                <Col xs={12} md={8}>
                                  <p className="item">Top</p>
                                  <p className="value">
                                    {
                                      candidateData?.candidate?.otherDetails[0]
                                        ?.ppeDetails?.top
                                    }
                                  </p>
                                  {/* )} */}
                                </Col>
                                <Col xs={12} md={8}>
                                  <p className="item">Bottom</p>
                                  <p className="value">
                                    {
                                      candidateData?.candidate?.otherDetails[0]
                                        ?.ppeDetails?.bottom
                                    }
                                  </p>
                                </Col>
                              </Row>
                            </div>
                          </>
                        ) : null}
                        <Divider className="horizontalDivider" />
                        <div>
                          <p className="title">Travel Details</p>
                          <Row gutter={16}>
                            <Col xs={12} md={8}>
                              <p className="item">Preferred Airline</p>
                              {
                                <p className="value">
                                  {
                                    candidateData?.candidate?.otherDetails[0]
                                      ?.travelDetails?.preferredAirline
                                  }
                                </p>
                              }
                            </Col>

                            <Col xs={12} md={8}>
                              <p className="item">Frequent Flyer Number</p>
                              {
                                <p className="value">
                                  {
                                    candidateData?.candidate?.otherDetails[0]
                                      ?.travelDetails?.frequentFlyerNumber
                                  }
                                </p>
                              }
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </div>
                    {candidateData?.candidate?.otherDetails[0]
                      ?.hasPoliceClearance ||
                    candidateData?.candidate?.medicalAssessment.length > 0 ? (
                      <div className="clearance_box">
                        {candidateData?.candidate?.otherDetails[0]
                          ?.hasPoliceClearance && (
                          <>
                            {" "}
                            <p className="title">
                              Police Clearance
                              {candidateData?.candidate?.otherDetails[0]
                                ?.policeClearanceDetails?.isVerified && (
                                <img src={CheckMark} />
                              )}
                            </p>
                            <Row gutter={24}>
                              <Col xs={24} md={14}>
                                <DocumentBox
                                  docLink={
                                    candidateData?.candidate?.otherDetails[0]
                                      ?.policeClearanceDetails?.document
                                  }
                                  updatedDate={
                                    candidateData?.candidate?.otherDetails[0]
                                      ?.policeClearanceDetails?.issueDate
                                  }
                                  isHidden={isHidden}
                                />
                              </Col>
                              <Col xs={24} md={10} className="sm-mt-10">
                                <Row justify={"space-between"}>
                                  <Col span={12}>
                                    <p className="item">Certifying Authority</p>
                                    <p className="value">
                                      {
                                        candidateData?.candidate
                                          ?.otherDetails[0]
                                          ?.policeClearanceDetails
                                          ?.certifyingAuthority?.name
                                      }
                                    </p>
                                  </Col>
                                  <Col span={12}>
                                    <p className="item">Issue Date </p>
                                    <p className="value">
                                      {moment(
                                        candidateData?.candidate
                                          ?.otherDetails[0]
                                          ?.policeClearanceDetails?.issueDate
                                      ).format("DD MMM YYYY")}
                                    </p>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                            <Divider className="horizontalDivider" />
                          </>
                        )}
                        {candidateData?.candidate?.medicalAssessment.length >
                          0 && (
                          <>
                            <p className="title">
                              {citizen === "australian"
                                ? "Medical Assessment"
                                : "Medical Assessment"}
                            </p>
                            {candidateData?.candidate?.medicalAssessment[0]?.medicals?.map(
                              (med) => (
                                <Row gutter={24} className="mb-5">
                                  <Col xs={24} md={14}>
                                    <DocumentBox
                                      docLink={med?.document}
                                      updatedDate={med?.issueDate}
                                      isHidden={isHidden}
                                    />
                                  </Col>
                                  <Col xs={24} md={10} className="sm-mt-10">
                                    <Row justify={"space-between"}>
                                      <Col span={12}>
                                        <p className="item">
                                          {citizen === "australian"
                                            ? "Medical Assessment"
                                            : "Medical Assessment"}
                                        </p>
                                        <p className="value">
                                          {med?.medicalAssessment?.name}
                                        </p>
                                      </Col>
                                      <Col span={12}>
                                        <p className="item">Issue Date </p>
                                        <p className="value">
                                          {moment(med?.issueDate).format(
                                            "DD MMM YYYY"
                                          )}
                                        </p>
                                      </Col>
                                    </Row>
                                  </Col>
                                </Row>
                              )
                            )}
                          </>
                        )}
                      </div>
                    ) : (
                      <div className="clearance_box">
                        <p className="title">
                          {citizen === "australian"
                            ? "Medical Assessment"
                            : "Medical Assessment"}
                        </p>
                        <Row gutter={24} className="mb-5">
                          <Col xs={24} md={14}>
                            <DocumentBox
                              docLink={""}
                              updatedDate={""}
                              isHidden={true}
                            />
                          </Col>
                          <Col xs={24} md={10} className="sm-mt-10">
                            <Row justify={"space-between"}>
                              <Col span={12}>
                                <p className="item">
                                  {citizen === "australian"
                                    ? "Medical Assessment"
                                    : "Medical Assessment"}
                                </p>
                                <p className="value">{"-"}</p>
                              </Col>
                              <Col span={12}>
                                <p className="item">Issue Date </p>
                                <p className="value">{"-"}</p>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </Col>
        </Row>
        <UpdateApplicantProfileStatus
          visible={updateStatusModal}
          setVisible={setUpdateStatusModal}
        />
      </div>
    </SubLayout>
  );
}

export default ApplicantProfile;
