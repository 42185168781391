import React, { useState, useEffect } from "react";
import { Row, Carousel, message, Col } from "antd";
import API_MANAGER from "../../API";
import { useNavigate } from "react-router-dom";
function NewsFeedCarousel() {
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const getData = async () => {
    try {
      const response = await API_MANAGER.getNewsFeed();
      const temp = [
        ...response?.data?.data?.hottopic,
        ...response?.data?.data?.weekly,
      ];
      setData(temp);
    } catch (error) {
      message.error("Something went wrong. Please try again.");
    }
  };
  useEffect(() => {
    getData();
  }, []);
  return (
    <Row>
      <Col xs={24}>
        <Carousel className="campaign_carousel" autoplay>
          {data?.map((item, index) => (
            <Row className="header_carousel_item" key={index}>
              <Col xs={24} className="imageContainer">
                <img
                  src={item?.image}
                  alt="img"
                  style={{ height: "261px", objectFit: "cover" }}
                />
                <Row className="overlay">
                  <Col xs={24}>
                    <Col className="heading" style={{ paddingBottom: "12px" }}>
                      {item?.heading}{" "}
                    </Col>
                    {item?.shortDescription && (
                      <Col style={{ paddingBottom: "12px" }} className="desc">
                        {item?.shortDescription}
                      </Col>
                    )}
                    <span onClick={() => navigate(`/news-feed/${item?.id}`)}>
                      Read more
                    </span>
                  </Col>
                </Row>
              </Col>
            </Row>
          ))}
        </Carousel>
      </Col>
    </Row>
  );
}

export default NewsFeedCarousel;
