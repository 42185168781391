import { Col, Image, Row } from "antd";
import dangerIcon from "../../../Assets/danger.svg";
const NotificationPopup = ({ status, text }) => {
  return (
    <Row className="notification_popup_row">
      <Col span={24} >
        <Row justify={"space-between"}>
          <Col xs={1} className="align_items_center">
            <Image src={dangerIcon} />
          </Col>
          <Col className="content" xs={22}>
            <span>
              You can’t change the status of candidates who are at different
              stages of their application process.
            </span>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
export default NotificationPopup;
