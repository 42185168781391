import React, { useEffect, useRef } from "react";
import { Avatar, Button, Col, Input, Modal, Row, Tabs } from "antd";
import { CloseOutlined, SearchOutlined, UserOutlined } from "@ant-design/icons";
import { useNavigate, createSearchParams } from "react-router-dom";
import SearchIcon from "../../../Assets/search_icon.svg";
import CancelIcon from "../../../Assets/cancelIcon.svg";
const SearchModal = ({
  searchType,
  setSearchType,
  setIsSearchModalOpen,
  isSearchModalOpen,
  searchedData,
  searchText,
  centered,
  setSearchText,
  handleEnterButton,
}) => {
  const inputRef = useRef(null);
  const navigate = useNavigate();
  const showModal = () => {
    setIsSearchModalOpen(true);
  };
  const handleOk = () => {
    setIsSearchModalOpen(false);
  };
  const handleCancel = () => {
    setIsSearchModalOpen(false);
  };

  const ListRecord = () => {
    return (
      <div className="searchListRecordContainer">
        {searchedData?.result?.map((item, index) => (
          <Row justify={"space-between"} className="list-record">
            <Col style={{ width: "calc(100% - 52px)" }}>
              <Row
                gutter={8}
                align={"middle"}
                className="cursor_pointer"
                onClick={() => {
                  navigate(`/applicant-profile/${item?.userId}`, {
                    state: {
                      isInvited: item?.isInvited,
                      search: true,
                      name: item?.name,
                    },
                  });
                  setIsSearchModalOpen(false);
                }}
              >
                <Col>
                {item?.profileImage? <Avatar
                    size={36}
                    src={item?.profileImage}
                    className="profile-icon text_blur"
                  />:  <Avatar
                  size={36}
                  icon={<UserOutlined />}
                  src={item?.profileImage}
                  className="profile-icon "
                />}
                </Col>
                <Col
                  style={{ width: "calc(100% - 52px)" }}
                  className="candidateName"
                >
                  {item?.name}
                </Col>
              </Row>
            </Col>
            <Col>
              <Button
                type="link"
                onClick={() => {
                  navigate(`/applicant-profile/${item?.userId}`, {
                    state: {
                      isInvited: item?.isInvited,
                      search: true,
                      name: item?.name,
                    },
                  });
                  setIsSearchModalOpen(false);
                }}
              >
                <svg
                  width="20"
                  height="21"
                  viewBox="0 0 20 21"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5 15.5L15 5.5M15 5.5H8.33333M15 5.5V12.1667"
                    stroke="#1638B1"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </Button>
            </Col>
          </Row>
        ))}
      </div>
    );
  };

  const items = [
    {
      key: "all",
      label: " All Results",
      children: ListRecord(),
    },
    {
      key: "candidates",
      label: "Candidates",
      children: ListRecord(),
    },
    {
      key: "discipline",
      label: "Disciplines",
      children: ListRecord(),
    },
  ];
  const handleInputChange = (e) => {
    setSearchText(e);
    // handleEnterButton();
  };
  useEffect(() => {
    setTimeout(() => {
      inputRef?.current?.focus({
        cursor: "end",
      });
    }, 200);

  }, [isSearchModalOpen, inputRef]);

  return (
    <Modal
      title={
        <Row justify={"space-between"}>
          <Col className="w-100">
            <Row align={"middle"} gutter={12}>
              {/* <Col xs={2}>
                <img src={SearchIcon} alt="icon" />
              </Col> */}
              <Col xs={24} className="searchModalTitle">
                <Input
                  prefix={<img src={SearchIcon} alt="icon" />}
                  ref={inputRef}
                  autoFocus
                  suffix={
                    searchText && (
                      <img
                        src={CancelIcon}
                        className="cursor_pointer"
                        alt="cross"
                        onClick={() => {
                          setSearchText("");
                          handleEnterButton();
                        }}
                      />
                    )
                  }
                  className="searchModalInputBox"
                  value={searchText}
                  onChange={(e) => handleInputChange(e.target.value)}
                />
              </Col>
              {/* <Col
                xs={2}
                style={{ display: "flex", alignItems: "center" }}
                onClick={() => setIsSearchModalOpen(false)}
              ></Col> */}
            </Row>
          </Col>
        </Row>
      }
      open={isSearchModalOpen}
      onCancel={handleCancel}
      className=""
      wrapClassName="search-modal-main-container"
      closeIcon={false}
      footer={null}
      centered={centered ? true : false}
    >
      <Row justify={"space-between"} className="mt-20">
        <Col className="searchBy">
          {`${searchedData?.totalCount ? searchedData?.totalCount : 0
            } results found`}{" "}
        </Col>
        <Col>
          {" "}
          <Button
            type="link"
            onClick={() => {
              if (searchedData?.result?.length > 0) {
                navigate(`/search?search=${searchText}&page=1`);
              }
            }}
            className="viewAll"
            style={{
              cursor: `${searchedData?.result?.length > 0 ? "pointer" : "not-allowed"
                }`,
              color: `${searchedData?.result?.length > 0 ? "#071135" : "#a7aebb"
                }`,
            }}
          >
            View All
          </Button>
        </Col>
      </Row>
      <span className="searchBy">Search by:</span>
      <Tabs
        defaultActiveKey="1"
        items={items}
        onChange={(e) => setSearchType(e)}
      />

      <Row justify={"end"} className="mt-20" gutter={16}>
        <Col>
          {" "}
          <Button
            className="cancel_btn"
            onClick={() => {
              handleInputChange(null);
              setIsSearchModalOpen(false);
            }}
          >
            Cancel
          </Button>
        </Col>
        <Col>
          {" "}
          <Button
            className="black_color_button"
            onClick={() => {
              if (searchedData?.result?.length > 0) {
                navigate(`/search?search=${searchText}&page=1`);
                setIsSearchModalOpen(false);
              }
            }}
            disabled={searchedData?.result?.length < 1}
          >
            Done
          </Button>
        </Col>
      </Row>
    </Modal>
  );
};

export default SearchModal;
