import { Col, Progress, Row } from "antd";
import React, { useEffect, useState } from "react";
import ProgressDone from "../../../Assets/campagin/sectionProgress/progress.svg";
import inactiveImage from "../../../Assets/campagin/sectionProgress/disabled.svg";
import checkFilled from "../../../Assets/campagin/sectionProgress/filled.svg";
import UpgradeMembershipModal from "../UpgradeMembershipPopup";
function SectionProgress({ percent, status }) {
  const [open, setOpen] = useState(false);
  const isPlusSubscription = JSON.parse(
    localStorage.getItem("isPlusSubscription")
  );
  return (
    <Row gutter={10} className="section-progress-container  justify-center">
      <Col xs={8} xl={5} lg={6} className="add-advert-btn">
        <Row className="justify-center mb-8">
          <img
            alt="advert detail status"
            src={status?.detail == "progress" ? ProgressDone : checkFilled}
          />
        </Row>
        <Row className="justify-center">
          <p className="active">Add Advert Details</p>
        </Row>
      </Col>
      <Col xs={8} md={10} className="progress-bar align-center d-flex">
        <Progress showInfo={false} percent={percent} />
      </Col>
      <Col
        xs={8}
        xl={4}
        lg={6}
        className="cursor-pointer"
        onClick={() => {
          if (!isPlusSubscription) {
            setOpen(true);
          }
        }}
      >
        <Row className="justify-center mb-8">
          <img
            alt="add requirement status"
            src={
              percent < 76
                ? inactiveImage
                : !percent === 100
                ? ProgressDone
                : checkFilled
            }
          />
        </Row>
        <Row className="justify-center">
          <p className={`${!percent == 100 ? "inactive" : "active"}`}>
            Add Requirements
          </p>
        </Row>
      </Col>
      <UpgradeMembershipModal
        setUpgradeMembership={setOpen}
        upgradeMembership={open}
      />
    </Row>
  );
}

export function OnBoardingProgress({ percent, status, setcurrentForm }) {
  const handleClick = (title, itemStatus) => {
    if (
      itemStatus === "success" ||
      itemStatus === "progress" ||
      itemStatus === "sucess"
    )
      setcurrentForm(title);
  };
  return (
    <Row className="onboarding_progerss_container w-100">
      <Col span={4} className="add-advert-btn ">
        <Row className="justify-center mb-8">
          <img
            alt="advert detail status"
            onClick={() => handleClick("onboarding", status.company)}
            className="cursor_pointer"
            src={status?.company === "progress" ? ProgressDone : checkFilled}
          />
        </Row>
        <Row className="justify-center">
          <p
            className={
              status?.company === "success" || status?.company == "progress"
                ? "active-text"
                : "normal_text"
            }
          >
            Onboard Your Company
          </p>
        </Row>
      </Col>
      <Col span={3} className="progress-bar align-center d-flex">
        <Progress
          showInfo={false}
          percent={percent?.first}
          className="onboardingProgressBar"
        />
      </Col>
      <Col span={3} className="add-advert-btn">
        <Row className="justify-center mb-8">
          <img
            alt="advert detail status"
            className={
              status.plan === "progress" || status.plan === "sucess"
                ? "cursor_pointer"
                : ""
            }
            onClick={() => handleClick("plan", status?.plan)}
            src={
              status?.plan == "progress"
                ? ProgressDone
                : status?.plan != "sucess"
                ? inactiveImage
                : checkFilled
            }
          />
        </Row>
        <Row className="justify-center">
          <p
            className={
              status.plan == "sucess" || status.plan == "progress"
                ? "active-text"
                : "normal_text"
            }
          >
            Select your Plan
          </p>
        </Row>
      </Col>
      <Col span={3} className="progress-bar align-center d-flex">
        <Progress
          showInfo={false}
          percent={percent?.second}
          className="onboardingProgressBar"
        />
      </Col>
      <Col span={3} className="add-advert-btn">
        <Row className="justify-center mb-8">
          <img
            className={
              status.user === "progress" || status.user === "sucess"
                ? "cursor_pointer"
                : ""
            }
            alt="advert detail status"
            onClick={() => handleClick("add user", status.user)}
            src={
              status?.user == "progress"
                ? ProgressDone
                : status?.plan != "sucess"
                ? inactiveImage
                : checkFilled
            }
          />
        </Row>
        <Row className="justify-center">
          <p
            className={
              status.user == "sucess" || status.user == "progress"
                ? "active-text"
                : "normal_text"
            }
          >
            Add Users
          </p>
        </Row>
      </Col>
      <Col span={3} className="progress-bar align-center d-flex">
        <Progress
          showInfo={false}
          percent={percent?.third}
          className="onboardingProgressBar"
        />
      </Col>
      <Col span={3} className="add-advert-btn">
        <Row className="justify-center mb-8">
          <img
            className={
              status?.pay === "progress" || status.pay === "sucess"
                ? "cursor_pointer"
                : ""
            }
            alt="advert detail status"
            onClick={() => handleClick("pay", status.pay)}
            src={
              status?.pay == "progress"
                ? ProgressDone
                : status?.user != "sucess"
                ? inactiveImage
                : checkFilled
            }
          />
        </Row>
        <Row className="justify-center">
          <p
            className={
              status.pay == "sucess" || status.pay == "progress"
                ? "active-text"
                : "normal_text"
            }
          >
            Review and Pay
          </p>
        </Row>
      </Col>
    </Row>
  );
}

export default SectionProgress;
