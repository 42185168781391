import { Button, Col, Modal, Row } from "antd";
import { useNavigate } from "react-router-dom";

const UserExhaust = ({
  userExhaustModal,setUserExhaustModal,
  textContent,
}) => {
  const navigate = useNavigate();
  const user = localStorage.getItem("user");
  const handleOkayButton = () => {
    setUserExhaustModal(false);
    navigate("/subscription-management");
  };
  const handleCancel = () => {
    setUserExhaustModal(false);
  };
  const text =
    textContent ||
    "Your current subscription does not support this feature. Please upgrade, if you want to gain access!";
  return (
    <Modal
      centered
      title="Buy User(s)"
      open={userExhaustModal}
      closeIcon={true}
      onCancel={handleCancel}
      className="upgradeMembershipModal"
      footer={
        <Row gutter={16} className="justify-end d-flex">
          <Col>
            {user === "Root" || user === "Admin" ? (
              <Button onClick={handleOkayButton} className="black_color_button">
                Buy User
              </Button>
            ) : (
              <Button className="black_color_button" onClick={handleCancel}>Okay</Button>
            )}
          </Col>
        </Row>
      }
    >
      <Row>
        <Col span={24}>
          <span className="modal_content">{text}</span>
        </Col>
      </Row>
    </Modal>
  );
};
export default UserExhaust;
