import { Col, Row, message } from "antd";
import React, { useEffect, useState } from "react";
import SubLayout from "../../components/layout/SubLayout";
import NonProtectedLayout from "../../components/layout/NonProtectedLayout";
import API_MANAGER from "../../API";
import Layout from "../../components/layout";
import { IsTokenValid } from "../../utils/middleware";
import FullScreenLoader from "../../components/common/FullScreenLoader";
import { useLocation } from "react-router-dom";
import moment from "moment";
import MobileHeader from "../../components/layout/MobileHeader";
function TermsOfUse() {
  const [data, setData] = useState();
  const isAuthenticated = IsTokenValid();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    API_MANAGER.getTermsConditionData()
      .then((res) => {
        setLoading(false);
        setData(res?.data?.data);
      })
      .catch((err) => {
        setLoading(false);
        message.error("something went wrong");
      });
  }, []);
  const location = useLocation();
  const name = localStorage.getItem("name");
  const createdAt = localStorage.getItem("createdAt");
  return (
    <>
      {isAuthenticated ? (
        <Layout>
          <SubLayout page={"Terms & Conditions"}>
            <div className="terms_of_use_container">
              <MobileHeader title={"Terms and Conditions"} />
              <div className="aboutus_banner">
                <h2 className="bannerHeading">Terms & Conditions</h2>
              </div>
              <div className="dynamic_content_container">
                <div className="mb-45">
                  <p className="terms_heading">
                    "Cinchy.me" Terms
                    <br />
                  </p>
                  <span className="acceptanceText">
                    Accepted by{" "}
                    <span
                      className="acceptanceText"
                      style={{ textTransform: "capitalize" }}
                    >
                      {name ? name : ""}
                    </span>{" "}
                    on {moment(createdAt).format("DD/MM/YYYY")}
                  </span>
                </div>
                <div
                  className="apiData"
                  dangerouslySetInnerHTML={{ __html: data?.description }}
                />
              </div>
            </div>
          </SubLayout>
        </Layout>
      ) : (
        <NonProtectedLayout
          // page={"Subscription Management"}
          back={location.search ? true : null}
          whiteBg
        >
          <FullScreenLoader isLoading={loading} />
          <div className="terms_of_use_container">
            <Row>
              <Col xs={24} md={0}>
                <p className="goback">{"< Terms and Conditions"}</p>
              </Col>
            </Row>
            <Row>
              <div style={{ marginTop: "20px" }} className="aboutus_banner">
                <h2 className="bannerHeading">Terms & Conditions</h2>
              </div>
            </Row>
            <div className="dynamic_content_container ">
              <p className="terms_heading">"Cinchy.me" Terms</p>
            <div
              className="apiData"
              dangerouslySetInnerHTML={{ __html: data?.description }}
            />
            </div>
          </div>
        </NonProtectedLayout>
      )}
    </>
  );
}

export default TermsOfUse;
