import React, { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import Layout from "../components/layout";
import CampaignManagement from "../pages/CampaignManagement";
import UserManagement from "../pages/UserManagement";
import SubscriptionManagement from "../pages/SubscriptionManagement";
import CampaignDetail from "../pages/CampaignManagement/CampaignDetail";
import NewsFeed from "../pages/NewsFeed";
import ApplicantProfile from "../pages/CampaignManagement/ApplicantProfile";
import { useNavigate } from "react-router-dom";
import CreateAdvert from "../pages/CampaignManagement/CreateAdvert";
import TrackerHistory from "../pages/CampaignManagement/TrackerHistory";
import AdvertDetail from "../pages/CampaignManagement/AdvertDetail";
import AddUser from "../pages/UserManagement/AddUser";
import AddCampaign from "../pages/CampaignManagement/AddCampaign";
import Search from "../pages/search/search";
import NewsDetails from "../pages/NewsFeed/NewsDetails";
import MyProfile from "../pages/Profile";
import EditProfile from "../pages/Profile/editProfile";
import UserProfile from "../pages/UserManagement/userProfile";
import EditUser from "../pages/UserManagement/EditUser";
import Archive from "../pages/archive/Archive";
import JobAdvert from "../pages/JobAdvert";
import ErrorPage from "../components/common/ErrorPage";
import EditCampaign from "../pages/CampaignManagement/EditCampaign";
import API_MANAGER from "../API";
import { message } from "antd";

const CustomRoutes = () => {
  const navigate = useNavigate();
  const getSubscription = async () => {
    try {
      const response = await API_MANAGER.getPaymentHistory();
      localStorage.setItem(
        "subscription",
        JSON.stringify(response?.data?.data)
      );
      if (
        response?.data?.data?.sp &&
        response?.data?.data?.sp?.plusSubscription
      ) {
        localStorage.setItem(
          "isPlusSubscription",
          response?.data?.data?.sp?.plusSubscription
        );
      } else {
        localStorage.setItem("isPlusSubscription", false);
      }
      if (response?.data?.data?.active) {
        localStorage.removeItem("isStripe");
        navigate("/campaign-management");
      }
    } catch (error) {
      message.error("Something went wrong. Please try again.");
    }
  };
  useEffect(() => {
    const isStripe = localStorage.getItem("isStripe");
    const subscription = JSON.parse(localStorage.getItem("subscription"));
    if (isStripe === "yes") {
      getSubscription();
    } else if (!subscription?.sp?.active) {
      navigate("/onboarding");
    }
  }, []);

  return (
    <Layout>
      <Routes>
        <Route path="/user-management" element={<UserManagement />} />
        <Route path="/user-management/:id" element={<UserProfile />} />
        <Route
          path="/user-management/edit-profile/:id"
          element={<EditUser />}
        />
        <Route path="/user-management/add-user" element={<AddUser />} />

        <Route
          path="/subscription-management"
          element={<SubscriptionManagement />}
        />

        <Route path="/news-feed" element={<NewsFeed />} />
        <Route path="/news-feed/:id" element={<NewsDetails />} />

        <Route path="/campaign-detail/:id" element={<CampaignDetail />} />
        <Route path="/campaign-management" element={<CampaignManagement />} />
        <Route
          path="/campaign-management/add-campaign"
          element={<AddCampaign />}
        />
        <Route
          path="/campaign-management/edit-campaign"
          element={<EditCampaign />}
        />

        <Route path="/create-advert" element={<CreateAdvert />} />
        <Route path="/create-advert/:id" element={<CreateAdvert />} />

        <Route path="/applicant-profile" element={<ApplicantProfile />} />
        <Route path="/applicant-profile/:id" element={<ApplicantProfile />} />

        <Route path="/advert-tracker/:id" element={<TrackerHistory />} />
        <Route path="/advert-detail/:id" element={<AdvertDetail />} />

        {/* Route for Associate User */}
        <Route path="/job-advert" element={<JobAdvert />} />
        {/* <Route path="/terms-of-use" element={<TermsOfUse />} /> */}
        {/* <Route path="/privacy-policy" element={<PrivacyPolicy />} /> */}
        {/* <Route path="/contact-us" element={<Contactus />} /> */}
        <Route path="/search" element={<Search />} />
        {/* <Route path="/news-details" element={<NewsDetails />} /> */}

        <Route path="/myProfile" element={<MyProfile />} />
        <Route path="/edit-profile" element={<EditProfile />} />
        <Route path="/archive" element={<Archive />} />
        <Route path="/404" element={<ErrorPage />} />
      </Routes>
    </Layout>
  );
};

export default CustomRoutes;
