import React from "react";
import ErrorImage from "../../Assets/layout/Session.svg";
import Layout from "../layout";
import NonProtectedLayout from "../layout/NonProtectedLayout";
import SubLayout from "../layout/SubLayout";
import { IsTokenValid } from "../../utils/middleware";
import { Button, Row, Col } from "antd";
import { useNavigate } from "react-router-dom";
function SessionExpired() {
  const isAuthenticated = IsTokenValid();
  const navigate = useNavigate();
  return (
    <>
      {isAuthenticated ? (
        <Layout>
          <SubLayout page={"No Internet"} showBack>
            <div
              style={{
                justifyContent: "center",
                display: "flex",
                alignItems: "center",
                height: "calc(100vh - 148px)",
                fontSize: "32px",
                textAlign: "center",
              }}
              className="errorPage"
            >
              <div className="text_align_center">
                <img src={ErrorImage} />
                <p
                  className="title"
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  Session Expired
                </p>
                <p className="desc">
                  Your session has expired.
                  <br /> Please log in again.
                </p>
                <Row justify={"center"}>
                  <Col>
                    <Button
                      onClick={() => {
                        localStorage.removeItem("session");
                        navigate("login");
                      }}
                    >
                      Log In
                    </Button>
                  </Col>
                </Row>
              </div>
            </div>
          </SubLayout>
        </Layout>
      ) : (
        <NonProtectedLayout>
          <div
            style={{
              justifyContent: "center",
              display: "flex",
              alignItems: "center",
              height: "calc(100vh - 100px)",
              fontSize: "32px",
              textAlign: "center",
            }}
            className="errorPage"
          >
            <div>
              <img src={ErrorImage} />
              <p
                className="title"
                style={{ display: "flex", justifyContent: "center" }}
              >
                Session Expired
              </p>
              <p className="desc">
                Your session has expired.
                <br /> Please log in again.
              </p>
              <Row justify={"center"}>
                <Col>
                  <Button
                    onClick={() => {
                      localStorage.removeItem("session");
                      navigate("login");
                    }}
                  >
                    Log In
                  </Button>
                </Col>
              </Row>
            </div>
          </div>
        </NonProtectedLayout>
      )}
    </>
  );
}

export default SessionExpired;
