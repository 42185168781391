import {
  Row,
  Col,
  Form,
  Input,
  Button,
  Switch,
  Modal,
  Upload,
  Table,
  message,
} from "antd";
import SubLayout from "../../components/layout/SubLayout";
import { permissionData } from "../../utils/constant";
import { useEffect, useRef, useState } from "react";
import DefaultCompanyLogo from "../../Assets/defaultCompanyLogo.svg";
import PermissionIndividualCard from "../../components/common/UserManagement/PermissionIndividualCard";
import API_MANAGER from "../../API";
import PhoneInput from "react-phone-number-input";
import AlertState from "../../components/common/AlertState";
import FullScreenLoader from "../../components/common/FullScreenLoader";
import { useNavigate } from "react-router-dom";
import SearchIcon from "../../Assets/search_icon.svg";
import PermissionModal from "../UserManagement/permissionModal";
import { PlusOutlined } from "@ant-design/icons";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import moment from "moment";
import CustomPagination from "../../components/common/CustomPagination";
import { GoogleComponent } from "react-google-location";
import axios from "axios";
const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
function EditProfile() {
  const [permissionIndex, setPermissionIndex] = useState();
  const [updatePermission, setUpdatePermission] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();
  const [userData, setUserData] = useState();
  const [address, setAddress] = useState(null);
  const [form] = Form.useForm();
  const [form1] = Form.useForm();
  const [countryCode, setCountryCode] = useState("+61");
  const [id, setId] = useState();
  const [alertState, setAlertState] = useState("");
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [permissions, setPermissions] = useState([]);
  const [selectedCount, setSelectedCount] = useState(2);
  const [permissionModalOpen, setPermissionModalOpen] = useState(false);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [imageChange, setImageChange] = useState(false);
  const [search, setSearch] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const user = localStorage.getItem("user");
  const [campaignList, setCampaignList] = useState();
  const [fileList, setFileList] = useState([]);
  const [marketingPermission, setMarketingPermission] = useState(false);
  const props = {
    name: "file",
    multiple: false,
    customRequest({ file, onSuccess }) {
      setTimeout(() => {
        onSuccess("ok");
      }, 0);
    },
  };
  const columns = [
    {
      title: "Campaign Name",
      dataIndex: "campaign",
      key: "campaignName",
      fixed: "left",
    },
    {
      title: "Job Advert",
      dataIndex: "jobTitle",
      key: "advertName",
    },
    {
      title: "Start Date",
      dataIndex: "startDate",
      key: "start_date",
      render: (item) => <span>{moment(item)?.format("DD MMM YYYY")}</span>,
    },
    {
      title: "End Date",
      dataIndex: "endDate",
      key: "end_date",
      render: (item) => <span>{moment(item)?.format("DD MMM YYYY")}</span>,
    },
    {
      title: "Status",
      dataIndex: "archive",
      render: (item) => {
        if (!item) {
          return <span className="Live_text">Live</span>;
        } else {
          return <span className="archived_text">Archived</span>;
        }
      },
      align: "center",
    },
  ];
  const navigate = useNavigate();
  const handleCancel = () => setPreviewOpen(false);

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
  };

  const handleChange = ({ fileList: newFileList }) => {
    setImageChange(true);
    setFileList(newFileList);
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );
  //API TO GET MY PROFILE DATA
  const getUserData = async () => {
    const params = {
      companyId: localStorage.getItem("clientId"),
    };
    try {
      const response = await API_MANAGER.getUserData(params);
      setData(response?.data?.data);
      setMarketingPermission(response?.data?.data?.marketingPermission);
    } catch (error) {
      message.error("Something went wrong. Please try again.");
    }
  };
  //USER DETAILS CARD API
  const SingleUserData = async () => {
    setLoading(true);
    try {
      const response = await API_MANAGER.getSingleUserData(
        localStorage.getItem("userID")
      );
      setUserData(response?.data?.data);
      setLoading(false);
    } catch (error) {
      message.error("Something went wrong. Please try again.");
    }
  };
  //change personal details API
  const onFinish = async (values) => {
    setLoading(true);
    values = { ...values, ["countryCode"]: countryCode };

    try {
      const response = await API_MANAGER.updateUserPersonalDetails(
        userData?.id,
        values
      );
      if (response?.data?.data?.firstName) {
        localStorage.setItem(
          "name",
          `${response?.data?.data?.firstName} ${response?.data?.data?.lastName}`
        );
      }
      SingleUserData();
      getUserData();
      setLoading(false);
      message.success("Profile successfully updated.");
    } catch (error) {
      setLoading(false);
      message.error("Something went wrong. Please try again.");
    }
  };
  //UPDATE COMPANY DETAILS API
  const companyDetailsOnFinish = async (values) => {
    setLoading(true);
    try {
      let imageData = null;
      if (imageChange && fileList.length > 0) {
        const dataImage = {
          extension:
            fileList?.length > 0 ? fileList[0]?.type.split("/")[1] : "",
          type: "PROFILE",
          contentType: fileList?.length > 0 ? fileList[0]?.type : "",
        };
        const responseImage = await API_MANAGER.fileUpload(dataImage);
        imageData = responseImage?.data?.data?.key;
        await axios.put(
          responseImage?.data?.data?.url,
          fileList[0]?.originFileObj
        );
        values.companyLogo = imageData;
      } else if (imageChange) {
        values.companyLogo = imageData;
      }

      await API_MANAGER.updateCompanyDetails(values);
      setLoading(false);
      message.success("Profile successfully updated.");

      // navigate(-1);
    } catch (error) {
      setLoading(false);

      message.error("Something went wrong. Please try again.");
    }
  };
  const getAdvertList = async () => {
    setLoading(true);
    try {
      const response = await API_MANAGER.getSingleUserCampaign();
      setCampaignList(response?.data?.data?.result);
    } catch (error) {
      message.error(error?.response?.data?.message?.message);
    }
    setLoading(false);
  };
  useEffect(() => {
    getUserData();
    SingleUserData();
  }, []);

  useEffect(() => {
    if (user === "Associate") {
      getAdvertList();
    }
  }, [search]);

  useEffect(() => {
    form.setFieldsValue({
      companyName: data?.companyName,
      tradingName: data?.tradingName,
      companyAbn: data?.companyAbn,
      billingEmail: data?.billingEmail,
      billingName: data?.billingName,
      officeAddress: data?.officeAddress,
    });
    setAddress(data?.officeAddress);
    setId(userData?.id);
    setFileList((prevFileList) => [
      {
        ...prevFileList[0],
        url: data?.companyLogo,
      },
    ]);
  }, [data]);
  useEffect(() => {
    form1.setFieldsValue({
      firstName: userData?.firstName,
      lastName: userData?.lastName,
      email: userData?.email,
      countryCode: userData?.countryCode,
      phoneNumber: userData?.phoneNumber,
    });
  }, [userData]);
  useEffect(() => {
    if (userData?.recruiterUserType === "Standard") setPermissionIndex(1);
    else if (
      userData?.recruiterUserType === "Admin" ||
      userData?.recruiterUserType === null
    )
      setPermissionIndex(0);
    else setPermissionIndex(2);
  }, [userData]);
  useEffect(() => {
    if (permissionIndex === 0) {
      setPermissions([0, 1, 2, 3, 4, 5]);
    } else if (permissionIndex === 1) {
      if (userData?.permissions) {
        setPermissions(userData?.permissions);
      } else {
        setPermissions([0, 1, 3, 4, 5]);
      }
    } else if (permissionIndex === 2) {
      setPermissions([4, 5]);
    } else {
      setPermissions([]);
    }
  }, [permissionIndex]);
  const handlePermissionModal = () => {
    if (permissions.length !== 0) setPermissionModalOpen(true);
    else {
      message.error("Permissions not selected yet.");
    }
  };

  return (
    <SubLayout page={"Edit Profile"} whiteBg padding showBack>
      <FullScreenLoader isLoading={loading} />
      <AlertState
        state={alertState}
        message={alertMessage}
        alertOpen={alertOpen}
        setAlertOpen={setAlertOpen}
      />
      <PermissionModal
        permissionModalOpen={permissionModalOpen}
        setPermissionModalOpen={setPermissionModalOpen}
        singleUserData={userData}
        permissionIndex={permissionIndex}
        editUser={userData?.recruiterUserType}
        permissions={permissions}
        id={userData?.id}
        editProfile={"true"}
      />
      <Row className="profile_container">
        <Col xs={24}>
          {/* Personal Details */}
          <Col>
            <p className="heading">
              {userData?.firstName?.charAt(0).toUpperCase() +
                userData?.firstName?.slice(1) +
                " " +
                userData?.lastName?.charAt(0).toUpperCase() +
                userData?.lastName?.slice(1)}
            </p>
          </Col>
          <Form form={form1} layout="vertical" onFinish={onFinish}>
            <Col xs={24} className="personal_detail_container">
              <Col xs={24} className="form_title_container">
                <p className="form_title">Personal Details</p>
              </Col>
              <Col className="form_container">
                <Row gutter={100}>
                  <Col xs={12}>
                    <Form.Item label="First Name" name="firstName">
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col xs={12}>
                    <Form.Item label="Last Name" name="lastName">
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={100}>
                  <Col xs={12}>
                    <Form.Item label="Email*" name="email">
                      <Input disabled={true} />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={12}>
                    <label className="custome_label">Contact Number</label>
                    <Row>
                      <Col xs={7} sm={6} lg={5}>
                        <Form.Item
                          name="countryCode"
                          rules={[
                            {
                              required: false,
                              message: "",
                            },
                          ]}
                        >
                          {/* components from other library */}
                          <PhoneInput
                            international
                            countryCallingCodeEditable={false}
                            className="w-100 emailInput PhoneInput"
                            defaultCountry="AU"
                            value={countryCode}
                            onChange={setCountryCode}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={17} sm={18} lg={19}>
                        <Form.Item
                          name="phoneNumber"
                          rules={[
                            {
                              required: true,
                              message: "",
                            },
                          ]}
                        >
                          <Input
                            placeholder="phone number"
                            type="phone"
                            className="form_input_box border_right_bottom"
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row justify={"end"} gutter={16}>
                  <Col className="buttonCol">
                    <Form.Item>
                      <Button
                        className="button_cancel"
                        onClick={() => navigate(-1)}
                      >
                        Cancel
                      </Button>
                    </Form.Item>
                  </Col>
                  <Col className="buttonCol">
                    <Form.Item>
                      <Button htmlType="submit" className="button_update">
                        Update
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Col>
          </Form>
          {/* Permissions */}
          {userData?.recruiterUserType !== "Associate" && (
            <Col
              span={24}
              className="personal_detail_container"
              style={{ margin: "20px 0px 40px 0px" }}
            >
              <Col xs={24} className="form_title_container">
                <p className="form_title">Permissions</p>
              </Col>
              <Col xs={24}>
                <Row justify={"center"} className="mt-24 mt-0 p-24" gutter={12}>
                  {permissionData.map((item, index) => (
                    <Col xs={24} lg={12} xl={8} className="cards_Col">
                      <PermissionIndividualCard
                        data={item}
                        index={index}
                        setPermissionIndex={setPermissionIndex}
                        permissionIndex={permissionIndex}
                        permissions={permissions}
                        setPermissions={setPermissions}
                        editUser={userData?.recruiterUserType}
                        selectedCount={selectedCount}
                        setSelectedCount={setSelectedCount}
                        userEditProfile={"yes"}
                      />
                    </Col>
                  ))}
                </Row>
                <Row className="p-24" gutter={16} justify={"end"}>
                  <Col className="justify_items_end f_col_rev">
                    <Button
                      className="button_no_border button_no_border_sm"
                      onClick={() => navigate(-1)}
                    >
                      Cancel
                    </Button>
                  </Col>
                  <Col className="justify_items_end f_col_rev">
                    <Button
                      className="button_1 button_1_sm"
                      onClick={handlePermissionModal}
                    >
                      Update
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Col>
          )}
          {/* Company Details */}
          {user !== "Associate" && user !== "Account" && (
            <Col xs={24} className="company_detail_container">
              <Form
                form={form}
                layout="vertical"
                onFinish={companyDetailsOnFinish}
              >
                <Col xs={24} className="form_title_container">
                  <p className="form_title">Company Details</p>
                </Col>
                <Col className="form_container">
                  <Row gutter={100}>
                    <Col xs={12}>
                      <Form.Item label="Company Name*" name="companyName">
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col xs={12}>
                      <Form.Item label="Trading Name" name="tradingName">
                        <Input />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={100}>
                    <Col xs={12}>
                      <Form.Item label="ACN/ABN*" name="companyAbn">
                        <Input disabled={true} />
                      </Form.Item>
                    </Col>
                    <Col xs={12}>
                      <Form.Item
                        label="Registered Address*"
                        name="officeAddress"
                        className="googleApiContainer"
                      >
                        <GooglePlacesAutocomplete
                          apiKey="AIzaSyBgimhyTUzrdCN2YF7Z81vu0WJbTECDCkA"
                          autocompletionRequest={{
                            componentRestrictions: {
                              country: ["au"], //to set the specific country
                            },
                          }}
                          selectProps={{
                            placeholder: "Add address",
                            value: address,
                            onChange: (e) => {
                              form.setFieldsValue({ officeAddress: e });
                              // handelVerify("registered_address", e);
                              setAddress(e);
                            },
                          }}
                          apiOptions={{
                            language: "en",
                            componentRestrictions: { country: "au" },
                            // types: ["regions"],
                          }}
                        />
                        {/* <GoogleComponent
                          apiKey={"AIzaSyBgimhyTUzrdCN2YF7Z81vu0WJbTECDCkA"}
                          language={"en"}
                          country={"country:au"}
                          coordinates={true}
                          locationBoxStyle={"custom-style"}
                          locationListStyle={"custom-style-list"}
                          value={address}
                          place={address}
                          defaultChecked={address}
                          onChange={(e) => {
                            form.setFieldsValue({
                              officeAddress: { ...e, ["label"]: e?.place },
                            });
                            setAddress({ ...e, ["label"]: e?.place });
                          }}
                        /> */}
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={100}>
                    <Col xs={12}>
                      <Form.Item label="Billing Name*" name="billingName">
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col xs={12}>
                      <Form.Item label="Billing Email*" name="billingEmail">
                        <Input type="email" />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={100}>
                    <Col xs={12}>
                      <Form.Item label="Upload Logo*" name="companyLogo">
                        <Upload
                          listType="picture-card"
                          fileList={fileList}
                          onPreview={handlePreview}
                          onChange={handleChange}
                          multiple={false}
                          accept="image/png, image/jpeg"
                          {...props}
                        >
                          {fileList?.length >= 1 ? null : uploadButton}
                        </Upload>
                        <Modal
                          centered
                          open={previewOpen}
                          title={previewTitle}
                          footer={null}
                          onCancel={handleCancel}
                        >
                          <img
                            alt="example"
                            style={{ width: "100%" }}
                            src={previewImage}
                          />
                        </Modal>
                      </Form.Item>
                    </Col>
                    <Col xs={12}>
                      <Row>
                        <Col xs={22}>
                          <p className="marketing_title">
                            Marketing Permission
                          </p>
                          <p className="marketing_value">
                            Receive the latest product and marketing updates{" "}
                            <br /> from Cinchy.Me and selected partners
                          </p>
                        </Col>
                        <Col xs={2} className="switch_Col">
                          <Form.Item name="marketingPermission">
                            <Switch
                              checked={marketingPermission}
                              // className="switch_toggle"
                              onChange={(e) => {
                                {
                                  form.setFieldsValue({
                                    marketingPermission: e,
                                  });
                                  setMarketingPermission(e);
                                }
                              }}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row gutter={16} justify={"end"}>
                    <Col className="buttonCol">
                      <Form.Item>
                        <Button
                          className="button_cancel"
                          onClick={() => navigate(-1)}
                        >
                          Cancel
                        </Button>
                      </Form.Item>
                    </Col>
                    <Col className="buttonCol">
                      <Form.Item>
                        <Button htmlType="submit" className="button_update">
                          Update
                        </Button>
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Form>
            </Col>
          )}
        </Col>
        {user === "Associate" && (
          <Col
            xs={24}
            className="user_management_table_box"
            style={{ paddingTop: "20px" }}
          >
            <Table
              columns={columns}
              dataSource={campaignList}
              loading={loading}
              title={() => (
                <Row gutter={16} align={"middle"} justify={"space-between"}>
                  <Col xs={24} md={12}>
                    <Row className="justify_space_between_resp">
                      <Col xs={24} md={24}>
                        <Input.Search
                          className="searchBox searchInput"
                          allowClear
                          placeholder="Search campaign or job advert"
                          onChange={(e) => setSearch(e?.target?.value)}
                          prefix={<img src={SearchIcon} alt="search" />}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              )}
            />
            <CustomPagination
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              total={campaignList?.length}
              itemPerPage={10}
            />
          </Col>
        )}
      </Row>
      <Modal
        className="permission_modal"
        title="Change Permission?"
        footer={null}
        open={updatePermission}
        onCancel={() => setUpdatePermission(false)}
      >
        <Row>
          <Col>
            <p className="para">
              Are you sure you want to change user type to <b>Standard User</b>?
              You will be redirected to home page after confirmation.
            </p>
          </Col>
          <Col xs={24} className="buttonCol">
            <Button className="modal_cancel">Cancel</Button>
            <Button className="modal_yes">Yes</Button>
          </Col>
        </Row>
      </Modal>
    </SubLayout>
  );
}
export default EditProfile;
