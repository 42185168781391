import { Button, Col, Form, Input, Row, Select, Space, message } from "antd";
import React, { useEffect, useState } from "react";
import NonProtectedLayout from "../../components/layout/NonProtectedLayout";
import form_arrow from "../../Assets/contactFormArrow.svg";
import { useNavigate } from "react-router-dom";
import { IsTokenValid } from "../../utils/middleware";
import Layout from "../../components/layout";
import SubLayout from "../../components/layout/SubLayout";
import API_MANAGER from "../../API";
import MobileHeader from "../../components/layout/MobileHeader";
const { TextArea } = Input;
function Contactus() {
  const navigate = useNavigate();
  const isAuthenticated = IsTokenValid();
  const [subjects, setSubjects] = useState([]);
  const [form] = Form.useForm();
  const getSubjects = async () => {
    try {
      const response = await API_MANAGER.getTags("SUBJECT&sort=name");
      setSubjects(response?.data?.data?.tags);
    } catch (error) {
      message.error("Something went wrong. Please try again.");
    }
  };
  const handleSubmit = async (values) => {
    const params = {
      name: `${values?.firstName} ${values?.lastName}`,
      email: values?.email,
      contactedBy: "RECRUITER",
      summary: values?.summary,
      subject: values?.subject?.value,
    };

    try {
      await API_MANAGER.postContactusData(params);
      message.success("Query successfully sent.");
      form.setFieldsValue({ subject: null, summary: "" });
    } catch (error) {
      message.error("Something went wrong. Please try again.");
    }
  };
  const filterOption = (input, option) => {
    return (option?.label ?? "").toLowerCase().includes(input.toLowerCase());
  };
  useEffect(() => {
    if (isAuthenticated) {
      const userEmail = localStorage.getItem("userEmail");
      const name = localStorage.getItem("name")?.split(" ");
      const firstName = name[0];
      const lastName = name[1];
      form?.setFieldsValue({ firstName: firstName });
      form?.setFieldsValue({ lastName: lastName });
      form?.setFieldsValue({ email: userEmail });
    }
  }, []);
  useEffect(() => {
    getSubjects();
  }, []);
  return (
    <>
      {isAuthenticated ? (
        <Layout>
          <SubLayout page={"Contact Us"} whiteBg>
            <>
              <div className="contact_us_container">
                <h1 className="contactHeading">Contact Us</h1>
                <MobileHeader title={"Contact Us"} />
                <Row gutter={45}>
                  <Col xs={0} md={12}>
                    <div className="contactImg"></div>
                    {/* <img className="w-100" src={coverImage} alt="cover image " /> */}
                  </Col>
                  <Col xs={24} md={12} className="contact_form">
                    <Form
                      layout="vertical"
                      onFinish={handleSubmit}
                      form={form}
                      requiredMark={false}
                    >
                      <Row gutter={[0, 20]} className="temp">
                        <Col span={24}>
                          <Form.Item
                            rules={[
                              {
                                required: true,
                                message: "Please enter your first name!",
                              },
                            ]}
                            label="First Name*"
                            name="firstName"
                          >
                            <Input
                              placeholder="John"
                              className="text_capitalize"
                            />
                          </Form.Item>
                        </Col>
                        <Col span={24}>
                          <Form.Item
                            rules={[
                              {
                                required: true,
                                message: "Please enter your last name!",
                              },
                            ]}
                            label="Last Name*"
                            name="lastName"
                          >
                            <Input
                              placeholder="Doe"
                              className="text_capitalize"
                            />
                          </Form.Item>
                        </Col>
                        <Col span={24}>
                          <Form.Item
                            rules={[
                              {
                                required: true,
                                message: "Please enter your email!",
                              },
                            ]}
                            label="Email*"
                            name="email"
                          >
                            <Input placeholder="Add your email" />
                          </Form.Item>
                        </Col>
                        <Col span={24}>
                          <Form.Item
                            rules={[
                              {
                                required: true,
                                message: "Please select your subject!",
                              },
                            ]}
                            label="Subject*"
                            name="subject"
                          >
                            <Select
                              showSearch
                              labelInValue={true}
                              filterOption={filterOption}
                              placeholder="Select"
                              className="contact-select"
                              optionFilterProp="children"
                            >
                              {subjects?.map((item, index) => (
                                <Select.Option
                                  value={item?.id}
                                  label={item?.name}
                                >
                                  {item?.name}
                                </Select.Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col span={24}>
                          <Form.Item
                            rules={[
                              {
                                required: true,
                                message: "Please write your message!",
                              },
                            ]}
                            label="Message*"
                            name="summary"
                          >
                            <TextArea
                              className="textBoxInput"
                              autoSize={{
                                minRows: 4,
                                maxRows: 4,
                              }}
                              placeholder="Start Typing..."
                            />
                          </Form.Item>
                        </Col>
                        <Col span={24}>
                          <Button
                            className="primaryBtn w-100 customBtn"
                            htmlType="submit"
                          >
                            Send
                          </Button>
                          <img
                            src={form_arrow}
                            alt="arrow"
                            className="form_arrow"
                          />
                        </Col>
                      </Row>
                    </Form>
                  </Col>
                </Row>
              </div>
            </>
          </SubLayout>
        </Layout>
      ) : (
        <NonProtectedLayout page={"Subscription Management"} whiteBg>
          <>
            <div style={{ paddingTop: "24px", paddingBottom: "24px" }}>
              <div className="contact_us_container">
                <h1 className="contactHeading">Contact Us</h1>
                <Row>
                  <Col xs={24} md={0}>
                    <p
                      className="goback curdor-pointer"
                      onClick={() => navigate(-1)}
                    >
                      {"< Contact Us"}
                    </p>
                  </Col>
                </Row>
                <Row gutter={45}>
                  <Col xs={0} md={12}>
                    <div className="contactImg"></div>
                    {/* <img className="w-100" src={coverImage} alt="cover image " /> */}
                  </Col>
                  <Col xs={24} md={12} className="contact_form">
                    <Form
                      layout="vertical"
                      onFinish={handleSubmit}
                      form={form}
                      requiredMark={false}
                    >
                      <Row gutter={[0, 20]} className="temp">
                        <Col span={24}>
                          <Form.Item
                            rules={[
                              {
                                required: true,
                                message: "Please enter your first name!",
                              },
                            ]}
                            label="First Name*"
                            name="firstName"
                          >
                            <Input placeholder="John" />
                          </Form.Item>
                        </Col>
                        <Col span={24}>
                          <Form.Item
                            rules={[
                              {
                                required: true,
                                message: "Please enter your last name!",
                              },
                            ]}
                            label="Last Name*"
                            name="lastName"
                          >
                            <Input placeholder="Doe" />
                          </Form.Item>
                        </Col>
                        <Col span={24}>
                          <Form.Item
                            rules={[
                              {
                                required: true,
                                message: "Please enter your email!",
                              },
                            ]}
                            label="Email*"
                            name="email"
                          >
                            <Input placeholder="Add your email" />
                          </Form.Item>
                        </Col>
                        <Col span={24}>
                          <Form.Item
                            rules={[
                              {
                                required: true,
                                message: "Please select your subject!",
                              },
                            ]}
                            label="Subject*"
                            name="subject"
                          >
                            <Select
                              placeholder="Select"
                              showSearch
                              labelInValue={true}
                              filterOption={filterOption}
                            >
                              {subjects?.map((item, index) => (
                                <Select.Option
                                  value={item?.id}
                                  label={item?.name}
                                >
                                  {item?.name}
                                </Select.Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col span={24}>
                          <Form.Item
                            rules={[
                              {
                                required: true,
                                message: "Please write your message!",
                              },
                            ]}
                            label="Message*"
                            name="summary"
                          >
                            <TextArea
                              className="textBoxInput"
                              autoSize={{
                                minRows: 4,
                                maxRows: 4,
                              }}
                              placeholder="Start Typing..."
                            />
                          </Form.Item>
                        </Col>
                        <Col span={24}>
                          <Button
                            className="primaryBtn w-100 customBtn"
                            htmlType="submit"
                          >
                            Send
                          </Button>
                          <img
                            src={form_arrow}
                            alt="arrow"
                            className="form_arrow"
                          />
                        </Col>
                      </Row>
                    </Form>
                  </Col>
                </Row>
              </div>
            </div>
          </>
        </NonProtectedLayout>
      )}
    </>
  );
}

export default Contactus;
