import React from "react";
import { Col, Row } from "antd";
import { useNavigate } from "react-router-dom";
/* This card is only for news page */
import moment from "moment";
const NewsCards = ({ item }) => {
  const navigate = useNavigate();
  return (
    <Row
      className="newsCardMainContainer cursor-pointer"
      onClick={() => navigate(`/news-feed/${item?.id}`)}
    >
      <Col xs={24} className="newImgContainer">
        <img
          src={item?.image}
          alt="news-card image"
          className="cursor-pointer"
          style={{objectFit: "cover"}}
        />
      </Col>
      <Col xs={24} className="newsCardContentBox">
        <Row>
          <Col xs={24} className="newsHeading cursor-pointer">
            {item?.heading?.length > 50
              ? item?.heading?.substring(0, 50) + "..."
              : item?.heading?.substring(0, 50)}
          </Col>
        </Row>
        <p className="newsDate">{moment(item?.createdAt).fromNow()}</p>
        <Col xs={24}>
          <p className="newsDesc">
            {item?.shortDescription?.length > 75
              ? item?.shortDescription?.substring(0, 75) + "..."
              : item?.shortDescription?.substring(0, 75)}
          </p>
        </Col>
      </Col>
    </Row>
  );
};

export default NewsCards;
