import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
  InputNumber,
} from "antd";
import React, { useEffect, useState } from "react";
import DownIcon from "../../../Assets/downArrowIcon.svg";
import calendarIcon from "../../../Assets/campagin/calendar.svg";
import dayjs from "dayjs";
import moment from "moment";
import { useNavigate, useSearchParams } from "react-router-dom";
import API_MANAGER from "../../../API";
const { Option } = Select;
function AdvertDetailsForm({
  setProgress,
  setdefaultData,
  setPhoneViewData,
  setCurrentForm,
  form,
  isHidden,
  data,
  jobTitles,
  employmentTypes,
  rosters,
  disciplines,
  industries,
  states,
  jobDurations,
  handleSubmit,
  setAdvertActive,
}) {
  let [searchParams] = useSearchParams();
  const { TextArea } = Input;
  const [salaryType, setSalaryType] = useState("single_input");
  const [formProgress, setFormProgress] = useState();
  const isPlusSubscription = JSON.parse(
    localStorage.getItem("isPlusSubscription")
  );
  const navigate = useNavigate();
  const selectAfter = (
    <Select
      className="select_input"
      value={salaryType}
      onChange={(e) => {
        setSalaryType(e);
        localStorage.setItem("advertRangeType", e);
      }}
    >
      <Option value="single_input">Single Input</Option>
      <Option value="range">Range</Option>
    </Select>
  );
  const formatDate = (date) => {
    return dayjs(date)?.format("DD MMM YYYY").toUpperCase();
  };
  const date = new Date();
  const todayDate = dayjs(date);
  const futureDate = dayjs(todayDate.add(20, "day"));
  const setFields = () => {
    let tempData = localStorage.getItem("createAdvert");
    if (tempData) {
      let advertRangeType = localStorage.getItem("advertRangeType");
      if (advertRangeType) {
        setSalaryType(advertRangeType);
      }
      tempData = JSON.parse(tempData);
      form.setFieldsValue({
        startDate: dayjs(tempData?.startDate),
        endDate: dayjs(tempData?.endDate),
        hours: tempData?.hours,
        roster: tempData?.roster,
        jobTitle: tempData?.jobTitle,
        employmentType: tempData?.employmentType,
        jobDuration: tempData?.jobDuration,
        discipline: tempData?.discipline,
        industry: tempData?.industry,
        suburb: tempData?.suburb,
        state: tempData?.state,
        postalCode: tempData?.postalCode,
        jobDescription: tempData?.jobDescription,
        targetHeadcount: tempData?.targetHeadcount,
        minSalary: tempData?.minSalary,
      });
      handleProgess({
        startDate: dayjs(tempData?.startDate),
        endDate: dayjs(tempData?.endDate),
        hours: tempData?.hours,
        roster: tempData?.roster,
        jobTitle: tempData?.jobTitle,
        employmentType: tempData?.employmentType,
        jobDuration: tempData?.jobDuration,
        discipline: tempData?.discipline,
        industry: tempData?.industry,
        suburb: tempData?.suburb,
        state: tempData?.state,
        postalCode: tempData?.postalCode,
        jobDescription: tempData?.jobDescription,
        targetHeadcount: tempData?.targetHeadcount,
        minSalary: tempData?.minSalary,
      });
      if (advertRangeType === "range") {
        form.setFieldsValue({
          maxSalary: tempData?.maxSalary,
        });
      }
      // form.setFieldsValue(tempData);
    } else {
      form.setFieldsValue({
        startDate: dayjs(todayDate),
        endDate: dayjs(futureDate),
        industry: { value: data?.industry?._id, label: data?.industry?.name },
        state: { value: data?.state?._id, label: data?.state?.name },
        postalCode: data?.postalCode,
        suburb: data?.suburb,
      });
    }
    let updatedPhoneData = form.getFieldsValue();
    updatedPhoneData["campaignName"] = data?.advertDetail?.name;
    setPhoneViewData(updatedPhoneData);
  };
  const getInitialValuesFunction = async (advertId) => {
    let res = await API_MANAGER.getAdvertDetail(advertId);
    setdefaultData(res?.data?.data);

    setAdvertActive(res?.data?.data?.isActive);
    res = res?.data?.data;
    let initialValues = {
      jobDescription: res?.jobDescription,
      targetHeadcount: res?.targetHeadcount,
      minSalary: res?.minSalary,
      maxSalary: res?.maxSalary,
      suburb: res?.suburb,
      postalCode: res?.postalCode,
      hours: res?.hours,
      endDate: res?.endDate ? dayjs(res?.endDate) : null,
      startDate: res?.startDate ? dayjs(res?.startDate) : null,
      industry: {
        label: res?.industry?.name,
        value: res?.industry?.id,
      },
      state: {
        value: res?.state?.id,
        label: res?.state?.name,
      },
      jobTitle: {
        value: res?.jobTitle?.id,
        label: res?.jobTitle?.name,
      },
      roster: {
        value: res?.roster?.id,
        label: res?.roster?.name,
      },
      jobDuration: {
        label: res?.jobDuration?.name,
        value: res?.jobDuration?.id,
      },
      employmentType: {
        value: res?.employmentType?.id,
        label: res?.employmentType?.name,
      },
      discipline: {
        value: res?.discipline?.id,
        label: res?.discipline?.name,
      },
    };
    form.setFieldsValue(initialValues);
    let updatedPhoneData = initialValues;
    updatedPhoneData["campaignName"] = res?.campaignId?.name;
    setPhoneViewData(updatedPhoneData);
    if (res?.minSalary !== res?.maxSalary && res?.maxSalary && res?.minSalary) {
      setSalaryType("range");
    }
    handleProgess(initialValues);
  };
  const advertId = searchParams.get("id");
  useEffect(() => {
    if (advertId) {
      getInitialValuesFunction(advertId);
    } else {
      setFields();
    }
  }, []);

  const handleProgess = (data) => {
    let count = 0;
    Object.values(data).forEach((item) => {
      if (item !== "" || item?.length > 0) count++;
    });
    setProgress(count * 4);
    setFormProgress(data);
  };
  const handelVerify = (field, e) => {
    let updatedPhoneData = form.getFieldsValue();
    updatedPhoneData["campaignName"] = data?.advertDetail?.name;
    setPhoneViewData(updatedPhoneData);
    const updateProgress = { ...formProgress, [field]: e };
    localStorage.setItem("createAdvert", JSON.stringify(updatedPhoneData));
    handleProgess(updateProgress);
  };
  const filterOption = (input, option) => {
    return (option?.label ?? "").toLowerCase().includes(input.toLowerCase());
  };
  const disabledDate = (current) => {
    return (
      dayjs(form.getFieldValue("startDate"), "DD MMM YYYY").add(0, "days") >=
      current
    );
  };
  useEffect(() => {
    if (
      dayjs(form.getFieldValue("endDate")).diff(
        dayjs(form.getFieldValue("startDate")),
        "days"
      ) < 20
    ) {
      form.setFieldsValue({
        endDate: dayjs(dayjs(form.getFieldValue("startDate")).add(20, "day")),
      });
    }
    let updatedPhoneData = form.getFieldValue();
    updatedPhoneData["endDate"] = form.getFieldValue("endDate");
    setPhoneViewData(updatedPhoneData);
  }, [form.getFieldValue("startDate")]);
  return (
    <Row style={{ display: isHidden ? "none" : "block" }}>
      <div className="advert-detail-form-container">
        <Row gutter={30}>
          <Col xs={24} md={12}>
            <Row>
              <Col span={24}>
                {salaryType === "single_input" ? (
                  <Form.Item
                    form={form}
                    name="minSalary"
                    rules={[{ required: true, message: "Enter salary/rate" }]}
                    label={<div>Salary/Rate*</div>}
                  >
                    <Input
                      className="salary_rate_input d_none_phone"
                      onChange={(e) =>
                        handelVerify("minSalary", e?.target?.value)
                      }
                      addonBefore={<span className="dollar_icon">$</span>}
                      addonAfter={selectAfter}
                      placeholder={"$40.00"}
                    />
                    {/* <Col xs={24} className="d-none salary_input_rate_phone_col">
                      <Input
                        onChange={(e) =>
                          handelVerify("minSalary", e?.target?.value)
                        }
                        placeholder="$10,000"
                      />
                    </Col>
                    <Col xs={24} className="d-none select_input_phone">
                      <Select
                        className="select_input"
                        value={salaryType}
                        onChange={(e) => {
                          setSalaryType(e);
                          localStorage.setItem("advertRangeType", e);
                        }}
                      >
                        <Option value="single_input">Single Input</Option>
                        <Option value="range">Range</Option>
                      </Select>
                    </Col> */}
                  </Form.Item>
                ) : (
                  <Form.Item
                    form={form}
                    name="salary"
                    label={<div>Salary/Rate*</div>}
                  >
                    <div
                      className="d_none_phone"
                      style={{
                        borderRadius: "8px",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Form.Item
                        form={form}
                        name="minSalary"
                        style={{
                          marginBottom: 0,
                          flexShrink: 2,
                          flexGrow: 2,
                        }}
                        rules={[
                          { required: true, message: "Enter min salary/rate" },
                        ]}
                      >
                        <Input
                          autoComplete="off"
                          className="salary_rate_input"
                          onChange={(e) => {
                            handelVerify("minSalary", e?.target?.value);
                          }}
                          style={{ border: "none" }}
                          addonBefore={<span className="dollar_icon">$</span>}
                        />
                      </Form.Item>
                      <span style={{ marginLeft: "10px", marginRight: "10px" }}>
                        to
                      </span>

                      <Form.Item
                        form={form}
                        name="maxSalary"
                        style={{
                          marginBottom: 0,
                          flexShrink: 1.3,
                          flexGrow: 1.3,
                        }}
                        rules={[
                          { required: true, message: "Enter max salary/rate" },
                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              if (
                                !value ||
                                getFieldValue("minSalary") - 0 <= value
                              ) {
                                return Promise.resolve();
                              }
                              return Promise.reject(
                                "Max salary must be greater than or equal to min salary."
                              );
                            },
                          }),
                        ]}
                      >
                        <Input
                          autoComplete="off"
                          className="salary_rate_input"
                          onChange={(e) => {
                            handelVerify("maxSalary", e?.target?.value);
                          }}
                          min={form.getFieldValue("minSalary")}
                          style={{ border: "none" }}
                          addonAfter={selectAfter}
                        />
                      </Form.Item>
                    </div>
                    <div
                      className="d-none range_input_box_div"
                      style={{ flexDirection: "column" }}
                    >
                      <Col xs={24} className="upper_col">
                        <Input
                          onChange={(e) => {
                            handelVerify("minSalary", e?.target?.value);
                          }}
                          placeholder="$10,000"
                        />
                      </Col>
                      <Col xs={24} className="lower_col">
                        <Input
                          onChange={(e) => {
                            handelVerify("maxSalary", e?.target?.value);
                          }}
                          placeholder="$20,000"
                        />
                      </Col>
                    </div>
                    <Col xs={24} className="d-none select_input_phone">
                      <Select
                        className="select_input"
                        value={salaryType}
                        onChange={(e) => {
                          setSalaryType(e);
                          localStorage.setItem("advertRangeType", e);
                        }}
                      >
                        <Option value="single_input">Single Input</Option>
                        <Option value="range">Range</Option>
                      </Select>
                    </Col>
                  </Form.Item>
                )}
              </Col>
            </Row>
          </Col>
          <Col xs={24} md={12}>
            <Row>
              <Col span={24}>
                <Form.Item
                  rules={[{ required: true, message: "Enter job title" }]}
                  name="jobTitle"
                  label={<div>Job Title*</div>}
                >
                  <Select
                    labelInValue={true}
                    showSearch
                    filterOption={filterOption}
                    className="Select_Input_Form"
                    onChange={(e) => handelVerify("jobTitle", e, "select")}
                    suffixIcon={<img src={DownIcon} alt="icon" />}
                    placeholder="Add job title"
                  >
                    {jobTitles?.map((item, index) => (
                      <Select.Option value={item?.id} label={item?.name}>
                        {item?.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col xs={24} md={12}>
            <Row>
              <Col span={24}>
                <Form.Item
                  name="hours"
                  label={<div>Hours</div>}
                  rules={[
                    () => ({
                      validator(_, value) {
                        if (value > 0) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          "Hours must be greater than zero."
                        );
                      },
                    }),
                  ]}
                >
                  <Input
                    className="form_input_box"
                    onChange={(e) => handelVerify("hours", e?.target?.value)}
                    placeholder="Add hours per week"
                    type="number"
                    min={1}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col xs={24} md={12}>
            <Row>
              <Col span={24}>
                <Form.Item name="roster" label={<div>Roster</div>}>
                  <Select
                    labelInValue={true}
                    showSearch
                    filterOption={filterOption}
                    className="Select_Input_Form"
                    onChange={(e) => handelVerify("roster", e, "select")}
                    suffixIcon={<img src={DownIcon} alt="icon" />}
                    placeholder="Add roster"
                  >
                    {rosters?.map((item, index) => (
                      <Select.Option value={item?.id} label={item?.name}>
                        {item?.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col xs={24} md={12}>
            <Row>
              <Col span={24}>
                <Form.Item name="jobDuration" label={<div>Job Duration</div>}>
                  <Select
                    labelInValue={true}
                    showSearch
                    filterOption={filterOption}
                    className="Select_Input_Form"
                    onChange={(e) => handelVerify("jobDuration", e)}
                    suffixIcon={<img src={DownIcon} alt="icon" />}
                    placeholder="Add duration"
                  >
                    {jobDurations?.map((item, index) => (
                      <Select.Option value={item?.id} label={item?.name}>
                        {item?.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col xs={24} md={12}>
            <Row>
              <Col span={24}>
                <Form.Item
                  name="employmentType"
                  rules={[{ required: true, message: "Enter employment type" }]}
                  label={<div>Employment Type*</div>}
                >
                  <Select
                    labelInValue={true}
                    showSearch
                    filterOption={filterOption}
                    onChange={(e) =>
                      handelVerify("employmentType", e, "select")
                    }
                    suffixIcon={<img src={DownIcon} alt="icon" />}
                    placeholder="Add employment type"
                    className="Select_Input_Form"
                  >
                    {employmentTypes?.map((item, index) => (
                      <Select.Option value={item?.id} label={item?.name}>
                        {item?.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col xs={24} md={12}>
            <Row gutter={12}>
              <Col xs={24} md={12}>
                <div className="date-picker-container">
                  <Form.Item
                    name="startDate"
                    className="w-100"
                    label={<div>Start Date-End Date*</div>}
                    rules={[
                      { required: true, message: "Enter the date range" },
                    ]}
                  >
                    <DatePicker
                      onChange={(e) => {
                        handelVerify("startDate", e?.target?.value, "date");
                      }}
                      allowClear={false}
                      suffixIcon={""}
                      className="w-100 date_picker_input"
                      format={formatDate}
                    />
                  </Form.Item>
                  <img
                    src={calendarIcon}
                    className="calendar-icon"
                    alt="icon"
                  />
                </div>
              </Col>
              <Col xs={24} md={12} className="mb-8">
                <div className="date-picker-container">
                  <Form.Item
                    name="endDate"
                    label={window?.innerWidth <= 767 ? "End Date" : " "}
                  >
                    <DatePicker
                      onChange={(selectedDate, dateString) =>
                        handelVerify("endDate", dateString, "date")
                      }
                      disabledDate={disabledDate}
                      suffixIcon={""}
                      // defaultValue={dayjs(futureDate1, dateFormat)}
                      allowClear={false}
                      className="w-100 date_picker_input"
                      // defaultValue={(endDate)}
                      format={formatDate}
                    />
                  </Form.Item>
                  <img
                    src={calendarIcon}
                    style={{ margin: "3px" }}
                    className="calendar-icon"
                    alt="icon"
                  />
                </div>
              </Col>
            </Row>
          </Col>
          <Col xs={24} md={12}>
            <Row>
              <Col span={24}>
                <Form.Item
                  rules={[{ required: true, message: "Enter discipline" }]}
                  name="discipline"
                  label={<div>Discipline*</div>}
                >
                  <Select
                    labelInValue={true}
                    className="Select_Input_Form"
                    showSearch
                    filterOption={filterOption}
                    onChange={(e) => handelVerify("discipline", e, "select")}
                    suffixIcon={<img src={DownIcon} alt="icon" />}
                    placeholder="Discipline"
                  >
                    {disciplines?.map((item, index) => (
                      <Select.Option value={item?.id} label={item?.name}>
                        {item?.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Col>

          <Col xs={24} md={12}>
            <Row>
              <Col span={24}>
                <Form.Item
                  rules={[{ required: true, message: "Enter the industry" }]}
                  name="industry"
                  label={<div>Industry*</div>}
                >
                  <Select
                    showSearch
                    disabled
                    labelInValue={true}
                    filterOption={filterOption}
                    className="Select_Input_Form"
                    onChange={(e) => handelVerify("industry", e, "select")}
                    suffixIcon={<img src={DownIcon} alt="icon" />}
                    placeholder="Add industry"
                  >
                    {industries?.map((item, index) => (
                      <Select.Option value={item?.id} label={item?.name}>
                        {item?.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col xs={24} md={12}>
            <Row>
              <Col span={24}>
                <Form.Item
                  rules={[{ required: true, message: "Enter suburb" }]}
                  name="suburb"
                  label={<div>Suburb*</div>}
                >
                  <Input
                    disabled
                    className="form_input_box"
                    onChange={(e) => handelVerify("suburb", e)}
                    placeholder="Add suburb"
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>

          <Col xs={24} md={12}>
            <Row>
              <Col span={24}>
                <Form.Item
                  name="state"
                  label={<div>State*</div>}
                  rules={[{ required: true, message: "Enter state" }]}
                >
                  <Select
                    labelInValue={true}
                    disabled
                    showSearch
                    filterOption={filterOption}
                    className="Select_Input_Form"
                    onChange={(e) => handelVerify("state", e, "select")}
                    suffixIcon={<img src={DownIcon} alt="icon" />}
                    placeholder="Add state"
                  >
                    {states?.map((item, index) => (
                      <Select.Option value={item?.id} label={item?.name}>
                        {item?.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col xs={24} md={12}>
            <Row>
              <Col span={24}>
                <Form.Item
                  rules={[{ required: true, message: "Enter postal code" }]}
                  name="postalCode"
                  label={<div>Postal Code*</div>}
                >
                  <Input
                    className="form_input_box"
                    disabled
                    onChange={(e) =>
                      handelVerify("postalCode", e?.target?.value)
                    }
                    placeholder="Add postal code"
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col xs={24} md={24}>
            <Row>
              <Col span={24}>
                <Form.Item
                  name="jobDescription"
                  label={
                    <div className="input_form_label">Job Description*</div>
                  }
                  rules={[
                    { required: true, message: "Enter the job description" },
                  ]}
                >
                  <TextArea
                    onChange={(e) => handelVerify("jobDescription", e)}
                    className="textfield_Input_form"
                    placeholder="Enter description..."
                    autoSize={{
                      minRows: 4,
                      maxRows: 10,
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
      <Row className="office-form-holder">
        <div>
          <Row>
            <Col span={24} className="mb-24">
              <h3>FOR OFFICE USE ONLY</h3>
            </Col>
            <Col span={24} className="mb-8">
              <p>Target Headcount*</p>
            </Col>
            <Col span={24}>
              <Form.Item
                name="targetHeadcount"
                rules={[
                  { required: true, message: "Enter target headcount" },
                  () => ({
                    validator(_, value) {
                      if (value > 0) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        "Target headcount must be greater than zero."
                      );
                    },
                  }),
                ]}
              >
                <InputNumber
                  onChange={(e) =>
                    handelVerify("targetHeadcount", e?.target?.value)
                  }
                  type="number"
                  className="form_input_box w-100 advert-head-count"
                  placeholder="Add target headcount"
                  max={999999999999999}
                  min={1}
                />
              </Form.Item>
            </Col>
          </Row>
        </div>
      </Row>
      <Row className="end mt-20" gutter={16}>
        <Col>
          <Button
            className="secondaryBtn custom-padding"
            onClick={() => {
              navigate(-1);
            }}
          >
            Cancel
          </Button>
        </Col>
        <Col>
          <Button
            className="secondaryBtn custom-padding"
            onClick={() => {
              form.validateFields().then(
                () => {
                  if (isPlusSubscription) {
                    setCurrentForm("hard_req");
                  } else {
                    handleSubmit();
                  }
                },
                (e) => {
                  // setCurrentForm("hard_req");
                }
              );
            }}
          >
            {isPlusSubscription
              ? "Next"
              : advertId
              ? "Update"
              : "+ Post Advert"}
          </Button>
        </Col>

        {isPlusSubscription && (
          <Col>
            <Button
              className="secondaryBtn custom-padding"
              onClick={() => {
                form.validateFields().then(
                  () => {
                    handleSubmit();
                  },
                  (e) => {
                    // setCurrentForm("hard_req");
                  }
                );
              }}
            >
              {advertId ? "Update" : "+ Post Advert"}
            </Button>
          </Col>
        )}
      </Row>
    </Row>
  );
}

export default AdvertDetailsForm;
