import { Button, Col, Input, Row, Select } from "antd";
import downArrow from "../../../Assets/campagin/downArrow.svg";
import { useEffect, useState } from "react";
import { CheckOutlined, CloseOutlined, DownOutlined } from "@ant-design/icons";
import API_MANAGER from "../../../API";
import FullScreenLoader from "../../../components/common/FullScreenLoader";

const HardRequirementSection = ({
  pickList,
  hardData,
  setHardData,
  softData,
  noListData,
  setNoListData,
  handlePostAdvert,
  selectedTagsData,
  setSelectedTagsData,
  checkSelectedTags,
  setCheckSelectedTags,
  tagData,
  setTagData,
  loading,
  setLoading,
  checkTagItem,
  setCheckTagItem,
  tempHardData,
  setTempHardData,
  softSelectedTagsData,
}) => {
  const [pickListOpen, setPickListOpen] = useState(false);
  const [defaultOpenTag, setDefaultOpenTag] = useState(null);
  const [singleSelectTag, setSingleSelectTag] = useState([]);

  const handlePickListOpen = () => {
    setPickListOpen((prev) => !prev);
  };

  const handleItemClick = (data) => {
    if (checkSelectedTags.includes(data?.key)) {
      const updatedArray = checkSelectedTags.filter(
        (tagName) => tagName !== data?.key
      );
      setCheckSelectedTags(updatedArray);
      const updatedSelectedTagsData = selectedTagsData.filter(
        (item) => item?.key !== data?.key
      );
      setSelectedTagsData(updatedSelectedTagsData);
    } else {
      const updatedArray = [...checkSelectedTags, data?.key];
      setCheckSelectedTags(updatedArray);
      const updatedSelectedTagsData = [...selectedTagsData, data];
      setSelectedTagsData(updatedSelectedTagsData);
    }
  };
  // SELECT BUTTON
  const handleSelectButton = () => {
    setPickListOpen((prev) => !prev);
  };
  const handleTagClick = async (item, index) => {
    setDefaultOpenTag(index);
    setLoading(true);
    try {
      const response = await API_MANAGER.getTags(`${item?.key}&sort=name`);

      setTagData(response?.data?.data?.tags);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };
  const handleTagItemclick = (key, tag) => {
    if (tempHardData.hasOwnProperty(key)) {
      const idArray = [...tempHardData[key], tag?.id];
      tempHardData[key] = idArray;
    } else {
      tempHardData[key] = [tag?.id];
      const updatedArray = [...checkTagItem, tag?.id];
      setCheckTagItem(updatedArray);
    }

    setTempHardData({ ...tempHardData });
    setHardData(tempHardData);
  };
  useEffect(() => {
    const idArray = softSelectedTagsData.map((obj) => obj?.key);

    setSingleSelectTag(idArray);
  }, [softSelectedTagsData]);
  return (
    <div className="hardRequirementSection_phone">
      <FullScreenLoader isLoading={loading} />
      <Row>
        <Col xs={24}>
          <span className="heading">Hard Requirements</span>
        </Col>
      </Row>
      {/* Choose Requirement box */}
      <Row>
        <Col
          xs={24}
          className="choose_requirement_box"
          onClick={handlePickListOpen}
        >
          <span className="placeholder_text">Choose Requirements</span>
          <DownOutlined />
        </Col>
      </Row>
      {/* All PickList Render When Choose Requirement button is clicked. */}
      {pickListOpen ? (
        <div className="pickList_main_div">
          <Row className="picklist_row">
            {pickList.map((item, index) => {
              if (!singleSelectTag.includes(item?.key) || item?.mode !== "") {
                return (
                  <Col
                    xs={24}
                    className={
                      checkSelectedTags.includes(item?.key)
                        ? "picklist_item active"
                        : "picklist_item"
                    }
                    onClick={() => handleItemClick(item)}
                  >
                    <span>{item?.title}</span>
                    {checkSelectedTags?.includes(item?.key) ? (
                      <CheckOutlined />
                    ) : null}
                  </Col>
                );
              }
            })}
          </Row>
          {/* Select and Cancel Button */}
          <Row className="select_cancel_button_section" justify={"center"}>
            <Col xs={24} align={"center"}>
              <Button
                className="select_btn"
                onClick={() => handleSelectButton()}
              >
                Select
              </Button>
            </Col>
            <Col xs={24} align={"center"}>
              <Button className="cancel_button">Cancel</Button>
            </Col>
          </Row>
        </div>
      ) : (
        <div className="selected_pickList_main_div">
          {selectedTagsData?.map((item, index) => (
            <div className="main_box">
              <Row>
                <Col xs={24} className="title_row">
                  <span className="title">{item?.title}</span>
                  <CloseOutlined />
                </Col>
                {item?.key !== "CITIZENSHIP" &&
                  item?.key !== "POLICECLEARANCE" &&
                  (item?.key !== "ID_DOCUMENT" ? (
                    <>
                      <Col
                        xs={24}
                        className="pickList_select_col"
                        onClick={() => handleTagClick(item, index)}
                      >
                        <span>Select {item?.title}</span>
                        <DownOutlined />
                      </Col>
                      {defaultOpenTag === index ? (
                        <div className="tagList_main_div">
                          {tagData?.map((tag) => {
                            let tagName = item?.key;
                            if (softData.hasOwnProperty(tagName)) {
                              const checkArray = softData[tagName];
                              if (checkArray.includes(tag?.id)) {
                                return (
                                  <Col
                                    className={"tag_col disabled"}
                                    span={24}
                                    // onClick={() =>
                                    //   handleTagItemclick(item?.key, tag)
                                    // }
                                  >
                                    {tag?.name}
                                  </Col>
                                );
                              }
                            }
                            if (tempHardData.hasOwnProperty(tagName)) {
                              const checkArray = tempHardData[tagName];

                              if (checkArray.includes(tag?.id)) {
                                return (
                                  <Col
                                    className={"tag_col active"}
                                    span={24}
                                    onClick={() =>
                                      handleTagItemclick(item?.key, tag)
                                    }
                                  >
                                    {tag?.name}
                                    <CheckOutlined />
                                  </Col>
                                );
                              } else {
                                return (
                                  <Col
                                    className={"tag_col"}
                                    span={24}
                                    onClick={() =>
                                      handleTagItemclick(item?.key, tag)
                                    }
                                  >
                                    {tag?.name}
                                  </Col>
                                );
                              }
                            } else {
                              return (
                                <Col
                                  className={"tag_col"}
                                  span={24}
                                  onClick={() =>
                                    handleTagItemclick(item?.key, tag)
                                  }
                                >
                                  {tag?.name}
                                </Col>
                              );
                            }
                          })}
                        </div>
                      ) : null}
                    </>
                  ) : null)}
              </Row>
            </div>
          ))}
        </div>
      )}
      {/* Post Advert Button Section */}
      {!pickListOpen ? (
        <Row
          className={
            selectedTagsData.length <= 1
              ? "post_advert_section_abs"
              : "post_advert_section"
          }
        >
          <Col xs={24}>
            <Button className="post_btn" onClick={() => handlePostAdvert()}>
              + Post Advert
            </Button>
          </Col>
          <Col xs={24}>
            <Button className="can_btn">Cancel</Button>
          </Col>
        </Row>
      ) : null}
    </div>
  );
};
export default HardRequirementSection;
