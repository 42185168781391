import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { IsTokenValidForRoutes, IsTokenValid } from "../utils/middleware";
const ProtectedRoute = () => {
  const isAuth = IsTokenValid();
  if (!isAuth) {
    return <Navigate to="/login" />;
  } else {
    const isAuthenticated = IsTokenValidForRoutes();
    return isAuthenticated ? <Outlet /> : <Navigate to="/404" />;
  }
};

export default ProtectedRoute;
