import React, { useState, useEffect } from "react";
import {
  Avatar,
  Col,
  Drawer,
  Modal,
  Dropdown,
  Radio,
  Row,
  Switch,
  message,
} from "antd";
import filterIcon from "../../../Assets/filter.svg";
import moment from "moment";
import API_MANAGER from "../../../API";
import { useNavigate } from "react-router-dom";

const Notification = ({
  isModalOpen,
  showModal,
  handleOk,
  handleCancel,
  viewAllState,
  setviewAllState,
  isMobile,
  setIsMobile,
  notifications,
  setDataChange,
}) => {
  const [open, setOpen] = useState(false);
  const [todayNotification, setTodayNotification] = useState([]);
  const [yesterdayNotification, setYesterdayNotification] = useState([]);
  const [olderNotification, setOlderNotification] = useState([]);
  const [filterType, setFilterType] = useState("all");
  const [allNotifications, setAllNotifications] = useState(notifications);
  const navigate = useNavigate();
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setFilterType("all");
    setOpen(false);
    setviewAllState((prev) => !prev);
    /* function calling here to close notification popup when when notification drawer is being closed*/
    handleCancel();
  };

  const setFields = () => {
    let tempTodaysNotification = [];
    let tempYesterdayNotification = [];
    let tempOlderNotification = [];
    allNotifications?.map((item) => {
      if (moment().diff(moment(item?.createdAt), "days") === 0) {
        tempTodaysNotification.push(item);
      } else if (moment().diff(moment(item?.createdAt), "days") === 1) {
        tempYesterdayNotification.push(item);
      } else {
        tempOlderNotification.push(item);
      }
    });
    setTodayNotification(tempTodaysNotification);
    setYesterdayNotification(tempYesterdayNotification);
    setOlderNotification(tempOlderNotification);
  };
  useEffect(() => {
    if (notifications && notifications?.length > 0) {
      setFields();
    }
  }, [allNotifications]);

  useEffect(() => {
    if (filterType === "all") {
      setAllNotifications(notifications);
    } else {
      if (filterType === "read") {
        const temp = notifications?.filter((item) => item?.isRead === true);
        setAllNotifications(temp);
      } else if (filterType === "unread") {
        const temp = notifications?.filter((item) => item?.isRead === false);
        setAllNotifications(temp);
      }
    }
  }, [filterType, notifications]);
  const items = [
    {
      key: "1",
      label: (
        <div className="">
          <Radio.Group
            onChange={(e) => setFilterType(e?.target?.value)}
            className="dropDown_radio_group"
            value={filterType}
          >
            <div className="">
              <Row className="filter_item_row">
                <Col span={24}>
                  <Radio value={"all"}>All</Radio>
                </Col>
              </Row>
              <Row className="filter_item_row">
                <Col span={24}>
                  <Radio value={"unread"}>Unread</Radio>
                </Col>
              </Row>
              <Row className="filter_item_row">
                <Col span={24}>
                  <Radio value={"read"}>Read</Radio>
                </Col>
              </Row>
            </div>
          </Radio.Group>
        </div>
      ),
    },
  ];
  const handleRead = async (item) => {
    // if (
    //   !item?.invitationId?.advertId?._id &&
    //   !item?.applicationId?.advertId?.id &&
    //   !item?.withdrawlBy &&
    //   !item?.newsId &&
    //   !(item?.type === "PRIVACY_POLICY") &&
    //   !(item?.type === "TERMS_CONDITION") &&
    //   !(item?.type === "CUSTOM") &&
    //   !(item?.type === "ADVERT")
    // ) {
    //   message.error("This advert does not exist.");
    //   return;
    // }
    try {
      await API_MANAGER.readNotification(item?._id);

      setDataChange((prev) => !prev);
      if (item?.type === "PRIVACY_POLICY") {
        navigate("/privacy-policy");
      } else if (item?.type === "TERMS_CONDITION") {
        navigate("/terms-of-use");
      } else if (item?.type === "INVITE" && item?.invitationId?.advertId?._id) {
        navigate(`/advert-detail/${item?.invitationId?.advertId?._id}`);
      } else if (
        (item?.type === "SHORTLISTED" && item?.applicationId?.advertId?.id) ||
        (item?.type === "OFFERED" && item?.applicationId?.advertId?.id)
      ) {
        navigate(`/advert-detail/${item?.applicationId?.advertId?.id}`);
      }
      // else if (
      //   (item?.type === "INVITE" ||
      //     item?.type === "SHORTLISTED" ||
      //     item?.type === "OFFERED") &&
      //   item?.advertId
      // ) {
      //   navigate(`/advert-detail/${item?.advertId}`);
      // }
      else if (item?.type === "ADVERT" && item?.advertId) {
        navigate(`/advert-detail/${item?.advertId}`);
      } else if (item?.type === "NEWS" || item?.newsId) {
        navigate(`/news-feed/${item?.newsId}`);
      } else if (item?.withdrawlBy) {
        navigate(`/applicant-profile/${item?.withdrawlBy}`, {
          state: { withDrawlCandidate: "yes" },
        });
      } else {
        setviewAllState((prev) => true);
      }
    } catch (error) {
      // message.error(error.response.data.message.message)
    }
  };
  useEffect(() => {
    if (viewAllState) {
      setOpen(true);
    }
  }, [viewAllState]);
  return (
    <>
      {/* small  notification popup  */}
      {!viewAllState ? (
        <Modal
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
          maskStyle={{ background: "transparent" }}
          footer={[]}
          width="350px"
          closeIcon={false}
          bodyStyle={{
            margin: "-18px",
          }}
          style={{
            position: "absolute",
            top: "70px",
            right: "40px",
          }}
          className="notification-container"
        >
          <div>
            <Row justify={"space-between"} className="Notifications-header">
              <Col className="notification-heading">Notifications</Col>
              {/* {(todayNotification?.length > 0 ||
                yesterdayNotification?.length > 0 ||
                olderNotification?.length > 0) && ( */}
              <Col
                className="viewall-text"
                onClick={() => {
                  showDrawer();
                  setviewAllState((prev) => !prev);
                }}
              >
                View All
              </Col>
              {/* )} */}
            </Row>
            {/* notification cards */}
            {notifications?.length > 0 ? (
              <Col span={24} className="notification-cards-container">
                {notifications?.map((item, i) => (
                  <Row
                    justify={"space-between"}
                    className={`${
                      item?.isRead
                        ? "notification-card-outline"
                        : "notification-card-outline-read"
                    } cursor-pointer`}
                    onClick={() => handleRead(item)}
                  >
                    <Col span={2}>
                      <Avatar
                        style={{
                          backgroundColor: "#fde3cf",
                          color: "#f56a00",
                          textTransform: "capitalize",
                        }}
                      >
                        {item?.message?.length > 0
                          ? item?.message?.trim()?.charAt(0)
                          : "N"}
                      </Avatar>
                    </Col>
                    <Col span={21}>
                      <p className="card-text">{item?.message}</p>
                      <p className="card-time-text">
                        {moment(item?.createdAt).fromNow()}
                      </p>
                    </Col>
                  </Row>
                ))}
              </Col>
            ) : (
              <Col span={24} className="noRecentNotification">
                <Row justify={"center"}>No recent notifications!</Row>
              </Col>
            )}
            {/* <Row
              className="notification-footer"
              justify={"space-between"}
              align={"middle"}
            >
              <Col>
                <span>Notifications</span>
              </Col>
              <Col>
                <Switch defaultChecked onChange={onChange} />
              </Col>
            </Row> */}
          </div>
        </Modal>
      ) : (
        /* Drawer to show all notification*/
        <>
          <Drawer
            title={
              <>
                <span>Notifications</span>
                <Dropdown
                  menu={{ items }}
                  overlayClassName="notificationFilterDropdown"
                >
                  <img
                    className="filter-icon"
                    src={filterIcon}
                    alt="filter icon"
                  />
                </Dropdown>
              </>
            }
            placement="right"
            width={"511px"}
            onClose={onClose}
            open={open}
            bodyStyle={{
              padding: "0px 0px 0px 0px",
              overflow: "hidden",
            }}
            contentWrapperStyle={{
              top: isMobile ? "88px" : "0px",
              width: isMobile ? "100vw" : "511px",
            }}
            className="drawer-container"
          >
            <Col span={24} className="notification-cards-container">
              {todayNotification?.length > 0 && (
                <>
                  <Col span={24}>
                    <p className="day-text">Today</p>
                  </Col>
                  {/* copy and paste */}
                  {todayNotification?.map((item, i) => (
                    <Row
                      justify={"space-between"}
                      className={`${
                        item?.isRead
                          ? "notification-card-outline"
                          : "notification-card-outline-read"
                      } cursor-pointer`}
                      gutter={16}
                      align={"middle"}
                      onClick={() => {
                        handleRead(item);
                      }}
                    >
                      <Col span={2}>
                        <Avatar
                          style={{
                            backgroundColor: "#fde3cf",
                            color: "#f56a00",
                          }}
                        >
                          {item?.message?.length > 0 ? item?.message[0] : "N"}
                        </Avatar>
                      </Col>
                      <Col span={17}>
                        <p className="card-text">{item?.message}</p>
                      </Col>
                      <Col span={5}>
                        <span className="card-time-text">
                          {moment(item?.createdAt).fromNow()}
                        </span>
                      </Col>
                    </Row>
                  ))}
                </>
              )}

              {/**************************  Yesterday ********************************/}

              {yesterdayNotification?.length > 0 && (
                <>
                  <Col span={24}>
                    <p className="day-text">Yesterday</p>
                  </Col>
                  {/* copy and paste */}
                  {yesterdayNotification?.map((item, i) => (
                    <Row
                      justify={"space-between"}
                      className={`${
                        item?.isRead
                          ? "notification-card-outline"
                          : "notification-card-outline-read"
                      } cursor-pointer`}
                      onClick={() => {
                        handleRead(item);
                      }}
                    >
                      <Col span={2}>
                        <Avatar
                          style={{
                            backgroundColor: "#fde3cf",
                            color: "#f56a00",
                          }}
                        >
                          {item?.message?.length > 0 ? item?.message[0] : "N"}
                        </Avatar>
                      </Col>
                      <Col span={18}>
                        <p className="card-text">{item?.message}</p>
                      </Col>
                      <Col span={3}>
                        <span className="card-time-text">
                          {moment(item?.createdAt).fromNow()}
                        </span>
                      </Col>
                    </Row>
                  ))}
                </>
              )}

              {olderNotification?.length > 0 && (
                <>
                  <Col span={24}>
                    <p className="day-text">Older</p>
                  </Col>
                  {/* copy and paste */}
                  {olderNotification?.map((item, i) => (
                    <Row
                      justify={"space-between"}
                      className={`${
                        item?.isRead
                          ? "notification-card-outline"
                          : "notification-card-outline-read"
                      } cursor-pointer`}
                      onClick={() => {
                        handleRead(item);
                      }}
                    >
                      <Col span={2}>
                        <Avatar
                          style={{
                            backgroundColor: "#fde3cf",
                            color: "#f56a00",
                          }}
                        >
                          {item?.message?.length > 0 ? item?.message[0] : "N"}
                        </Avatar>
                      </Col>
                      <Col span={18}>
                        <p className="card-text">{item?.message}</p>
                      </Col>
                      <Col span={3}>
                        <span className="card-time-text">
                          {moment(item?.createdAt).fromNow()}
                        </span>
                      </Col>
                    </Row>
                  ))}
                </>
              )}
              {!(allNotifications?.length > 0) && (
                <Col span={24} className="noRecentNotification">
                  <Row justify={"center"}>No recent notifications!</Row>
                </Col>
              )}
            </Col>
          </Drawer>
        </>
      )}
    </>
  );
};

export default Notification;
