import { Button, Col, Modal, Row, Tooltip, message } from "antd";
import React, { useEffect, useState } from "react";
import dpImage from "../../../Assets/susbcription_mangement/standard_card_image.svg";
import checkIcon from "../../../Assets/susbcription_mangement/checkIcon.svg";
import API_MANAGER from "../../../API";

function RecruitmentPlan({
  border,
  handelSelect,
  data,
  setChange,
  currentPlanId,
  paymentHistory,
}) {
  const [price, setPrice] = useState("");
  const [upgrade, setUpgrade] = useState(false);
  const isPlusSubscription = paymentHistory?.sp?.plusSubscription;
  const handleUpdgrade = async () => {
    try {
      const response = await API_MANAGER.upgradeSubscription({
        subscriptionProductId: data?._id,
      });
      const Url = response?.data?.data?.url;
      window.open(Url, "_self");
      setChange("true");
      setUpgrade(false);
    } catch (error) {
      message.error(error?.response?.data?.message?.message);
    }
  };
  const numberWithCommas = (number) => {
    if (number) {
      var parts = number?.toString()?.split(".");
      parts[0] = parts[0]?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return parts.join(".");
    }
  };
  useEffect(() => {
    const temp_price = numberWithCommas(data?.default_price);
    setPrice(temp_price);
  }, [data]);
  return (
    <div
      className={
        currentPlanId === data?._id
          ? "standard_plan_card_container_active"
          : "standard_plan_card_container"
      }
      style={
        border == "Recruitment"
          ? {
              border: "1px solid #122D8E",
              background: "#F8F9FF",
            }
          : {}
      }
      onClick={() => (handelSelect ? handelSelect("Recruitment") : "")}
    >
      <Row>
        <Col span={19} className="gap-16">
          <img className="dp_image" src={dpImage} alt="profile image" />
          <h4>{data?.name}</h4>
        </Col>
        {currentPlanId === data?._id && (
          <Col span={5} className="current_div">
            Current
          </Col>
        )}
      </Row>
      <Col span={24} className="price_holder">
        <h4>${price}</h4>
        <p>/{data?.recurringType.toLowerCase()}</p>
      </Col>
      <Col span={24} className="benefits_holder">
        {data?.feature?.map((item, index) => (
          <div className="gap-10 mt-16">
            <img src={checkIcon} />
            <p>{item?.name}</p>
          </div>
        ))}
      </Col>
      {!isPlusSubscription && data?.cardType === 2 && (
        <Col span={24} align={"center"} className="mt-14 ">
          <Button
            className="primaryBtn"
            onClick={() => {
              setUpgrade(true);
            }}
          >
            Upgrade
          </Button>
        </Col>
      )}
      <Modal
        centered
        title="Upgrade Subscription"
        open={upgrade}
        closeIcon={true}
        onCancel={() => setUpgrade(false)}
        className="upgradeMembershipModal"
        footer={
          <Row gutter={16} className="justify-end d-flex">
            <Col>
              <Button onClick={handleUpdgrade} className="black_color_button">
                Upgrade
              </Button>
            </Col>
          </Row>
        }
      >
        <Row>
          <Col span={24}>
            <span className="modal_content">
              {"Are you sure you want to upgrade your subscription?"}
            </span>
          </Col>
        </Row>
      </Modal>
    </div>
  );
}

export default RecruitmentPlan;
