import { Button, Col, Image, Input, Modal, Row, message } from "antd";
import { useEffect, useState } from "react";
import { CheckOutlined } from "@ant-design/icons";
import addUserIcon from "../../../Assets/campagin/add-user-icon.svg";
import { useNavigate } from "react-router-dom";

const AddUserModal = ({
  isModalOpen,
  setUserModalOpen,
  data,
  handleOk,
  checkSelected,
  setCheckSelected,
  users,
  setUsers,
  setSearchUsers,
}) => {
  const navigate = useNavigate();
  const handleCancel = () => {
    setUserModalOpen(false);
  };
  const handleUserClick = (item, index) => {
    if (checkSelected?.includes(item?._id)) {
      const updateUserList = users.filter(
        (itemSelected) => itemSelected?._id !== item?._id
      );
      setUsers(updateUserList);
      const updatedCheckSelected = checkSelected.filter(
        (itemSelected) => itemSelected !== item?._id
      );
      setCheckSelected(updatedCheckSelected);
    } else {
      const updateUserList = [...users, item];
      setUsers(updateUserList);
      const updatedCheckSelected = [...checkSelected, item?._id];
      setCheckSelected(updatedCheckSelected);
    }
  };
  const permissions = localStorage.getItem("permissions");
  return (
    <Modal
      className="add_user_modal_container"
      title="Add Users"
      visible={isModalOpen}
      onOk={() => handleOk(users)}
      onCancel={handleCancel}
      footer={[
        <Row>
          <Col span={24} align={"right"} className="justify_items_end">
            <Button key="submit" className="cancel_btn" onClick={handleCancel}>
              Cancel
            </Button>
            <Button
              key="submit"
              className="black_color_button"
              onClick={() => handleOk(users)}
            >
              Done
            </Button>
            ,
          </Col>
        </Row>,
      ]}
    >
      <Row>
        <Col xs={24} className="choose_user_title_col">
          <Input
            className="input_box_users w-100"
            placeholder="Choose users"
            onChange={(e) => setSearchUsers(e.target.value)}
          />
        </Col>
      </Row>
      <div style={{ height: "330px", overflowY: "auto" }}>
        {data.map((item, index) => {
          return (
            <>
              {checkSelected?.includes(item?._id) ? (
                <Row
                  justify={"space-between"}
                  className={"individual_item_row_active"}
                  onClick={() => handleUserClick(item, index)}
                >
                  <Col xs={16}>
                    <span className="text_capitalize">{`${item?.firstName} ${item?.lastName}`}</span>
                  </Col>
                  <Col xs={6} className="user_type_col">
                    <span>
                      {item?.recruiterUserType
                        ? item?.recruiterUserType
                        : item?.recruiterUserType === null
                        ? "Admin"
                        : ""}
                    </span>
                  </Col>
                  <Col xs={2} className="align_check_left">
                    <CheckOutlined />
                  </Col>
                </Row>
              ) : (
                <Row
                className={
                  item?.isVerified
                    ? "individual_item_row_inactive"
                    : "individual_item_row_inactive invitation_color"
                }
                onClick={() => handleUserClick(item, index)}
              >
                <Col xs={16}>
                  <span className="text_capitalize">{`${item?.firstName} ${item?.lastName}`}</span>
                </Col>
                <Col xs={8} className="user_type_col">
                  <span>
                    {item?.recruiterUserType
                      ? item?.recruiterUserType
                      : item?.recruiterUserType === null
                      ? "Admin"
                      : ""}
                  </span>
                </Col>
              </Row>
              )}
            </>
          );
        })}
      </div>
      <Row className="add_new_user_row">
        <Col span={24} align={"right"}>
          <div className="add_user_div">
            <div className="pending_request_text_div">
              <div className="yellow_color_div"></div>{" "}
              <span>Pending Request</span>
            </div>
            {/* 
            <div
              className="cursor_pointer"
              onClick={() => navigate("/user-management/add-user")}
            >
              <Image preview={false} src={addUserIcon} />
              <span> +Create User</span>
            </div> */}
          </div>
        </Col>
      </Row>
    </Modal>
  );
};
export default AddUserModal;
