import { Button, Checkbox, Modal, Row, Select } from "antd";
import React from "react";

function SelectCamaignModal({ pickCampaignModal, setPickCampaignModal }) {
  const options = [
    {
      value: "Westconnect",
    },
    {
      value: "Operations",
    },
    {
      value: "WestconnectX",
    },
  ];
  return (
    <div>
      <Modal
        centered
        width={344}
        title="Select Campaign:"
        open={pickCampaignModal}
        onCancel={() => setPickCampaignModal(false)}
        footer={false}
        className="select-campaign-modal"
      >
        <Select
          mode="multiple"
          className="select-campaign-select mt-24"
          placeholder="Choose one or multiple Campaigns"
          options={options}
        ></Select>
        <Row className="justify-end mt-24 gap-20">
          <Button className="cancelBtn">Cancel</Button>
          <Button className="doneBtn">Done</Button>
        </Row>
      </Modal>
    </div>
  );
}

export default SelectCamaignModal;
