import {
  Col,
  Row,
  Switch,
  Table,
  Input,
  Button,
  Image,
  message,
  Tooltip,
  Avatar,
} from "antd";
import EditIcon from "../../Assets/campagin/editIcon.svg";
import SubLayout from "../../components/layout/SubLayout";
import UpperArrow from "../../Assets/profile/upper_arrow.svg";
import InfoIcon from "../../Assets/info_icon.svg";
import DefaultCompanyLogo from "../../Assets/defaultCompanyLogo.svg";
import SearchIcon from "../../Assets/search_icon.svg";
import EditWithoutBg from "../../Assets/edit_without_bg.svg";
import { useNavigate } from "react-router-dom";
import API_MANAGER from "../../API";
import { useEffect, useState } from "react";
import moment from "moment";
import edit_icon from "../../Assets/user_mangement/edit_icon.svg";
import CustomPagination from "../../components/common/CustomPagination";
import SelectCampaigns from "../UserManagement/selectCampaigns";
import FullScreenLoader from "../../components/common/FullScreenLoader";
import { checkPermissions } from "../../utils";

function MyProfile() {
  const navigate = useNavigate();
  const [data, setData] = useState();
  const [userData, setUserData] = useState();
  const [campaignList, setCampaignList] = useState([]);
  const [selectCampaignsModal, setSelectedCampaignsModal] = useState(false);
  const [permissionsText, setPermissionsText] = useState();
  const [editCampaignData, setEditCampaignData] = useState();
  const [campaignSearch, setCampaignSearch] = useState(null);
  const [alreadySelected, setAlreadySelected] = useState(null);
  const [dataUpdated, setDataUpdated] = useState(false);
  const [search, setSearch] = useState(null);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const user = localStorage.getItem("user");
  //USER DETAILS CARD API
  const SingleUserData = async () => {
    // setLoading(true);
    try {
      const response = await API_MANAGER.getSingleUserData(
        localStorage.getItem("userID")
      );
      setUserData(response?.data?.data);
      setAlreadySelected(response?.data?.data?.campaign);
    } catch (error) {
      // message.error("Something went wrong. Please try again.");
    }
  };
  const getUserEditCampaignData = async () => {
    setLoading(true);
    const param = {
      search: campaignSearch ? campaignSearch : "",
      sort: "-createdAt",
    };
    try {
      const response = await API_MANAGER.getCampaignList(param);
      setEditCampaignData(response?.data?.data?.result);
    } catch (error) {
      message.error("Something went wrong. Please try again.");
    }
    setLoading(false);
  };
  //API TO GET MY PROFILE DATA
  const getUserData = async () => {
    const params = {
      companyId: localStorage.getItem("clientId"),
    };
    try {
      const response = await API_MANAGER.getUserData(params);
      setData(response?.data?.data);
    } catch (error) {
      // message.error("Something went wrong. Please try again.");
    }
  };

  //USERS CAMPAIGNS/ADVERTS LISTING API
  const userId = localStorage.getItem("userID");

  const singleUserCampaignDetails = async () => {
    console.log("jfbdsf");
    if (userId)
      try {
        const param = {
          id: userId,
        };
        const response = await API_MANAGER.getSingleUserAdvertList(
          param,
          currentPage,
          search
        );

        setCampaignList(response?.data?.data);
      } catch (error) {
        message.error(error?.response?.data?.message?.message);
      }
  };
  useEffect(() => {
    let id;
    if (id) {
      clearTimeout(id);
    }
    id = setTimeout(() => {
      getUserEditCampaignData();
    }, 500);
    return () => {
      clearTimeout(id);
    };
  }, [campaignSearch]);

  useEffect(() => {
    const textData = checkPermissions(userData?.permissions,userData?.recruiterUserType);
    setPermissionsText(textData);
  }, [userData]);

  useEffect(() => {
    getUserData();
    SingleUserData();
    singleUserCampaignDetails();
  }, [dataUpdated, search]);
  useEffect(() => {
    if (userId) singleUserCampaignDetails();
  }, [dataUpdated, search, currentPage]);
  const columns = [
    {
      title: "Campaign Name",
      dataIndex: "campaign",
      key: "campaignName",
      fixed: "left",
    },
    {
      title: "Job Advert",
      dataIndex: "jobTitle",
      key: "advertName",
    },
    {
      title: "Start Date",
      dataIndex: "startDate",
      key: "start_date",
      render: (item) => <span>{moment(item)?.format("DD MMM YYYY")}</span>,
    },
    {
      title: "End Date",
      dataIndex: "endDate",
      key: "end_date",
      render: (item) => <span>{moment(item)?.format("DD MMM YYYY")}</span>,
    },
    {
      title: "Status",
      dataIndex: "archive",
      render: (item, row) => {
        if (!item && row?.isActive) {
          return <span className="Live_text">Live</span>;
        } else {
          return <span className="archived_text">Archived</span>;
        }
      },
      align: "center",
    },
  ];
  return (
    <SubLayout page={"User Profile"} whiteBg padding>
      <FullScreenLoader isLoading={loading} />
      <SelectCampaigns
        selectCampaignsModal={selectCampaignsModal}
        setSelectedCampaignsModal={setSelectedCampaignsModal}
        campaignList={editCampaignData}
        setSearch={setCampaignSearch}
        alreadySelected={alreadySelected}
        setDataUpdated={setDataUpdated}
        dataUpdated={dataUpdated}
        userId={userData?.id}
        loading={loading}
      />
      <Row className="profile_container">
        {/* Upper Section */}
        <Col xs={24} className="user_detail_container">
          <Row justify={"space-between"}>
            <Col>
              <p className="heading"></p>
            </Col>
            <Col>
              <span>
                <img
                  style={{ cursor: "pointer" }}
                  src={EditIcon}
                  onClick={() => navigate("/edit-profile")}
                />
              </span>
            </Col>
          </Row>
          <Row gutter={12}>
            <Col xs={12} md={5}>
              <p className="title">
                User
                <Tooltip
                  placement="right"
                  title={
                    <Row>
                      <Col span={24} className="align_items_center flex-col">
                        {permissionsText?.map((item) => {
                          return (
                            <div className="align_center_start w-100">
                              <div className="green_dot mr-5"></div>
                              <span>{item}</span>
                            </div>
                          );
                        })}
                      </Col>
                    </Row>
                  }
                >
                  <img className="cursor_pointer ml-10" src={InfoIcon} />
                </Tooltip>
              </p>
              <p className="title_value">
                {userData?.recruiterUserType === null
                  ? "Admin"
                  : userData?.recruiterUserType === "Associate"
                  ? "Association"
                  : userData?.recruiterUserType}
              </p>
            </Col>
            <Col xs={12} md={5}>
              <p className="title">Email</p>
              <p className="title_value">{userData?.email}</p>
            </Col>
            <Col xs={24} md={5}>
              <p className="title">Contact Number</p>
              <p className="title_value">
                {userData?.countryCode + "-"}
                {userData?.phoneNumber}
              </p>
            </Col>
          </Row>
          <hr className="horizontal_line" />
          <Row>
            <Col>
              <p className="company_title">Company Details</p>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={5}>
              <p className="title">Company Name*</p>
              <p className="title_value">{data?.companyName}</p>
            </Col>
            <Col xs={12} md={5} className="align_items_right">
              <p className="title">Trading Name</p>
              <p className="title_value t_align_right">{data?.tradingName}</p>
            </Col>
            <Col xs={12} md={5}>
              <p className="title">ACN/ABN*</p>
              <p className="title_value">{data?.companyAbn}</p>
            </Col>
            <Col xs={12} md={9} className="align_items_right">
              <p className="title">Registered Address*</p>
              <p className="title_value t_align_right">
                {data?.officeAddress?.label}
              </p>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={5}>
              <p className="title">Billing Name*</p>
              <p className="title_value">{data?.billingName}</p>
            </Col>
            <Col xs={12} md={5} className="align_items_right">
              <p className="title">Billing Email</p>
              <p className="title_value t_align_right">{data?.billingEmail}</p>
            </Col>
            <Col xs={24} md={5}>
              <p className="title">Logo*</p>
              <p className="title_value">
                {!data?.companyLogo ? (
                  <Avatar size={64} shape="square">
                    {data?.tradingName?.slice(0, 2).toUpperCase()}
                  </Avatar>
                ) : (
                  <Image
                    src={data?.companyLogo}
                    style={{ borderRadius: "8px" }}
                    preview={false}
                  />
                )}
              </p>
            </Col>
          </Row>
          <Row>
            <Col xs={20} md={10}>
              <p className="marketing_title">Marketing Permission</p>
              <p className="marketing_value">
                Receive the latest product and marketing updates <br /> from
                Cinchy.Me and selected partners
              </p>
            </Col>

            <Col xs={4} md={14} className="switch_Col">
              <Switch
                // className="switch_toggle"
                // isActive={data?.marketingPermission ? true : false}
                checked={data?.marketingPermission ? true : false}
              />
            </Col>
          </Row>
        </Col>
        {/* Table Section */}

        <Col
          xs={24}
          className="user_management_table_box"
          style={{ paddingTop: "20px" }}
        >
          <Table
            columns={columns}
            dataSource={campaignList?.result}
            pagination={false}
            title={() => (
              <Row gutter={16} align={"middle"} justify={"space-between"}>
                <Col xs={24} md={12}>
                  <Row className="justify_space_between_resp">
                    <Col xs={24} md={24}>
                      <Input.Search
                        className="searchBox searchInput"
                        allowClear
                        placeholder="Search campaign or job advert"
                        onChange={(e) => setSearch(e?.target?.value)}
                        prefix={<img src={SearchIcon} alt="search" />}
                      />
                    </Col>
                  </Row>
                </Col>
                {user !== "Associate" && (
                  <Col xs={0} md={12} align={"end"}>
                    <Row justify={"end"}>
                      <Col className="d_none_phone">
                        <Button
                          onClick={() => {
                            setSelectedCampaignsModal(true);
                          }}
                          className="createBtn"
                        >
                          <img
                            style={{ fontSize: "16px" }}
                            src={EditWithoutBg}
                          />
                          <span style={{ color: "#091647" }}>
                            Edit campaigns
                          </span>
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                )}
              </Row>
            )}
          />
          <CustomPagination
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            total={campaignList?.totalRecord}
            itemPerPage={10}
          />
          {user !== "Associate" && (
            <Row>
              <Col xs={24} md={0} align={"end"}>
                <Row justify={"end"}>
                  <Col className="d-none">
                    <Button
                      onClick={() => {
                        setSelectedCampaignsModal(true);
                      }}
                      className="createBtn-sm"
                    >
                      <img style={{ fontSize: "16px" }} src={edit_icon} />
                      <span style={{ color: "#091647" }}>Edit campaigns</span>
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          )}
        </Col>
      </Row>
    </SubLayout>
  );
}
export default MyProfile;
