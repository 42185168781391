import { Row, Col } from "antd";
import React, { useState } from "react";
import CloseArrow from "../../../Assets/closeArrow.svg";
import OpenArrow from "../../../Assets/openArrow.svg";
import ListArrow from "../../../Assets/listArrow.svg";
import RightIcon from "../../../Assets/rightIcon.svg";
function TransferCampaignDropdown({
  item,
  setInviteIds,
  inviteIds,
  setNewAdvertId,
  setSelectedAdverts,
  selectedAdverts,
}) {
  const [open, setOpen] = useState(true);
  const handleClick = (id) => {
    if (selectedAdverts?.includes(id)) {
      const tempData = selectedAdverts?.filter((e) => e !== id);
      setSelectedAdverts(tempData);
      setInviteIds({ ...inviteIds, [item?._id]: tempData });
      setNewAdvertId(tempData);
    } else {
      const tempData = [...selectedAdverts, id];
      setSelectedAdverts(tempData);
      setInviteIds({ ...inviteIds, [item?._id]: tempData });
      setNewAdvertId(tempData);
    }
  };

  return (
    <div className="inviteCampaignDropDown">
      <Row
        align={"middle"}
        gutter={12}
        justify={"space-between"}
        className={`cursor-pointer ${
          //   selectedAdverts?.length > 0
          // ? "activeCampaignContainer"
          "campaignContainer"
        }`}
        onClick={() => setOpen(!open)}
      >
        <Col style={{ width: "calc(100% - 36px)" }}>
          <Row align={"middle"} gutter={12}>
            <Col>
              {open ? (
                <img src={OpenArrow} alt="opwn" />
              ) : (
                <img src={CloseArrow} alt="close" />
              )}
            </Col>
            <Col style={{ width: "calc(100% - 30px)" }} className="name">
              {item?.name}
            </Col>
          </Row>
        </Col>
        {/* {selectedAdverts?.length > 0 && (
          <Col>
            <img src={RightIcon} alt="img" />
          </Col>
        )} */}
      </Row>
      {open && (
        <div className="advertContainer">
          {item?.adverts?.map((data, index) => (
            <Row
              align={"middle"}
              gutter={8}
              justify={"space-between"}
              className={`cursor-pointer ${
                selectedAdverts?.includes(data?._id)
                  ? "activeAdvertItem"
                  : "advertItem"
              } `}
              onClick={() => {
                handleClick(data?._id);
              }}
            >
              <Col style={{ width: "calc(100% - 36px)" }}>
                <Row align={"middle"} gutter={8}>
                  <Col>
                    <img src={ListArrow} alt="opwn" />
                  </Col>
                  <Col style={{ width: "calc(100% - 30px)" }} className="name">
                    {data?.jobTitle?.name}
                  </Col>
                </Row>
              </Col>
              {selectedAdverts?.includes(data?._id) && (
                <Col>
                  <img src={RightIcon} alt="img" />
                </Col>
              )}
            </Row>
          ))}
        </div>
      )}
    </div>
  );
}

export default TransferCampaignDropdown;
