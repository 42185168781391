import React, { useEffect, useState } from "react";
import SubLayout from "../../components/layout/SubLayout";
import SectionProgress from "../../components/common/campaignManagement/SectionProgress";
import { Col, Form, Row, message, Modal, Button } from "antd";
import { PcView, PhoneView } from "../../Assets/campagin/sectionProgress/svgs";
import AdvertDetailsForm from "../../components/common/campaignManagement/AdvertDetailsForm";
import AddRequirementForm from "../../components/common/campaignManagement/AddRequirementForm";
import { useLocation, useSearchParams } from "react-router-dom";
import API_MANAGER from "../../API";
import { useNavigate } from "react-router-dom";
import AddSoftRequirementForm from "../../components/common/campaignManagement/AddSoftRequirementForm";
import PhoneViewModal from "./PhoneViewModal";
import AddRequirementPhone from "../../components/common/campaignManagement/AddRequirementPhone";
import closeicon from "../../Assets/advert_detail/closeicon.svg";

const RequiredItems = [
  "QUALIFICATION",
  "INDUCTION",
  "MEDICAL_ASSESSMENT",
  "VISA",
  "SKILL",
];

function CreateAdvert() {
  let [searchParams] = useSearchParams();
  let advertId = searchParams.get("id");
  const [form] = Form.useForm();
  const [progress, setprogress] = useState(0);
  const [currentForm, setCurrentForm] = useState("advert");
  const [values, setValues] = useState({});
  const [hardReqSelected, setHardReqSelected] = useState({});
  const [softReqSelected, setSoftReqSelected] = useState({});
  const [phoneModal, setPhoneModal] = useState(false);
  const [phoneViewData, setPhoneViewData] = useState();
  const [defaultData, setdefaultData] = useState();
  const [hardReqColumn, setHardReqColumn] = useState({});
  const [softReqColumn, setSoftReqColumn] = useState({});
  const [jobTitles, setJobTitles] = useState([]);
  const [employmentTypes, setEmploymentTypes] = useState([]);
  const [rosters, setRosters] = useState([]);
  const [disciplines, setDisciplines] = useState([]);
  const [industries, setIndustries] = useState([]);
  const [states, setStates] = useState([]);
  const [jobDurations, setJobDurations] = useState([]);
  const [IsSubmit, setIsSubmit] = useState(false);
  const [status, setstatus] = useState({
    detail: "progress",
    requirement: "disable",
  });
  const [advertActive, setAdvertActive] = useState(true);
  const [finalSubmit, setFinalSubmit] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [mobileFinalData, setMobileFinalData] = useState({});
  const props = useLocation();
  const data = props?.state?.advertDetail ? props?.state?.advertDetail : props?.state;
  const editType = props?.state?.type;
  localStorage.setItem('editType',props?.state?.type);
  useEffect(() => {
    if (editType === "HARD_REQUIREMENTS") setCurrentForm("hard_req");
    else if (editType === "SOFT_REQUIREMENTS") setCurrentForm("soft_req");
    else setCurrentForm("advert");
  }, [editType]);
  const navigate = useNavigate();
  const verifyAdvertDetailForm = () => {
    if (progress == 75) {
      setstatus((prev) => ({ ...prev, requirement: "progress" }));
      setCurrentForm("hard_req");
    } else {
      setCurrentForm("hard_req");
    }
  };
  const handlePhoneView = (isPhoneView = false) => {
    setPhoneModal(isPhoneView);
  };
  const handelFinish = (values) => {
    setValues(values);
    verifyAdvertDetailForm();
  };

  const handelStoreRequirementHard = (isSubmit = false, isModal = false) => {
    setIsSubmit(isSubmit);
    setFinalSubmit("hard");

    let temp = true;
    if (!isModal) {
      Object.entries(hardReqSelected).map(([key, value]) => {
        if (RequiredItems?.includes(key) && !(value && value?.length > 0)) {
          // setFilterLength(filterLength + 1);
          temp = false;
          setIsModalOpen(true);
          return;
        }
      });
    } else {
      setIsModalOpen(false);
    }
    if (temp) {
      if (isSubmit) {
        handleSubmit(hardReqSelected, softReqSelected);
      } else {
        setCurrentForm("soft_req");
      }
    }
  };
  const handelStoreRequirementSoft = (isSubmit = false, isModal = false) => {
    setIsSubmit(isSubmit);
    setFinalSubmit("soft");
    let temp = true;

    if (!isModal) {
      Object.entries(softReqSelected).map(([key, value]) => {
        if (RequiredItems?.includes(key) && !(value && value?.length > 0)) {
          // setFilterLength(filterLength + 1);
          temp = false;
          setIsModalOpen(true);
          return;
        }
      });
    } else {
      setIsModalOpen(false);
    }
    if (temp) {
      if (isSubmit) {
        handleSubmit(hardReqSelected, softReqSelected);
      }
    }
  };
  const mobileHandleSubmit = (finaldata, isModal = false) => {
    setFinalSubmit("mobile");
    setMobileFinalData(finaldata);
    setIsModalOpen(false);
    let temp = true;
    if (!isModal) {
      Object.entries(finaldata?.hardRequirements).map(([key, value]) => {
        if (RequiredItems?.includes(key) && !(value && value?.length > 0)) {
          // setFilterLength(filterLength + 1);
          temp = false;
          setIsModalOpen(true);
          return;
        }
      });
      Object.entries(finaldata?.softRequirements).map(([key, value]) => {
        if (RequiredItems?.includes(key) && !(value && value?.length > 0)) {
          // setFilterLength(filterLength + 1);
          temp = false;
          setIsModalOpen(true);
          return;
        }
      });
    } else {
      setIsModalOpen(false);
    }
    if (temp) {
      handleSubmit(finaldata?.hardRequirements, finaldata?.softRequirements);
    }
  };
  const handleSubmit = async (hard_req, soft_req) => {
    try {
      const values = form.getFieldsValue();

      let tempHard = hard_req ? Object.keys(hard_req) : [];
      tempHard?.map((item) => {
        if (hard_req[item]) {
        } else {
          hard_req[item] = [];
        }
      });
      let tempSoft = soft_req ? Object.keys(soft_req) : [];
      tempSoft?.map((item) => {
        if (soft_req[item]) {
        } else {
          soft_req[item] = [];
        }
      });
      const body = {
        ...values,
        // ["campaignId"]: "test",
        jobTitle:
          typeof values?.jobTitle === "object"
            ? values?.jobTitle?.value
            : values?.jobTitle,
        jobDuration:
          typeof values?.jobDuration === "object"
            ? values?.jobDuration?.value
            : values?.jobDuration,
        roster:
          typeof values?.roster === "object"
            ? values?.roster?.value
            : values?.roster,
        discipline:
          typeof values?.discipline === "object"
            ? values?.discipline?.value
            : values?.discipline,
        postalCode: values?.postalCode,
        state: values?.state?.value,
        industry: values?.industry?.value,
        minSalary: values?.minSalary,
        maxSalary: values?.maxSalary ? values?.maxSalary : values?.minSalary,
        campaignId: data?.campaignId?.id
          ? data?.campaignId?.id
          : data?.id,
        isActive: advertActive,
        employmentType:
          typeof values?.employmentType === "object"
            ? values?.employmentType?.value
            : values?.employmentType,
        hours: Number(values?.hours),
        targetHeadcount: Number(values?.targetHeadcount),
        softRequirements: soft_req
          ? soft_req
          : defaultData?.softRequirements
          ? defaultData?.softRequirements
          : {},
        hardRequirements: hard_req
          ? hard_req
          : defaultData?.hardRequirements
          ? defaultData?.hardRequirements
          : {},
      };

      if (advertId) {
        await API_MANAGER.updateAdvert(advertId, body);
        message.success("Advert successfully updated.");
      } else {
        await API_MANAGER.createAdvert(body);
        message.success("Advert successfully created.");
      }
      navigate(-1);
      localStorage.removeItem("createAdvert");
      localStorage.removeItem("advertRangeType");
    } catch (error) {
      const { response } = error;
      message.error(
        error?.response?.data?.message?.message ||
          "Something went wrong, please try again!"
      );
    }
  };
  const getTags = async (type, setData) => {
    try {
      // const params = {
      //   limit: 20000,
      //   type: type,
      //   sort: "name",
      //   // search: search,
      // };
      const response = await API_MANAGER.getTags(type);
      setData(response?.data?.data?.tags);
    } catch (error) {}
  };
  useEffect(() => {
    getTags("ROSTER", setRosters);
    getTags("JOBTITLE&sort=name", setJobTitles);
    getTags("DISCIPLINE&sort=name", setDisciplines);
    getTags("STATE&sort=name", setStates);
    getTags("INDUSTRY&sort=name", setIndustries);
    getTags("EMPLOYMENT_TYPE&sort=name", setEmploymentTypes);
    getTags("JOB_DURATION", setJobDurations);
  }, []);
  useEffect(() => {
    if (progress === 75) {
      setstatus((prev) => ({ ...prev, detail: "done" }));
    }
  }, [progress]);

  const isPlusSubscription = JSON.parse(
    localStorage.getItem("isPlusSubscription")
  );

  return (
    <SubLayout page="Create Advert" whiteBg showBack>
      <PhoneViewModal
        phoneViewData={phoneViewData}
        phoneModal={phoneModal}
        setPhoneModal={handlePhoneView}
        campaignName={
          data?.campaignId?.name
            ? data?.campaignId?.name
            : data?.name
            ? data?.name
            : ""
        }
      />
      <Modal
        centered
        width={392}
        title="Post Advert?"
        wrapClassName="report_modal_container"
        open={isModalOpen}
        // onOk={handleOk}
        closeIcon={<img src={closeicon} />}
        footer={
          <Row gutter={16} className="justify-end d-flex">
            <Col>
              <Button className="okBtn" onClick={() => setIsModalOpen(false)}>
                Cancel
              </Button>
            </Col>
            <Col>
              <Button
                className="cancelBtn"
                onClick={() => {
                  if (finalSubmit === "hard") {
                    handelStoreRequirementHard(IsSubmit, true);
                  } else if (finalSubmit === "soft") {
                    handelStoreRequirementSoft(IsSubmit, true);
                  } else if (finalSubmit === "mobile") {
                    mobileHandleSubmit(mobileFinalData, true);
                  }
                }}
              >
                Proceed
              </Button>
            </Col>
          </Row>
        }
        onCancel={() => setIsModalOpen(false)}
      >
        <Row>
          <Col span={24}>
            <h4>
              You haven't populated one or more requirements. If you proceed,
              these requirements will be deleted.
            </h4>
          </Col>
        </Row>
      </Modal>
      <div className="create-advert-container">
        <SectionProgress percent={progress} status={status} />
        <Row className="form-caution" justify={"space-between"}>
          <Col xs={0} md={23} className="desc_laptop">
            <div className="caution">
              Please note, adverts with more details
            </div>
            <div className="caution">are likely to get more responses.</div>
          </Col>
          <Col xs={18} md={0} className="desc_mobile">
            <div className="caution desc">
              Please note, adverts with more details are likely to get more
              responses.
            </div>
          </Col>
          <Col xs={5} md={1} className="tab-btn-holder">
            <div
              style={{ borderRadius: "12px 0px 0px 12px " }}
              className={`${!phoneModal ? "active" : "inactive"}`}
              onClick={() => handlePhoneView(false)}
            >
              <PcView
                color={!phoneModal ? "white" : "rgba(135, 158, 241, 1)"}
              />
            </div>
            <div
              style={{ borderRadius: "0px 12px 12px 0px " }}
              className={`${!phoneModal ? "inactive" : "active"}`}
              onClick={() => handlePhoneView(true)}
            >
              <PhoneView
                color={!phoneModal ? "rgba(135, 158, 241, 1)" : "white"}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Form
            layout="vertical"
            form={form}
            style={{ width: "100%" }}
            requiredMark={false}
            onFinish={(e) => handelFinish(e)}
          >
            <AdvertDetailsForm
              form={form}
              isHidden={currentForm !== "advert"}
              setCurrentForm={setCurrentForm}
              setProgress={setprogress}
              setPhoneViewData={setPhoneViewData}
              setdefaultData={setdefaultData}
              setHardReqSelected={setHardReqSelected}
              setSoftReqSelected={setSoftReqSelected}
              data={data}
              jobTitles={jobTitles}
              employmentTypes={employmentTypes}
              rosters={rosters}
              disciplines={disciplines}
              industries={industries}
              states={states}
              jobDurations={jobDurations}
              handleSubmit={handleSubmit}
              setAdvertActive={setAdvertActive}
            />
            {isPlusSubscription && (
              <div className="d_none_phone" style={{ flexDirection: "column" }}>
                <AddRequirementForm
                  form={form}
                  isHidden={currentForm !== "hard_req"}
                  setCurrentForm={setCurrentForm}
                  setprogress={setprogress}
                  handleSubmit={handelStoreRequirementHard}
                  defaultData={defaultData}
                  softReqSelected={softReqSelected}
                  hardReqSelected={hardReqSelected}
                  setHardReqSelected={setHardReqSelected}
                  setSoftReqSelected={setSoftReqSelected}
                  isEdit={advertId}
                  setColumns={setHardReqColumn}
                  columns={hardReqColumn}
                  setSoftReqColumn={setSoftReqColumn}
                  softReqColumn={softReqColumn}
                />
                <AddSoftRequirementForm
                  form={form}
                  isHidden={currentForm !== "soft_req"}
                  setprogress={setprogress}
                  handleSubmit={handelStoreRequirementSoft}
                  setCurrentForm={setCurrentForm}
                  defaultData={defaultData}
                  hardReqSelected={hardReqSelected}
                  softReqSelected={softReqSelected}
                  setHardReqSelected={setHardReqSelected}
                  setSoftReqSelected={setSoftReqSelected}
                  isEdit={advertId}
                  columns={softReqColumn}
                  setColumns={setSoftReqColumn}
                  hardReqColumn={hardReqColumn}
                  setHardReqColumn={setHardReqColumn}
                />
              </div>
            )}
          </Form>

          {currentForm !== "advert" ? (
            <div className="d-none " style={{ width: "100%" }}>
              <AddRequirementPhone onSubmit={mobileHandleSubmit} />
            </div>
          ) : null}
        </Row>
      </div>
    </SubLayout>
  );
}

export default CreateAdvert;
