import React, { useEffect, useState } from "react";
import SubLayout from "../../components/layout/SubLayout";
import {
  Row,
  Col,
  Table,
  Button,
  Input,
  Dropdown,
  Radio,
  message,
  Tooltip,
} from "antd";
import EditIcon from "../../Assets/editIcon.svg";
import verticleDot from "../../Assets/dots-vertical.svg";
import DeleteImg from "../../Assets/campagin/delete.svg";
import SearchIcon from "../../Assets/campagin/searchIcon.svg";
import CancelIcon from "../../Assets/cancelIcon.svg";

import NoElementBox from "../../components/common/NoElementBox";
import FilterIcon from "../../Assets/filter.svg";
// import { JobActiveDeactivateModal } from "../../components/common/Popup";
import { useNavigate } from "react-router-dom";
import deleteIcon from "../../Assets/advert_detail/delete_icon_red.svg";
import AdvertSummary from "../../components/common/AdvertDetail/AdvertSummary";
import status_image from "../../Assets/advert_detail/status.svg";
import downloadIcon from "../../Assets/advert_detail/download.svg";
import { TableStatusImage } from "../../Assets/campagin/sectionProgress/svgs";
import { CommonDeleteSvg } from "../../Assets/campagin/sectionProgress/svgs";
import checkFilled_image from "../../Assets/advert_detail/table-cell/check.svg";
import unCheckFilled_image from "../../Assets/advert_detail/table-cell/Tracker.svg";
import infoIcon from "../../Assets/advert_detail/infoIcon.svg";
import alert_triangle from "../../Assets/advert_detail/alert-triangle.svg";
import transeferImage from "../../Assets/advert_detail/transfer.svg";
import action_delete from "../../Assets/advert_detail/action-delete.svg";
import Reportpopup, {
  DeleteUserPopup,
  StatusModal,
  TransferModal,
  UpdateAlert,
  UpdatestatusPopup,
} from "../../components/common/AdvertDetail/Reportpopup";
import { DeleteAdvertModal, DeleteModal } from "../../components/common/Popup";
// import StatusModal from "../../components/common/AdvertDetail/StatusModal";
import NotificationPopup from "../../components/common/notificationPopup";
import UpgradeMembershipModal from "../../components/common/UpgradeMembershipPopup";
import BulkDelete from "./BulkDelete";
import API_MANAGER from "../../API";
import { useParams } from "react-router-dom";
import moment from "moment";
import CustomPagination from "../../components/common/CustomPagination";
import {
  downloadCsv,
  downloadPDF,
  numberWithCommas,
} from "../../utils/constant";
import MobileHeader from "../../components/layout/MobileHeader";
function AdvertDetail() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [isModalOpen, setisModalOpen] = useState(false);
  const [reportModal, setReportModal] = useState(false);
  const [updatePopup, setUpdatePopup] = useState(false);
  const [transferModal, setTransferModal] = useState(false);
  const [updateAlert, setUpdateAlert] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedCandidates, setSelectedCandidates] = useState([]);
  const [statusModal, setStatusModal] = useState(false);
  const [errorNotification, setErrorNotification] = useState(false);
  const [candidateSelectedData, setCandidateSelectedData] = useState({});
  const [upgradeMembership, setUpgradeMembership] = useState(false);
  const [bulkDeleteModal, setBulkDeleteModal] = useState(false);
  const [advertDetail, setAdvertDetail] = useState({});
  const [candidateDetails, setCandidatesDetails] = useState([]);
  const [sortType, setSortType] = useState("-createdAt");
  const [search, setSearch] = useState("");
  const [advertSearch, setAdvertSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [activeDeleteModal, setActiveDeleteModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const subscription = JSON.parse(localStorage.getItem("subscription"));
  const [campaignID, setCampaignID] = useState("");
  const [transferData, setTransferData] = useState([]);
  const [oldAdvertId, setOldAdvertId] = useState("");
  const [profileId, setProfileId] = useState("");
  const [candidateName, setCandidateName] = useState("");
  const [reportLoading, setReportLoading] = useState(false);
  const [ehHrLoading, setEhHrLoading] = useState(false);
  const [reloadState, setReloadState] = useState(false);
  const [deleteKeys, setDeleteKeys] = useState();
  const [selectedRowData, setSelectedRowData] = useState([]);
  const deleteAdvert = async () => {
    let campaignId = advertDetail?.campaignId?.id;
    setDeleteLoading(true);
    try {
      await API_MANAGER.deleteAdvert(id);
      message.success("Advert successfully deleted.");
      setDeleteLoading(false);
      setActiveDeleteModal(false);
      navigate(`/campaign-detail/${campaignId}`);
    } catch (error) {
      setDeleteLoading(false);
      message.error("Something went wrong. Please try again.");
    }
  };
  const getData = async () => {
    try {
      const response = await API_MANAGER.getAdvertDetail(id);
      setAdvertDetail(response?.data?.data);
      setCampaignID(response?.data?.data?.campaignId?.id);
    } catch (error) {
      message.error("Something went wrong. Please try again.");
    }
  };

  const getCandidateDetails = async () => {
    setLoading(true);
    const param = {
      page: currentPage,
      limit: 10,
      sort: sortType,
      search: search,
    };
    try {
      const response = await API_MANAGER.getAdvertCandidatesDetail(id, param);
      setReloadState(false);
      setCandidatesDetails(response?.data?.data);
      setOldAdvertId(response?.data?.data?.result[0]?.advertId?._id);
      setProfileId(response?.data?.data?.result[0]?.profileId?._id);
      setCandidateName(response?.data?.data?.result[0]?.candidateName);
    } catch (error) {
      message.error("Something went wrong. Please try again.");
    }
    setLoading(false);
  };
  const getAdvertData = async (id) => {
    setLoading(true);
    // let params = {
    //   page: 1,
    //   limit: 100,
    //   sort: "-createdAt",
    // };
    let params = {
      profileId: candidateSelectedData?.profileId?._id,
      sort: "-createdAt",
      limit: 20000,
      page: 1,
    };
    if (advertSearch) {
      params = { ...params, ["search"]: advertSearch };
    }
    if (userType !== "Associate") {
      try {
        const response = await API_MANAGER.getSingleUserCampaignDetails(params);
        setTransferData(response?.data?.data);
      } catch (error) {
        message.error("Something went wrong. Please try again.");
      }
    }
    setLoading(false);
  };
  const userType = localStorage.getItem("user");
  useEffect(() => {
    getData();
  }, [id]);
  useEffect(() => {
    getCandidateDetails();
  }, [search, sortType, currentPage, id, reloadState == true]);
  useEffect(() => {
    getAdvertData();
  }, [advertSearch]);
  const items = [
    {
      key: "1",
      label: (
        <Radio.Group
          className="dropDown_radio_group"
          onChange={(e) => setSortType(e?.target?.value)}
        >
          <Row className="filter_item_row">
            <Col span={24} className="">
              <Radio value={"-createdAt"}>Latest First</Radio>
            </Col>
          </Row>
          <Row className="filter_item_row">
            <Col span={24} className="">
              <Radio value={"createdAt"}>Oldest First</Radio>
            </Col>
          </Row>
          <Row className="filter_item_row">
            <Col span={24} className="">
              <Radio value={"candidateName"}>Sort by A-Z</Radio>
            </Col>
          </Row>
          <Row className="filter_item_row">
            <Col span={24} className="">
              <Radio value={"-candidateName"}>Sort by Z-A</Radio>
            </Col>
          </Row>
        </Radio.Group>
      ),
    },
  ];

  const columns = [
    {
      title: (
        <div>
          <span style={{ marginRight: "10px" }}>Name</span>
          <Dropdown
            menu={{ items }}
            overlayClassName="notificationFilterDropdown "
          >
            <img src={FilterIcon} className="cursor_pointer" alt="filter" />
          </Dropdown>
        </div>
      ),
      dataIndex: "name",
      fixed: "left",
      // render: (item, record) => (

      // ),
      render: (item, row) => (
        <Tooltip
          title={
            <div>
              <span>Contact Details</span>
              {row?.profileId?.phoneNumber && (
                <Row gutter={8} align={"middle"}>
                  <Col>
                    <svg
                      width="8"
                      height="8"
                      viewBox="0 0 8 8"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="4" cy="4" r="4" fill="#31B948" />
                    </svg>
                  </Col>
                  <Col>
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6.28325 6.63806C6.80525 7.72528 7.51684 8.74426 8.41803 9.64544C9.31921 10.5466 10.3382 11.2582 11.4254 11.7802C11.5189 11.8251 11.5657 11.8476 11.6249 11.8648C11.8351 11.9261 12.0933 11.8821 12.2714 11.7546C12.3215 11.7187 12.3644 11.6758 12.4501 11.5901C12.7123 11.3279 12.8434 11.1968 12.9752 11.1111C13.4724 10.7878 14.1133 10.7878 14.6105 11.1111C14.7423 11.1968 14.8734 11.3279 15.1356 11.5901L15.2818 11.7362C15.6804 12.1348 15.8797 12.3341 15.9879 12.5481C16.2032 12.9738 16.2032 13.4765 15.9879 13.9022C15.8797 14.1162 15.6804 14.3155 15.2818 14.7141L15.1636 14.8323C14.7664 15.2295 14.5677 15.4281 14.2977 15.5798C13.9981 15.7482 13.5327 15.8692 13.1891 15.8681C12.8793 15.8672 12.6677 15.8071 12.2443 15.687C9.9693 15.0413 7.82253 13.8229 6.03154 12.0319C4.24056 10.2409 3.0222 8.09417 2.37647 5.81912C2.25632 5.39579 2.19624 5.18412 2.19532 4.87441C2.1943 4.53074 2.31532 4.06538 2.48363 3.76575C2.63532 3.49572 2.83393 3.29711 3.23114 2.8999L3.34937 2.78167C3.74795 2.38309 3.94724 2.1838 4.16128 2.07554C4.58695 1.86024 5.08965 1.86024 5.51532 2.07554C5.72936 2.1838 5.92865 2.38309 6.32723 2.78167L6.47338 2.92782C6.73559 3.19003 6.86669 3.32113 6.9524 3.45296C7.27565 3.95013 7.27565 4.59107 6.9524 5.08823C6.86669 5.22007 6.73559 5.35117 6.47338 5.61337C6.38765 5.69911 6.34478 5.74198 6.3089 5.79208C6.18139 5.97016 6.13736 6.22835 6.19866 6.43862C6.2159 6.49779 6.23835 6.54454 6.28325 6.63806Z"
                        stroke="white"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </Col>
                  <Col>{"0" + row?.profileId?.phoneNumber}</Col>
                </Row>
              )}
              {row?.profileId?.userId?.email && (
                <Row gutter={8} align={"middle"}>
                  <Col>
                    <svg
                      width="8"
                      height="8"
                      viewBox="0 0 8 8"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="4" cy="4" r="4" fill="#31B948" />
                    </svg>
                  </Col>
                  <Col>
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1.66797 5.83594L8.47207 10.5988C9.02304 10.9845 9.29853 11.1773 9.59819 11.252C9.86288 11.318 10.1397 11.318 10.4044 11.252C10.7041 11.1773 10.9796 10.9845 11.5305 10.5988L18.3346 5.83594M5.66797 16.6693H14.3346C15.7348 16.6693 16.4348 16.6693 16.9696 16.3968C17.44 16.1571 17.8225 15.7747 18.0621 15.3042C18.3346 14.7695 18.3346 14.0694 18.3346 12.6693V7.33594C18.3346 5.93581 18.3346 5.23574 18.0621 4.70096C17.8225 4.23056 17.44 3.8481 16.9696 3.60842C16.4348 3.33594 15.7348 3.33594 14.3346 3.33594H5.66797C4.26784 3.33594 3.56777 3.33594 3.03299 3.60842C2.56259 3.8481 2.18014 4.23056 1.94045 4.70096C1.66797 5.23574 1.66797 5.93581 1.66797 7.33594V12.6693C1.66797 14.0694 1.66797 14.7695 1.94045 15.3042C2.18014 15.7747 2.56259 16.1571 3.03299 16.3968C3.56777 16.6693 4.26784 16.6693 5.66797 16.6693Z"
                        stroke="white"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </Col>
                  <Col>{row?.profileId?.userId?.email}</Col>
                </Row>
              )}
            </div>
          }
        >
          <>
            <span
              onClick={() => {
                navigate(`/applicant-profile/${row?.profileId?.userId?._id}`, {
                  state: {
                    id: id,
                  },
                });
              }}
              className={
                row?.isRejected
                  ? "rejected_candidate cursor-pointer"
                  : row?.final_select
                  ? "selected_candidate_div  cursor-pointer table-font-14"
                  : "cursor-pointer table-font-14"
              }
            >
              {row?.isApproved === "accepted" ? (
                <span className="approvedStyle">
                  <span>{row?.profileId?.name}</span>
                </span>
              ) : row?.isApproved === "rejected" ? (
                <span>
                  <strike className="rejectStyleClass">
                    {row?.profileId?.name}
                  </strike>
                </span>
              ) : (
                <span>{row?.profileId?.name}</span>
              )}
            </span>
          </>
        </Tooltip>
      ),
    },

    {
      title: "Invited",
      align: "center",

      dataIndex: "invited",
      render: (item, record) => (
        <>
          {record?.isInvited ? (
            <img src={checkFilled_image} />
          ) : (
            <img src={unCheckFilled_image} />
          )}
        </>
      ),
    },
    {
      title: "Applied",
      dataIndex: "applied",
      align: "center",

      render: (item, record) =>
        record?.progress?.length > 0 ? (
          <img src={checkFilled_image} />
        ) : (
          <img src={unCheckFilled_image} />
        ),
    },
    {
      title: (
        <div>
          Shortlists<br></br>Offered
        </div>
      ),
      dataIndex: "shortlists_offered",
      align: "center",

      render: (item, record) =>
        record?.progress &&
        record?.progress?.[record?.progress?.length - 1]?.state >= 2 ? (
          <img src={checkFilled_image} />
        ) : (
          <img src={unCheckFilled_image} />
        ),
    },
    {
      title: (
        <div>
          Hard<br></br>Review
        </div>
      ),
      dataIndex: "hard_review",
      align: "center",
      render: (item, record) =>
        record?.progress &&
        record?.progress[record?.progress?.length - 1]?.state >= 3 ? (
          <img src={checkFilled_image} />
        ) : (
          <img src={unCheckFilled_image} />
        ),
    },
    {
      title: (
        <div>
          Role<br></br>Offered
        </div>
      ),
      dataIndex: "role_offered",
      align: "center",

      render: (item, record) =>
        record?.progress &&
        record?.progress[record?.progress?.length - 1]?.state >= 4 ? (
          <img src={checkFilled_image} />
        ) : (
          <img src={unCheckFilled_image} />
        ),
    },
    {
      title: (
        <div>
          Role<br></br>Accepted
        </div>
      ),
      dataIndex: "roleAccepted",
      align: "center",

      render: (item) =>
        item ? (
          <img src={checkFilled_image} />
        ) : (
          <img src={unCheckFilled_image} />
        ),
    },
    {
      title: "",
      dataIndex: "action",
      align: "center",

      render: (item, rawData) => (
        <Row align={"middle"}>
          <Dropdown
            placement="left"
            overlayClassName="action-dropdown"
            menu={{
              items: actionItems,
            }}
            trigger={"click"}
          >
            <img
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setCandidateSelectedData(rawData);
              }}
              className="cursor_pointer"
              src={infoIcon}
              alt="edit"
            />
          </Dropdown>
        </Row>
      ),
    },
  ];

  const updateCandidateJobStatus = async () => {
    try {
      if (
        candidateSelectedData?.progress[
          candidateSelectedData?.progress?.length - 1
        ]?.state === 0
      ) {
        await API_MANAGER.updateCandidateJobStatus(candidateSelectedData?._id, {
          status: "SHORTLIST_OFFERED",
        });
      } else if (
        candidateSelectedData?.progress[
          candidateSelectedData?.progress?.length - 1
        ]?.state === 2
      ) {
        await API_MANAGER.acceptShortlistOffer({
          applicationId: candidateSelectedData?._id,
          acceptance: true,
        });
      } else if (
        candidateSelectedData?.progress[
          candidateSelectedData?.progress?.length - 1
        ]?.state === 3
      ) {
        await API_MANAGER.updateCandidateJobStatus(candidateSelectedData?._id, {
          status: "ROLE_OFFERED",
        });
      }

      message.success("Status successfully updated.");
      setUpdatePopup(false);
      getCandidateDetails();
      getData();
    } catch (error) {
      // message.error("Something went wrong. Please try again.");
      // setUpdateAlert(true);
      message.error(
        "A Candidate must accept your previous request, before you can push them to the next stage of the recruitment process."
      );
      setUpdatePopup(false);
    }
  };
  const updateBulkCandidateJobStatus = async () => {
    try {
      if (
        selectedRowData[0]?.progress[selectedRowData[0]?.progress?.length - 1]
          ?.state === 0
      ) {
        const param = {
          applicationIds: [...selectedRowKeys],
          status: "SHORTLIST_OFFERED",
        };
        await API_MANAGER.updateBulkCandidateJobStatus(param);
        message.success("Status successfully updated.");
        getCandidateDetails();
      } else if (
        selectedRowData[0]?.progress[selectedRowData[0]?.progress?.length - 1]
          ?.state === 3
      ) {
        const param = {
          applicationIds: [...selectedRowKeys],
          status: "ROLE_OFFERED",
        };
        await API_MANAGER.updateBulkCandidateJobStatus(param);

        message.success("Status successfully updated.");
        getCandidateDetails();
      } else {
        setStatusModal(false);
        message.error(
          "A Candidate must accept your previous request, before you can push them to the next stage of the recruitment process."
        );
        return;
      }
      setStatusModal(false);
    } catch (error) {
      message.error("Something went wrong. Please try again.");
      // setUpdateAlert(true);
    }
  };
  const onSelectChange = (newSelectedRowKeys) => {
    let updatedSelectedCandidates = [];
    // newSelectedRowKeys.filter((e)=> )
    const temp = candidateDetails?.result?.filter((e) => {
      if (newSelectedRowKeys.includes(e?._id)) {
        return e;
      } else {
        return null;
      }
    });
    setSelectedRowData(temp);
    newSelectedRowKeys.map((e, item_index) => {
      updatedSelectedCandidates.push(candidateDetails?.result[item_index - 1]);
    });

    setSelectedCandidates(updatedSelectedCandidates);
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const handleStatusButton = () => {
    let tempBool = false;
    if (selectedRowData?.length <= 1) {
      setStatusModal(true);
    } else if (selectedRowData?.length > 1) {
      if (selectedRowData[0]?.progress) {
        const tempState =
          selectedRowData[0]?.progress[selectedRowData[0]?.progress?.length - 1]
            ?.state;
        selectedRowData?.map((e) => {
          if (e?.progress) {
            if (e?.progress[e?.progress?.length - 1]?.state !== tempState) {
              setErrorNotification(true);
              tempBool = true;
              setTimeout(() => {
                setErrorNotification(false);
              }, 3000);
            }
          } else {
            const tempState1 = selectedRowData[0]?.isInvited;
            if (e?.isInvited !== tempState1) {
              setErrorNotification(true);
              tempBool = true;
              setTimeout(() => {
                setErrorNotification(false);
              }, 3000);
            }
          }
        });
      } else {
        const tempState = selectedRowData[0]?.isInvited;
        selectedRowData.map((e) => {
          if (e?.isInvited !== tempState) {
            setErrorNotification(true);
            tempBool = true;
            setTimeout(() => {
              setErrorNotification(false);
            }, 3000);
            return;
          }
        });
      }
      if (!tempBool) {
        setStatusModal(true);
      }
    } else {
      setErrorNotification(true);
      setTimeout(() => {
        setErrorNotification(false);
      }, 3000);
    }
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const hasSelected = selectedRowKeys.length > 0;
  const handleUpdagradeMembership = () => {
    if (localStorage.getItem("isPlusSubscription")) console.log("downloaddd");
    else setUpgradeMembership(true);
  };
  const handleTrackerClick = () => {
    if (JSON.parse(localStorage.getItem("isPlusSubscription")))
      navigate(`/advert-tracker/${advertDetail?.id}`);
    else setUpgradeMembership(true);
  };
  const candidateReport = async () => {
    setReportLoading(true);
    try {
      const response = await API_MANAGER.getCandidateReport(id);
      downloadPDF(response?.data?.data, "Candidate Report");
      setReportLoading(false);
    } catch (error) {
      setReportLoading(false);
      message.error(
        error?.response?.data?.message ||
          "Something went wrong. Please try again."
      );
    }
  };
  const ehHrReport = async () => {
    setEhHrLoading(true);
    try {
      const response = await API_MANAGER.ehHrReport({ advertId: id });
      console.log(response);
      downloadCsv(response?.data, "Employment Hero Report");
      setEhHrLoading(false);
    } catch (error) {
      setEhHrLoading(false);
      message.error(
        error?.response?.data?.message ||
          "Something went wrong. Please try again."
      );
    }
  };

  const handleAcceptReject = async (value) => {
    setLoading(true);
    try {
      await API_MANAGER.acceptRejectCandidate(
        { isApproved: value },
        candidateSelectedData?._id
      );
      message.success("Successfully updated candidate.");
      getCandidateDetails();
    } catch (error) {
      message.error(
        error.response.data.message.message ||
          "Something went wrong. Please try again."
      );
    }
    setLoading(false);
  };
  const handleDelete = async () => {
    const params = {
      applicationIds: [...selectedRowKeys],
    };
    setDeleteKeys(params);
    setBulkDeleteModal(true);
  };
  const handleDeleteSubmit = async () => {
    try {
      const response = await API_MANAGER.bulkApplicationDelete(deleteKeys);
      message.success("Application(s) successfully deleted.");
      setDeleteKeys(null);
      setBulkDeleteModal(false);
      getCandidateDetails();
    } catch (error) {
      message.error("Something went wrong. Please try again.");
    }
  };
  const actionItems = [
    {
      key: "1",
      label: (
        <div className="action-dropdown">
          {userType !== "Associate" ? (
            <>
              <Row>
                <Col
                  onClick={() => setUpdatePopup(true)}
                  className="item"
                  span={24}
                >
                  <img src={status_image} />
                  <span>Status</span>
                </Col>
              </Row>
              <Row>
                <Col
                  onClick={() => {
                    const params = {
                      applicationIds: [candidateSelectedData?._id],
                    };
                    setDeleteKeys(params);
                    setBulkDeleteModal(true);
                  }}
                  className="item"
                  span={24}
                >
                  <img src={action_delete} />
                  <span>Delete</span>
                </Col>
              </Row>
              <Row>
                <Col
                  onClick={() => {
                    getAdvertData();
                    setTransferModal({ transfer: true });
                  }}
                  className="item"
                  span={24}
                >
                  <img src={transeferImage} />
                  <span>Transfer</span>
                </Col>
              </Row>
              <Row>
                <Col
                  onClick={() => setReportModal(true)}
                  className="item"
                  span={24}
                >
                  <img src={alert_triangle} />
                  <span>{reportLoading ? "Downloading..." : "Report"}</span>
                </Col>
              </Row>
            </>
          ) : (
            <>
              <Row>
                <Col
                  onClick={() => handleAcceptReject("accepted")}
                  className="item"
                  span={24}
                >
                  <img src={status_image} />
                  <span>Accept</span>
                </Col>
              </Row>
              <Row>
                <Col
                  onClick={() => handleAcceptReject("rejected")}
                  className="item"
                  span={24}
                >
                  <img src={action_delete} />
                  <span>Reject</span>
                </Col>
              </Row>
            </>
          )}
        </div>
      ),
    },
  ];
  const tableHeaderPop = [
    {
      key: "1",
      label: (
        <Row gutter={[0, 10]} className="dropDown_radio_group">
          <Col
            className="filter_item_row"
            span={24}
            style={{ display: "flex", alignItems: "center" }}
            onClick={selectedRowKeys?.length > 0 ? handleDelete : null}
          >
            {/* <Row  align={"middle"} justify={"center"}> */}
            {/* <Col> */}
            <CommonDeleteSvg
              color={selectedRowKeys?.length > 0 ? "#D03C28" : "#A7AEBB"}
            />
            {/* </Col> */}
            <span
              style={{
                color: selectedRowKeys?.length > 0 ? "#D03C28" : "#A7AEBB",
              }}
            >
              Delete
            </span>
            {/* </Row> */}
          </Col>
          <Col
            className="filter_item_row"
            span={24}
            onClick={selectedRowKeys?.length > 0 ? handleStatusButton : null}
            style={{ marginLeft: "2%" }}
          >
            <img src={status_image} /> <span>Status</span>
          </Col>
          <Col
            className="filter_item_row"
            span={24}
            onClick={() => candidateReport()}
          >
            <img src={downloadIcon} /> <span>Report</span>
          </Col>
          <Col className="filter_item_row" span={24} onClick={ehHrReport}>
            <img src={downloadIcon} />
            <span>{ehHrLoading ? "Downloading..." : "Export"}</span>
          </Col>
        </Row>
      ),
    },
  ];

  return (
    <SubLayout page={"Advert Details"} showBack showSearch>
      {errorNotification && <NotificationPopup />}
      <UpgradeMembershipModal
        setUpgradeMembership={setUpgradeMembership}
        upgradeMembership={upgradeMembership}
      />
      {/* <BulkDelete
        selectedCandidates={selectedCandidates}
        BulkDeleteModal={BulkDeleteModal}
        setBulkDeleteModal={setBulkDeleteModal}
      /> */}
      <DeleteModal
        visible={bulkDeleteModal}
        setVisible={setBulkDeleteModal}
        text={"Applications"}
        handleSubmit={handleDeleteSubmit}
        loading={deleteLoading}
      />
      <StatusModal
        open={statusModal}
        setOpen={setStatusModal}
        candidateSelectedData={
          selectedRowData?.length > 0 ? selectedRowData[0] : {}
        }
        selectedRowData={selectedRowData}
        onSubmit={updateBulkCandidateJobStatus}
      />
      <Reportpopup
        data={candidateSelectedData}
        reportModal={reportModal}
        setReportModal={setReportModal}
      />
      <UpdatestatusPopup
        updatePopup={updatePopup}
        setUpdatePopUp={setUpdatePopup}
        setUpdatePopup={setUpdatePopup}
        candidateSelectedData={candidateSelectedData}
        onSubmit={updateCandidateJobStatus}
      />
      <DeleteAdvertModal
        visible={activeDeleteModal}
        setVisible={setActiveDeleteModal}
        text={"Advert"}
        handleSubmit={deleteAdvert}
        loading={deleteLoading}
      />

      <TransferModal
        transferModal={transferModal}
        setTransferModal={setTransferModal}
        candidateSelectedData={candidateSelectedData}
        oldAdvertId={oldAdvertId}
        profileId={profileId}
        candidateName={candidateName}
        setReloadState={setReloadState}
        transferData={transferData}
        search={advertSearch}
        setSearch={setAdvertSearch}
      />
      <DeleteUserPopup
        isModalOpen={isModalOpen}
        setisModalOpen={setisModalOpen}
      />

      <UpdateAlert updateAlert={updateAlert} setUpdateAlert={setUpdateAlert} />
      <div className="advert_detail_container">
        <Row>
          <Col xs={24} md={0}>
            <MobileHeader title={"Advert Details"} />
          </Col>
        </Row>
        <div className="advert_detail_box">
          <Row align={"middle"} justify={"space-between"}>
            <Col xs={16} md={20}>
              <Row align={"middle"}>
                <Col className="name">{advertDetail?.jobTitle?.name}</Col>
              </Row>
            </Col>
            <Col className="gap-12" xs={7} md={3}>
              <div className="w-100">
                {userType !== "Associate" && (
                  <Row gutter={20} justify={"end"} align={"middle"}>
                    <Col>
                      <img
                        src={deleteIcon}
                        className="cursor_pointer"
                        alt="delete"
                        onClick={() => setActiveDeleteModal(true)}
                      />
                    </Col>
                    <Col>
                      <img
                        src={EditIcon}
                        className="cursor_pointer"
                        alt="edit"
                        onClick={() =>
                          navigate(`/create-advert?id=${advertDetail?.id}`, {
                            state: advertDetail,
                          })
                        }
                      />
                    </Col>
                  </Row>
                )}
              </div>
            </Col>
          </Row>
          <div className="detail_row">
            <Row gutter={16} justify={"space-between"}>
              <Col xs={12} md={6}>
                <p className="title">Location</p>
                <p className="detail">
                  {advertDetail?.suburb}, {advertDetail?.state?.name},{" "}
                  {advertDetail?.postalCode}
                </p>
              </Col>
              <Col xs={12} md={4}>
                <p className="title  align-text-end-sm">Salary/Rate</p>
                <p className="detail align-text-end-sm">
                  {advertDetail?.minSalary &&
                  advertDetail?.maxSalary &&
                  advertDetail?.minSalary !== advertDetail?.maxSalary
                    ? `$${
                        advertDetail?.minSalary
                          ? numberWithCommas(
                              advertDetail?.minSalary?.toString()?.includes(".")
                                ? parseFloat(advertDetail?.minSalary)?.toFixed(
                                    2
                                  )
                                : advertDetail?.minSalary
                            )
                          : 0
                      }-$${
                        advertDetail?.maxSalary
                          ? numberWithCommas(
                              advertDetail?.maxSalary?.toString()?.includes(".")
                                ? parseFloat(advertDetail?.maxSalary)?.toFixed(
                                    2
                                  )
                                : advertDetail?.maxSalary
                            )
                          : 0
                      }`
                    : `$${
                        advertDetail?.minSalary
                          ? numberWithCommas(
                              advertDetail?.minSalary?.toString()?.includes(".")
                                ? parseFloat(advertDetail?.minSalary)?.toFixed(
                                    2
                                  )
                                : advertDetail?.minSalary
                            )
                          : 0
                      }`}
                </p>
              </Col>
              <Col xs={12} md={6}>
                <p className="title">Start Date</p>
                <p className="detail">
                  {moment(advertDetail?.startDate).format("DD MMM YYYY")}
                </p>
              </Col>
              <Col xs={12} md={4}>
                <p className="title align-text-end-sm">End Date</p>
                <p className="detail align-text-end-sm">
                  {moment(advertDetail?.endDate).format("DD MMM YYYY")}
                </p>
              </Col>
            </Row>
          </div>
        </div>
        <div>
          <AdvertSummary advertDetail={advertDetail} />
        </div>
        {candidateDetails?.result?.length > 0 || search !== null ? (
          <div className="advert_summary_box">
            <div className="advert_table_box ">
              <Table
                rowSelection={rowSelection}
                columns={columns}
                dataSource={candidateDetails?.result}
                pagination={false}
                scroll={{ y: 400 }}
                loading={!search ? loading : false}
                rowKey={"_id"}
                title={() => (
                  <>
                    <Row className="justify_space">
                      {/* table title part 1 */}
                      <Col xs={0} md={12}>
                        <Row
                          justify={"start"}
                          align="middle"
                          gutter={{ lg: 20, xl: 32 }}
                        >
                          {userType !== "Associate" && (
                            <Col
                              className="align_items_center"
                              onClick={
                                selectedRowKeys?.length > 0
                                  ? handleDelete
                                  : null
                              }
                            >
                              <Row align={"middle"} gutter={4}>
                                <Col style={{ marginTop: "4px" }}>
                                  <CommonDeleteSvg
                                    color={hasSelected ? "#D03C28" : "#A7AEBB"}
                                  />
                                </Col>
                                <Col>
                                  <span
                                    className={` ${
                                      hasSelected
                                        ? "selectedRow_active"
                                        : "delete"
                                    }   cursor-pointer`}
                                  >
                                    Delete
                                  </span>
                                </Col>
                              </Row>
                            </Col>
                          )}
                          {userType !== "Associate" && (
                            <Col
                              className="align_items_center"
                              onClick={
                                selectedRowKeys?.length > 0
                                  ? handleStatusButton
                                  : null
                              }
                            >
                              <Row align={"middle"} gutter={8}>
                                <Col style={{ marginTop: "4px" }}>
                                  <TableStatusImage
                                    color={hasSelected ? "#1638B1" : "#A7AEBB"}
                                  />
                                </Col>
                                <Col>
                                  <span
                                    className={` ${
                                      hasSelected
                                        ? "selectedRow_active_status"
                                        : "delete"
                                    }   cursor-pointer`}
                                  >
                                    Status
                                  </span>
                                </Col>
                              </Row>
                            </Col>
                          )}

                          {subscription &&
                            subscription?.sp?.active &&
                            JSON.parse(
                              localStorage.getItem("isPlusSubscription")
                            ) && (
                              <Col
                                // span={11}
                                className="candidate_report_col cursor_pointer"
                                // onClick={() => handleUpdagradeMembership()}
                                onClick={() => candidateReport()}
                              >
                                <Row align={"middle"} gutter={4}>
                                  <Col>
                                    <img src={downloadIcon} />
                                  </Col>
                                  <Col>
                                    <span>
                                      {reportLoading
                                        ? "Downloading..."
                                        : "Candidate Report"}
                                    </span>
                                  </Col>
                                </Row>
                              </Col>
                            )}
                          <Col
                            // span={11}
                            className="candidate_report_col cursor_pointer"
                            // onClick={() => handleUpdagradeMembership()}
                            onClick={ehHrReport}
                          >
                            <Row align={"middle"} gutter={4}>
                              <Col>
                                <img src={downloadIcon} />
                              </Col>
                              <Col>
                                <span>
                                  {ehHrLoading ? "Downloading..." : "Export"}
                                </span>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Col>
                      {/* table titile part2  */}
                      <Col xs={24} md={12}>
                        <Row gutter={16} className="w-100" justify={"end"}>
                          <Col xs={24} md={16} className="sm-w100 sm-flex">
                            <Input.Search
                              className="searchBox sm-w100"
                              placeholder="Search candidate name"
                              prefix={<img src={SearchIcon} alt="search" />}
                              onChange={(e) => {
                                setSearch(e?.target?.value);
                                setCurrentPage(1);
                              }}
                              value={search}
                              suffix={
                                search && (
                                  <img
                                    src={CancelIcon}
                                    className="cursor_pointer"
                                    alt="cross"
                                    onClick={() => {
                                      setSearch(null);
                                      setCurrentPage(1);
                                    }}
                                  />
                                )
                              }
                            />
                            <div>
                              <Dropdown
                                menu={{
                                  items: tableHeaderPop,
                                }}
                                overlayClassName="notificationFilterDropdown"
                              >
                                <img
                                  className="dp-none-md advert-vertical-dots "
                                  src={verticleDot}
                                  alt="vertical dots"
                                />
                              </Dropdown>
                            </div>
                          </Col>
                          <Col xs={0} md={8} className="dp-none-sm">
                            <Button
                              className="btn"
                              onClick={() =>
                                // navigate(`/advert-tracker/${advertDetail?.id}`)
                                handleTrackerClick()
                              }
                            >
                              Tracker
                            </Button>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </>
                )}
                // onRow={(record, rowIndex) => {
                //   return {
                //     onClick: (event) => {
                //       navigate(
                //         `/applicant-profile/${record?.profileId?.userId?._id}`,
                //         {
                //           state: {
                //             id: id,
                //           },
                //         }
                //       );
                //     }, // click row
                //     style: { cursor: "pointer" },
                //   };
                // }}
              />

              <CustomPagination
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                total={candidateDetails?.totalCount}
                itemPerPage={10}
              />
            </div>
            <Row className="w-100 mt-20">
              <Col xs={24} md={0}>
                <Button
                  className="btn_sm w-100"
                  onClick={() => handleTrackerClick()}
                >
                  Tracker
                </Button>
              </Col>
            </Row>
          </div>
        ) : (
          <NoElementBox title="Nobody applied yet!" />
        )}

        {/* <JobActiveDeactivateModal
          visible={jobModal}
          setVisible={setJobModal}
          active={jobActive}
        /> */}
      </div>
    </SubLayout>
  );
}

export default AdvertDetail;
