import React, { useEffect, useRef, useState } from "react";
import SubLayout from "../../components/layout/SubLayout";
import { Col, Drawer, Input, Row, Spin, message } from "antd";
import ProfileCards from "../../components/common/search/profileCards";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import SearchListItem from "../../components/common/search/SearchListItem";
import API_MANAGER from "../../API";
import CustomPagination from "../../components/common/CustomPagination";
import FullScreenLoader from "../../components/common/FullScreenLoader";
import FIlterIcon from "../../Assets/filterIcon.svg";
import CloseIcon from "../../Assets/cancelIcon.svg";
import CancelIcon from "../../Assets/cancelIcon.svg";

function Search() {
  const [searchParams] = useSearchParams();
  const [searchedData, setSearchedData] = useState();
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const [filters, setFilters] = useState({});
  const [loading, setLoading] = useState(false);
  const [campaignList, setCampaignList] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [campaignSearch, setCampaignSearch] = useState(null);
  const [filterLength, setFilterLength] = useState(0);
  const [count, setCount] = useState(0);
  const inputRef = useRef(null);

  const navigate = useNavigate();
  const { state } = useLocation();
  const filterData = [
    { id: 1, name: "Location", value: "STATE", label: "location" },
    { id: 2, name: "Industry", value: "INDUSTRY", label: "industry" },
    { id: 3, name: "Skills", value: "SKILL", label: "skills" },
    {
      id: 4,
      name: "Qualifications",
      value: "QUALIFICATION",
      label: "qualifications",
    },
    { id: 5, name: "Inductions", value: "INDUCTION", label: "inductions" },
    { id: 6, name: "Medicals", value: "MEDICAL_ASSESSMENT", label: "medicals" },
    { id: 7, name: "Ethnicity", value: "ETHNICITY", label: "ethnicity" },
    { id: 8, name: "Gender", value: "GENDER", label: "gender" },
    {
      id: 9,
      name: "Citizenship",
      value: "CITIZENSHIP",
      label: "citizenship",
      options: [
        {
          label: "Australian",
          value: "YES",
        },
        {
          label: "Non-Australian",
          value: "NO",
        },
      ],
    },
    { id: 10, name: "Visa", value: "VISA", label: "visa" },
  ];
  useEffect(() => {
    const element = document.getElementById("scrolling");
    element.scrollTo(0, 0);
  }, [page]);

  const GetGlobalSearch = async () => {
    setLoading(true);
    try {
      const params = {
        ...filters,
        search: search,
        limit: 10,
        page: page,
      };
      const response = await API_MANAGER.getGlobalDetailSearch(params);
      setSearchedData(response?.data?.data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      message.error("Something went wrong. Please try again.");
    }
  };
  const singleUserCampaignDetails = async () => {
    const param = {
      search: campaignSearch ? campaignSearch : "",
      sort: "-createdAt",
      limit: 20000,
      page: 1,
    };
    try {
      const response = await API_MANAGER.getSingleUserCampaignDetails(
        param,
        localStorage.getItem("userID")
      );
      setCampaignList(response?.data?.data);
    } catch (error) {
      message.error("Something went wrong. Please try again.");
    }
  };
  useEffect(() => {
    singleUserCampaignDetails();
  }, [campaignSearch]);
  useEffect(() => {
    if (searchParams) {
      const temp = searchParams.get("search");
      setSearch(temp?.replace("?", "")?.split("%20")?.join(" "));
    }
    if (searchParams.get("page")) {
      setPage(parseInt(searchParams.get("page")));
    }
  }, [searchParams.get("search"), searchParams.get("page")]);
  const handlePageChange = (pageNumber) => {
    const link = `/search?search=${search
      ?.split(" ")
      ?.join("%20")}&page=${pageNumber}`;
    navigate(link);
  };
  useEffect(() => {
    setFilterLength(0);
    let temp = 0;
    Object.entries(filters).map(([key, value]) => {
      if (value?.length > 1) {
        temp = temp + 1;
      }
    });
    setFilterLength(temp);
    if (search && search?.length > 0) {
      GetGlobalSearch();
    }
  }, [search, page, filters]);
  useEffect(() => {
    if(state?.filter){
      setFilters(state?.filter);
    }
  }, []);
  useEffect(() => {
    inputRef?.current?.focus({
      cursor: "end",
    });
  }, []);
  return (
    <div className="w-100">
      <SubLayout
        padding={true}
        page={"Search Results"}
      >
        <Row className="search-main-container">
          <Col xs={24} md={17} className="main-container-part1">
            <Row align={"middle"} justify={"space-between"}>
              <Col span={20} className="mb-20">
                <Input
                  id="searchBox"
                  className="search-input"
                  size="large"
                  value={search}
                  onChange={(e) => {
                    setSearch(e?.target?.value);
                    const link = `/search?search=${e?.target?.value
                      ?.split(" ")
                      ?.join("%20")}&page=1`;
                    navigate(link);
                  }}
                  placeholder="Carmen Davis"
                  ref={inputRef}
                  suffix={
                    search && (
                      <img
                        src={CancelIcon}
                        className="cursor_pointer"
                        alt="cross"
                        onClick={() => {
                          setSearch(null);
                          const link = `/search?search=&page=1`;
                          navigate(link);
                        }}
                      />
                    )
                  }
                  prefix={
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="21"
                      height="20"
                      viewBox="0 0 21 20"
                      fill="none"
                    >
                      <path
                        d="M19.8947 19.0002L15.5517 14.6572M15.5517 14.6572C16.2946 13.9143 16.8839 13.0324 17.286 12.0618C17.688 11.0911 17.8949 10.0508 17.8949 9.00021C17.8949 7.9496 17.688 6.90929 17.286 5.93866C16.8839 4.96803 16.2946 4.08609 15.5517 3.34321C14.8088 2.60032 13.9269 2.01103 12.9563 1.60898C11.9857 1.20693 10.9453 1 9.89474 1C8.84414 1 7.80382 1.20693 6.83319 1.60898C5.86256 2.01103 4.98062 2.60032 4.23774 3.34321C2.73741 4.84354 1.89453 6.87842 1.89453 9.00021C1.89453 11.122 2.73741 13.1569 4.23774 14.6572C5.73807 16.1575 7.77295 17.0004 9.89474 17.0004C12.0165 17.0004 14.0514 16.1575 15.5517 14.6572Z"
                        stroke="#1638B1"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  }
                />
              </Col>
              <Col md={0} className="mt-8 mb-20">
                <img
                  className="cursor-pointer"
                  src={FIlterIcon}
                  onClick={() => setOpenModal(true)}
                />
              </Col>
            </Row>
            {/* all search cards */}
            <Col
              span={24}
              className="cards-parent"
              style={{
                // height: "100%",
                // display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              id="scrolling"
            >
              {/* <FullScreenLoader isLoading={loading} /> */}
              <Spin spinning={loading}>
                {searchedData?.result?.map((item, index) => (
                  <ProfileCards
                    data={item}
                    filters={filters}
                    search={search}
                    page={page}
                    campaignList={campaignList}
                    setCampaignSearch={setCampaignSearch}
                    campaignSearch={campaignSearch}
                    GetGlobalSearch={GetGlobalSearch}
                  />
                ))}
              </Spin>
            </Col>
            <Row align={"middle"} justify={"end"}>
              <Col className="pr-20">
                <CustomPagination
                  currentPage={page}
                  setCurrentPage={handlePageChange}
                  total={searchedData?.totalCount}
                  itemPerPage={10}
                />
              </Col>
            </Row>
          </Col>
          {/* filter section */}
          <Col xs={0} md={7} className="filter-section">
            <Row className="w-100" justify={"space-between"}>
              <h4 className="h4">All Filters {`(${filterLength})`}</h4>
            </Row>

            <p className="body-text-b2 mb-20">Select filters to apply</p>
            <Col span={24} className="filter-body">
              {filterData.map((item, index) => (
                <SearchListItem
                  key={index}
                  item={item}
                  filters={filters}
                  setFilters={setFilters}
                />
              ))}
            </Col>
          </Col>
        </Row>
        <Drawer
          placement="right"
          open={openModal}
          closable={false}
          contentWrapperStyle={{
            top: "90px",
            width: "100vw",
          }}
          className="globalSearchMobileDrawer"
        >
          <Row className="w-100" align={"middle"} justify={"space-between"}>
            <Col>
              <h4 className="h4">All Filters (0)</h4>
            </Col>
            <Col>
              <img
                src={CloseIcon}
                alt="close"
                className="cursor-pointer"
                onClick={() => setOpenModal(false)}
              />
            </Col>
          </Row>

          <p className="body-text-b2 mb-20">Select filters to apply</p>
          <Col span={24} className="filter-body">
            {filterData.map((item, index) => (
              <SearchListItem
                key={index}
                item={item}
                filters={filters}
                setFilters={setFilters}
              />
            ))}
          </Col>
        </Drawer>
      </SubLayout>
    </div>
  );
}

export default Search;
