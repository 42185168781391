import React, { useEffect, useState } from "react";
import SubLayout from "../../components/layout/SubLayout";
import {
  Button,
  Col,
  Dropdown,
  Modal,
  Radio,
  Row,
  Table,
  Tooltip,
  message,
} from "antd";
import RecruitmentPlan from "../../components/common/subscriptionMangement/RecuritementPlanCard";
import FilterIcon from "../../Assets/filter_final.svg";
import ByUserDrawer from "../../components/common/subscriptionMangement/ByUserDrawer";
import API_MANAGER from "../../API";
import FullScreenLoader from "../../components/common/FullScreenLoader";
import dayjs from "dayjs";
import moment from "moment";
import { numberWithCommas } from "../../utils/constant";
import MobileHeader from "../../components/layout/MobileHeader";
import { InfoCircleOutlined } from "@ant-design/icons";
function SubscriptionManagement() {
  const [selectedPlan, setSelectedPlan] = useState("");
  const [subscriptionData, setSubscriptionData] = useState();
  const [totalUsers, setTotalUsers] = useState(0);
  const [totalAccountUser, setTotalAccountUser] = useState(0);
  const [totalAssociateUser, setTotalAssociateUser] = useState(0);
  const [expiryDate, setExpiryDate] = useState("");
  const [cancelSub, setCancelSub] = useState(false);
  const [autoRenew, setAutoRenew] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [paymentData, setPaymentData] = useState([]);
  const [renewSubs, setRenewSubs] = useState(false);
  /*************/
  const [paymentHistory, setPaymentHistory] = useState({});
  const [openDrawer, setOpenDrawer] = useState(false);
  const [loading, setLoading] = useState(false);
  //Remaining account and associate users
  const [accountUserCount, setAccountUserCount] = useState(0);
  const [associateUserCount, setAssociateUserCount] = useState(0);
  const [sortType, setSortType] = useState("-createdAt");
  const [change, setChange] = useState("");
  const [total, setTotal] = useState();
  const showDrawer = () => {
    setOpenDrawer(true);
  };
  const onCloseDrawer = () => {
    setOpenDrawer(false);
  };
  const getSubscriptionData = async () => {
    setLoading(true);
    try {
      const response = await API_MANAGER.getSubscriptionData();
      setSubscriptionData(response?.data?.data);
      setLoading(false);
    } catch (error) {
      message.error("Something went wrong. Please try again.");
      setLoading(false);
    }
  };
  useEffect(() => {
    const sortedData = subscriptionData?.sort(
      (a, b) => a?.cardType - b?.cardType
    );
    setSubscriptionData(sortedData);
  }, [subscriptionData]);
  const getPaymentHistory = async () => {
    // setLoading(true);
    const param = {
      page: currentPage,
      limit: 10,
      sort: sortType,
    };
    try {
      const response = await API_MANAGER.getPaymentHistory(param);
      setPaymentHistory(response?.data?.data);
      setPaymentData(response?.data?.data?.orders);
      setAutoRenew(response?.data?.data?.isAutoRenew);
      if (response?.data?.data?.sp?.plusSubscription === true) {
        localStorage.setItem("isPlusSubscription", true);
      }
      // setLoading(false);
    } catch (error) {
      message.error("Something went wrong. Please try again.");
    }
  };

  useEffect(() => {
    getSubscriptionData();
  }, [change]);
  useEffect(() => {
    getPaymentHistory();
  }, [sortType, change]);

  //REMAINING USERS / ACTIVE USERS COUNT API
  const activeUserData = async () => {
    try {
      const response = await API_MANAGER.activeUser();
      setAccountUserCount(response?.data?.data?.remaining?.account);
      setAssociateUserCount(response?.data?.data?.remaining?.associate);
      setSelectedPlan(response?.data?.data?.activePlan);
      setTotal(response?.data?.data?.totalUser);
      const temp = response?.data?.data?.totalUser;
      if (temp) {
        let associateUsersCount = parseInt(temp?.associateUser);
        let accountUsersCount = parseInt(temp?.accountUser);
        if (temp?.additionalUser) {
          Object?.entries(temp?.additionalUser).forEach(([key, value]) => {
            if (key === "associateUser") {
              associateUsersCount += parseInt(value);
            } else {
              accountUsersCount += parseInt(value);
            }
          });
        }

        const totalSubsUsers =
          parseInt(accountUsersCount) + parseInt(associateUsersCount);
        setTotalUsers(totalSubsUsers);
        setTotalAccountUser(accountUsersCount);
        setTotalAssociateUser(associateUsersCount);
      }
    } catch (error) {
      message.error("Something went wrong. Please try again.");
    }
  };

  useEffect(() => {
    activeUserData();
  }, []);
  const handleSorting = async (e) => {
    // let tempData = [];
    if (e === "az") {
      let tempData = await paymentHistory?.orders?.sort(function (a, b) {
        let x =
          a?.subscriptionProductId !== null
            ? a?.subscriptionProductId?.name
            : "Additional users";
        let y =
          b?.subscriptionProductId !== null
            ? b?.subscriptionProductId?.name
            : "Additional users";

        if (x < y) {
          return -1;
        }
        if (x > y) {
          return 1;
        }
        return 0;
      });

      // setPaymentData([]);
      setPaymentData([...tempData]);
    } else if (e === "za") {
      let tempData = await paymentHistory?.orders?.sort(function (a, b) {
        let x =
          a?.subscriptionProductId !== null
            ? a?.subscriptionProductId?.name
            : "Additional users";
        let y =
          b?.subscriptionProductId !== null
            ? b?.subscriptionProductId?.name
            : "Additional users";

        if (x < y) {
          return 1;
        }
        if (x > y) {
          return -1;
        }
        return 0;
      });

      setPaymentData([...tempData]);
    } else if (e === "LF") {
      let tempData = await paymentHistory?.orders?.sort(function (a, b) {
        let x = new Date(a?.updatedAt);
        let y = new Date(b?.updatedAt);

        return y - x;
      });

      setPaymentData([...tempData]);
    } else if (e === "OF") {
      let tempData = await paymentHistory?.orders?.sort(function (a, b) {
        let x = new Date(a?.updatedAt);
        let y = new Date(b?.updatedAt);

        return x - y;
      });
      setPaymentData([...tempData]);
    }
  };

  const items = [
    {
      key: "1",
      label: (
        <Radio.Group
          // value={sortType}
          onChange={(e) => handleSorting(e?.target?.value)}
          className="dropDown_radio_group"
        >
          <div className="">
            <Row className="filter_item_row">
              <Col span={24} className="">
                <Radio value={"LF"} className="radio_text">
                  Latest First
                </Radio>
              </Col>
            </Row>
            <Row className="filter_item_row">
              <Col span={24} className="">
                <Radio value={"OF"} className="radio_text">
                  Oldest First
                </Radio>
              </Col>
            </Row>
            <Row className="filter_item_row">
              <Col span={24} className="">
                <Radio value={"az"} className="radio_text">
                  Sort by A-Z
                </Radio>
              </Col>
            </Row>
            <Row className="filter_item_row">
              <Col span={24} className="">
                <Radio value={"za"} className="radio_text">
                  Sort by Z-A
                </Radio>
              </Col>
            </Row>
          </div>
        </Radio.Group>
      ),
    },
  ];
  const columns = [
    {
      title: (
        <div style={{}} className="subscription_user_col">
          Subscriptions/User{" "}
          <Dropdown
            menu={{ items }}
            overlayClassName="notificationFilterDropdown"
          >
            <img src={FilterIcon} alt="filter" />
          </Dropdown>{" "}
        </div>
      ),
      dataIndex: "user",
      render: (item, row) => {
        if (row?.subscriptionProductId !== null) {
          return <span>{row?.subscriptionProductId?.name}</span>;
        } else {
          return <span>Additional users</span>;
        }
      },
      fixed: "left",
    },
    {
      title: "Status",
      dataIndex: "status",
      align: "center",
      render: (item, row) => {
        if (row?.subscriptionProductId !== null) {
          return (
            <span
              className={
                paymentHistory?.sp?._id === row?.subscriptionProductId?._id
                  ? "Live_text "
                  : "archived_text"
              }
            >
              {paymentHistory?.sp?._id === row?.subscriptionProductId?._id
                ? "Active"
                : "Inactive"}
            </span>
          );
        } else {
          return <span>N/A</span>;
        }
      },
    },

    {
      title: "Purchased on",
      dataIndex: "createdAt",
      align: "center",
      render: (item) => <span>{moment(item).format("MMM DD, YYYY")}</span>,
    },
    {
      title: "Expiry Date",
      dataIndex: "createdAt",
      align: "center",
      render: (item) => (
        <span>{dayjs(item).add(1, "year").format("MMM DD, YYYY")}</span>
      ),
    },
    {
      title: "Paid Amount",
      dataIndex: "price",
      align: "center",
      render: (item) => {
        return "$" + numberWithCommas(item?.toFixed(2));
      },
    },
  ];
  const data = [
    {
      key: 1,
      user: "Standard Subscription",
      filter: "",
      status: "Active",
      Quantity: "1",
      purchased_on: "27 JUN 2023",
      expiry_Date: "27 JUN 2023",
      paid_Amount: "$3,999",
    },
  ];
  //SETTING NUMBER OF USERS AVAILABLE
  useEffect(() => {
    getPaymentHistory();
  }, [paymentHistory]);
  useEffect(() => {
    const subscriptionData = JSON.parse(localStorage.getItem("subscription"));

    let startDate = subscriptionData?.startDate;
    const originalDate = dayjs(startDate, "YYYY MM DD");
    const newDate = originalDate.add(1, "year");

    setExpiryDate(newDate.format("MMM DD, YYYY"));
  }, []);
  const handleSwitch = async () => {
    setLoading(true);
    try {
      const response = await API_MANAGER.autoRenew({
        auto_renew: false,
        cancelSubs: true,
      });
      setLoading(false);
      setCancelSub(false);
      message.success("Subscription successfully deactivated.");
    } catch (error) {
      message.error("Something went wrong. Please try again.");
    }
  };
  const handleSwitchRenew = async () => {
    setLoading(true);
    try {
      const response = await API_MANAGER.autoRenew({
        auto_renew: true,
        cancelSubs: false,
      });
      setLoading(false);
      setRenewSubs(false);
      message.success("Subscription successfully renewed.");
    } catch (error) {
      message.error("Something went wrong. Please try again.");
    }
  };
  return (
    <div id="modalMount" className="modal-mount">
      <SubLayout page={"Subscription Details"} whiteBg>
        <Modal
          centered
          title="Cancel Subscription"
          className="candidateDeleteModal"
          open={cancelSub}
          onCancel={() => setCancelSub(false)}
          footer={[
            <Row>
              <Col span={24} align={"center"} className="justify_items_end">
                <Button
                  key="submit"
                  className="black_color_button"
                  onClick={handleSwitch}
                >
                  Proceed
                </Button>
              </Col>
            </Row>,
          ]}
        >
          <Row className="header">
            <Col>
              <p className="desc">
                Remember, you'll still have access until the end of your
                subscription period.
              </p>
            </Col>
          </Row>
        </Modal>
        <Modal
          centered
          title="Renew Subscription"
          className="candidateDeleteModal"
          open={renewSubs}
          onCancel={() => setRenewSubs(false)}
          footer={[
            <Row>
              <Col span={24} align={"center"} className="justify_items_end">
                <Button
                  key="submit"
                  className="black_color_button"
                  onClick={handleSwitchRenew}
                >
                  Proceed
                </Button>
              </Col>
            </Row>,
          ]}
        >
          <Row className="header">
            <Col>
              <p className="desc">
                By proceeding, you're turning on the auto-renew feature or if
                your previous subscription has expired, you're purchasing a new
                subscription.
              </p>
            </Col>
          </Row>
        </Modal>
        <MobileHeader showSearch title={"Subscription Details"} />
        <FullScreenLoader isLoading={loading} />
        <ByUserDrawer onCloseDrawer={onCloseDrawer} openDrawer={openDrawer} />
        <div className="subscription_management_container">
          <Row align={"middle"} justify={"space-between"} className="mt-16">
            <Col span={20}>
              <Row>
                <Col xs={24} lg={6}>
                  <h2 className="subs-heading">Current Subscription</h2>
                </Col>
                <Col xs={24} lg={12} className="flex-text-center sm-mt-10">
                  <p className="subs-sub-heading">Expiring On: {expiryDate} </p>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row gutter={[0, 20]} className="total_users_details">
            <Col span={24} className="mb-18">
              <Row>
                <Col xs={12} md={24}>
                  <p className="user-count">{totalUsers} Users</p>
                </Col>
                <Col xs={12} md={0} className="sm-buy-btn-parent">
                  <Button onClick={showDrawer} className="buyuserBtn">
                    <span> Buy Users</span>
                  </Button>
                </Col>
              </Row>
            </Col>
            <Col xs={24} lg={18} xl={16}>
              <Row gutter={[0, 20]} className="space-btw subs-header-main">
                <Col>
                  {!paymentHistory?.cancelSubs ? (
                    <div>
                      <span>
                        <Button
                          onClick={() => {
                            setCancelSub(true);
                          }}
                          disabled={paymentHistory?.cancelSubs}
                          className="buyuserBtn1"
                        >
                          <span> Cancel </span>
                        </Button>
                      </span>
                      <span>
                        Cancel Subscription{" "}
                        <Tooltip
                          className="cursor_pointer"
                          title="By cancelling your subscription you will continue to have access to the system and all related features, until your current plan expires."
                        >
                          <InfoCircleOutlined />
                        </Tooltip>
                      </span>
                    </div>
                  ) : (
                    <div>
                      <span>
                        <Button
                          onClick={() => {
                            setRenewSubs(true);
                          }}
                          className="buyuserBtn1"
                        >
                          <span> Renew </span>
                        </Button>
                      </span>
                      <span>
                        Renew Subscription{" "}
                        <Tooltip
                          className="cursor_pointer"
                          title="By renewing your subscription you will restart the automatic payment cycle for your subscription."
                        >
                          <InfoCircleOutlined />
                        </Tooltip>
                      </span>
                    </div>
                  )}
                </Col>
                <Col className="gap-38">
                  <div>
                    <h4>
                      {accountUserCount}/{totalAccountUser} Remaning
                    </h4>
                    <span>
                      Account Users{" "}
                      <Tooltip
                        className="cursor_pointer"
                        title="Account Users can set up recruitment campaigns, post job adverts, manage your subscription and other users, depending on the permissions you assign them."
                      >
                        <InfoCircleOutlined />
                      </Tooltip>
                    </span>
                  </div>
                  <div>
                    <h4>
                      {associateUserCount}/{totalAssociateUser} Remaining
                    </h4>
                    <span>
                      Association Users{" "}
                      <Tooltip
                        className="cursor_pointer"
                        title="Association Users are users who are invited to specific job adverts, of your choosing. They can leave comments or approve candidates for mobilisation. They do not have full access to your application."
                      >
                        <InfoCircleOutlined />
                      </Tooltip>
                    </span>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col lg={6} xl={8} className="buy-userbtn-parent">
              <Button
                // onClick={() => setBuyUserModal(true)}
                onClick={showDrawer}
                className="buyuserBtn"
              >
                <span> Buy Users</span>
              </Button>
            </Col>
          </Row>
          <Row className="justify-center gap-48 mb-32">
            {subscriptionData &&
              subscriptionData.map((subsData) => {
                return (
                  <RecruitmentPlan
                    showDrawer={showDrawer}
                    data={subsData}
                    currentPlanId={selectedPlan?._id}
                    setChange={setChange}
                    paymentHistory={paymentHistory}
                  />
                );
              })}
          </Row>

          <Row>
            <h1>Payment History</h1>
          </Row>
          <Row className="w-100 mb-24">
            <Table
              columns={columns}
              dataSource={paymentData}
              rootClassName="payment_history_table"
              pagination={false}
              scroll={{ x: "calc(750px + 10%)" }}
            />
          </Row>
        </div>
      </SubLayout>
    </div>
  );
}

export default SubscriptionManagement;
