import { Select, Tag } from "antd";
import React, { useEffect, useState } from "react";
import { DragablleIcon } from "../../../Assets/campagin/sectionProgress/svgs";
import API_MANAGER from "../../../API";
import { PickListMockData } from "../../../utils/constant";

function RequirementItemSearchable({
  title,
  handelSelect,
  type,
  reqDisabled,
  selected,
  tags,
  unique_id,
}) {
  const [selectedValues, setSelectedValues] = useState([]);
  const [options, setoptions] = useState([]);
  const tagOption = options?.map((item) => {
    // Check the type condition
    const index = unique_id;

    if (reqDisabled?.[index]) {
      // Check if the item is not in the hardRequirement_selected
      if (!reqDisabled?.[index]?.find((req) => req === item?.id)) {
        // Return an object for valid items
        return {
          value: item?.id,
          label: item?.name,
        };
      } else {
        // Return an empty string for invalid items
        return {
          value: item?.id,
          label: item?.name,
          disabled: true,
        };
      }
    } else {
      // For other types, always return an object
      return {
        value: item?.id,
        label: item?.name,
      };
    }
  });
  const getTagsOptions = async () => {
    try {
      const res = await API_MANAGER.getTags(`${tags}&sort=name`);
      setoptions(res?.data?.data?.tags);
    } catch (err) {}
  };
  const mode = PickListMockData?.find(
    (feat) =>
      feat?.title?.toUpperCase() === title?.toUpperCase() &&
      feat?.mode === "single"
  )
    ? "single"
    : "multiple";
  const handleChange = (values) => {
    setSelectedValues(values);
    handelSelect(values, type, unique_id, mode);
  };
  useEffect(() => {
    getTagsOptions();
  }, []);
  useEffect(() => {
    if (selected?.length > 0) {
      setSelectedValues(selected);
    }
  }, [selected]);

  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());
  return (
    <div className="searchable-requirement-item">
      <div className="draggable-icon">
        <DragablleIcon />
      </div>
      <Select
        mode={mode === "multiple" ? "multiple" : ""}
        className={mode === "multiple" ? "multiple_selected_add" : ""}
        allowClear
        style={{
          width: "100%",
        }}
        defaultValue={selected}
        showSearch
        filterOption={filterOption}
        placeholder={`${title}`}
        onChange={(e) => handleChange(e)}
        maxTagCount={2}
        maxTagPlaceholder={<div>+{selectedValues?.length - 2} more</div>}
        tagRender={(props) => {
          const { value, closable, onClose, label } = props;
          const isSelected = selectedValues?.includes(value);
          return (
            <Tag
              closable={closable}
              onClose={(e) => {
                e.preventDefault();
                onClose();
                const newSelectedValues = selectedValues?.filter(
                  (val) => val !== value
                );
                setSelectedValues(newSelectedValues);
              }}
              style={{
                background: isSelected ? "#FAE7B6" : "transparent",
                padding: isSelected ? "2px 5px" : "",
              }}
            >
              {label}
            </Tag>
          );
        }}
        options={tagOption}
      />
    </div>
  );
}

export default RequirementItemSearchable;
