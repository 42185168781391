import { Checkbox, Col, Row, Tooltip, message } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import UpgradeMembershipModal from "../UpgradeMembershipPopup";
import API_MANAGER from "../../../API";
import UserExhaust from "../subscriptionMangement/UserExhaust";

const PermissionIndividualCard = ({
  data,
  index,
  setPermissionIndex,
  permissionIndex,
  userCount,
  permissions,
  setPermissions,
  selectedCount,
  setSelectedCount,
  editUser,
  userEditProfile,
}) => {
  const [subType, setSubType] = useState();
  const [remainingUsers, setRemainingUsers] = useState();
  const activeUserData = async () => {
    try {
      const response = await API_MANAGER.activeUser();
      setRemainingUsers(response?.data?.data?.remaining);
    } catch (error) {
      message.error("Something went wrong. Please try again.");
    }
  };
  useEffect(() => {
    activeUserData();
  }, []);

  const [disableCards, setDisableCards] = useState([]);
  useEffect(() => {
    if (editUser === "Associate") {
      const disable = [0, 1];
      setDisableCards(disable);
    } else if (
      editUser === "Admin" ||
      editUser === "Standard" ||
      editUser === null
    ) {
      const disable = [2];
      setDisableCards(disable);
    }
  }, [editUser]);

  useEffect(() => {
    setSubType(localStorage.getItem("isPlusSubscription"));
  }, []);
  useEffect(() => {
    if (selectedCount === 0) {
      setPermissionIndex(null);
      setSelectedCount(2);
    }
  }, [selectedCount]);

  const [upgradeMembership, setUpgradeMembership] = useState(false);
  const [userExhaustModal, setUserExhaustModal] = useState(false);

  const [textContent, setTextContent] = useState("");
  const handleChange = (e, index) => {
    if (userEditProfile !== "yes") {

      if (subType==="false" && index === 2) {
        setUpgradeMembership(true);
        setTextContent("Your current subscription does not allow you create an association user. Please upgrade!")
      } else if (subType==="false" && remainingUsers?.account === 0) {
        setUserExhaustModal(true);
        setTextContent("You've utilised all available licences to create this user type.");
      } else if (
        subType==="true" &&
        remainingUsers?.account === 0 &&
        (index === 0 || index === 1)
      ) {
        // setUpgradeMembership(true);
        setUserExhaustModal(true);
        setTextContent("You've utilised all available licences to create this user type.");
      } else if (subType==="true" && remainingUsers?.associate === 0 && index === 2) {
        setUserExhaustModal(true);
        setTextContent("You've utilised all available licences to create this user type.");
      } else {
        if (e.target.checked) {
          setPermissionIndex(index);
        } else {
          setPermissionIndex();
        }
      }
    } else {
      if (e.target.checked) {
        setPermissionIndex(index);
      } else {
        setPermissionIndex();
      }
    }
  };
  return (
    <div
      className={
        disableCards && disableCards.includes(index)
          ? "permissionCard_mainDiv_deactive"
          : "permissionCard_mainDiv "
      }
    >
      {/* Title Row */}
      <UpgradeMembershipModal
        setUpgradeMembership={setUpgradeMembership}
        upgradeMembership={upgradeMembership}
        textContent={textContent}
      />
      <UserExhaust
        userExhaustModal={userExhaustModal}
        setUserExhaustModal={setUserExhaustModal}
        textContent={textContent}
      />
      <Row>
        <Col xs={24} className="title_main_col">
          <Checkbox
            disabled={disableCards && disableCards?.includes(index)}
            onChange={(e) => handleChange(e, index)}
            checked={index === permissionIndex}
          ></Checkbox>
          <span>{data?.title}</span>
          <Tooltip title={data?.tooltip_text}>
            <InfoCircleOutlined className="info_icon" />
          </Tooltip>
        </Col>
      </Row>
      {/* Content Row */}
      <Row className="content_row">
        <Col className="content_main_div">
          <Row>
            {data?.description?.map((item) => (
              <Col xs={24} className="content_item_col">
                {index === permissionIndex && (
                  <Checkbox
                    // style={{ marginRight: "8px" }}
                    onChange={(e) => {
                      if (!e?.target?.checked) {
                        const count = selectedCount;
                        setSelectedCount(count - 1);
                        let tempPermissions = permissions?.filter(
                          (e) => e !== item?.value
                        );

                        setPermissions(tempPermissions);
                      } else {
                        setPermissions([...permissions, item.value]);
                        const count = selectedCount;
                        setSelectedCount(count + 1);
                      }
                    }}
                    className="content_div_checkbox"
                    checked={
                      data?.title === "Standard User" &&
                      !permissions?.includes(item?.value)
                        ? false
                        : true
                    }
                    disabled={data?.title === "Standard User" ? false : true}
                  ></Checkbox>
                )}
                <span>{item?.name}</span>
              </Col>
            ))}
          </Row>
        </Col>
      </Row>
    </div>
  );
};
export default PermissionIndividualCard;
