import { Button, Col, Row, message } from "antd";
import React, { useEffect, useState } from "react";
import StandardPlan from "../../common/subscriptionMangement/StandardPlan";
import RecuritementPlanCard from "../../common/subscriptionMangement/RecuritementPlanCard";
import API_MANAGER from "../../../API";
function ChoosePlan({
  setcurrentForm,
  handelFinish,
  setprogress,
  setStatus,
  setSelectedPlan,
  selectedPlan,
  data,
  userData,
}) {
  const [active, setactive] = useState(false);
  useState(() => {
    setSelectedPlan(selectedPlan);
  }, [selectedPlan]);
  const handleSelect = (e) => {
    setSelectedPlan(e);
  };
  const handleNext = async () => {
    try {
      const params = {
        subscriptionProductId: selectedPlan?._id,
        accountUser: selectedPlan?.standard_users,
        associateUser: selectedPlan?.association_users,
      };

      const response = await API_MANAGER.postSelectPlan(params);
      setcurrentForm("add user");

      setStatus((prev) => ({
        ...prev,
        plan: "sucess",
        user: "progress",
      }));

      setprogress((prev) => ({
        ...prev,
        second: 100,
      }));
    } catch (error) {
      message.error("Something went wrong. Please try again.");
    }
  };
  useEffect(() => {
    if (userData?.subscription?.subscriptionProductId) {
      const sub = data?.filter(
        (a) => a?._id === userData?.subscription?.subscriptionProductId
      );
      if (sub?.length > 0) {
        setSelectedPlan(sub[0]);
      }
    }
    if (userData?.subscription?.additionalUser) {
      // setStatus((prev) => ({
      //   ...prev,
      //   plan: "sucess",
      //   user: "progress",
      // }));
      // setprogress((prev) => ({
      //   ...prev,
      //   second: 100,
      // }));
      // setcurrentForm("add user");
    }
  }, [userData]);
  return (
    <div className="choose_plan_container">
      <Row gutter={[0, 16]}>
        <Col span={24} className="justify-center d-flex mt-42">
          <h2>Choose Your Plan</h2>
        </Col>
        <Col span={24} className="justify-center d-flex">
          <p className="heading para-desc">
            "Cinchy.me" makes recruiting for the construction industry easier
            and faster than ever before.
          </p>
        </Col>
      </Row>
      {/* cards section */}
      <Row className="justify-center gap-48 mb-32">
        {data?.map((item, index) => (
          <>
            <StandardPlan
              btn={false}
              data={item}
              id={item?._id}
              selectedPlan={selectedPlan}
              handleSelect={handleSelect}
            />
          </>
        ))}
        {/* <RecuritementPlanCard
          btn={false}
          border={border}
          handelSelect={handelSelect}
        /> */}
      </Row>
      {/* cards section ends here */}
      <Row gutter={[0, 24]} className="mt-24">
        <Col span={24} className="justify-center d-flex">
          <Button
            onClick={() => handleNext()}
            disabled={!Object.keys(selectedPlan)?.length}
            className="next_btn"
          >
            Next
          </Button>
        </Col>
      </Row>
    </div>
  );
}

export default ChoosePlan;
