import React from "react";
import { DropableIcon } from "../../../Assets/campagin/sectionProgress/svgs";
import { Col, Row } from "antd";

function HardRequirementsDropArea({ title }) {
  return (
    <div className="hard-soft-req-main-container">
      <Row>
        <p>{title} </p>
      </Row>
      <Row className="dropable-area">
        <Col span={24} className="justify-center d-flex mb-8">
          <DropableIcon />
        </Col>
        <Col span={24} className="d-flex justify-center text-center">
          <p>Drag and drop a field to this area</p>
        </Col>
      </Row>
    </div>
  );
}

export default HardRequirementsDropArea;
