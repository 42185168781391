import React from "react";
import ErrorImage from "../../Assets/layout/Error.svg";
import Layout from "../layout";
import NonProtectedLayout from "../layout/NonProtectedLayout";
import SubLayout from "../layout/SubLayout";
import { IsTokenValid } from "../../utils/middleware";
import { Button, Row, Col } from "antd";
function NoInternetError() {
  const isAuthenticated = IsTokenValid();
  return (
    <>
      {isAuthenticated ? (
        <Layout>
          <SubLayout page={"No Internet"} showBack>
            <div
              style={{
                justifyContent: "center",
                display: "flex",
                alignItems: "center",
                height: "calc(100vh - 148px)",
                fontSize: "32px",
                textAlign: "center",
              }}
              className="errorPage"
            >
              <div className="text_align_center">
                <img src={ErrorImage} />
                <p
                  className="title"
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  No Internet Connection
                </p>
                <p className="desc">
                  We are sorry, but it looks like you
                  <br /> are not connected to internet. <br />
                  Please connect and try again.
                </p>
                <Row justify={"center"}>
                  <Col>
                    <Button onClick={() => window?.location?.reload()}>
                      Try Again
                    </Button>
                  </Col>
                </Row>
              </div>
            </div>
          </SubLayout>
        </Layout>
      ) : (
        <NonProtectedLayout>
          <div
            style={{
              justifyContent: "center",
              display: "flex",
              alignItems: "center",
              height: "calc(100vh - 100px)",
              fontSize: "32px",
              textAlign: "center",
            }}
            className="errorPage"
          >
            <div>
              <img src={ErrorImage} />
              <p
                className="title"
                style={{ display: "flex", justifyContent: "center" }}
              >
                No Internet Connection
              </p>
              <p className="desc">
                We are sorry, but it looks like you
                <br /> are not connected to internet. <br />
                Please connect and try again.
              </p>
            </div>
          </div>
        </NonProtectedLayout>
      )}
    </>
  );
}

export default NoInternetError;
