import React, { useState, useEffect } from "react";
import SubLayout from "../../components/layout/SubLayout";
import { useNavigate } from "react-router-dom";
import NewsFeedCarousel from "../../components/common/NewsFeedCarousel";
import { Row, Col, Table, Button, Input, Dropdown, Radio, message } from "antd";
import EditActionIcon from "../../Assets/advert_detail/editIcon.svg";
import ArchiveIcon from "../../Assets/advert_detail/archiveIcon.svg";
import action_delete from "../../Assets/advert_detail/action-delete.svg";
import infoIcon from "../../Assets/advert_detail/infoIcon.svg";
import DownLoad from "../../Assets/downloadIcon.svg";
import SearchIcon from "../../Assets/campagin/searchIcon.svg";
import NoElementBox from "../../components/common/NoElementBox";
import FilterIcon from "../../Assets/filter.svg";
import API_MANAGER from "../../API";
import CancelIcon from "../../Assets/cancelIcon.svg";

import CountCards from "../../components/common/campaignManagement/CountCards";
import {
  CommonDeleteSvg,
  DownloadIcon,
  TableDeleteImage,
} from "../../Assets/campagin/sectionProgress/svgs";
import CustomPagination from "../../components/common/CustomPagination";
import { DeleteModal } from "../../components/common/Popup";
import AlertState from "../../components/common/AlertState";
import FullScreenLoader from "../../components/common/FullScreenLoader";
import LeftArrow from "../../Assets/leftArrow.svg";
import { downloadPDF } from "../../utils/constant";
import MobileHeader from "../../components/layout/MobileHeader";
function CampaignManagement() {
  const navigate = useNavigate();
  const [jobModal, setJobModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [jobActive, setJobActive] = useState(true);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [data, setData] = useState([]);
  const [search, setSearch] = useState(null);
  const [activeDeleteModal, setActiveDeleteModal] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertState, setAlertState] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [deleteType, setDeleteType] = useState("single");
  const [sortType, setSortType] = useState("-createdAt");
  const [rowData, setRowData] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const subscription = JSON.parse(localStorage.getItem("subscription"));
  const [headerData, setHeaderData] = useState();
  const [reportLoading, setReportLoading] = useState(false);
  const [initialLoading, setInitialLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const handleSwitch = (checked) => {
    setJobActive(checked);
    setJobModal(true);
  };
  const getData = async () => {
    const param = {
      page: currentPage,
      limit: 10,
      sort: sortType,
      search: search ? search : "",
    };
    setLoading(true);
    try {
      setLoading(true);
      if (localStorage.getItem("user") === "Root") {
        const response = await API_MANAGER.getCampaignListRoot(param);
        setTotalCount(response?.data?.data?.totalCampaign);
        const newArray = response?.data?.data?.result?.filter(
          (obj) => obj?.archive === false
        );
        setData(newArray);
      } else {
        const response = await API_MANAGER.getCampaignList(param);
        const newArray = response?.data?.data?.result?.filter(
          (obj) => obj.archive === false
        );
        setTotalCount(response?.data?.data?.totalRecord);

        setData(newArray);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);

      message.error("Something went wrong. Please try again.");
    }
  };
  useEffect(() => {
    // This function will be called whenever `currentPage` changes
    const element = document.getElementById("topDiv");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  }, [currentPage]);

  const deleteCampaign = async () => {
    setDeleteLoading(true);
    try {
      if (deleteType === "single") {
        await API_MANAGER.deleteCampaign(rowData?._id);
        message.success("Campaign successfully deleted.");
      } else if (deleteType === "multiple") {
        const params = {
          campaignIds: [...selectedRowKeys],
        };
        await API_MANAGER.bulkCampaignDelete(params);
        message.success("Campaign(s) successfully deleted.");
      }

      getData();
      setDeleteLoading(false);
      setActiveDeleteModal(false);
    } catch (error) {
      setDeleteLoading(false);
      setActiveDeleteModal(false);

      message.error("Something went wrong. Please try again.");
    }
  };
  const campaignArchive = async () => {
    try {
      const param = {
        archive: rowData?.archive ? false : true,
      };
      await API_MANAGER.campaignArchive(rowData?._id, param);
      // message.success(
      //   `Campaign ${rowData?.archive ? "unarchived" : "archived"} successfully!`
      // );
      message.success(
        `Campaign successfully ${rowData?.archive ? "unarchived" : "archived"}.`
      );
      campaignHeaderData();
      getData();
    } catch (error) {
      message.error(`Something went wrong. Please try again.`);
    }
  };

  const campaignReport = async () => {
    setReportLoading(true);
    try {
      const response = await API_MANAGER.getCampaignReport();
      downloadPDF(response?.data?.data, "Campaign Report");
      setReportLoading(false);
    } catch (error) {
      setReportLoading(false);

      message.error(
        error?.response?.data?.message ||
          "Something went wrong. Please try again."
      );
    }
  };
  useEffect(() => {
    getData();
  }, [search, sortType, currentPage]);
  const getActionItems = (row) => {
    const actionItems = [
      {
        key: "1",
        label: (
          <div className="action-dropdown">
            <Row>
              <Col
                className="item"
                span={24}
                onClick={() =>
                  navigate(`/campaign-management/edit-campaign`, {
                    state: row,
                  })
                }
              >
                <img src={EditActionIcon} />
                <span>Edit</span>
              </Col>
            </Row>
            <Row>
              <Col
                onClick={() => {
                  setDeleteType("single");
                  setActiveDeleteModal(true);
                }}
                className="item"
                span={24}
              >
                <img src={action_delete} />
                <span>Delete</span>
              </Col>
            </Row>
            <Row>
              <Col className="item" span={24} onClick={() => campaignArchive()}>
                <img src={ArchiveIcon} />
                <span>{row?.archive ? "Unarchive" : "Archive"}</span>
              </Col>
            </Row>
          </div>
        ),
      },
    ];
    return actionItems;
  };

  const items = [
    {
      key: "1",
      label: (
        <Radio.Group
          value={sortType}
          onChange={(e) => setSortType(e?.target?.value)}
          className="dropDown_radio_group"
        >
          <div className="">
            <Row className="filter_item_row">
              <Col span={24} className="">
                <Radio value={"-createdAt"} className="radio_text">
                  Latest First
                </Radio>
              </Col>
            </Row>
            <Row className="filter_item_row">
              <Col span={24} className="">
                <Radio value={"createdAt"} className="radio_text">
                  Oldest First
                </Radio>
              </Col>
            </Row>
            <Row className="filter_item_row">
              <Col span={24} className="">
                <Radio value={"name"} className="radio_text">
                  Sort by A-Z
                </Radio>
              </Col>
            </Row>
            <Row className="filter_item_row">
              <Col span={24} className="">
                <Radio value={"-name"} className="radio_text">
                  Sort by Z-A
                </Radio>
              </Col>
            </Row>
          </div>
        </Radio.Group>
      ),
    },
  ];
  const columns = [
    {
      title: (
        <Row
          gutter={16}
          align={"middle"}
          className="d-flex wrap-unset align_center_start"
        >
          <Col className="campaign-table-heading">Campaign Name</Col>
          <Col>
            <Dropdown
              menu={{ items }}
              overlayClassName="notificationFilterDropdown"
            >
              <img src={FilterIcon} alt="filter" />
            </Dropdown>
          </Col>
        </Row>
      ),
      align: "left",
      dataIndex: "name",
      fixed: "left",
      render: (item, row) => (
        <span
          className="cursor-pointer w-100"
          style={{ textAlign: "left", display: "block" }}
          onClick={() => navigate(`/campaign-detail/${row?._id}`)}
        >
          {item?.charAt(0)?.toUpperCase() + item?.substring(1)}
        </span>
      ),
    },

    {
      title: "Job Adverts",
      dataIndex: "advertCount",
      render: (item, row) => (
        <span className="cursor-pointer w-100">{item ? item : "0"}</span>
      ),
    },
    {
      title: "Target Headcount",
      dataIndex: "targetHeadCount",
      render: (item, row) => (
        <span className="cursor-pointer w-100">{item ? item : "0"}</span>
      ),
    },
    {
      title: "Applied",
      dataIndex: "appliedJobsCount",
      render: (item, row) => (
        <span className="cursor-pointer w-100">{item ? item : "0"}</span>
      ),
    },
    {
      title: "Shortlists Offered",
      dataIndex: "shortlistCount",
      render: (item, row) => (
        <span className="cursor-pointer w-100">{item ? item : "0"}</span>
      ),
    },
    {
      title: "Hard Review",
      dataIndex: "hardReview",
      render: (item, row) => (
        <span className="cursor-pointer w-100">{item ? item : "0"}</span>
      ),
    },
    {
      title: "Role Offered",
      dataIndex: "roleOffered",
      render: (item, row) => (
        <span className="cursor-pointer w-100">{item ? item : "0"}</span>
      ),
    },
    {
      title: "Role Accepted",
      dataIndex: "offerAccepted",
      render: (item, row) => (
        <span className="cursor-pointer w-100">{item ? item : "0"}</span>
      ),
    },
    {
      title: "",
      dataIndex: "action",
      render: (item, row) => (
        <Row align={"middle"}>
          <Dropdown
            placement="bottom"
            overlayClassName="action-dropdown"
            menu={{
              items: getActionItems(row),
            }}
            trigger={"click"}
          >
            <img
              onClick={(e) => {
                e.preventDefault();
                setRowData(row);
              }}
              className="cursor_pointer"
              src={infoIcon}
              alt="edit"
            />
          </Dropdown>
        </Row>
      ),
    },
  ];
  const campaignHeaderData = async () => {
    setInitialLoading(true);
    try {
      const response = await API_MANAGER.campaignHeader();
      setHeaderData(response?.data?.data);
      setInitialLoading(false);
      if (localStorage.getItem("user") === "Root") {
        const response = await API_MANAGER.campaignHeaderRoot();
        setHeaderData(response?.data?.data);
      } else {
        const response = await API_MANAGER.campaignHeader();
        setHeaderData(response?.data?.data);
      }
    } catch (error) {
      setInitialLoading(false);
      message.error("Something went wrong. Please try again.");
    }
  };
  useEffect(() => {
    campaignHeaderData();
  }, []);
  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  return (
    <SubLayout page={"Campaign Management"} whiteBg showSearch>
      {!search && <FullScreenLoader isLoading={loading} />}

      <div className="landing_campaign_page" id="topDiv">
        <MobileHeader showSearch title={"Campaign Management"} />
        <NewsFeedCarousel />
        {headerData && (
          <div className="mt-20" id="countCardDiv">
            <CountCards data={headerData} />
          </div>
        )}
        {data?.length > 0 || search !== null ? (
          <div className="campaign_summary_box mt-20">
            <div className="campaign_table_box">
              <Table
                rowSelection={rowSelection}
                columns={columns}
                dataSource={data}
                pagination={false}
                className="campaignListTable"
                scroll={{
                  x: "calc(750px + 60%)",
                  // y: "calc(100vh - 100%)",
                }}
                // loading={loading}
                onRow={(record, rowIndex) => {
                  return {
                    onClick: (event) => {
                      if (event?.target?.tagName === "TD") {
                        navigate(`/campaign-detail/${record?._id}`);
                      }
                      //
                    }, // click row
                  };
                }}
                title={() => (
                  <Row
                    align={"middle"}
                    justify={"space-between"}
                    className="campaignTableTitle"
                    // gutter={4}
                  >
                    <Col xs={6} md={9}>
                      <Row gutter={{ lg: 16, xl: 32 }} className="align-end-sm">
                        <Col>
                          <Row
                            className="cursor-pointer"
                            align={"middle"}
                            onClick={() => {
                              if (
                                selectedRowKeys?.length > 0 &&
                                selectedRowKeys?.length <= 1
                              ) {
                                setDeleteType("multiple");
                                setActiveDeleteModal(true);
                              } else {
                                setDeleteType("multiple");
                                setActiveDeleteModal(true);
                              }
                            }}
                            gutter={4}
                          >
                            <Col style={{ marginTop: "4px" }}>
                              {/* <img src={DeleteImg} alt="delete" /> */}
                              <CommonDeleteSvg
                                color={
                                  selectedRowKeys.length > 0
                                    ? "#D03C28"
                                    : "#A7AEBB"
                                }
                              />
                            </Col>
                            <Col
                              className={`${
                                selectedRowKeys.length > 0
                                  ? "deleteRed"
                                  : "delete"
                              } dp-none-sm`}
                            >
                              Delete
                            </Col>
                          </Row>
                        </Col>
                        {subscription &&
                          subscription?.sp?.active &&
                          JSON.parse(
                            localStorage.getItem("isPlusSubscription")
                          ) && (
                            <Col>
                              <Row
                                align={"middle"}
                                gutter={4}
                                onClick={() => campaignReport()}
                              >
                                <Col style={{ marginTop: "4px" }}>
                                  <DownloadIcon color={"#1638B1"} />
                                  {/* <img
                                  src={DownLoad}
                                  alt="campaign-report cursor-pointer"
                                /> */}
                                </Col>
                                <Col className="Campaign-Report dp-none-sm cursor-pointer">
                                  {reportLoading
                                    ? "Downloading..."
                                    : "Campaign Report"}
                                </Col>
                              </Row>
                            </Col>
                          )}
                      </Row>
                    </Col>

                    <Col xs={18} md={15} align={"right"}>
                      <Row align={"right"} gutter={8}>
                        <Col xs={24} md={12} lg={14} xl={17} xxl={19}>
                          <Input.Search
                            className="searchBox"
                            placeholder="Search campaign"
                            prefix={<img src={SearchIcon} alt="search" />}
                            onChange={(e) => setSearch(e?.target?.value)}
                            value={search}
                            suffix={
                              search && (
                                <img
                                  src={CancelIcon}
                                  className="cursor_pointer"
                                  alt="cross"
                                  onClick={() => {
                                    setSearch(null);
                                  }}
                                />
                              )
                            }
                          />
                        </Col>
                        <Col xs={0} md={12} lg={10} xl={7} xxl={5}>
                          <Button
                            onClick={() => {
                              navigate(`/campaign-management/add-campaign`);
                              // localStorage.removeItem("campaignData");
                            }}
                            className="createBtn"
                          >
                            + Create Campaign
                          </Button>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                )}
                rowKey={"_id"}
              />
              <CustomPagination
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                total={totalCount}
                itemPerPage={10}
              />
              <Col xs={24} md={0} className="w-100">
                <Button
                  onClick={() => navigate(`/campaign-management/add-campaign`)}
                  className="createBtn createBtn-sm"
                >
                  + Create Campaign
                </Button>
              </Col>
            </div>
          </div>
        ) : (
          <NoElementBox
            title="No campaigns created yet!"
            btnText="+ Create Campaign"
            handleClick={() => navigate(`/campaign-management/add-campaign`)}
          />
        )}
        <DeleteModal
          visible={activeDeleteModal}
          setVisible={setActiveDeleteModal}
          text={deleteType === "single" ? "Campaign" : "Campaigns"}
          handleSubmit={deleteCampaign}
          loading={deleteLoading}
        />
      </div>
      <AlertState
        state={alertState}
        message={alertMessage}
        alertOpen={alertOpen}
        setAlertOpen={setAlertOpen}
      />
    </SubLayout>
  );
}

export default CampaignManagement;
