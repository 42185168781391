import React, { useEffect, useState } from "react";
import { Col, Row, message } from "antd";
import SubLayout from "../../components/layout/SubLayout";
import OnboardingLayout from "../../components/layout/OnboardingLayout";
import { OnBoardingProgress } from "../../components/common/campaignManagement/SectionProgress";
import OnboardingForm from "../../components/common/Onboarding/OnboardingForm";
import ChoosePlan from "../../components/common/Onboarding/ChoosePlan";
import Adduser from "../../components/common/Onboarding/Adduser";
import ReviewAndPay from "../../components/common/Onboarding/ReviewAndPay";
import API_MANAGER from "../../API";
import { useNavigate } from "react-router-dom";
import axios from "axios";
function OnBoardingForm() {
  const navigate = useNavigate();

  const [progress, setprogress] = useState({
    first: 0,
    second: 0,
    third: 0,
    forth: 0,
  });
  const [formProgress, setformProgress] = useState({
    marketingPermission: false,
  });

  // const [currentForm, setcurrentForm] = useState("plan");
  const [currentForm, setcurrentForm] = useState("onboarding");
  const [onboardData, setOnboardData] = useState([]);
  const [subscriptionData, setSubscriptionData] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState({});
  const [editLogo, setEditLogo] = useState(false);
  const [addUserData, setAddUserData] = useState({
    account: 0,
    association: 0,
  });
  const [formNextLoading, setFormNextLoading] = useState(false);
  const [userData, setUserData] = useState({});
  const [payLoading, setPayLoading] = useState(false);
  const [editCompanyProfileId, setEditCompanyProfileId] = useState(null);
  const [discountCode, setDiscountCode] = useState(null);
  const getUserProfile = async () => {
    try {
      const response = await API_MANAGER.getUserData();
      setUserData(response?.data?.data);
      setEditCompanyProfileId(response?.data?.data?._id);
      if (response?.data?.data) {
        setprogress((prev) => ({ ...prev, first: 100 }));
        setStatus((prev) => ({
          ...prev,
          company: "success",
          plan: "progress",
        }));
      }
      if (response?.data?.data?.subscription?.additionalUser) {
        if (response?.data?.data?.subscription?.additionalUser?.accountUser) {
          setAddUserData({
            account: response?.data?.data?.subscription?.additionalUser
              ?.accountUser
              ? parseInt(
                  response?.data?.data?.subscription?.additionalUser
                    ?.accountUser
                )
              : 0,
            association: response?.data?.data?.subscription?.additionalUser
              ?.associateUser
              ? parseInt(
                  response?.data?.data?.subscription?.additionalUser
                    ?.associateUser
                )
              : 0,
          });
          // setcurrentForm("pay");
          // setStatus((prev) => ({
          //   ...prev,
          //   user: "sucess",
          //   pay: "sucess",
          // }));
          // setprogress((prev) => ({
          //   ...prev,
          //   third: 100,
          // }));
        }
      }
    } catch (error) {
      message.error("Something went wrong. Please try again.");
    }
  };
  const getSubscriptionData = async () => {
    try {
      const response = await API_MANAGER.getSubscriptionData();
      setSubscriptionData(response?.data?.data);
    } catch (error) {
      message.error("Something went wrong. Please try again.");
    }
  };

  useEffect(() => {
    getSubscriptionData();
    getUserProfile();
  }, []);
  const hadelFormFilled = async (e, type) => {
    if (type === "onboard") {
      setOnboardData(e);
      let params = {
        companyName: formProgress?.companyName,
        tradingName: formProgress?.tradingName,
        companyAbn: formProgress?.companyAbn,
        // companyLogo: formProgress?.upload_logo ? formProgress?.upload_logo : "",
        officeAddress: formProgress?.registered_address,
        billingName: formProgress?.billingName,
        billingEmail: formProgress?.billingEmail,
        marketingPermission: formProgress?.marketingPermission,
      };
      try {
        setFormNextLoading(true);
        if (
          e?.upload_logo?.length > 0 &&
          typeof e?.upload_logo[0] === "object"
        ) {
          const dataLogo = {
            extension:
              e?.upload_logo?.length > 0
                ? e?.upload_logo[0]?.type?.split("/")[1]
                : "",
            type: "PROFILE",
            contentType:
              e?.upload_logo?.length > 0 ? e?.upload_logo[0]?.type : "",
          };
          try {
            const responseLogo = await API_MANAGER.fileUpload(dataLogo);
            if (responseLogo) {
              await axios.put(
                responseLogo?.data?.data?.url,
                e?.upload_logo[0]?.originFileObj
              );
            }
            params["companyLogo"] = responseLogo?.data?.data
              ? responseLogo?.data?.data?.key
              : "";
            if (editCompanyProfileId) {
              const response = await API_MANAGER.updateRecruiterProfile(params);
            } else {
              const response = await API_MANAGER.createRecruiterProfile(params);
              if (response?.data?.data) {
                setEditCompanyProfileId(response?.data?.data?._id);
              }
            }
            setFormNextLoading(false);
            setStatus((prev) => ({
              ...prev,
              company: "success",
              plan: "progress",
            }));
            setprogress((prev) => ({ ...prev, first: 100 }));
            setcurrentForm("plan");
          } catch (error) {
            setFormNextLoading(false);

            message.error(
              error?.response?.data?.message?.message ||
                "Something went wrong. Please try again."
            );
          }
          setFormNextLoading(false);
        } else {
          if (editCompanyProfileId) {
            const response = await API_MANAGER.updateRecruiterProfile(params);
          } else {
            const response = await API_MANAGER.createRecruiterProfile(params);
            if (response?.data?.data) {
              setEditCompanyProfileId(response?.data?.data?._id);
            }
          }
          setFormNextLoading(false);
          setStatus((prev) => ({
            ...prev,
            company: "success",
            plan: "progress",
          }));
          setprogress((prev) => ({ ...prev, first: 100 }));
          setcurrentForm("plan");
        }
      } catch (error) {
        setFormNextLoading(false);
        message.error(
          error?.response?.data?.message?.message ||
            "Something went wrong. Please try again."
        );
      }
    }
  };
  const [status, setStatus] = useState({
    company: "progress",
    plan: "wait",
    user: "wait",
    pay: "wait",
  });
  const handleSubmit = async () => {
    setPayLoading(true);
    let params = {
      companyName: formProgress?.companyName,
      tradingName: formProgress?.tradingName,
      companyAbn: formProgress?.companyAbn,
      // companyLogo: formProgress?.upload_logo ? formProgress?.upload_logo : "",
      officeAddress: formProgress?.registered_address,
      billingName: formProgress?.billingName,
      billingEmail: formProgress?.billingEmail,
      marketingPermission: formProgress?.marketingPermission,
    };

    try {
      if (
        formProgress?.upload_logo?.length > 0 &&
        typeof formProgress?.upload_logo[0] === "object"
      ) {
        const dataLogo = {
          extension:
            formProgress?.upload_logo?.length > 0
              ? formProgress?.upload_logo[0]?.type?.split("/")[1]
              : "",
          type: "PROFILE",
          contentType:
            formProgress?.upload_logo?.length > 0
              ? formProgress?.upload_logo[0]?.type
              : "",
        };
        const responseLogo = await API_MANAGER.fileUpload(dataLogo);
        await axios.put(
          responseLogo?.data?.data?.url,
          formProgress?.upload_logo[0]?.originFileObj
        );
        params["companyLogo"] = responseLogo?.data?.data
          ? responseLogo?.data?.data?.key
          : "";
      } else if (
        formProgress?.upload_logo?.length > 0 &&
        typeof formProgress?.upload_logo[0] !== "object"
      ) {
        params["companyLogo"] = formProgress?.upload_logo?.split()[1];
      }
      const response = await API_MANAGER.updateRecruiterProfile(params);
      localStorage.setItem("clientId", response?.data?.data?._id);
      const paramData = {
        priceId: selectedPlan?.product_price,
        subscriptionProductId: selectedPlan?._id,
        accountUser: selectedPlan?.standard_users,
        associateUser: selectedPlan?.association_users,
        billingEmail: formProgress?.billingEmail,
        additionalData: [
          {
            title: "Additional Association Users",
            price: selectedPlan?.additional_association_user_price,
            quantity: addUserData?.association,
            userType: "associate",
          },
          {
            title: "Additional Account Users",
            price: selectedPlan?.additional_standard_user_price,
            quantity: addUserData?.account,
            userType: "account",
          },
        ],
      };
      if (discountCode) {
        paramData["discount"] = discountCode;
      }
      const subscriptionResponse = await API_MANAGER.addSubscription(paramData);
      localStorage.setItem("isStripe", "yes");
      setPayLoading(false);

      window.open(subscriptionResponse?.data?.data, "_self");
    } catch (error) {
      setPayLoading(false);
      message.error(
        error?.response?.data?.message?.message ||
          "Something went wrong. Please try again."
      );
    }
  };
  const handleDelete = () => {
    setSelectedPlan({});
    setAddUserData({
      account: 0,
      association: 0,
    });
    setprogress({
      first: 100,
      second: 0,
      third: 0,
      forth: 0,
    });
    setStatus({
      company: "progress",
      plan: "progress",
      user: "wait",
      pay: "wait",
    });
    setcurrentForm("plan");
  };

  return (
    <OnboardingLayout whiteBg>
      <div
        style={{
          display: "flex",
          justifyContent: "end",
          height: "100vh",
        }}
      >
        <SubLayout page={"Create Your Profile"}>
          <div className="onboarding_form_container">
            <Row>
              <Col xs={24} md={0} className="Onboarding-sm">
                Onboarding
              </Col>
            </Row>
            <Row className="onboarding-progress-container">
              <OnBoardingProgress
                percent={progress}
                status={status}
                setcurrentForm={setcurrentForm}
              />
            </Row>
            {currentForm === "onboarding" && (
              <Row className="w-100">
                <OnboardingForm
                  setprogress={setprogress}
                  setStatus={setStatus}
                  handelFinish={hadelFormFilled}
                  formProgress={formProgress}
                  setformProgress={setformProgress}
                  onboardData={onboardData}
                  setOnboardData={setOnboardData}
                  userData={userData}
                  setcurrentForm={setcurrentForm}
                  loading={formNextLoading}
                  setEditLogo={setEditLogo}
                  editLogo={editLogo}
                />
              </Row>
            )}
            {currentForm === "plan" && (
              <ChoosePlan
                setcurrentForm={setcurrentForm}
                setprogress={setprogress}
                setStatus={setStatus}
                handelFinish={hadelFormFilled}
                setSelectedPlan={setSelectedPlan}
                selectedPlan={selectedPlan}
                data={subscriptionData}
                userData={userData}
              />
            )}
            {currentForm === "add user" && (
              <Adduser
                setcurrentForm={setcurrentForm}
                setprogress={setprogress}
                setStatus={setStatus}
                handelFinish={hadelFormFilled}
                setAddUserData={setAddUserData}
                addUserData={addUserData}
                data={selectedPlan}
                userData={userData}
              />
            )}
            {currentForm == "pay" && (
              <ReviewAndPay
                handleSubmit={handleSubmit}
                data={selectedPlan}
                addUserData={addUserData}
                setAddUserData={setAddUserData}
                handleDelete={handleDelete}
                loading={payLoading}
                setLoading={setPayLoading}
                setDiscountCode={setDiscountCode}
                discountCode={discountCode}
              />
            )}
          </div>
        </SubLayout>
      </div>
    </OnboardingLayout>
  );
}

export default OnBoardingForm;
