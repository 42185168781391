import React, { useState, useEffect } from "react";
import SubLayout from "../../components/layout/SubLayout";
import { useNavigate } from "react-router-dom";
import { Row, Col, Table, Button, Input, Dropdown, Radio, message } from "antd";
import ArchiveIcon from "../../Assets/advert_detail/archiveIcon.svg";
import action_delete from "../../Assets/advert_detail/action-delete.svg";
import infoIcon from "../../Assets/advert_detail/infoIcon.svg";
import SearchIcon from "../../Assets/campagin/searchIcon.svg";
import verticleIocn from "../../Assets/menuIcon.svg";
import NoElementBox from "../../components/common/NoElementBox";
import FilterIcon from "../../Assets/filter.svg";
import API_MANAGER from "../../API";
import {
  CommonDeleteSvg,
  TableArchiveImage,
  TableDeleteImage,
} from "../../Assets/campagin/sectionProgress/svgs";
import CustomPagination from "../../components/common/CustomPagination";
import { DeleteModal } from "../../components/common/Popup";
import FullScreenLoader from "../../components/common/FullScreenLoader";
import AlertState from "../../components/common/AlertState";
function CampaignManagement() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [data, setData] = useState([]);
  const [search, setSearch] = useState(null);
  const [activeDeleteModal, setActiveDeleteModal] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  /************to show delete and status icon color diffrent when checkbox is selected ****************/
  const hasSelected = selectedRowKeys.length > 0;
  const [deleteType, setDeleteType] = useState("single");
  const [sortType, setSortType] = useState("-createdAt");
  const [rowData, setRowData] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const smallScreenPopupData = [
    {
      key: "1",
      label: (
        <div style={{ width: "130px", padding: "5px" }}>
          <Row
            className="cursor-pointer"
            align={"middle"}
            justify={"space-between"}
            onClick={() => {
              if (selectedRowKeys?.length > 0) {
                setDeleteType("multiple");
                setActiveDeleteModal(true);
              }
            }}
          >
            <Col span={4}>
              <TableDeleteImage color={hasSelected ? "#122D8E" : "#A7AEBB"} />
            </Col>
            <Col
              span={19}
              style={hasSelected ? { color: "#091647" } : { color: "#A7AEBB" }}
            >
              Delete
            </Col>
          </Row>
          <Row
            style={{ marginTop: "5px", paddingLeft: "2px" }}
            align={"middle"}
            justify={"space-between"}
            onClick={() => {
              if (selectedRowKeys?.length > 0) {
                bulkArchive();
              }
            }}
          >
            <Col span={4} className="flex-archive cursor-pointer">
              <TableArchiveImage color={hasSelected ? "#122D8E" : "#A7AEBB"} />
            </Col>
            <Col
              span={19}
              style={hasSelected ? { color: "#091647" } : { color: "#A7AEBB" }}
            >
              Unarchive
            </Col>
          </Row>
        </div>
      ),
    },
  ];
  const getData = async () => {
    setSelectedRowKeys([]);
    const param = {
      page: currentPage,
      limit: 10,
      archive: true,
      sort: sortType,
      search: search ? search : "",
    };
    try {
      // setLoading(true);
      const response = await API_MANAGER.getArchiveList(param);
      setData(response?.data?.data);
      // setLoading(false);
    } catch (error) {
      // setLoading(false);

      message.error(
        error?.response?.data?.message?.message ||
          "Something went wrong. Please try again."
      );
    }
  };
  const deleteCampaign = async () => {
    setDeleteLoading(true);
    try {
      if (deleteType === "single") {
        await API_MANAGER.deleteCampaign(rowData?._id);
        message.success("Campaign deleted successfully!");
      } else if (deleteType === "multiple") {
        const params = {
          campaignIds: [...selectedRowKeys],
        };
        await API_MANAGER.bulkCampaignDelete(params);
        message.success("Campaigns deleted successfully!");
      }
      getData();
      setDeleteLoading(false);
      setActiveDeleteModal(false);
    } catch (error) {
      message.error("Something went wrong. Please try again.");
      setDeleteLoading(false);
    }
  };
  /****************   to make unarchive data ***********************/
  const campaignArchive = async () => {
    try {
      const param = {
        archive: rowData?.archive ? false : true,
      };
      await API_MANAGER.campaignArchive(rowData?._id, param);

      message.success("Campaign successfully unarchived.");
      getData();
    } catch (error) {
      message.error("Something went wrong. Please try again.");
    }
  };

  const bulkArchive = async () => {
    const data = {
      campaignId: [...selectedRowKeys],
      archive: false,
    };
    try {
      await API_MANAGER.bulkArchive(data);
      message.success("Campaign(s) successfully unarchive.");

      getData();
    } catch (error) {
      message.error("Something went wrong. Please try again.");
    }
  };

  useEffect(() => {
    getData();
  }, [search, sortType, currentPage]);
  const getActionItems = (row) => {
    const actionItems = [
      {
        key: "1",
        label: (
          <div className="action-dropdown">
            <Row>
              <Col
                onClick={() => {
                  setDeleteType("single");
                  setActiveDeleteModal(true);
                }}
                className="item"
                span={24}
              >
                <img src={action_delete} />

                <span>Delete</span>
              </Col>
            </Row>
            <Row>
              <Col className="item" span={24} onClick={() => campaignArchive()}>
                <img src={ArchiveIcon} />
                <span>{"Unarchive"}</span>
              </Col>
            </Row>
          </div>
        ),
      },
    ];
    return actionItems;
  };

  const items = [
    {
      key: "1",
      label: (
        <Radio.Group
          onChange={(e) => setSortType(e?.target?.value)}
          className="dropDown_radio_group"
        >
          <div className="">
            <Row className="filter_item_row">
              <Col span={24}>
                <Radio value={"-createdAt"} className="radio_text">
                  Latest First
                </Radio>
              </Col>
            </Row>
            <Row className="filter_item_row">
              <Col span={24}>
                <Radio className="radio_text" value={"createdAt"}>
                  Oldest First
                </Radio>
              </Col>
            </Row>
            <Row className="filter_item_row">
              <Col span={24}>
                <Radio className="radio_text" value={"name"}>
                  Sort by A-Z
                </Radio>
              </Col>
            </Row>
            <Row className="filter_item_row">
              <Col span={24}>
                <Radio className="" value={"-name"}>
                  Sort by Z-A
                </Radio>
              </Col>
            </Row>
          </div>
        </Radio.Group>
      ),
    },
  ];
  const columns = [
    {
      title: (
        <Row
          gutter={16}
          align={"middle"}
          className="d-flex wrap-unset align-center-justify"
        >
          <Col className="campaign-table-heading">Campaign Name</Col>
          <Col>
            <Dropdown
              menu={{ items }}
              overlayClassName="notificationFilterDropdown"
            >
              <img src={FilterIcon} alt="filter" />
            </Dropdown>
          </Col>
        </Row>
      ),
      dataIndex: "name",
      fixed: "left",
      align: "left",
      render: (item, row) => (
        <span
          style={{ color: "#1b2444" }}
          //   className="cursor-pointer"
          className="text_capitalize"
          //   onClick={() => navigate(`/campaign-detail/${row?._id}`)}
        >
          {item}
        </span>
      ),
    },

    {
      title: "Job Adverts",
      dataIndex: "advertCount",
    },
    {
      title: "Target",
      dataIndex: "targetHeadCount",
    },
    {
      title: "Applied",
      dataIndex: "appliedJobsCount",
    },
    {
      title: "Shortlists Offered",
      dataIndex: "shortlistCount",
    },
    {
      title: "Hard Review",
      dataIndex: "hardReview",
    },
    {
      title: "Role Offered",
      dataIndex: "roleOffered",
    },
    {
      title: "Role Accepted",
      dataIndex: "offerAccepted",
    },
    {
      title: "",
      dataIndex: "action",
      render: (item, row) => (
        <Row align={"middle"}>
          <Dropdown
            placement="left"
            overlayClassName="action-dropdown"
            menu={{
              items: getActionItems(row),
            }}
            trigger={"click"}
          >
            <img
              onClick={(e) => {
                e.preventDefault();
                setRowData(row);
              }}
              className="cursor_pointer"
              src={infoIcon}
              alt="edit"
            />
          </Dropdown>
        </Row>
      ),
    },
  ];

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  return (
    <SubLayout page={"Archived Campaigns"} whiteBg>
      <FullScreenLoader isLoading={loading} />
      <div className="archive_detail_container">
        <Row>
          <Col xs={24} md={0}>
            <p className="go-back">{"< Archive"}</p>
          </Col>
        </Row>
        {/* {data?.result?.length > 0 || search !== null ? ( */}
        <div className="campaign_summary_box mt-20">
          <div className="campaign_table_box">
            <Table
              rowSelection={rowSelection}
              columns={columns}
              dataSource={data?.result}
              pagination={false}
              scroll={{ x: "calc(750px + 60%)" }}
              loading={loading}
              title={() => (
                <Row
                  align={"middle"}
                  justify={"space-between"}
                  className="campaignTableTitle"
                >
                  <Col xs={0} md={9}>
                    <Row gutter={{ lg: 16, xl: 32 }}>
                      <Col>
                        <Row
                          className="cursor-pointer dp-none-sm"
                          align={"middle"}
                          onClick={() => {
                            if (selectedRowKeys?.length > 0) {
                              setDeleteType("multiple");
                              setActiveDeleteModal(true);
                            }
                          }}
                        >
                          <Col
                            className="d-flex"
                            style={{ marginBottom: "3px" }}
                          >
                            <CommonDeleteSvg
                              color={hasSelected ? "#D03C28" : "#A7AEBB"}
                            />
                          </Col>
                          <Col
                            style={{ marginLeft: "4px" }}
                            className={`${
                              hasSelected ? "deleteRed" : "archive-color-gary"
                            } dp-none-sm`}
                          >
                            Delete
                          </Col>
                        </Row>
                      </Col>
                      <Col>
                        <Row
                          className="cursor-pointer dp-none-sm"
                          align={"middle"}
                          onClick={() => {
                            if (selectedRowKeys?.length > 0) {
                              bulkArchive();
                            }
                          }}
                        >
                          <Col className="d-flex" style={{ marginTop: "2px" }}>
                            <TableArchiveImage
                              color={hasSelected ? "#D03C28" : "#A7AEBB"}
                            />
                          </Col>
                          <Col
                            className={` ${
                              hasSelected ? "deleteRed" : "archive-color-gary"
                            } dp-none-sm `}
                            style={{ marginTop: "3px", marginLeft: "4px" }}
                          >
                            Unarchive
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                  {/* for mobile screen */}
                  <Col xs={3} md={0} align="right">
                    <Dropdown
                      overlayClassName="action-dropdown"
                      trigger={"click"}
                      menu={{ items: smallScreenPopupData }}
                      placement="bottomLeft"
                    >
                      <img
                        src={verticleIocn}
                        alt="verticle Logo"
                        className="verticleLogo"
                      />
                    </Dropdown>
                  </Col>

                  <Col xs={20} md={15} align={"right"}>
                    <Row
                      align={"right"}
                      gutter={8}
                      justify={"end"}
                      className="w-100"
                    >
                      <Col xs={24} md={16} >
                        <Input.Search
                          className="searchBox"
                          placeholder="Search campaign name"
                          prefix={<img src={SearchIcon} alt="search" />}
                          onChange={(e) => setSearch(e?.target?.value)}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              )}
              rowKey={"_id"}
            />
            <CustomPagination
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              total={data?.totalCampaign}
              itemPerPage={10}
            />
          </div>
        </div>
        {/* ) : (
          <NoElementBox
            title="No archived campaigns yet!"
            // description="Click below on the “Create advert button” to create an advert."
            // btnText="+ Create Advert"
            // handleClick={() => navigate(`/create-advert`, { state: data })}
          />
        )} */}

        <DeleteModal
          visible={activeDeleteModal}
          setVisible={setActiveDeleteModal}
          text={deleteType === "single" ? "Campaign" : "Campaigns"}
          handleSubmit={deleteCampaign}
          loading={deleteLoading}
        />
      </div>
    </SubLayout>
  );
}

export default CampaignManagement;
