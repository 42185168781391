import { Button, Col, Modal, Row } from "antd";

const UsersExhaust = ({ userExhaustModal, setUserExhaustModal }) => {
  const handleOk = () => {
    setUserExhaustModal(false);
  };
  const handleCancel = () => {
    setUserExhaustModal(false);
  };
  return (
    <Modal
      title="Warning!"
      centered
      className="userExhaustModalMainDiv"
      open={userExhaustModal}
      onCancel={handleCancel}
      footer={[
        <Row>
          <Col span={24} align={"right"} className="justify_items_end">
            <Button
              key="submit"
              className="black_color_button"
              onClick={handleOk}
            >
              Done
            </Button>
            ,
          </Col>
        </Row>,
      ]}
    >
      <p className="desc">
        You don’t have any available user licence’s for this action. Please
        purchase an additional licence to activate this profile.
      </p>
    </Modal>
  );
};
export default UsersExhaust;
