import { Button, Col, Modal, Row, Spin, Tooltip, message } from "antd";
import SubLayout from "../../layout/SubLayout";
import { CheckOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import API_MANAGER from "../../../API";
import RightIcon from "../../../Assets/rightIcon.svg";
import WarningIcon from "../../../Assets/campagin/warning.svg";

import { useNavigate } from "react-router-dom";
const SwitchAccount = ({ switchAccountModal, setSwitchAccountModal }) => {
  const [selectedAccount, setSelectedAccount] = useState({});
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const handleOk = () => {
    const tempClientId = localStorage.getItem("clientId");
    if (selectedAccount?.recruiterId?._id !== tempClientId) {
      localStorage.setItem("clientId", selectedAccount?.recruiterId?._id);
      localStorage.setItem(
        "permissions",
        JSON.stringify(selectedAccount?.permissions)
      );
      if (
        selectedAccount?.recruiterId?.userId?.subscription?.[0]?.sp
          ?.plusSubscription
      ) {
        localStorage.setItem(
          "isPlusSubscription",
          selectedAccount?.recruiterId?.userId?.subscription?.[0]?.sp
            ?.plusSubscription
        );
      } else {
        localStorage.setItem("isPlusSubscription", false);
      }
      if (
        selectedAccount?.recruiterId?.userId?.subscription?.length > 0 &&
        selectedAccount?.recruiterId?.userId?.subscription
      ) {
        localStorage.setItem(
          "subscription",
          JSON.stringify(selectedAccount?.recruiterId?.userId?.subscription[0])
        );
      } else {
        localStorage.setItem("subscription", JSON.stringify({}));
      }
      const userType = selectedAccount?.recruiterUserType
        ? selectedAccount?.recruiterUserType !== "Standard"
          ? selectedAccount?.recruiterUserType
          : "Account"
        : "Root";
      localStorage.setItem("user", userType);
      if (selectedAccount?.recruiterUserType === "Standard") {
        if (selectedAccount?.permissions?.includes(0)) {
          navigate(`/campaign-management`);
        } else if (selectedAccount?.permissions?.includes(3)) {
          navigate(`/user-management`);
        }
      } else if (selectedAccount?.recruiterUserType === "Associate") {
        navigate(`/job-advert`);
      } else if (
        selectedAccount?.recruiterUserType === " " ||
        selectedAccount?.recruiterUserType === "Admin" ||
        selectedAccount
      ) {
        navigate(`/campaign-management`);
      }
      window?.location?.reload();
    }
    setSwitchAccountModal(false);
  };
  const handleCancel = () => {
    setSwitchAccountModal(false);
  };
  const handleClick = (item) => {
    if (item?.hasAcceptedInvite || item?.isOwner) {
      setSelectedAccount(item);
    }
  };

  const getData = async () => {
    try {
      setLoading(true);
      setSelectedAccount({});
      const response = await API_MANAGER.getSwitchUserList();
      setData(response?.data?.data);
      const clientId = localStorage.getItem("clientId");
      response?.data?.data?.map((a) => {
        if (a?.recruiterId?._id === clientId) {
          setSelectedAccount(a);
        }
      });
      setLoading(false);
    } catch {
      message.error("Something went wrong. Please try again.");
    }
  };
  useEffect(() => {
    if (switchAccountModal) {
      getData();
    }
  }, [switchAccountModal]);

  return (
    <div>
      <Modal
        centered
        title="Switch Account?"
        className="switchAccountModal"
        open={switchAccountModal}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Row>
            <Col span={24} align={"right"} className="justify_items_end">
              <Button
                key="submit"
                className="black_color_button"
                onClick={handleOk}
              >
                Switch
              </Button>
              <Button
                key="submit"
                className="cancel_btn"
                onClick={handleCancel}
              >
                Cancel
              </Button>
              ,
            </Col>
          </Row>,
        ]}
      >
        <p className="desc">
          Are you sure you want to{" "}
          <span className="highlighted_text">switch account</span>? Please
          select the company.
        </p>
        <Spin spinning={loading} />
        {/* Users Section */}
        <Row className="usersContentSection">
          {data?.map((item) => {
            return (
              <Col
                span={24}
                className={
                  item?.recruiterId?._id === selectedAccount?.recruiterId?._id
                    ? "items_col justify_space active cursor_pointer"
                    : "items_col justify_space cursor_pointer"
                }
                onClick={() => {
                  handleClick(item);
                }}
              >
                {item?.recruiterId?.companyName}
                {item?.recruiterId?._id ===
                  selectedAccount?.recruiterId?._id && (
                  <img src={RightIcon} alt="img" />
                )}
                {!item?.hasAcceptedInvite && !item?.isOwner && (
                  <Tooltip title={"Invite not accepted."}>
                    <img src={WarningIcon} alt="img" />
                  </Tooltip>
                )}
              </Col>
            );
          })}
        </Row>
      </Modal>
    </div>
  );
};
export default SwitchAccount;
