import React, { useState, useEffect } from "react";
import { Row, Col, Input, Checkbox, message, Button } from "antd";
import DownArrow from "../../../Assets/downArrowIcon.svg";
import CancelIcon from "../../../Assets/cancelIcon.svg";

import API_MANAGER from "../../../API";
import { ReloadOutlined } from "@ant-design/icons";
const CheckboxGroup = Checkbox.Group;

function SearchListItem({ item, setFilters, filters }) {
  const [expand, setExpand] = useState(false);
  const [data, setData] = useState([]);
  const [search, setSearch] = useState(null);
  const [selectedListCount, setSelectedListCount] = useState(0);
  const [selectedList, setSelectedList] = useState(0);
  const getData = async () => {
    try {
      if (item?.value === "CITIZENSHIP") {
        setData(item?.options);
      } else {
        const params = {
          limit: 20000,
          sort: 'name',
          type: item?.value,
          search: search,
        };
        const response = await API_MANAGER.getTagsBySearch(params);
        if (item?.value === "STATE" || item?.value === "GENDER") {
          const tempData2 = response?.data?.data?.tags?.map((e) => {
            return {
              label: e?.name,
              value: e?.name,
            };
          });

          setData(tempData2);
        } else {
          const tempData = response?.data?.data?.tags?.map((e) => {
            return {
              label: e?.name,
              value: e?._id,
              // checked: false,
            };
          });

          setData(tempData);
        }
      }
    } catch (error) {
      message.error("Something went wrong. Please try again.");
    }
  };
  useEffect(() => {
    getData();
  }, []);
  const handleChange = (e) => {
    let tempFilter = [];
    if (e?.target?.checked) {
      tempFilter = filters[item?.label]?.split(",") ?? [];
      tempFilter = [...tempFilter, e?.target?.value];
      setSelectedListCount(tempFilter?.length);
      tempFilter = tempFilter.join(",");
    } else {
      tempFilter = filters[item?.label]?.split(",") ?? [];
      tempFilter = tempFilter?.filter((value) => value !== e?.target?.value);
      setSelectedListCount(tempFilter?.length);

      tempFilter = tempFilter.join(",");
    }

    setFilters({
      ...filters,
      [item?.label]: tempFilter?.length > 0 ? tempFilter : null,
    });
    // default - true data
    // search -  false data
    // uncheck -  1 . default  - true data
    // 2. search - false data
    // search change

    //assumption - e always false , prev state always true
  };

  return (
    <div className="searchListItem">
      <Row
        justify={"space-between"}
        align={"middle"}
        className="cursor-pointer"
      >
        <Col xs={19} className="title" onClick={() => setExpand(!expand)}>
          {item?.name} {selectedListCount > 0 ? ` (${selectedListCount})` : ""}
        </Col>
        {expand && (
          <Col
            xs={1}
            className="resetButton"
            onClick={() => {
              setFilters({ ...filters, [item?.label]: null });
              setSelectedListCount(0);
            }}
          >
            <img src={CancelIcon} />
          </Col>
        )}
        <Col>
          <img
            className="cursor-pointer"
            onClick={() => setExpand(!expand)}
            src={DownArrow}
            alt="down"
          />
        </Col>
      </Row>
      {/* {expand && ( */}
      <div style={{ display: expand ? "block" : "none" }}>
        {item?.value !== "CITIZENSHIP" && (
          <Row>
            <Col xs={24}>
              <Input
                className="search-input"
                size="large"
                value={search}
                placeholder={`Search ${item?.name}`}
                onChange={(e) => setSearch(e?.target?.value)}
                suffix={
                  search && (
                    <img
                      src={CancelIcon}
                      className="cursor_pointer"
                      alt="cross"
                      onClick={() => {
                        setSearch(null);
                      }}
                    />
                  )
                }
                prefix={
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="21"
                    height="20"
                    viewBox="0 0 21 20"
                    fill="none"
                  >
                    <path
                      d="M19.8947 19.0002L15.5517 14.6572M15.5517 14.6572C16.2946 13.9143 16.8839 13.0324 17.286 12.0618C17.688 11.0911 17.8949 10.0508 17.8949 9.00021C17.8949 7.9496 17.688 6.90929 17.286 5.93866C16.8839 4.96803 16.2946 4.08609 15.5517 3.34321C14.8088 2.60032 13.9269 2.01103 12.9563 1.60898C11.9857 1.20693 10.9453 1 9.89474 1C8.84414 1 7.80382 1.20693 6.83319 1.60898C5.86256 2.01103 4.98062 2.60032 4.23774 3.34321C2.73741 4.84354 1.89453 6.87842 1.89453 9.00021C1.89453 11.122 2.73741 13.1569 4.23774 14.6572C5.73807 16.1575 7.77295 17.0004 9.89474 17.0004C12.0165 17.0004 14.0514 16.1575 15.5517 14.6572Z"
                      stroke="#1638B1"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                }
              />
            </Col>
          </Row>
        )}

        <div className="checkBoxContainer">
          {/* <CheckboxGroup
              value={filters[item?.label]?.split(",")}
              options={data}
              className="searchCheckBoxGroup"
              onChange={(e) => handleChange(e)}
            /> */}

          {data?.map((e) => (
            <div
              className="searchListItems"
              style={{
                display: !search
                  ? "block"
                  : e?.label?.toLowerCase().includes(search?.toLowerCase())
                  ? "block"
                  : "none",
              }}
            >
              <Checkbox
                hidden
                value={e?.value}
                checked={(filters[item?.label]?.split(",") ?? [])?.includes(
                  e?.value
                )}
                onChange={(e) => {
                  handleChange(e);
                }}
                className="checkBoxItem"
              >
                {e?.label}
              </Checkbox>
            </div>
          ))}
        </div>
      </div>
      {/* )} */}
    </div>
  );
}

export default SearchListItem;
