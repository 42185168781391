import { Avatar, Button, Col, Input, Modal, Row, message } from "antd";
import React, { useEffect, useState } from "react";
import InviteCampaignDropdown from "./InviteCampaignDropdown";
import API_MANAGER from "../../../API";
import { useNavigate } from "react-router-dom";
import { UserOutlined } from "@ant-design/icons";

const ProfileCards = ({
  data,
  filters,
  search,
  page,
  // campaignList,
  // setCampaignSearch,
  // campaignSearch,
  GetGlobalSearch,
}) => {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [inviteIds, setInviteIds] = useState({});
  const [inviteLoading, setInviteLoading] = useState(false);
  const [campaignList, setCampaignList] = useState([]);
  const [campaignSearch, setCampaignSearch] = useState(null);
  const [inviteStatus, setInvitStatus] = useState(false);
  const showModal = () => {
    singleUserCampaignDetails();
    setIsModalOpen(true);
  };
  const singleUserCampaignDetails = async () => {
    const param = {
      profileId: data?._id,
      search: campaignSearch ? campaignSearch : "",
      sort: "-createdAt",
      limit: 20000,
      page: 1,
    };
    try {
      const response = await API_MANAGER.getSingleUserCampaignDetails(
        param,
        localStorage.getItem("userID")
      );
      setCampaignList(response?.data?.data);
    } catch (error) {
      message.error("Something went wrong. Please try again.");
    }
  };
  useEffect(() => {
    singleUserCampaignDetails();
  }, [campaignSearch]);
  const handleOk = async () => {
    setInviteLoading(true);
    let tempInvites = Object.entries(inviteIds);
    let adverts = [];
    tempInvites?.map((item) => {
      item[1]?.map((e) => {
        adverts.push({
          advertId: e,
          campaignId: item[0],
        });
      });
    });
    const params = {
      adverts: adverts,
      profileId: data?._id,
    };

    try {
      const response = await API_MANAGER.inviteUsers(params);
      setInviteLoading(false);
      message.success("Successfully invited.");
      setInvitStatus(true);
      setIsModalOpen(false);
      GetGlobalSearch();
    } catch (error) {
      setInviteLoading(false);

      message.error(
        error?.response?.data?.message?.message ||
          "Something went wrong. Please try again."
      );
    }
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  return (
    <>
      <div
        className="profileCardContainer"
        onClick={(event) => {
          if (event?.target?.tagName === "DIV") {
            navigate(`/applicant-profile/${data?.userId}`, {
              state: {
                isInvited: data?.isInvited,
                search: true,
                name: data?.name,
                filter: filters,
                searchText: search,
                page: page,
                isBackFunc: true,
              },
            });
          }
        }}
      >
        <div className="profileCardWeb">
          <Row span={24} className="profile-cards mb-20 w-100">
            <Row className="w-100" justify={"space-between"}>
              <Col style={{ width: "calc(100% - 95px)" }}>
                <Row gutter={12}>
                  <Col>
                    {data?.profileImage ? (
                      <Avatar
                        src={data?.profileImage}
                        size={50}
                        className="text_blur"
                      />
                    ) : (
                      <Avatar
                        icon={<UserOutlined />}
                        src={data?.profileImage}
                        size={50}
                      />
                    )}
                  </Col>
                  <Col style={{ maxWidth: "calc(100% - 90px)" }}>
                    <h4
                      className="h4 cursor-pointer"
                      onClick={() =>
                        navigate(`/applicant-profile/${data?.userId}`, {
                          state: {
                            isInvited: data?.isInvited,
                            search: true,
                            name: data?.name,
                            filter: filters,
                            searchText: search,
                            page: page,
                          },
                        })
                      }
                    >
                      {data?.name}
                    </h4>
                    <Row align={""} gutter={[8, 8]} className="mt-8">
                      <Col>
                        <Row gutter={[4, 4]}>
                          <Col>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                              fill="none"
                            >
                              <path
                                d="M5.33398 14V4.66667C5.33398 4.04669 5.33398 3.7367 5.40213 3.48236C5.58707 2.79218 6.12616 2.25308 6.81635 2.06815C7.07068 2 7.38067 2 8.00065 2C8.62063 2 8.93062 2 9.18496 2.06815C9.87514 2.25308 10.4142 2.79218 10.5992 3.48236C10.6673 3.7367 10.6673 4.04669 10.6673 4.66667V14M3.46732 14H12.534C13.2807 14 13.6541 14 13.9393 13.8547C14.1902 13.7268 14.3942 13.5229 14.522 13.272C14.6673 12.9868 14.6673 12.6134 14.6673 11.8667V6.8C14.6673 6.05326 14.6673 5.6799 14.522 5.39468C14.3942 5.1438 14.1902 4.93982 13.9393 4.81199C13.6541 4.66667 13.2807 4.66667 12.534 4.66667H3.46732C2.72058 4.66667 2.34721 4.66667 2.062 4.81199C1.81111 4.93982 1.60714 5.1438 1.47931 5.39468C1.33398 5.6799 1.33398 6.05326 1.33398 6.8V11.8667C1.33398 12.6134 1.33398 12.9868 1.47931 13.272C1.60714 13.5229 1.81111 13.7268 2.062 13.8547C2.34721 14 2.72058 14 3.46732 14Z"
                                stroke="#071135"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                          </Col>
                          <Col className="body-text-b3">{data?.jobTitle}</Col>
                        </Row>
                      </Col>
                      <Col>
                        <Row gutter={[4, 4]}>
                          <Col>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                              fill="none"
                            >
                              <path
                                d="M7.99935 8.33203C9.10392 8.33203 9.99935 7.4366 9.99935 6.33203C9.99935 5.22746 9.10392 4.33203 7.99935 4.33203C6.89478 4.33203 5.99935 5.22746 5.99935 6.33203C5.99935 7.4366 6.89478 8.33203 7.99935 8.33203Z"
                                stroke="#091647"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M7.99935 14.6654C9.33268 11.9987 13.3327 10.2776 13.3327 6.66536C13.3327 3.71985 10.9449 1.33203 7.99935 1.33203C5.05383 1.33203 2.66602 3.71985 2.66602 6.66536C2.66602 10.2776 6.66602 11.9987 7.99935 14.6654Z"
                                stroke="#091647"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                          </Col>
                          <Col className="body-text-b3">
                            {data?.city +
                              ", " +
                              data?.state +
                              ", " +
                              data?.country +
                              ", " +
                              data?.postalCode}
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>

              <Col>
                <Button
                  onClick={showModal}
                  type="primary"
                  className="btn-invite"
                  ghost
                >
                  {inviteStatus ? "Invited" : "Invite"}
                </Button>
              </Col>
            </Row>
            <Row className="w-100 mt-16">
              <Col span={24}>
                <p className="body-text-b3">
                  <span className="body-text-bold-b4">Industries &nbsp;</span>
                  {data?.industries?.length > 0
                    ? data?.industries?.join(", ")
                    : ""}
                </p>
              </Col>
            </Row>
            <Row className="w-100">
              <Col span={24}>
                <p className="body-text-b3">
                  <span className="body-text-bold-b4">
                    Qualifications &nbsp;
                  </span>
                  {data?.qualificationTypes?.length > 0
                    ? data?.qualificationTypes?.join(", ")
                    : ""}
                </p>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <p className="profile-para">{data?.profileSummary}</p>
              </Col>
            </Row>
          </Row>
        </div>
        <div className="profileCardMobile">
          <Row gutter={12} align={"middle"} className="w-100">
            <Col>
              <Avatar src={data?.profileImage} size={38} />
            </Col>
            <Col style={{ width: "calc(100% - 50px)" }}>
              <h4 className="h4">{data?.name}</h4>
            </Col>
          </Row>
          <Row justify={"space-between"} gutter={[8, 8]} className="mt-16">
            <Col xs={12}>
              <Row gutter={[4, 4]}>
                <Col>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                  >
                    <path
                      d="M5.33398 14V4.66667C5.33398 4.04669 5.33398 3.7367 5.40213 3.48236C5.58707 2.79218 6.12616 2.25308 6.81635 2.06815C7.07068 2 7.38067 2 8.00065 2C8.62063 2 8.93062 2 9.18496 2.06815C9.87514 2.25308 10.4142 2.79218 10.5992 3.48236C10.6673 3.7367 10.6673 4.04669 10.6673 4.66667V14M3.46732 14H12.534C13.2807 14 13.6541 14 13.9393 13.8547C14.1902 13.7268 14.3942 13.5229 14.522 13.272C14.6673 12.9868 14.6673 12.6134 14.6673 11.8667V6.8C14.6673 6.05326 14.6673 5.6799 14.522 5.39468C14.3942 5.1438 14.1902 4.93982 13.9393 4.81199C13.6541 4.66667 13.2807 4.66667 12.534 4.66667H3.46732C2.72058 4.66667 2.34721 4.66667 2.062 4.81199C1.81111 4.93982 1.60714 5.1438 1.47931 5.39468C1.33398 5.6799 1.33398 6.05326 1.33398 6.8V11.8667C1.33398 12.6134 1.33398 12.9868 1.47931 13.272C1.60714 13.5229 1.81111 13.7268 2.062 13.8547C2.34721 14 2.72058 14 3.46732 14Z"
                      stroke="#071135"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </Col>
                <Col
                  style={{ maxWidth: "calc(100% - 22px)" }}
                  className="value"
                >
                  {data?.jobTitle}
                </Col>
              </Row>
            </Col>
            <Col xs={12}>
              <Row gutter={[4, 4]} justify={"end"}>
                <Col>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                  >
                    <path
                      d="M7.99935 8.33203C9.10392 8.33203 9.99935 7.4366 9.99935 6.33203C9.99935 5.22746 9.10392 4.33203 7.99935 4.33203C6.89478 4.33203 5.99935 5.22746 5.99935 6.33203C5.99935 7.4366 6.89478 8.33203 7.99935 8.33203Z"
                      stroke="#091647"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M7.99935 14.6654C9.33268 11.9987 13.3327 10.2776 13.3327 6.66536C13.3327 3.71985 10.9449 1.33203 7.99935 1.33203C5.05383 1.33203 2.66602 3.71985 2.66602 6.66536C2.66602 10.2776 6.66602 11.9987 7.99935 14.6654Z"
                      stroke="#091647"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </Col>
                <Col
                  style={{ maxWidth: "calc(100% - 22px)" }}
                  className="value"
                >
                  {data?.city + ", " + data?.country + ", " + data?.postalCode}
                </Col>
              </Row>
            </Col>
          </Row>
          <div className="w-100">
            <div>
              <p className="item">Industries</p>
              <p className="value">
                {data?.industries?.length > 0
                  ? data?.industries?.join(", ")
                  : ""}
              </p>
            </div>
            <div>
              <p className="item">Qualifications</p>
              <p className="value">
                {data?.qualificationTypes?.length > 0
                  ? data?.qualificationTypes?.join(", ")
                  : ""}
              </p>
            </div>
            <Row className="w-100">
              <Col span={24}>
                <Button
                  onClick={showModal}
                  type="primary"
                  className="btn-invite w-100"
                  ghost
                >
                  {inviteStatus ? "Invited" : "Invite"}
                </Button>
              </Col>
            </Row>
          </div>
        </div>
      </div>
      {/* Modal it will open on invite button click*/}
      <Modal
        title={`Invite ${data?.name} to:`}
        open={isModalOpen}
        onCancel={handleCancel}
        centered
        closable={true}
        footer={[
          <Row>
            <Col span={24} align={"right"} className="justify_items_end">
              <Button
                key="submit"
                className="black_color_button"
                onClick={handleOk}
                loading={inviteLoading}
                // disabled={Object.values(inviteIds)?.join(",")?.length < 2}
              >
                {inviteStatus ? "Invited" : "Invite"}
              </Button>
              <Button
                key="submit"
                className="cancel_btn"
                onClick={handleCancel}
              >
                Cancel
              </Button>
              ,
            </Col>
          </Row>,
        ]}
      >
        <Input
          className="search-input mb-20"
          size="large"
          placeholder="Select or search adverts"
          value={campaignSearch}
          onChange={(e) => setCampaignSearch(e?.target?.value)}
          prefix={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="21"
              height="20"
              viewBox="0 0 21 20"
              fill="none"
            >
              <path
                d="M19.8947 19.0002L15.5517 14.6572M15.5517 14.6572C16.2946 13.9143 16.8839 13.0324 17.286 12.0618C17.688 11.0911 17.8949 10.0508 17.8949 9.00021C17.8949 7.9496 17.688 6.90929 17.286 5.93866C16.8839 4.96803 16.2946 4.08609 15.5517 3.34321C14.8088 2.60032 13.9269 2.01103 12.9563 1.60898C11.9857 1.20693 10.9453 1 9.89474 1C8.84414 1 7.80382 1.20693 6.83319 1.60898C5.86256 2.01103 4.98062 2.60032 4.23774 3.34321C2.73741 4.84354 1.89453 6.87842 1.89453 9.00021C1.89453 11.122 2.73741 13.1569 4.23774 14.6572C5.73807 16.1575 7.77295 17.0004 9.89474 17.0004C12.0165 17.0004 14.0514 16.1575 15.5517 14.6572Z"
                stroke="#1638B1"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          }
        />
        <div className="campaignListContainer">
          {campaignList?.map((item, index) => (
            <InviteCampaignDropdown
              setInviteIds={setInviteIds}
              inviteIds={inviteIds}
              item={item}
              key={index}
            />
          ))}
        </div>
      </Modal>
    </>
  );
};

export default ProfileCards;
