import React from "react";
import { Row, Col, Avatar, Dropdown, Switch } from "antd";
import NotificationIcon from "../../Assets/layout/notification.svg";
import SettingIcon from "../../Assets/layout/settings.svg";
import { UserOutlined, SettingOutlined, MenuOutlined } from "@ant-design/icons";
import Logo from "../../Assets/login/Logo.svg";

import LeftArrow from "../../Assets/layout/leftArrow.svg";
import { useNavigate } from "react-router-dom";
function NonProtectedLayout({ page, whiteBg, children, back }) {
  const navigate = useNavigate();

  return (
    <div className="nonProtectedLayoutContainer">
      <div className="main_header">
        <Row align={"middle"} className="navbar" gutter={92}>
          <Col>
            <div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <img
                  src={Logo}
                  alt="menu"
                  className="cursor-pointer logo-svg"
                  onClick={() => navigate("/")}
                />
              </div>
            </div>
          </Col>
          <Col className="dp-none-sm">
            <Row
              align={"middle"}
              onClick={() => navigate(-1)}
              className="cursor-pointer"
            >
              {back ? (
                ""
              ) : (
                <>
                  <Col>
                    <img src={LeftArrow} />
                  </Col>
                  <Col className="pageTitle">Back</Col>
                </>
              )}
            </Row>
          </Col>
        </Row>
      </div>

      <Col className="">
        <div className="nonProtectedContentContainer">{children}</div>
      </Col>
    </div>
  );
}

export default NonProtectedLayout;
