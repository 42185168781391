import { Col, Dropdown, Input, Radio, Row, Table, message } from "antd";
import SubLayout from "../../components/layout/SubLayout";
// import { jobAdvertsMockData } from "../../utils/constant";
import SearchIcon from "../../Assets/campagin/searchIcon.svg";
import FilterIcon from "../../Assets/filter.svg";
import { useEffect, useState } from "react";
import API_MANAGER from "../../API";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import FullScreenLoader from "../../components/common/FullScreenLoader";
import CancelIcon from "../../Assets/cancelIcon.svg";
import CustomPagination from "../../components/common/CustomPagination";

const JobAdvert = () => {
  const [data, setData] = useState([]);
  const [search, setSearch] = useState(null);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [sortType, setSortType] = useState("-createdAt");
  const navigate = useNavigate();
  const items = [
    {
      key: "1",
      label: (
        <Radio.Group
          value={sortType}
          onChange={(e) => setSortType(e?.target?.value)}
          className="dropDown_radio_group"
        >
          <Row className="filter_item_row">
            <Col span={24}>
              <Radio value={"-createdAt"}>Latest First</Radio>
            </Col>
          </Row>
          <Row className="filter_item_row">
            <Col span={24}>
              <Radio value={"createdAt"}>Oldest First</Radio>
            </Col>
          </Row>
          <Row className="filter_item_row">
            <Col span={24}>
              <Radio value={"jobTitle"}>Sort by A-Z</Radio>
            </Col>
          </Row>
          <Row className="filter_item_row">
            <Col span={24}>
              <Radio value={"-jobTitle"}>Sort by Z-A</Radio>
            </Col>
          </Row>
        </Radio.Group>
      ),
    },
  ];
  const columns = [
    {
      title: (
        <Row justify={"space-between"} align={"middle"}>
          <Col className="gray_text">Company Name</Col>
          <Col>
            <Dropdown
              menu={{ items }}
              overlayClassName="notificationFilterDropdown"
            >
              <img src={FilterIcon} alt="filter" />
            </Dropdown>
          </Col>
        </Row>
      ),
      width: "200px",
      dataIndex: "company",
      render: (item, row) => {
        return (
          <span className="cursor-pointer">
            {item?.charAt(0)?.toUpperCase() + item?.substring(1)}
          </span>
        );
      },
    },
    {
      title: (
        <div>
          <span className="gray_text">Campaign Name</span>
        </div>
      ),
      dataIndex: "campaign",
      align: "center",
      className: "cursor-pointer",
      render: (item, row) => {
        return (
          <span>{item?.charAt(0)?.toUpperCase() + item?.substring(1)}</span>
        );
      },
    },
    {
      title: (
        <div>
          <span className="gray_text">Job Advert</span>
        </div>
      ),
      dataIndex: "jobTitle",
      align: "center",
      className: "cursor-pointer",
      render: (item, row) => {
        return <span className="cursor-pointer">{item}</span>;
      },
    },
    {
      title: (
        <div>
          <span className="gray_text">Start Date</span>
        </div>
      ),
      dataIndex: "startDate",
      align: "center",
      className: "cursor-pointer",
      render: (item, row) => {
        return (
          <span className="cursor-pointer">
            {moment(item).format("DD/MM/YYYY")}
          </span>
        );
      },
    },
    {
      title: (
        <div>
          <span className="gray_text">End Date</span>
        </div>
      ),
      dataIndex: "endDate",
      align: "center",
      className: "cursor-pointer",
      render: (item, row) => {
        return (
          <span className="cursor-pointer">
            {moment(item).format("DD/MM/YYYY")}
          </span>
        );
      },
    },
  ];
  const getData = async () => {
    setLoading(true);
    const param = {
      id: localStorage.getItem("userID"),
    };
    try {
      const response = await API_MANAGER.getAdvertsAssociate({
        param,
        search,
        page,
        sortType,
      });
      setData(response?.data?.data);
    } catch (error) {
      message.error(error?.response?.data?.message?.message);
    }
    setLoading(false);
  };
  useEffect(() => {
    getData();
  }, [search, sortType, page]);
  return (
    <div className="jobAdvertMainDiv">
      <SubLayout whiteBg showSearch>
        {/* <FullScreenLoader isLoading={loading} /> */}
        <div className="jobAdvertTableMainDiv">
          <Table
            columns={columns}
            dataSource={data?.result}
            loading={loading}
            onRow={(record, rowIndex) => {
              return {
                onClick: (event) => {
                  navigate(`/advert-detail/${record?.id}`);
                }, // click row
              };
            }}
            title={() => (
              <Row>
                <Col xs={24} md={12}>
                  <Input.Search
                    className="searchBox"
                    placeholder="Search campaign or adverts"
                    prefix={<img src={SearchIcon} alt="search" />}
                    onChange={(e) => setSearch(e?.target?.value)}
                    value={search}
                    suffix={
                      search && (
                        <img
                          src={CancelIcon}
                          className="cursor_pointer"
                          alt="cross"
                          onClick={() => {
                            setSearch(null);
                          }}
                        />
                      )
                    }
                  />
                </Col>
              </Row>
            )}
            pagination={false}
          />
          <CustomPagination
            currentPage={page}
            setCurrentPage={setPage}
            total={data?.totalRecord}
            itemPerPage={10}
          />
        </div>
      </SubLayout>
    </div>
  );
};
export default JobAdvert;
