import { Button, Col, Modal, Row } from "antd";
import { useNavigate } from "react-router-dom";

const UpgradeMembershipModal = ({
  setUpgradeMembership,
  upgradeMembership,
  textContent,
}) => {
  const navigate = useNavigate();
  const user = localStorage.getItem("user");
  const handleOkayButton = () => {
    setUpgradeMembership(false);
    navigate("/subscription-management");
  };
  const handleCancel = () => {
    setUpgradeMembership(false);
  };
  const text =
    textContent ||
    "Your current subscription does not support this feature. Please upgrade, if you want to gain access!";
  return (
    <Modal
      centered
      title="Upgrade Subscription"
      open={upgradeMembership}
      closeIcon={true}
      onCancel={handleCancel}
      className="upgradeMembershipModal"
      footer={
        <Row gutter={16} className="justify-end d-flex">
          <Col>
            {user === "Root" || user === "Admin" ? (
              <Button onClick={handleOkayButton} className="black_color_button">
                Upgrade
              </Button>
            ) : (
              <Button className="black_color_button">Okay</Button>
            )}
          </Col>
        </Row>
      }
    >
      <Row>
        <Col span={24}>
          <span className="modal_content">{text}</span>
        </Col>
      </Row>
    </Modal>
  );
};
export default UpgradeMembershipModal;
