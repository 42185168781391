import { Button, Col, Row, Table, Tooltip, Input, Form, message } from "antd";
import SubLayout from "../../components/layout/SubLayout";
import infoIcon from "../../Assets/user_mangement/information.svg";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import EditWithoutBg from "../../Assets/edit_without_bg.svg";
import editIcon from "../../Assets/user_mangement/edit.svg";
import SearchIcon from "../../Assets/search_icon.svg";
import DeleteIcon from "../../Assets/user_mangement/delete_icon_box.svg";
import SelectCampaigns from "./selectCampaigns";
import { useEffect, useState } from "react";
import DeleteUserModal from "./DeleteUserModal";
import API_MANAGER from "../../API";
import FullScreenLoader from "../../components/common/FullScreenLoader";
import { checkPermissions } from "../../utils";
import moment from "moment";
import CustomPagination from "../../components/common/CustomPagination";

function UserProfile() {
  const location = useLocation();
  const [data, setData] = useState();
  const [singleUserData, setSingleUserData] = useState();
  const [campaignList, setCampaignList] = useState();
  const [selectCampaignsModal, setSelectedCampaignsModal] = useState(false);
  const [editCampaignData, setEditCampaignData] = useState();
  const [campaignSearch, setCampaignSearch] = useState(null);
  const [search, setSearch] = useState(null);
  const [alreadySelected, setAlreadySelected] = useState(null);
  const [permissionsText, setPermissionsText] = useState();
  const [page, setPage] = useState(1);
  const [form] = Form.useForm();
  const userData = location.state;
  const navigate = useNavigate();
  const mainProfileData = location?.state ? location?.state?.mainProfile : "";
  //useEffect for storing data for main user edit form data
  const [myProfileData, setMyProfileData] = useState();
  const [showEdit, setShowEdit] = useState(false);
  const [deleteUser, setDeleteUser] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dataUpdated, setDataUpdated] = useState(false);
  const { id } = useParams();
  const date = new Date();

  //USER DETAILS CARD API
  const SingleUserData = async () => {
    setLoading(true);
    try {
      const response = await API_MANAGER.getSingleUserData(id);
      setAlreadySelected(response?.data?.data?.campaign);
      setSingleUserData(response?.data?.data);
      setLoading(false);
    } catch (error) {
      message.error("Something went wrong. Please try again.");
    }
  };
  useEffect(() => {
    const textData = checkPermissions(singleUserData?.permissions, userType);
    setPermissionsText(textData);
  }, [singleUserData]);
  //USERS CAMPAIGNS/ADVERTS LISTING API
  const singleUserCampaignDetails = async () => {
    const param = {
      id: id,
    };
    try {
      const response = await API_MANAGER.getSingleUserAdvertList(
        param,
        page,
        search
      );
      setCampaignList(response?.data?.data);
    } catch (error) {
      message.error("Something went wrong. Please try again.");
    }
  };
  //EDIT CAMPAIGNS API
  const getUserEditCampaignData = async () => {
    const param = {
      search: campaignSearch ? campaignSearch : "",
      sort: "-createdAt",
      limit: 20000,
      page: 1,
    };
    try {
      const response = await API_MANAGER.getCampaignList(param);
      setEditCampaignData(response?.data?.data?.result);
    } catch (error) {
      message.error("Something went wrong. Please try again.");
    }
  };
  useEffect(() => {
    SingleUserData();
  }, [dataUpdated]);
  useEffect(() => {
    singleUserCampaignDetails();
  }, [dataUpdated, search, page]);
  useEffect(() => {
    let id;
    if (id) {
      clearTimeout(id);
    }
    id = setTimeout(() => {
      getUserEditCampaignData();
    }, 500);
    return () => {
      clearTimeout(id);
    };
  }, [campaignSearch]);
  useEffect(() => {
    if (userData) {
      setData(userData);
    }
  }, [userData]);
  useEffect(() => {
    if (mainProfileData) {
      setMyProfileData(mainProfileData[0]);
      setData(mainProfileData[0]);
      form.setFieldsValue({ first_name: mainProfileData[0]?.first_name });
      form.setFieldsValue({ last_name: mainProfileData[0]?.last_name });
      form.setFieldsValue({ email: mainProfileData[0]?.email });
      form.setFieldsValue({ phone: mainProfileData[0]?.contact_number });
    }
  }, [mainProfileData]);
  const columns = [
    {
      title: (
        <div>
          <span className="gray_text">Campaign Name</span>
        </div>
      ),
      dataIndex: "campaign",
      align: "left",
      render: (item) => <span className="text_capitalize">{item}</span>,
    },
    {
      title: <div className="gray_text">Job Advert</div>,
      dataIndex: "jobTitle",
      align: "center",
    },
    {
      title: <div className="gray_text">Start Date</div>,
      dataIndex: "startDate",
      align: "center",
      render: (item) => <span>{moment(item)?.format("DD MMM YYYY")}</span>,
    },
    {
      title: <div className="gray_text">End Date</div>,
      dataIndex: "endDate",
      align: "center",
      render: (item) => <span>{moment(item)?.format("DD MMM YYYY")}</span>,
    },
    {
      title: <div className="gray_text">Status</div>,
      dataIndex: "archive",
      render: (item, row) => {
        if (
          row?.isActive &&
          !item &&
          moment(row?.startDate) <= date &&
          moment(row?.endDate) >= date
        ) {
          return <span className="Live_text">Live</span>;
        } else {
          return <span className="archived_text">Archived</span>;
        }
      },
      align: "center",
    },
  ];
  const handleEditCampaign = () => {
    setSelectedCampaignsModal(true);
  };
  const handleEditUser = () => {
    if (data.permission_type === "main_admin") {
      setShowEdit(true);
    } else
      navigate(`/user-management/edit-profile/${id}`, {
        state: singleUserData,
      });
  };
  const handleDeleteUser = () => {
    setDeleteUser(true);
  };
  const userType =
    singleUserData?.recruiterUserType !== null
      ? singleUserData?.recruiterUserType === "Standard"
        ? "Account"
        : singleUserData?.recruiterUserType
      : "Admin";
  return (
    <div className="user_profile_main_div">
      <SubLayout page={"User Profile"} whiteBg showBack showSearch>
        <FullScreenLoader isLoading={loading} />
        <DeleteUserModal
          data={singleUserData}
          setDeleteModal={setDeleteUser}
          deleteModal={deleteUser}
        />
        <SelectCampaigns
          selectCampaignsModal={selectCampaignsModal}
          setSelectedCampaignsModal={setSelectedCampaignsModal}
          campaignList={editCampaignData}
          setSearch={setCampaignSearch}
          alreadySelected={alreadySelected}
          setDataUpdated={setDataUpdated}
          dataUpdated={dataUpdated}
          loading={loading}
        />
        {/* Upper Section */}
        {!showEdit && (
          <Row className="title_row">
            <Col span={24} className="title_col">
              <Row>
                <Col xs={12} md={20} className="title_name_column ">
                  <span
                    className="title text_capitalize"
                    style={{ wordBreak: "break-all" }}
                  >
                    {singleUserData?.firstName} {singleUserData?.lastName}
                  </span>
                </Col>
                <Col xs={12} md={4} className="edit_delete_buttons">
                  <img
                    onClick={handleEditUser}
                    className="cursor_pointer"
                    src={editIcon}
                  />{" "}
                  {myProfileData?.permission_type !== "main_admin" && (
                    <img
                      onClick={handleDeleteUser}
                      className="cursor_pointer ml-10"
                      src={DeleteIcon}
                    />
                  )}
                </Col>
              </Row>
              <Row gutter={12} className="user_details_row">
                <Col xs={12} md={6}>
                  <p className="user_details_title align_items_center">
                    User
                    <Tooltip
                      placement="right"
                      title={
                        <Row>
                          <Col span={24}>{data?.permission_type}</Col>
                          <Col
                            span={24}
                            className="align_items_center flex-col"
                          >
                            {permissionsText?.map((item) => {
                              return (
                                <div className="align_center_start w-100">
                                  <div className="green_dot mr-5"></div>
                                  <span>{item}</span>
                                </div>
                              );
                            })}
                          </Col>
                        </Row>
                      }
                    >
                      <img className="cursor_pointer ml-10" src={infoIcon} />
                    </Tooltip>
                  </p>
                  <p className="user_details_desc">
                    {userType === "Associate" ? "Association" : userType}
                  </p>
                </Col>
                <Col xs={12} md={6}>
                  <p className="user_details_title">Email</p>
                  <p className="user_details_desc">{singleUserData?.email}</p>
                </Col>
                <Col xs={24} md={6} className="contact_number_section">
                  <p className="user_details_title">Contact Number</p>
                  <p className="user_details_desc">
                    {singleUserData?.countryCode}
                    {singleUserData?.phoneNumber}
                  </p>
                </Col>
              </Row>
            </Col>
          </Row>
        )}
        {/* Edit Section after opening profile section of main user who has LOGGED IN */}
        {showEdit && (
          <Row className="edit_section_main_user">
            <Col xs={24}>
              <span className="name_title">{data?.name}</span>
            </Col>
            <Col xs={24} className="personal_detail_main_col mt-24">
              <Row>
                <Col xs={24} className="title_col">
                  <span>Personal Details</span>
                </Col>
                <Col xs={24} className="first_form_col">
                  <Form
                    name="basic"
                    layout="vertical"
                    form={form}
                    requiredMark={false}
                    autoComplete="off"
                  >
                    <Row justify={"space-between"}>
                      <Col xs={24} md={11}>
                        <Form.Item
                          label="First Name*"
                          name="first_name"
                          rules={[
                            {
                              required: true,
                              message: "Please input your first name!",
                            },
                          ]}
                        >
                          <Input size="large" />
                        </Form.Item>
                      </Col>
                      <Col xs={24} md={11}>
                        <Form.Item
                          label="Last Name*"
                          name="last_name"
                          rules={[
                            {
                              required: true,
                              message: "Please input your last name!",
                            },
                          ]}
                        >
                          <Input size="large" />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row justify={"space-between"}>
                      <Col xs={24} md={11}>
                        <Form.Item
                          label="Email*"
                          name="email"
                          rules={[
                            {
                              required: true,
                              message: "Please input your first name!",
                            },
                          ]}
                        >
                          <Input size="large" disabled />
                        </Form.Item>
                      </Col>
                      <Col xs={24} md={11}>
                        <Form.Item
                          label="Contact Number*"
                          name="phone"
                          rules={[
                            {
                              required: true,
                              message: "Please input your phone number!",
                            },
                          ]}
                        >
                          <Input size="large" />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form>
                </Col>
              </Row>
              <Row className="p-24">
                <Col span={24} className="justify_items_end f_col_rev">
                  <Button className="button_no_border button_no_border_sm">
                    Cancel
                  </Button>
                  <Button className="button_1 button_1_sm">Update</Button>
                </Col>
              </Row>
            </Col>
          </Row>
        )}
        {/* Lower Section */}
        <div className="table_outer_border">
          <Table
            columns={columns}
            dataSource={campaignList?.result}
            pagination={false}
            title={() => (
              <div className="table_header_div">
                <Row justify={"space-between"} className="table_header_row">
                  <Col xs={24} md={8}>
                    <Input.Search
                      className="searchBox"
                      allowClear
                      placeholder="Search campaign or job advert"
                      onChange={(e) => setSearch(e?.target?.value)}
                      prefix={<img src={SearchIcon} alt="search" />}
                    />
                  </Col>
                  <Col xs={0} md={16} align={"right"}>
                    <Button
                      className="createBtn"
                      onClick={handleEditCampaign}
                      disabled={editCampaignData?.length === 0 ? true : false}
                    >
                      <img style={{ marginRight: "3px" }} src={EditWithoutBg} />
                      Edit Campaigns
                    </Button>
                  </Col>
                </Row>
              </div>
            )}
          />
          <CustomPagination
            currentPage={page}
            setCurrentPage={setPage}
            total={campaignList?.totalRecord}
            itemPerPage={10}
          />
          <Col xs={24} md={0} align={"right"}>
            <Button
              className="createBtn createBtn-sm "
              onClick={handleEditCampaign}
              disabled={editCampaignData?.length === 0 ? true : false}
            >
              <img style={{ marginRight: "3px" }} src={EditWithoutBg} />
              Edit Campagins
            </Button>
          </Col>
        </div>
      </SubLayout>
    </div>
  );
}
export default UserProfile;
