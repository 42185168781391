import { Button, Col, Modal, Row, message } from "antd";
import API_MANAGER from "../../API";
import { useState } from "react";
import AlertState from "../../components/common/AlertState";
import FullScreenLoader from "../../components/common/FullScreenLoader";

const ResendMail = ({ data, SetResendModal, ResendModal }) => {
  const [loading, setLoading] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertState, setAlertState] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const handleOk = async () => {
    SetResendModal(false);
    setLoading(true);
    try {
      const response = await API_MANAGER.resendVerificationLink({
        id: data?.id,
        userType: "recruiter",
        clientId: localStorage.getItem('clientId'),
      });

      setLoading(false);

      message.success("Mail successfully sent.");
    } catch (error) {
      setLoading(false);
      message.error(
        error?.response?.data?.message?.message ||
          "Something went wrong. Please try again."
      );
    }
  };
  const handleCancel = () => {
    SetResendModal(false);
  };
  return (
    <>
      <AlertState
        state={alertState}
        message={alertMessage}
        alertOpen={alertOpen}
        setAlertOpen={setAlertOpen}
      />
      <FullScreenLoader isLoading={loading} />
      <Modal
        title="Mail sent!"
        centered
        open={ResendModal}
        className="deleteUserModalMainModal"
        onOk={handleOk}
        onCancel={handleCancel}
        closable={false}
        footer={[
          <Row>
            <Col
              span={24}
              align={"right"}
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <Button
                key="submit"
                className="black_color_button"
                onClick={handleOk}
              >
                Okay
              </Button>
              ,
            </Col>
          </Row>,
        ]}
      >
        <Row>
          <Col span={24}>
            <span className="desc">
              We have sent an email to{" "}
              <span>
                {data?.firstName} {data?.lastName}
              </span>{" "}
              on <span>{data?.email}</span> to complete the registration.
            </span>
          </Col>
        </Row>
      </Modal>
    </>
  );
};
export default ResendMail;
