import React, { useState } from "react";
import { Row, Col, Button, message } from "antd";

import { useLocation, useNavigate } from "react-router-dom";
import API_MANAGER from "../../API";
import OTP from "../../components/common/verifyEmailOtp/OTP";
import LoginImg from "../../Assets/login/welcome.png";
import Logo from "../../Assets/login/Logo.svg";

import RightArrow from "../../Assets/login/rightArrow.svg";
import sucess from "../../Assets/otp-verifyed-sucess.svg.svg";
import FullScreenLoader from "../../components/common/FullScreenLoader";
import Cookies from "js-cookie";
const VerifyOtp = () => {
  const [loading, setLoading] = useState(false);
  const [otpVerify, setOtpVerified] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const data = location.state;
  const email = data.email;
  const token = data.token;

  const LoginUser = async () => {
    let lsData = JSON.parse(localStorage.getItem("loginCreds"));
    try {
      setLoading(true);
      const response = await API_MANAGER.login(lsData);
      if (response) {
        // localStorage.removeItem("loginCreds");
        localStorage.setItem(
          "name",
          response?.data?.data?.user?.firstName +
            " " +
            response?.data?.data?.user?.lastName
        );
        localStorage.setItem("userEmail", response?.data?.data?.user?.email);
      }
      let expireTime = new Date().getTime() + 4 * 3600 * 1000;
      localStorage.setItem("expireTime", expireTime);
      if (
        response?.data?.data?.user?.subscription?.[0]?.sp?.[0]?.plusSubscription
      ) {
        localStorage.setItem(
          "isPlusSubscription",
          response?.data?.data?.user?.subscription?.[0]?.sp?.[0]
            ?.plusSubscription
        );
      } else {
        localStorage.setItem("isPlusSubscription", false);
      }
      localStorage.setItem("userID", response?.data?.data?.user?.id);
      localStorage.setItem("createdAt", response?.data?.data?.user?.createdAt);
      localStorage.setItem(
        "user",
        response?.data?.data?.user?.recruiterUserType
          ? response?.data?.data?.user?.recruiterUserType
          : "Root"
      );
      localStorage.setItem(
        "clientId",
        response?.data?.data?.user?.recruiter?._id
      );
      Cookies.set("access_token", response?.data?.data?.accessToken, {
        expires: 1 / 24,
      });
      if (
        response?.data?.data?.user?.subscription &&
        response?.data?.data?.user?.subscription?.length > 0
      ) {
        localStorage.setItem(
          "subscription",
          JSON.stringify(response?.data?.data?.user?.subscription[0])
        );
      } else {
        localStorage.setItem("subscription", JSON.stringify({}));
      }
      if (
        response?.data?.data?.user?.permissions &&
        response?.data?.data?.user?.permissions?.length > 0
      ) {
        localStorage.setItem(
          "permissions",
          JSON.stringify(response?.data?.data?.user?.permissions)
        );
      } else {
        localStorage.setItem("permissions", JSON.stringify([0, 1, 2, 4, 5]));
      }
      if (response?.data?.data?.user?.recruiterUserType === "Account") {
        if (response?.data?.data?.user?.permissions?.includes(0)) {
          navigate(`/campaign-management`);
        } else if (response?.data?.data?.user?.permissions?.includes(3)) {
          navigate(`/user-management`);
        }
      } else if (
        response?.data?.data?.user?.recruiterUserType === "Associate"
      ) {
        navigate(`/job-advert`);
      } else if (
        response?.data?.data?.user?.recruiterUserType === " " ||
        response?.data?.data?.user?.recruiterUserType === "Admin" ||
        response?.data?.data?.user?.recruiter
      ) {
        navigate(`/campaign-management`);
      } else {
        navigate("/onboarding");
      }

      setLoading(false);
    } catch (err) {
      if (err?.response?.data?.message?.message) {
        message.error(
          err?.response?.data?.message?.message ||
            "Something went wrong. Please try again."
        );
      } else {
        message.error("Something went wrong, please try again!");
      }

      setLoading(false);
    }
  };

  // const handleButtonClick = async () => {
  //   let lsData = JSON.parse(localStorage.getItem("loginCreds"));
  //   try {
  //     setLoading(true);
  //     await API_MANAGER.login(lsData);
  //     setLoading(false);
  //     localStorage.removeItem("loginCreds");
  //     navigate("/onboarding");
  //   } catch (error) {
  //     setLoading(false);
  //     message.error("something went wrong");
  //   }
  // };
  return (
    <div className="verifyotp_main_container">
      <FullScreenLoader isLoading={loading} />
      <Row className=" w-100 h-100" justify={"space-between"}>
        <Col xs={24} md={14}>
          <div className="login_container">
            <div className="verify-logo-img">
              <img src={Logo} className="logo-img" />
            </div>
            {/*=========== if otp verifyed hide this section */}

            <div className="login_form_container">
              {!otpVerify ? (
                <div className="w-100">
                  <div className="forgotHeader">
                    <h3 className="forgotHeading">Verify Account</h3>
                    {email && (
                      <p className="desc">
                        We have sent you a code on <b>{email}</b>.
                      </p>
                    )}
                  </div>

                  <Row>
                    <Col span={24} className="otp_container">
                      <OTP
                        setLoading={setLoading}
                        token={token} //this prop is to set the token
                        email={email}
                        setOtpVerified={setOtpVerified} //this prop is to makesure you are going to show sucess screen
                      />
                    </Col>
                  </Row>
                </div>
              ) : (
                <div className="verify_container">
                  <div className="sucess_logo">
                    <img
                      src={sucess}
                      alt="sucess logo"
                      className="sucess_logo_img"
                    />
                  </div>

                  <h3 className="main-heading mt-20">Verified</h3>
                  <p className="main-subheading">
                    Your account has been verified. You can now start working.
                  </p>

                  <Button className="submitBtn w-100" onClick={LoginUser}>
                    Continue
                    <div className="arrowBtn">
                      <img src={RightArrow} alt="img" />
                    </div>
                  </Button>
                </div>
              )}
            </div>
          </div>
        </Col>
        <Col xs={0} md={10} className="loginImgContainer">
          <img src={LoginImg} alt="img" className="loginImg" />
        </Col>
      </Row>
    </div>
  );
};

export default VerifyOtp;
