import React from "react";
import ErrorImage from "../../Assets/layout/404.svg";
import Layout from "../layout";
import NonProtectedLayout from "../layout/NonProtectedLayout";
import SubLayout from "../layout/SubLayout";
import { IsTokenValid } from "../../utils/middleware";
function ErrorPage() {
  const isAuthenticated = IsTokenValid();
  return (
    <>
      {isAuthenticated ? (
        <Layout>
          <SubLayout page={"404"} showBack>
            <div
              style={{
                justifyContent: "center",
                display: "flex",
                alignItems: "center",
                height: "calc(100vh - 148px)",
                fontSize: "32px",
                textAlign: "center",
              }}
              className="errorPage"
            >
              <div>
                <img src={ErrorImage} />
                <p className="title">Page Not Found</p>
                <p className="desc">
                  We are sorry, but it looks like
                  <br /> that page does not exist anymore.
                </p>
              </div>
            </div>
          </SubLayout>
        </Layout>
      ) : (
        <NonProtectedLayout>
          <div
            style={{
              justifyContent: "center",
              display: "flex",
              alignItems: "center",
              height: "calc(100vh - 100px)",
              fontSize: "32px",
              textAlign: "center",
            }}
            className="errorPage"
          >
            <div>
              <img src={ErrorImage} />
              <p className="title">Page Not Found</p>
              <p className="desc">
                We are sorry, but it looks like
                <br /> that page does not exist anymore.
              </p>
            </div>
          </div>
        </NonProtectedLayout>
      )}
    </>
  );
}

export default ErrorPage;
