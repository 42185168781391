import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Form,
  Input,
  Button,
  message,
  Checkbox,
  Progress,
} from "antd";
import { Link, useNavigate } from "react-router-dom";
import LoaderGif from "../../Assets/loader.gif";
import LoginImg from "../../Assets/login/welcome.png";
import Logo from "../../Assets/login/Logo.svg";
import RightLogo from "../../Assets/login/right.svg";
import AlertImg from "../../Assets/login/alert.svg";
import RightArrow from "../../Assets/login/rightArrow.svg";
import API_MANAGER from "../../API";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
function SignUp() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [progressPercentage, setProgressPercentage] = useState(0);
  const [value, setValue] = useState();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [validate, setValidate] = useState(false);
  const [passwordNotMatch, setPasswordNotMatch] = useState(false);
  const [form] = Form.useForm();
  const [phoneNumber, setPhoneNumber] = useState(null);

  /************  password validation logic===============*/
  const validatePassword = (e) => {
    setProgressPercentage(0);
    if (e?.match(/[A-Z]/) || e?.match(/[a-z]/) || e?.match(/[1-9]/)) {
      setProgressPercentage(25);
    }
    if (
      (e?.match(/[a-z]/) && e?.match(/[A-Z]/)) ||
      (e?.match(/[a-z]/) && e?.match(/[1-9]/)) ||
      (e?.match(/[1-9]/) && e?.match(/[A-Z]/))
    ) {
      setProgressPercentage(50);
    }
    if (e?.match(/[1-9]/) && e?.match(/[a-z]/) && e?.match(/[A-Z]/)) {
      setProgressPercentage(75);
    }
    if (
      e?.length >= 8 &&
      e?.match(/[1-9]/) &&
      e?.match(/[a-z]/) &&
      e?.match(/[A-Z]/)
    ) {
      setProgressPercentage(100);
    }
  };
  /************  setting default country code===============*/
  useEffect(() => {
    form.setFieldsValue({ countryCode: "+61" });
  }, []);
  /************ to confirm both password are same or not ===============*/
  useEffect(() => {
    if (password && confirmPassword && password === confirmPassword) {
      setValidate(true);
      setPasswordNotMatch(false);
    } else if (password && confirmPassword && password !== confirmPassword) {
      setPasswordNotMatch(true);
      setValidate(false);
    } else {
      setValidate(false);
      setPasswordNotMatch(false);
    }
  }, [password, confirmPassword]);
  /************ calling api for signup ===============*/
  const handelSignUp = async (e) => {
    let data = e;
    let creds = { email: e?.email, password: e?.password };

    data = { ...data, ["phoneNumber"]: phoneNumber };
    if (password === confirmPassword) {
      localStorage.setItem("loginCreds", JSON.stringify(creds));
      try {
        setLoading(true);
        // calling signup api
        const res = await API_MANAGER.signUp(data)
          .then(async (res) => {
            // calling second time api to verify email
            const verifyEmail = await API_MANAGER.verifyEmail(
              res.data.data.accessToken
            )
              .then(() => {
                navigate("/verifyOtp", {
                  state: {
                    token: res.data.data.accessToken,
                    email: data.email,
                  },
                });
                setLoading(false);
              })
              .catch((err) => {});
          })
          .catch((err) => {
            setLoading(false);
            message.error(
              err?.response?.data?.message?.message ||
                "Something went wrong. Please try again."
            );
          });
      } catch (err) {
        setLoading(false);
        message.error(
          err?.response?.data?.message?.message ||
            "Something went wrong. Please try again."
        );
      }
    } else {
    }
  };

  const numberValidation = (e) => {
    if (/^[0-9]*$/.test(e)) {
      setPhoneNumber(e);
    }
  };
  return (
    <>
      {loading ? (
        <div className="loaderContainer">
          <img src={LoaderGif} alt="Loader image" />
        </div>
      ) : (
        <div className="signup_main_container">
          <Row className=" w-100" justify={"space-between"}>
            <Col xs={24} md={15}>
              <div className="login_container">
                <div className="logo_container">
                  <Link to="/">
                    <img
                      src={Logo}
                      onClick={() => navigate("/")}
                      className="company_logo"
                    />
                  </Link>
                </div>
                <div className="login_form_container">
                  <Form
                    form={form}
                    onFinish={(e) => handelSignUp(e)}
                    className="w-100"
                  >
                    <div>
                      <h3 className="heading">Sign Up</h3>
                    </div>
                    {/* first name & last name section */}
                    <Row gutter={{ xs: 0, md: 24 }}>
                      <Col xs={24} md={12}>
                        <Form.Item
                          label="First Name*"
                          name="firstName"
                          rules={[
                            {
                              required: true,
                              message: "Please input your first name!",
                            },
                          ]}
                        >
                          <Input
                            className="w-100 emailInput"
                            placeholder="John"
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} md={12}>
                        <Form.Item
                          label="Last Name*"
                          name="lastName"
                          rules={[
                            {
                              required: true,
                              message: "Please input your last name!",
                            },
                          ]}
                        >
                          <Input
                            className="w-100 emailInput"
                            placeholder="Doe"
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    {/* user email */}
                    <Row>
                      <Col span={24}>
                        <Form.Item
                          label="Email*"
                          name="email"
                          rules={[
                            {
                              required: true,
                              message: "Please input your email!",
                            },
                            {
                              type: "email",
                              message: "Please enter correct email!",
                            },
                          ]}
                        >
                          <Input
                            autoComplete="off"
                            type="email"
                            className="w-100 emailInput"
                            placeholder="Johndoe@abc.com"
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    {/* user mobile number */}
                    <Row>
                      <label className="custome_label">Contact Number*</label>

                      <Col xs={7} sm={6}>
                        <Form.Item
                          name="countryCode"
                          rules={[
                            {
                              required: true,
                              message: "Please input your country code!",
                            },
                          ]}
                        >
                          {/* components from other library */}
                          <PhoneInput
                            international
                            countryCallingCodeEditable={false}
                            className="w-100 emailInput PhoneInput"
                            defaultCountry="AU"
                            value={value}
                            onChange={setValue}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={17} sm={18}>
                        <Form.Item
                          maxLength={10}
                          name={"phoneNumber"}
                          rules={[
                            {
                              required: true,
                              message: "Please input your phone no!",
                            },
                          ]}
                        >
                          <Input
                            placeholder="Phone number"
                            value={phoneNumber}
                            onChange={(e) => numberValidation(e?.target?.value)}
                            type="text"
                            inputMode="numeric"
                            maxLength={10}
                            className="w-100 emailInput phoneNumberInput"
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    {/* user password */}
                    <Row>
                      <Col span={24}>
                        <Form.Item
                          name="password"
                          label="Password*"
                          rules={[
                            {
                              required: true,
                              message: "Please input your password!",
                            },
                          ]}
                        >
                          <Input.Password
                            autoComplete="off"
                            placeholder="Password"
                            type="password"
                            value={password}
                            onChange={(e) => {
                              validatePassword(e?.target?.value);
                              setPassword(e?.target?.value);
                            }}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    {/*=============== progress bar and password verification message ====================*/}
                    <Row>
                      {progressPercentage ? (
                        <Row align={"middle"} gutter={16}>
                          <Col
                            className="progressbar_margin"
                            style={{
                              width: "209px",
                            }}
                          >
                            <Progress
                              percent={progressPercentage}
                              className="formProgressBar"
                              showInfo={false}
                            />
                          </Col>
                          <Col className="progressbar_margin">
                            {progressPercentage < 100 ? (
                              <span className="weakText">Weak</span>
                            ) : (
                              <span className="strongText">Strong</span>
                            )}
                          </Col>
                        </Row>
                      ) : (
                        ""
                      )}
                    </Row>
                    <div>
                      {progressPercentage > 0 && progressPercentage !== 100 && (
                        <Row>
                          <div className="errorDescBox">
                            Your password must contain at least:{" "}
                            <span>
                              8 Characters, 1 Upper Case Letter, 1 Lower Case
                              Letter, 1 Number
                            </span>
                          </div>
                        </Row>
                      )}
                    </div>

                    {/* confirm password */}
                    <Row>
                      <Col span={24}>
                        <Form.Item
                          name="confirmPassword"
                          label="Confirm Password*"
                          // dependencies={["password"]}
                          // hasFeedback

                          rules={[
                            {
                              required: true,
                              message: "Please confirm your password!",
                            },
                          ]}
                        >
                          <Input.Password
                            autoComplete="off"
                            placeholder="Confirm password"
                            type="password"
                            onChange={(e) =>
                              setConfirmPassword(e?.target?.value)
                            }
                            className={
                              passwordNotMatch ? "ant-input-error-border" : ""
                            }
                            prefix={
                              validate ? (
                                <img src={RightLogo} alt="right" />
                              ) : (
                                ""
                              )
                            }
                          />
                        </Form.Item>
                        {passwordNotMatch ? (
                          <Row align={"middle"} gutter={8} className="alertMsg">
                            <Col>
                              <img src={AlertImg} alt="alert" />
                            </Col>
                            <Col>
                              <span>Password doesn’t match.</span>
                            </Col>
                          </Row>
                        ) : (
                          ""
                        )}
                      </Col>
                    </Row>
                    <Row className="mb-20">
                      <Col>
                        <Form.Item
                          name="isTermsAccepted"
                          valuePropName="checked"
                          rules={[
                            {
                              required: true,
                              message:
                                "Please check terms and conditions and privacy policy to proceed!",
                            },
                          ]}
                        >
                          <Checkbox checked={true} className="termsCheckBox">
                            I accept the{" "}
                            <Link to="/terms-of-use?back=true" target="_blank">
                              <span className="span-text">
                                Terms and Conditions
                              </span>
                            </Link>{" "}
                            and <br></br>
                            <Link
                              to="/privacy-policy?back=true"
                              target="_blank"
                            >
                              <span className="span-text">Privacy Policy</span>{" "}
                            </Link>
                            of "Cinchy.me".
                          </Checkbox>
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24}>
                        <Form.Item>
                          <Button
                            htmlType="submit"
                            style={{ width: "100%" }}
                            className="submitBtn mt-30"
                          >
                            Sign Up
                            <div className="arrowBtn">
                              <img src={RightArrow} alt="img" />
                            </div>
                          </Button>
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row justify={"center"}>
                      <p className="notAMember">
                        Already a member?{" "}
                        <span onClick={() => navigate("/login")}>Login</span>
                      </p>
                    </Row>
                  </Form>
                </div>
              </div>
            </Col>
            <Col xs={0} md={9} className="loginImgContainer">
              <img src={LoginImg} alt="img" className="loginImg" />
            </Col>
          </Row>
        </div>
      )}
    </>
  );
}

export default SignUp;
