import React, { useEffect, useState } from "react";
import { Row, Col, Modal, Button } from "antd";
import FileIcon from "../../Assets/document/file.svg";
import OpenIcon from "../../Assets/document/open.svg";
import moment from "moment";
import { getFileNameFromUrl, getFileSizeFromUrl } from "../../utils";
function DocumentBox({ docLink, updatedDate, isHidden }) {
  const [date, setDate] = useState();
  const [fileSize, setFileSize] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const getFileSize = async () => {
    const size = await getFileSizeFromUrl(docLink);
    setFileSize(size);
  };

  useEffect(() => {
    getFileSize();
  }, [docLink]);
  useEffect(() => {
    setDate(moment(updatedDate)?.format("MMM DD , YYYY") || "---");
  }, []);

  const handleDocOpen = () => {
    if(isHidden){
      return
    }
    if (docLink) window.open(docLink, "_blank");
    else setIsModalOpen(true);
  };
  return (
    <>
      <Modal
        className="noDocModal"
        title="Preview"
        open={isModalOpen}
        onOk={handleOk}
        centered
        onCancel={handleCancel}
        footer={[
          <Row>
            <Col span={24} align={"right"} className="justify_items_end">
              {/* <Button className="cancel_btn" onClick={handleCancel}>
                Cancel
              </Button> */}
              <Button
                key="submit"
                className="black_color_button"
                onClick={handleOk}
              >
                Okay
              </Button>
            </Col>
          </Row>,
        ]}
      >
        <p className="desc">No document available to preview.</p>
      </Modal>
      <div className="document_box_container cursor_pointer" onClick={handleDocOpen}>
        <Row>
          <Col span={21}>
            <Row align={"middle"}>
              <Col xs={8}>
                <img src={FileIcon} alt="file" />
              </Col>
              <Col xs={16}>
                <p className="title" style={{wordBreak: "break-word"}}>
                  {docLink ? getFileNameFromUrl(docLink) : "DOCUMENT"}
                </p>
                <p className="size">{fileSize} MB</p>
                <p className="date">{date}</p>
              </Col>
            </Row>
          </Col>
          <Col span={3}>
            <Row justify={"end"}>
              {!isHidden ? (
                <img
                  src={OpenIcon}
                  onClick={handleDocOpen}
                  className="cursor_pointer"
                  alt="open"
                />
              ) : null}
            </Row>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default DocumentBox;
