import React from "react";
import newsbanner from "../../../Assets/news/newsDetailsbanner.jpeg";
import { Row, Col } from "antd";
import moment from "moment";
import { useNavigate } from "react-router-dom";
const TrendingNewsCard = ({ item }) => {
  const navigate = useNavigate();
  return (
    <>
      <Row className="trending-news-cards mb-20">
        <Col
          span={24}
          onClick={() => navigate(`/news-feed/${item?.id}`)}
          className="cursor-pointer"
        >
          <img
            src={item?.image}
            className="trending-news-img"
            alt="trending-news image"
          />
        </Col>
        <Col
          span={24}
          onClick={() => navigate(`/news-feed/${item?.id}`)}
          className="cursor-pointer"
        >
          <h4 className="heading-h4">{item?.heading}</h4>
        </Col>
        <p className="date-text">{moment(item?.createdAt).fromNow()}</p>
        <Col span={24}>
          <p className="trending-body-text">{item?.shortDescription}</p>
        </Col>
      </Row>
    </>
  );
};

export default TrendingNewsCard;
