import React from "react";
import { Row, Col, Avatar, Dropdown, Switch } from "antd";
import NotificationIcon from "../../Assets/layout/notification.svg";
import SettingIcon from "../../Assets/layout/settings.svg";
import Logo from "../../Assets/login/Logo.svg";
import { UserOutlined, SettingOutlined } from "@ant-design/icons";
function OnboardingLayout({ children, whiteBg }) {
  return (
    <div className="onboarding_layout_container d-flex">
      <div className="onboarding_layout">
        <div className="mainSidebarParent">
          <div className="mainSidebar">
            <div>
              <div className="mainSidebarHeader">
                <img src={Logo} alt="logo" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Col className="onboarding_layout_content">
        <div className={`mainContainer ${whiteBg ? "white-bg" : ""}`}>
          {children}
        </div>
      </Col>
    </div>
  );
}

export default OnboardingLayout;
