import { Button, Col } from "antd";
import React from "react";
import dpImage from "../../../Assets/susbcription_mangement/standard_card_image.svg";
import checkIcon from "../../../Assets/susbcription_mangement/checkIcon.svg";
import { numberWithCommas } from "../../../utils/constant";
function StandardPlan({
  active,
  btn,
  selectedPlan,
  handleSelect,
  showDrawer,
  data,
  id,
}) {
  return (
    <div
      className="standard_plan_card_container"
      style={
        selectedPlan?._id === id
          ? { background: "#F8F9FF", border: "1px solid #122D8E" }
          : {}
      }
      onClick={() => (handleSelect ? handleSelect(data) : "")}
    >
      <Col span={24} className="gap-16">
        <img className="dp_image" src={dpImage} alt="profile image" />
        <h4>{data?.name}</h4>
        <div className={`${active ? "active" : "none"}`}>Current Plan</div>
      </Col>
      <Col span={24} className="price_holder">
        <h4>{"$"+numberWithCommas(data?.default_price?.toString())}</h4>
        <p>/{data?.recurringType?.toLowerCase()}</p>
      </Col>
      <Col span={24} className="benefits_holder">
        {data?.feature?.map((item, index) => (
          <div className="gap-10 mt-24" style={{ alignItems: "flex-start" }}>
            <img src={checkIcon} />
            <p>{item?.name}</p>
          </div>
        ))}
      </Col>
      {btn == false ? (
        ""
      ) : (
        <Col span={24} className="justify-center d-flex ">
          <Button
            className="primaryBtn"
            style={{ color: "black" }}
            onClick={showDrawer}
          >
            Buy Again
          </Button>
        </Col>
      )}
    </div>
  );
}

export default StandardPlan;
