import React, { useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import HardRequirementsDropArea from "./HardRequirementsDropArea";
import { DragablleIcon } from "../../../Assets/campagin/sectionProgress/svgs";
import RequirementItem from "./RequirementItem";
import { Button, Input, Row } from "antd";
import {
  InputSearchCrossIcon,
  InputSearchSuffix,
  defaultData,
} from "../../../Assets/campagin/sectionProgress/svgs";
import { CloseCircleFilled } from "@ant-design/icons";
import RequirementItemSearchable from "./RequirementItemSearchable";

const REQUIREMENTS = [
  {
    id: "first",
    content: "Qualification",
    label: "Qualification",
    unique_id: "QUALIFICATION",
    tags: "QUALIFICATION",
  },
  {
    id: "second",
    content: "Inductions",
    label: "Induction",
    unique_id: "INDUCTION",
    tags: "INDUCTION",
  },
  {
    id: "third",
    content: "Skills",
    label: "Skill",
    unique_id: "SKILL",
    tags: "SKILL",
  },
  {
    id: "forth",
    content: "Citizenship",
    label: "Citizenship",
    unique_id: "CITIZENSHIP",
    tags: "CITIZENSHIP",
    type: "single",
  },
  {
    id: "fifth",
    content: "Visa Type Number",
    label: "Visa Type Number",
    unique_id: "VISA",
    tags: "VISA",
    type: "one",
  },
  {
    id: "sixth",
    content: "Police Clearance",
    label: "Police Clearance",
    unique_id: "CERTIFYING_AUTHORITY",
    tags: "CERTIFYING_AUTHORITY",
    type: "single",
  },

  {
    id: "seventh",
    content: "Medical Assessment",
    label: "Medical Assessment",
    unique_id: "MEDICAL_ASSESSMENT",
    tags: "MEDICAL_ASSESSMENT",
  },
  {
    id: "eigth",
    content: "ID Document",
    label: "ID Document",
    unique_id: "ID_TYPE",
    tags: "ID_TYPE",
    type: "single",
  },
];

function AddSoftRequirementForm({
  handleSubmit,
  hardReqSelected,
  setCurrentForm,
  setprogress,
  defaultData,
  form,
  isHidden,
  setHardReqSelected,
  setSoftReqSelected,
  softReqSelected,
  isEdit,
  columns,
  setColumns,
  hardReqColumn,
  setHardReqColumn,
}) {
  const [searchValue, setSearchValue] = useState("");
  const [progressFlag, setprogressFlag] = useState(false);

  const handleClearClick = () => {
    setSearchValue("");
  };

  const onDragEnd = (result, columns, setColumns) => {
    if (!result.destination) return;
    const { source, destination } = result;

    if (source.droppableId !== destination.droppableId) {
      const sourceColumn = columns[source.droppableId];
      const destColumn = columns[destination.droppableId];
      const sourceItems = [...sourceColumn.items];
      const destItems = [...destColumn.items];
      if (source.droppableId === "soft_req")
        sourceItems[source.index].selected = [];
      const [removed] = sourceItems.splice(source.index, 1);
      destItems.splice(destination.index, 0, removed);
      const temp = {
        ...columns,
        [source.droppableId]: {
          ...sourceColumn,
          items: sourceItems,
        },
        [destination.droppableId]: {
          ...destColumn,
          items: destItems,
        },
      };
      const tempColumn = temp?.soft_req?.items?.map((item) => item?.unique_id);
      let allColumn = REQUIREMENTS?.filter((item) => {
        if (
          (item?.type === "single" || item?.type === "one") &&
          tempColumn?.includes(item?.unique_id)
        ) {
          return false;
        } else {
          return true;
        }
      });
      let hardColumn = hardReqColumn?.hard_req?.items?.map(
        (item) => item?.unique_id
      );
      allColumn = allColumn?.filter(
        (item) => !hardColumn?.includes(item?.unique_id)
      );
      const columnsFromBackend = {
        ...hardReqColumn,
        ["all_requ"]: {
          name: "All Task ",
          items: allColumn,
          type: "all",
        },
      };
      setHardReqColumn(columnsFromBackend);
      setColumns({
        ...columns,
        [source.droppableId]: {
          ...sourceColumn,
          items: sourceItems,
        },
        [destination.droppableId]: {
          ...destColumn,
          items: destItems,
        },
      });
    } else {
      const column = columns[source.droppableId];
      const copiedItems = [...column.items];
      const [removed] = copiedItems.splice(source.index, 1);
      copiedItems.splice(destination.index, 0, removed);
      setColumns({
        ...columns,
        [source.droppableId]: {
          ...column,
          items: copiedItems,
        },
      });
    }
  };

  const handelSelect = (data, type, title, mode) => {
    if (type === "soft_req") {
      setSoftReqSelected((prev) => ({
        ...prev,
        [title?.toUpperCase()]:
          mode === "single" ? [data] : mode === "multiple" ? [...data] : [],
      }));
    }
  };
  const handelProgress = () => {
    if (columns?.soft_req?.items?.length == 0 && progressFlag == true) {
      setprogressFlag(false);
      setprogress(75);
    } else if (columns?.soft_req?.items?.length == 0) return;
    else if (columns?.soft_req?.items?.length > 0 && progressFlag == false) {
      setprogress(100);
      setprogressFlag(true);
    } else if (columns?.soft_req?.items?.length > 0 && progressFlag == true)
      return;
  };

  useEffect(() => {
    handelProgress();
  }, [onDragEnd]);

  useEffect(() => {
    setSoftReqSelected(defaultData?.softRequirements);
  }, [defaultData]);

  useEffect(() => {
    let softRequirements = {};
    columns?.soft_req?.items?.forEach((item) => {
      softRequirements[item?.tags] = softReqSelected[item?.tags];
    });
    setSoftReqSelected(softRequirements);
  }, [columns?.soft_req]);

  useEffect(() => {
    let AllReq = [...REQUIREMENTS];

    const tempColumn = defaultData?.hardRequirements
      ? Object.keys(defaultData?.hardRequirements)
      : [];
    AllReq = REQUIREMENTS?.filter((item) => {
      if (
        (item?.type === "single" || item?.type === "one") &&
        tempColumn?.includes(item?.unique_id)
      ) {
        return false;
      } else {
        return true;
      }
    });
    const softReqs = defaultData?.softRequirements;
    const handelSoftReq = () => {
      let defaultSoftReqData = [];
      if (softReqs) {
        let softReqKeys = Object.keys(softReqs);

        defaultSoftReqData = AllReq.filter((a) =>
          softReqKeys?.includes(a?.tags)
        ).map((req) => ({ ...req, selected: softReqs[req?.tags] }));
        AllReq = AllReq.filter(
          (a) => !defaultSoftReqData?.find((req) => req.tags === a?.tags)
        );
      }

      return defaultSoftReqData;
    };

    const columnsFromBackend = {
      soft_req: {
        name: "Soft Requ",
        items: handelSoftReq(),
        type: "soft",
      },
      all_requ: {
        name: "All Task ",
        items: AllReq,
        type: "all",
      },
    };

    setColumns(columnsFromBackend);
  }, [defaultData]);

  const rightColumn = (column) => {
    let list = column.items
      ?.filter((item) => item?.content?.toLowerCase()?.includes(searchValue))
      ?.map((item, index) => {
        return (
          <Draggable key={item.id} draggableId={item.id} index={index}>
            {(provided, snapshot) => {
              return (
                <div
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                  style={{
                    margin: "0px 0px 20px 0px",
                    userSelect: "none",
                    borderRadius: "8px",
                    backgroundColor: "#FFF",
                    ...provided.draggableProps.style,
                  }}
                >
                  <RequirementItem
                    dragging={snapshot.isDragging}
                    title={item?.content}
                  />
                </div>
              );
            }}
          </Draggable>
        );
      });
    return list?.length ? (
      list
    ) : (
      <div className="single-item-title" style={{ textAlign: "center" }}>
        No Data
      </div>
    );
  };

  return (
    <Row style={{ display: isHidden ? "none" : "block" }}>
      <div
        style={{ display: "flex", width: "100%" }}
        className="add-requirement-main-container"
      >
        <DragDropContext
          onDragEnd={(result) => onDragEnd(result, columns, setColumns)}
        >
          {/* Parent container for the first two columns */}
          <div className="w-100">
            {Object.entries(columns).map(([columnId, column], index) => {
              return (
                <div key={columnId} className="">
                  {column.type === "soft" && (
                    <div
                      style={{
                        marginRight: 8,
                      }}
                      className="h-100"
                    >
                      <Droppable droppableId={"soft_req"} key={"soft_req"}>
                        {(provided, snapshot) => {
                          return (
                            <div
                              {...provided.droppableProps}
                              ref={provided.innerRef}
                              style={{
                                userSelect: "none",
                                borderRadius: "8px",
                                backgroundColor: "#FFF",
                                color: "white",
                                height: "calc(100vh - 380px)",
                                overflow: "auto",
                              }}
                              className="soft-hard-box"
                            >
                              {/* Check if there are any items in the column */}
                              {column.items.length === 0 ? (
                                <div
                                  style={{
                                    margin: "0px 0px 20px 0px",
                                    userSelect: "none",
                                    borderRadius: "8px",
                                    backgroundColor: "#FFF",
                                    color: "black",
                                  }}
                                >
                                  <div
                                    style={{
                                      // maxWidth: snapshot.isDraggingOver
                                      // ? "unset"
                                      // : "395px",
                                      display: "block",
                                      marginLeft: "auto",
                                      marginRight: "auto",
                                    }}
                                  >
                                    <HardRequirementsDropArea
                                      title={"Soft Requirements "}
                                    />
                                  </div>
                                </div>
                              ) : (
                                <div className="selected_requirement_main_div">
                                  <div className="selected_requirement_inner_div h-100">
                                    <div className="title-heading p-lr-24">
                                      Soft Requirement ({column.items.length})
                                    </div>
                                    <div className="selected_req">
                                      {column.items.map((item, index) => {
                                        return (
                                          <Draggable
                                            key={item.id}
                                            draggableId={item.id}
                                            index={index}
                                          >
                                            {(provided, snapshot) => {
                                              return (
                                                <div
                                                  ref={provided.innerRef}
                                                  {...provided.draggableProps}
                                                  {...provided.dragHandleProps}
                                                  style={{
                                                    margin:
                                                      "0px 20px 20px 20px",
                                                    userSelect: "none",
                                                    borderRadius: "8px",
                                                    backgroundColor: "#FFF",
                                                    color: "white",
                                                    ...provided.draggableProps
                                                      .style,
                                                  }}
                                                >
                                                  {item?.type === "single" ? (
                                                    <>
                                                      {/* <div className="single-item-title">
                                                        Choose {item?.label}
                                                      </div> */}
                                                      <div className="searchable-requirement-item">
                                                        <div className="draggable-icon">
                                                          <DragablleIcon />
                                                        </div>

                                                        <div className="withoutSelectBox">
                                                          <p>{item?.label}</p>
                                                        </div>
                                                      </div>
                                                    </>
                                                  ) : (
                                                    <>
                                                      <div className="single-item-title">
                                                        Choose a {item?.label}
                                                      </div>
                                                      <RequirementItemSearchable
                                                        title={item?.content}
                                                        handelSelect={
                                                          handelSelect
                                                        }
                                                        selected={
                                                          item?.selected ?? []
                                                        }
                                                        type="soft_req"
                                                        reqDisabled={
                                                          hardReqSelected
                                                        }
                                                        tags={item?.tags}
                                                        unique_id={
                                                          item?.unique_id
                                                        }
                                                      />
                                                    </>
                                                  )}
                                                </div>
                                              );
                                            }}
                                          </Draggable>
                                        );
                                      })}
                                    </div>
                                  </div>
                                </div>
                              )}
                              {provided.placeholder}
                            </div>
                          );
                        }}
                      </Droppable>
                    </div>
                  )}
                </div>
              );
            })}
          </div>

          {/* Third column */}
          <div className="w-100">
            {Object.entries(columns).map(([columnId, column], index) => {
              return (
                <div key={columnId}>
                  {column.type === "all" && (
                    <div className="box">
                      <Droppable droppableId={columnId} key={columnId}>
                        {(provided, snapshot) => {
                          return (
                            <div
                              {...provided.droppableProps}
                              ref={provided.innerRef}
                              style={{
                                // width: "35vw",
                                height: "calc(100vh - 380px)",
                                overflow: "auto",
                              }}
                            >
                              <div className="search_requirement_div">
                                <Input
                                  className="search-input"
                                  placeholder="Search requirements"
                                  prefix={<InputSearchSuffix />} // Replace with your custom prefix icon component
                                  suffix={
                                    searchValue && (
                                      <div
                                        className="align-center d-flex"
                                        onClick={handleClearClick}
                                      >
                                        <InputSearchCrossIcon />
                                      </div>
                                    )
                                  }
                                  value={searchValue}
                                  onChange={(e) =>
                                    setSearchValue(e.target.value)
                                  }
                                />
                              </div>
                              <div style={{ padding: "24px" }}>
                                {rightColumn(column)}
                              </div>
                              {provided.placeholder}
                            </div>
                          );
                        }}
                      </Droppable>
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </DragDropContext>
      </div>
      <div className="add-requirement-main-container justify-end d-flex w-100 ">
        <Row className="post-advert-btn">
          <Button
            className="secondaryBtn"
            onClick={() => {
              setCurrentForm("advert");
              setprogress(75);
            }}
          >
            Cancel
          </Button>{" "}
          <Button
            className="primaryBtn"
            onClick={() => {
              setCurrentForm("hard_req");
            }}
          >
            Hard Requirement
          </Button>
          <Button
            className="primaryBtn"
            onClick={() => {
              handleSubmit(true, false);
            }}
          >
            {isEdit ? "Update" : "+ Post Advert"}
          </Button>
        </Row>
      </div>
    </Row>
  );
}

export default AddSoftRequirementForm;
