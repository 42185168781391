import React, { useState, useEffect } from "react";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import API_MANAGER from "../../API";
import { message } from "antd";
import FullScreenLoader from "../../components/common/FullScreenLoader";

function UserInvite() {
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const params = useParams();
  const token = searchParams.get("token");
  const status = searchParams.get("status");
  const recruiterId = searchParams.get("recruiterId");
  const invitationId = searchParams.get("invitationId");
  const handleSubmit = async () => {
    try {
      const response = await API_MANAGER.acceptRejectInvite(
        invitationId,
        {
          status: status,
          recruiterId: recruiterId,
        },
        token
      );
      setLoading(false);
      if(response){
        message.success("Invitation accepted successfully.")
      }
      else{
        message.error('Token expired');
      }
      navigate("/login", "_self");
    } catch (error) {
      setLoading(false);
      message.error(error?.response?.data?.message?.message);
      window.open("/login");
    }
  };
  useEffect(() => {
    if (token && status && invitationId && recruiterId) {
      handleSubmit();
    }
  }, [token, status, invitationId, recruiterId]);
  return (
    <div>
      <FullScreenLoader isLoading={loading} />
    </div>
  );
}

export default UserInvite;
