import { Button, Col, Form, Input, Modal, Progress, Row, message } from "antd";
import { useEffect, useState } from "react";
import OTP from "../common/forgot/OTP";

import ArrowRightOutlined from "@ant-design/icons";

// import AlertImg from "../../../Assets/login/alert.svg";
import AlertImg from "../../Assets/login/alert.svg";
import RightLogo from "../../Assets/login/right.svg";
import API_MANAGER from "../../API";
import Cookies from "js-cookie";
import RightArrow from "../../Assets/login/rightArrow.svg";
import AlertState from "../common/AlertState";

const ChangePassword = ({ changePasswordModal, setChangePasswordModal }) => {
  const [createPassword, setCreatePassword] = useState(true);
  const [otpVerification, setOtpVerification] = useState(false);
  const [token, setToken] = useState(false);
  const [email, setEmail] = useState("abc@gmail.com");
  const [otpVerify, setOtpVerified] = useState(false);
  const [progressPercentage, setProgressPercentage] = useState(0);
  const [oldPasswordError, setOldPassword] = useState(false);
  const [validate, setValidate] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [currentPassword, setCurrentPassword] = useState("");
  const [passwordNotMatch, setPasswordNotMatch] = useState(false);
  const accessToken = Cookies.get("access_token");
  const [alertState, setAlertState] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [alertOpen, setAlertOpen] = useState("");

  const onFinish = async (values) => {
    // setCreatePassword(false);
    // setOtpVerification(true);

    try {
      const response = await API_MANAGER.updateUserpassword(
        values,
        accessToken
      );

      setChangePasswordModal(false);
      message.success("Password successfully updated.");
    } catch (error) {
      message.error(
        error?.response?.data?.message?.message ||
          "Something went wrong. Please try again."
      );
    }
  };
  const onFinishFailed = (errorInfo) => {};
  useEffect(() => {
    if (otpVerify) setChangePasswordModal(false);
  }, [otpVerify]);
  const handleClose = () => {
    setChangePasswordModal(false);
  };
  const validatePassword = (e) => {
    setProgressPercentage(0);
    if (e?.match(/[A-Z]/) || e?.match(/[a-z]/) || e?.match(/[1-9]/)) {
      setProgressPercentage(25);
    }
    if (
      (e?.match(/[a-z]/) && e?.match(/[A-Z]/)) ||
      (e?.match(/[a-z]/) && e?.match(/[1-9]/)) ||
      (e?.match(/[1-9]/) && e?.match(/[A-Z]/))
    ) {
      setProgressPercentage(50);
    }
    if (e?.match(/[1-9]/) && e?.match(/[a-z]/) && e?.match(/[A-Z]/)) {
      setProgressPercentage(75);
    }
    if (
      e?.length >= 8 &&
      e?.match(/[1-9]/) &&
      e?.match(/[a-z]/) &&
      e?.match(/[A-Z]/)
    ) {
      setProgressPercentage(100);
    }
  };
  useEffect(() => {
    if (password && confirmPassword && password === confirmPassword) {
      setValidate(true);
      setPasswordNotMatch(false);
    } else if (password && confirmPassword && password !== confirmPassword) {
      setPasswordNotMatch(true);
    } else {
      setValidate(false);
      setPasswordNotMatch(false);
    }
  }, [password, confirmPassword]);
  return (
    <>
      <AlertState
        state={alertState}
        message={alertMessage}
        alertOpen={alertOpen}
        setAlertOpen={setAlertOpen}
      />
      <Modal
        title=""
        centered
        wrapClassName="create_new_password_modal"
        open={changePasswordModal}
        onCancel={handleClose}
        footer={false}
      >
        <Row className="content_main_row">
          <Col xs={24} align={"center"}>
            <span className="title">
              {createPassword ? "Change Password" : "Mail Sent!"}
            </span>
          </Col>
          <Col xs={24} align={"center"} className="mt-14">
            <span className="title_desc">
              {createPassword
                ? "Set your new password below."
                : "We have sent you a code on abc@gmail.com"}
            </span>
          </Col>
        </Row>
        {/* Form for create password */}
        {createPassword && (
          <Form
            name="basic"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            layout="vertical"
            requiredMark={false}
          >
            <Form.Item
              label="Current Password*"
              name="currentPassword"
              className="password_form_item"
              rules={[
                {
                  required: true,
                  message: "Please input your current password!",
                },
              ]}
            >
              <Input.Password
                className="form_input_box"
                placeholder="Enter current password"
                onChange={(e) => setCurrentPassword(e?.target?.value)}
              />
            </Form.Item>
            <Form.Item
              label="New Password*"
              name="newPassword"
              className="password_form_item"
              rules={[
                {
                  required: true,
                  message: "Please input your new password!",
                },
              ]}
            >
              <Input.Password
                className="form_input_box"
                placeholder="Enter new password"
                value={password}
                onChange={(e) => {
                  validatePassword(e.target.value);
                  setPassword(e?.target?.value);
                }}
              />
            </Form.Item>
            {oldPasswordError ? (
              <Row align={"middle"} gutter={8} className="alertMsg">
                <Col>
                  <img src={AlertImg} alt="alert" />
                </Col>
                <Col>
                  <span>You have entered an old password</span>
                </Col>
              </Row>
            ) : progressPercentage ? (
              <Row align={"middle"} gutter={16}>
                <Col style={{ width: "209px" }}>
                  <Progress
                    percent={progressPercentage}
                    className={
                      progressPercentage < 100
                        ? "formProgressBar_red"
                        : "formProgressBar"
                    }
                    showInfo={false}
                  />
                </Col>
                <Col>
                  {progressPercentage < 100 ? (
                    <span className="weakText">Weak</span>
                  ) : (
                    <span className="strongText">Strong</span>
                  )}
                </Col>
              </Row>
            ) : (
              ""
            )}
            <Form.Item
              autoComplete="new-password"
              label="Confirm Password*"
              name="change_password"
              className="password_form_item"
              rules={[
                {
                  required: true,
                  message: "Please enter confirm password!",
                },
              ]}
            >
              <Input.Password
                className="form_input_box"
                placeholder="Enter confirm password"
                onChange={(e) => setConfirmPassword(e?.target?.value)}
                prefix={
                  !passwordNotMatch && validate ? (
                    <img src={RightLogo} alt="right" />
                  ) : (
                    ""
                  )
                }
              />
            </Form.Item>
            {passwordNotMatch ? (
              <Row align={"middle"} gutter={8} className="alertMsg">
                <Col>
                  <img src={AlertImg} alt="alert" />
                </Col>
                <Col>
                  <span>Password doesn’t match.</span>
                </Col>
              </Row>
            ) : (
              ""
            )}
            {progressPercentage !== 100 && (
              <Row>
                <div className="errorDescBox">
                  Your password must contain at least:{" "}
                  <span>
                    8 Characters, 1 Upper Case Letter, 1 Lower Case Letter, 1
                    Number
                  </span>
                </div>
              </Row>
            )}

            <Form.Item>
              <Button
                className="cp_modal_btn"
                htmlType="submit"
                disabled={!validate || passwordNotMatch}
              >
                Change Password
                <div className="arrowBtn">
                  <img src={RightArrow} alt="img" />
                </div>
              </Button>
            </Form.Item>
          </Form>
        )}
        {/* OTP Verification screen */}
        {otpVerification && (
          <OTP
            setToken={setToken}
            email={email}
            setOtpVerified={setOtpVerified}
          />
        )}
        {/* Form for OTP VERIFICATION through mail */}
      </Modal>
    </>
  );
};
export default ChangePassword;
