import React, { useEffect, useState } from "react";
import SubLayout from "../../components/layout/SubLayout";
import { Button, Col, Form, Input, Row, Select, Tooltip, message } from "antd";
import PermissionCard from "../../components/common/UserManagement/PermissionCard";
import SelectCamaignModal from "../../components/common/UserManagement/SelectCamaignModal";
import UserRegisterdConfirmationModal from "../../components/common/UserManagement/UserRegisterdConfirmationModal";
import { permissionData } from "../../utils/constant";
import PermissionIndividualCard from "../../components/common/UserManagement/PermissionIndividualCard";

import { DownOutlined, CloseOutlined } from "@ant-design/icons";
import AddCampaignModal from "../../components/common/AddUser/AddCampaignModal";
import { useLocation, useNavigate } from "react-router-dom";
import rightArrow from "../../Assets/campagin/right_arrow.svg";
import DownIcon from "../../Assets/downArrowIcon.svg";
import API_MANAGER from "../../API";
import PhoneInput from "react-phone-number-input";
import AlertState from "../../components/common/AlertState";
function AddUser() {
  const [pickCampaignModal, setPickCampaignModal] = useState();
  const [userregistedSuccessModal, setUserregistedSuccessModal] = useState();
  const [permissionIndex, setPermissionIndex] = useState(null);
  const [selectedCount, setSelectedCount] = useState(2);
  const [selectedCampaigns, setSelectedCampaigns] = useState([]);
  const [campaignModal, setCampaignModal] = useState(false);
  const [countryCode, setCountryCode] = useState("+61");
  const [permissions, setPermissions] = useState([]);
  const [campaignList, setCampaignList] = useState([]);
  const [campaignSearch, setCampaignSearch] = useState(null);
  const [selectedItems, setSelectedItems] = useState([]);
  const [checkSelected, setCheckSelected] = useState([]);
  const [userData, setUserData] = useState({});
  const [loading, setLoading] = useState(false);
  const [alertState, setAlertState] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [alertOpen, setAlertOpen] = useState("");

  const [form] = Form.useForm();
  const navigate = useNavigate();
  const props = useLocation();
  const userCount = props.state;
  const backRoute = props?.state?.backRoute;
  const handleCampaignModal = () => {
    setCampaignModal(true);
  };
  const handleGoBack = () => {
    navigate(-1);
  };
  const getData = async () => {
    const param = {
      search: campaignSearch ? campaignSearch : "",
      sort: "-createdAt",
      limit: 20000,
      page: 1,
    };
    try {
      if (localStorage.getItem("user") === "Root") {
        const response = await API_MANAGER.getCampaignListRoot(param);
        setCampaignList(response?.data?.data?.result);
      } else {
        const response = await API_MANAGER.getCampaignList(param);
        setCampaignList(response?.data?.data?.result);
      }
    } catch (error) {
      message.error("Something went wrong. Please try again.");
    }
  };
  useEffect(() => {
    form.setFieldsValue({ countryCode: "+61" });
  }, []);
  const handleSubmit = async (values) => {
    setLoading(true);
    let temp = values;
    temp = {
      ...temp,
      ["permissions"]: permissions,
      ["countryCode"]: countryCode,
      ["campaign"]: checkSelected,
      ["campaignUser"]: {
        tag: "free",
        type: "Regular",
      },
      ["isTermsAccepted"]: true,
      ["recruiterUserType"]:
        permissionIndex === 0
          ? "Admin"
          : permissionIndex == 1
          ? "Standard"
          : permissionIndex === 2
          ? "Associate"
          : null,
    };
    setUserData(temp);

    try {
      const response = await API_MANAGER.createUser(temp);
      setLoading(false);
      if (response?.data?.status === 201) {
        setUserregistedSuccessModal(true);
        form.resetFields();
        setCheckSelected([]);
        setSelectedCampaigns([]);
        setSelectedItems([]);
        navigate(backRoute);
      }
    } catch (error) {
      setLoading(false);

      message.error(
        error?.response?.data?.message?.message + "." ||
          "Something went wrong. Please try again."
      );
    }
  };
  useEffect(() => {
    if (permissionIndex === 0) {
      setPermissions([0, 1, 2, 3, 4, 5]);
    } else if (permissionIndex === 1) {
      setPermissions([0, 1, 3, 4, 5]);
    } else if (permissionIndex === 2) {
      setPermissions([4, 5]);
    } else {
      setPermissions([]);
    }
  }, [permissionIndex]);
  useEffect(() => {
    getData();
  }, [campaignSearch]);

  const numberValidation = (e) => {
    if (/^[0-9]*$/.test(e)) {
      form.setFieldsValue({ phoneNumber: e });
    } else {
      form.setFieldsValue({ phoneNumber: e?.substr(0, e?.length - 1) });
    }
  };
  return (
    <div className="user_management_form_container">
      <AlertState
        state={alertState}
        message={alertMessage}
        alertOpen={alertOpen}
        setAlertOpen={setAlertOpen}
      />
      <AddCampaignModal
        campaignModal={campaignModal}
        setCampaignModal={setCampaignModal}
        selectedCampaigns={selectedCampaigns}
        setSelectedCampaigns={setSelectedCampaigns}
        data={campaignList}
        search={campaignSearch}
        setSearch={setCampaignSearch}
        selectedItems={selectedItems}
        setSelectedItems={setSelectedItems}
        checkSelected={checkSelected}
        setCheckSelected={setCheckSelected}
      />
      <SelectCamaignModal
        pickCampaignModal={pickCampaignModal}
        setPickCampaignModal={setPickCampaignModal}
      />
      <UserRegisterdConfirmationModal
        userregistedSuccessModal={userregistedSuccessModal}
        setUserregistedSuccessModal={setUserregistedSuccessModal}
        data={userData}
        userCount={userCount}
      />
      <SubLayout page={userCount ? "Add User" : "Create User"} whiteBg showBack>
        {/* for small screen only */}
        <Row>
          <Col xs={24} md={0}>
            <p className="go-back" onClick={handleGoBack}>
              <img src={rightArrow} alt="icons" /> Add users
            </p>
          </Col>
        </Row>
        <Form
          form={form}
          layout="vertical"
          onFinish={handleSubmit}
          requiredMark={false}
        >
          <Row className="form_holder" justify={"space-between"}>
            <Col xs={24} md={11}>
              <Form.Item
                label="First Name*"
                name="firstName"
                rules={[
                  {
                    required: true,
                    message: "Please fill first name.",
                  },
                ]}
              >
                <Input
                  placeholder="John"
                  autoComplete="off"
                  className="form_input_box"
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={11}>
              <Form.Item
                label="Last Name*"
                name="lastName"
                rules={[
                  {
                    required: true,
                    message: "Please fill last name.",
                  },
                ]}
              >
                <Input
                  placeholder="Doe"
                  autoComplete="off"
                  className="form_input_box"
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={11}>
              <Form.Item
                label="Email*"
                name="email"
                rules={[
                  {
                    required: true,
                    message: "Please fill email.",
                  },
                  {
                    type: "email",
                    message: "Enter a valid email.",
                  },
                ]}
              >
                <Input
                  placeholder="Johndoe@abc.com"
                  className="form_input_box"
                  autoComplete="off"
                  type="email"
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={11}>
              <Form.Item label="Campaign" name="campaign">
                <div
                  className="multiple_select_main_div cursor_pointer"
                  onClick={handleCampaignModal}
                >
                  <Row justify={"space-between"} className="w-100">
                    <Col style={{ display: "flex" }}>
                      {selectedCampaigns?.length === 0 && (
                        <span className="campaign_placeholder">
                          Pick the campaigns to add the user
                        </span>
                      )}
                      {selectedCampaigns?.slice(0, 2).map((item) => (
                        <div className="selected_item_main_div">
                          <span>{item?.name}</span>
                          <CloseOutlined />
                        </div>
                      ))}
                      <Tooltip
                        title={
                          <div>
                            {selectedCampaigns.slice(2).map((item) => (
                              <div className="align_center_start">
                                <div
                                  className="green_dot"
                                  style={{ marginRight: "5px " }}
                                ></div>
                                <span>{item.name}</span>
                              </div>
                            ))}
                          </div>
                        }
                      >
                        {selectedCampaigns.length > 2 && (
                          <div className="additional_items">
                            <span>+{selectedCampaigns.length - 2}</span>
                          </div>
                        )}
                      </Tooltip>
                    </Col>
                  </Row>

                  <Col>
                    <img src={DownIcon} />
                  </Col>
                </div>
              </Form.Item>
            </Col>
            <Col xs={24} md={24} lg={11}>
              <label className="custome_label">Contact Number*</label>
              <Row>
                <Col xs={7} sm={7} md={8} lg={8} xl={6}>
                  <Form.Item
                    name="countryCode"
                    rules={[
                      {
                        required: false,
                        message: "please select country code",
                      },
                    ]}
                  >
                    {/* components from other library */}
                    <PhoneInput
                      international
                      countryCallingCodeEditable={false}
                      className="w-100 emailInput PhoneInput"
                      defaultCountry="AU"
                      value={countryCode}
                      onChange={setCountryCode}
                    />
                  </Form.Item>
                </Col>
                <Col xs={17} sm={17} md={16} lg={16} xl={18}>
                  <Form.Item
                    name="phoneNumber"
                    rules={[
                      {
                        required: true,
                        message: "Please enter phone number.",
                      },
                      {
                        pattern: /^[0-9]*$/,
                        message: "Please input valid number",
                      },
                      {
                        max: 10,
                        message: "",
                      },
                    ]}
                  >
                    <Input
                      placeholder="Phone number"
                      type="phone"
                      maxLength={10}
                      onChange={(e) => numberValidation(e.target.value)}
                      className="form_input_box border_right_bottom"
                      autoComplete="off"
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row
            justify={"space-between"}
            align={"top"}
            className="permission_container w-100"
            gutter={[10, 16]}
            // style={{ border: "2px solid red" }}
          >
            <Col span={24} className="mb-24">
              <h2>Permissions</h2>
            </Col>
            {permissionData.map((item, index) => (
              <Col xs={24} lg={12} xl={8}>
                <PermissionIndividualCard
                  data={item}
                  index={index}
                  setPermissionIndex={setPermissionIndex}
                  permissionIndex={permissionIndex}
                  userCount={userCount}
                  permissions={permissions}
                  setPermissions={setPermissions}
                  selectedCount={selectedCount}
                  setSelectedCount={setSelectedCount}
                />
              </Col>
            ))}
          </Row>
          <Row justify={"center"}>
            <Col xs={24} align={"right"}>
              <Form.Item className="registerBtnItem">
                <Button
                  className={
                    permissions?.length === 0
                      ? "registerBtn_inactive mt-24"
                      : "registerBtn mt-24"
                  }
                  htmlType="submit"
                  loading={loading}
                  disabled={permissions?.length === 0}
                >
                  Register
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </SubLayout>
    </div>
  );
}

export default AddUser;
