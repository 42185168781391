import React, { useState, useEffect, useRef } from "react";
import { Button, Col, Input, Modal, Row, message } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import API_MANAGER from "../../../API";
import DeleteIcon from "../../../Assets/delete.svg";
import crossIcon from "../../../Assets/cancelIcon.svg";
const EditAddMessageModal = ({
  messageModal,
  setMessageModal,
  buttonVisible,
  selectedMessage,
  getData,
}) => {
  const [initialValue, setValue] = useState({});
  const [loading, setLoading] = useState(false);
  const { TextArea } = Input;
  const inputRef = useRef(null);

  const handleOk = async () => {
    // setMessageModal(false);
    try {
      setLoading(true);
      await API_MANAGER.updateTrackerNotes(
        {
          notes: initialValue,
        },
        selectedMessage?.appliedJob
      );
      setLoading(false);
      setMessageModal(false);
      message.success("Updated successfully!");
      getData();
    } catch (error) {
      setLoading(false);

      message.error(
        error?.response?.data?.message ||
        "Something went wrong. Please try again."
      );
    }
  };
  const handleCancel = () => {
    setMessageModal(false);
  };
  const renderFooterButtons = () => {
    if (buttonVisible) {
      return (
        <div className="align_left">
          <Button
            key="ok"
            loading={loading}
            className="black_color_button"
            onClick={handleOk}
          >
            Post
          </Button>
          <Button key="cancel" className="cancel_btn" onClick={handleCancel}>
            Cancel
          </Button>
        </div>
      );
    }
    // Return an empty array if `buttonVisible` is false
    return [];
  };
  useEffect(() => {
    setValue(selectedMessage?.notes ? selectedMessage?.notes : {});
  }, [selectedMessage]);

  useEffect(() => {
    setTimeout(() => {
      inputRef?.current?.focus({
        cursor: "end",
      });
    }, 200);

  }, [messageModal, inputRef]);
  return (
    <div className="addEditMessageMainDiv">
      {selectedMessage && (
        <Modal
          centered
          footer={renderFooterButtons()}
          title={selectedMessage?.profileId?.name}
          open={messageModal}
          className="modal_main"
          // closeIcon={
          //   <>
          //     {buttonVisible && <DeleteOutlined style={{ color: "#122D8E" }} />}
          //     {!buttonVisible && <CloseOutlined />}
          //   </>
          // }
          closable={false}
          onOk={handleOk}
          onCancel={handleCancel}
        >
          <div style={{ position: "absolute", top: "20px", right: "24px" }}>
            {buttonVisible && (
              <img
                className="cursor-pointer"
                onClick={() =>
                  setValue({ ...initialValue, [selectedMessage?.type]: "" })
                }
                src={DeleteIcon}
                alt="delete"
                style={{ color: "#122D8E" }}
              />
            )}
            {!buttonVisible && (
              <img
                src={crossIcon}
                alt="cross"
                onClick={() => {
                  setValue({});
                  setMessageModal(false);
                }}
              />
            )}
          </div>
          <Row className="content_main_row">
            {/* <div className="p-20"> */}
            {!buttonVisible ? (
              <Col>
                {selectedMessage?.notes
                  ? selectedMessage?.notes[selectedMessage?.type]
                  : ""}
              </Col>
            ) : (
              <TextArea
                placeholder="Leave a comment"
                value={
                  initialValue ? initialValue?.[selectedMessage?.type] : ""
                }
                ref={inputRef}
                onChange={(e) => {
                  setValue({
                    ...initialValue,
                    [selectedMessage?.type]: e?.target?.value,
                  });
                }}
                rows={4}
              />
            )}
            {/* </div> */}
          </Row>
        </Modal>
      )}
    </div>
  );
};
export default EditAddMessageModal;
