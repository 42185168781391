import React, { useState, useEffect } from "react";
import SubLayout from "../../components/layout/SubLayout";
import NewsCards from "../../components/common/news/NewsCards";
import { message } from "antd";
import Carousel from "react-multi-carousel";
import API_MANAGER from "../../API";
import FullScreenLoader from "../../components/common/FullScreenLoader";
import { useNavigate } from "react-router-dom";
import MobileHeader from "../../components/layout/MobileHeader";
import NewsFeedCarousel from "../../components/common/NewsFeedCarousel";
function NewsFeed() {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  // const navigate = useNavigate();
  const getData = async () => {
    setLoading(true);
    try {
      const response = await API_MANAGER.getNewsFeed();

      setLoading(false);

      setData(response?.data?.data);
    } catch (error) {
      setLoading(false);
      message.error("Something went wrong. Please try again.");
    }
  };
  useEffect(() => {
    getData();
  }, []);
  return (
    <SubLayout page={"Latest News & updates"} whiteBg showSearch>
      <MobileHeader title={"Latest News & updates"} showSearch />
      <div>
        <FullScreenLoader isLoading={loading} />
        {/* <div className="landing_campaign_page">
          <NewsFeedCarousel />
        </div> */}
        <div className="newsMainContainer">
          {data?.weekly && (
            <>
              <h2 className="newsTypeHeading mt-20">Trending</h2>

              <Carousel
                additionalTransfrom={0}
                arrows={true}
                autoPlaySpeed={3000}
                centerMode={false}
                className=""
                containerClass="container-padding-bottom"
                // customButtonGroup={<ButtonGroup />}
                dotListClass=""
                draggable
                focusOnSelect={false}
                infinite={false}
                itemClass=""
                keyBoardControl
                minimumTouchDrag={80}
                pauseOnHover
                renderArrowsWhenDisabled={false}
                renderButtonGroupOutside={false}
                renderDotsOutside={false}
                responsive={{
                  desktop: {
                    breakpoint: {
                      max: 3000,
                      min: 1201,
                    },
                    items: 3.5,
                    partialVisibilityGutter: 40,
                  },
                  tablet: {
                    breakpoint: {
                      max: 1200,
                      min: 901,
                    },
                    items: 2.5,
                    partialVisibilityGutter: 30,
                  },
                  mobile: {
                    breakpoint: {
                      max: 900,
                      min: 0,
                    },
                    items: 1.5,
                    partialVisibilityGutter: 10,
                  },
                }}
                // rewind={false}
                rewindWithAnimation={false}
                rtl={false}
                shouldResetAutoplay
                swipeable
              >
                {data?.weekly?.map((item, index) => (
                  <NewsCards key={index} item={item} />
                ))}
              </Carousel>
            </>
          )}

          <div>
            {data?.latest && (
              <>
                <h2 className="newsTypeHeading mt-20">Latest</h2>
                <Carousel
                  additionalTransfrom={0}
                  arrows={true}
                  autoPlaySpeed={3000}
                  centerMode={false}
                  className="newsFeedCarousel"
                  containerClass="container-padding-bottom"
                  // customButtonGroup={<ButtonGroup />}
                  dotListClass=""
                  draggable
                  focusOnSelect={false}
                  infinite={false}
                  itemClass=""
                  keyBoardControl
                  minimumTouchDrag={80}
                  pauseOnHover
                  renderArrowsWhenDisabled={false}
                  renderButtonGroupOutside={false}
                  renderDotsOutside={false}
                  responsive={{
                    desktop: {
                      breakpoint: {
                        max: 3000,
                        min: 1201,
                      },
                      items: 3.5,
                      partialVisibilityGutter: 40,
                    },
                    tablet: {
                      breakpoint: {
                        max: 1200,
                        min: 901,
                      },
                      items: 2.5,
                      partialVisibilityGutter: 30,
                    },
                    mobile: {
                      breakpoint: {
                        max: 900,
                        min: 0,
                      },
                      items: 1.5,
                      partialVisibilityGutter: 20,
                    },
                  }}
                  rewindWithAnimation={false}
                  rtl={false}
                  shouldResetAutoplay
                  showDots={false}
                  sliderClass=""
                  slidesToSlide={1}
                  swipeable
                >
                  {data?.latest?.map((item, index) => (
                    <NewsCards key={index} item={item} />
                  ))}
                </Carousel>
              </>
            )}
          </div>
        </div>
      </div>
    </SubLayout>
  );
}

export default NewsFeed;
