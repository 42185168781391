import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { ValidRoutes } from "./validRoutes";
export const IsTokenValid = () => {
  // const navigate = useNavigate();
  let currentTime = new Date().getTime();
  let expireTime = localStorage.getItem("expireTime");
  if (currentTime > expireTime) {
    // navigate("/login");
    return false;
  } else {
    return true;
  }
};

export const IsTokenValidForRoutes = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const user = localStorage.getItem("user");
  let currentTime = new Date().getTime();
  let expireTime = localStorage.getItem("expireTime");
  if (currentTime > expireTime) {
    const Email = localStorage.getItem("email");
    const Password = localStorage.getItem("password");

    localStorage.clear();
    if (Email && Password) {
      localStorage.setItem("email", Email);
      localStorage.setItem("password", Password);
    }
    // navigate("/login");
    return false;
  } else {
    const path = location?.pathname?.split("/")[1];

    if (user === "Root" || user === "Admin" || user === "Associate") {
      if (ValidRoutes[user]?.includes(path)) {
        return true;
      }
    } else if (user === "Account") {
      const permissions = JSON.parse(localStorage.getItem("permissions"));
      if (permissions?.includes(0) && permissions?.includes(3)) {
        if (ValidRoutes[user]?.includes(path)) {
          return true;
        }
      } else if (permissions?.includes(3)) {
        if (ValidRoutes["AccountUser"]?.includes(path)) {
          return true;
        }
      } else if (permissions?.includes(0)) {
        if (ValidRoutes["AccountCampaign"]?.includes(path)) {
          return true;
        }
      }
    }
    return false;
  }
};
