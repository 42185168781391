import { Button, Col, Input, Modal, Row } from "antd";
import {
  SearchOutlined,
  CheckOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import { AddCampaignMockData } from "../../../utils/constant";
import { useState } from "react";
import SearchIcon from "../../../Assets/user_mangement/searchIcon.svg";

const AddCampaignModal = ({
  campaignModal,
  setCampaignModal,
  selectedCampaigns,
  setSelectedCampaigns,
  data,
  search,
  setSearch,
  selectedItems,
  setSelectedItems,
  checkSelected,
  setCheckSelected,
}) => {
  const handleOk = () => {
    setCampaignModal(false);
    setSelectedCampaigns(selectedItems);
  };

  const handleCancel = () => {
    setCampaignModal(false);
  };
  const handleItemClick = (data, index) => {
    if (!checkSelected.includes(data?._id)) {
      const updatedSelectedItems = [...selectedItems, data];
      setSelectedItems(updatedSelectedItems);
      const updatedCheckItems = [...checkSelected, data._id];
      setCheckSelected(updatedCheckItems);
    } else {
      const updatedSelectedItems = selectedItems.filter(
        (item) => item._id !== data?._id
      );

      setSelectedItems(updatedSelectedItems);
      const updatedCheckItems = checkSelected.filter(
        (item) => item !== data?._id
      );
      setCheckSelected(updatedCheckItems);
    }
  };
  return (
    <Row className="addCampaignModal_main_row">
      <Modal
        centered
        title="Select Campaign:"
        className="multi_select_modal addCampaignModal"
        open={campaignModal}
        // onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Row>
            <Col span={24} align={"right"} className="justify_items_end">
              <Button className="cancel_btn" onClick={handleCancel}>
                Cancel
              </Button>
              <Button
                key="submit"
                className="black_color_button"
                onClick={handleOk}
              >
                Done
              </Button>
            </Col>
          </Row>,
        ]}
      >
        <Input
          size="large"
          placeholder="Search campaign"
          className="search_input_field"
          value={search}
          onChange={(e) => setSearch(e?.target?.value)}
          prefix={<img src={SearchIcon} className="search_icon" />}
        />
        {/* Selected Campaigns  */}
        <Row className="mt-8 mw_overflow_x">
          <Col xs={24} className="d-flex">
            {selectedItems?.slice(0, 2)?.map((item) => (
              <div className="selected_item_div">
                <span>{item?.name}</span>
                {/* <CloseOutlined /> */}
              </div>
            ))}
            {selectedItems.length > 2 && (
              <div className="selected_item_div">
                <span>+{selectedItems.length - 2}</span>
              </div>
            )}
          </Col>
        </Row>
        {/* Content Section */}
        <div className="content_items_main_div mt-8">
          <Row className="content_items_main_row">
            {data?.length === 0 && (
              <span className="no_camp_text">No campaigns created yet!</span>
            )}
            {data?.length > 0 &&
              data.map((item, index) => (
                <Col
                  span={24}
                  className={
                    checkSelected.includes(item?._id)
                      ? "individual_column_active  cursor_pointer"
                      : "individual_column cursor_pointer"
                  }
                  onClick={() => handleItemClick(item, index)}
                >
                  <div className="justify_space w-100 p_8">
                    <div>
                      <span className="title_name text_capitalize"> {item?.name}</span>
                    </div>
                    {checkSelected.includes(item?._id) && (
                      <div>
                        <span>
                          <CheckOutlined />
                        </span>
                      </div>
                    )}
                  </div>
                </Col>
              ))}
          </Row>
        </div>
      </Modal>
    </Row>
  );
};
export default AddCampaignModal;
