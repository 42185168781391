import React, { useEffect, useState } from "react";
import NonProtectedLayout from "../../components/layout/NonProtectedLayout";
import { Col, Row, message } from "antd";
import API_MANAGER from "../../API";
import { useNavigate } from "react-router-dom";
import { IsTokenValid } from "../../utils/middleware";
import Layout from "../../components/layout";
import SubLayout from "../../components/layout/SubLayout";
import FullScreenLoader from "../../components/common/FullScreenLoader";
import MobileHeader from "../../components/layout/MobileHeader";
const Aboutus = () => {
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const isAuthenticated = IsTokenValid();
  useEffect(() => {
    setLoading(true);
    API_MANAGER.getAboutusData()
      .then((res) => {
        setLoading(false);
        setData(res?.data?.data);
      })
      .catch((err) => {
        setLoading(false);
        message.error("something went wrong");
      });
  }, []);

  return (
    <>
      {isAuthenticated ? (
        <Layout>
          <SubLayout page={"About Us"}>
            {/* <FullScreenLoader isLoading={loading} /> */}
            <div className="aboutus_container">
              {/* bannner image */}
              <MobileHeader title={"About Us"} />
              <div className="aboutus_banner2">
                <h2 className="bannerHeading">About us</h2>
              </div>
              <div className="dynamic_content_container">
                <div
                  className="apiData"
                  dangerouslySetInnerHTML={{ __html: data?.description }}
                />
              </div>
            </div>
          </SubLayout>
        </Layout>
      ) : (
        <NonProtectedLayout>
          <FullScreenLoader isLoading={loading} />
          <div style={{ marginTop: "20px" }} className="aboutus_container">
            {/* bannner image */}
            <Row>
              <Col xs={24} md={0}>
                <p className="goback" onClick={() => navigate(-1)}>
                  {"< About us"}
                </p>
              </Col>
              <Col md={0}></Col>
            </Row>
            <div className="aboutus_banner">
              <h2 className="bannerHeading">About us</h2>
            </div>
            <div className="dynamic_content_container">
              {/*API data*/}
              <div
                className="apiData"
                dangerouslySetInnerHTML={{ __html: data?.description }}
              />
            </div>
          </div>
        </NonProtectedLayout>
      )}
    </>
  );
};
export default Aboutus;
