import React, { useState, useEffect } from "react";
import { Row, Col, Form, Input, Button, message, Checkbox } from "antd";
import ForgotImg from "../../Assets/login/forgot.svg";
import EmailImg from "../../Assets/login/email.svg";
import { useNavigate } from "react-router-dom";
import API_MANAGER from "../../API";
import OTP from "../../components/common/forgot/OTP";
import LoginImg from "../../Assets/login/welcome.png";
import Logo from "../../Assets/login/Logo.svg";
import NewPassword from "../../components/common/forgot/NewPassword";
import RightArrow from "../../Assets/login/rightArrow.svg";
import sucess from "../../Assets/otp-verifyed-sucess.svg.svg";
function Forgot() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [otpSent, setOtpSent] = useState(false);
  const [otpVerify, setOtpVerified] = useState(false);
  const [token, setToken] = useState(false);
  const [sucessScreen, setSucessScreen] = useState(false);
  const [email, setEmail] = useState("");
  const [form] = Form.useForm();

  const ForgotPassword = async (values) => {
    try {
      setLoading(true);
      setEmail(values?.email);
      const response = await API_MANAGER.forgotPassword(values);

      setLoading(false);
      setOtpSent(true);
      message.success("OTP sent to your mail.");
      setOtpSent(true);
    } catch (err) {
      if (err?.response?.data?.message?.message) {
        message.error(err?.response?.data?.message?.message);
      } else {
        message.error("Something went wrong. Please try again.");
      }

      setLoading(false);
    }
  };

  if (sucessScreen) {
    return (
      <div className="verifyotp_main_container">
        <Row className=" w-100 h-100" justify={"space-between"}>
          <Col xs={24} md={14}>
            <div className="login_container">
              <div className="verify-logo-img">
                <img src={Logo} className="logo-img" />
              </div>

              <div className="login_form_container">
                <div className="verify_container">
                  <div className="sucess_logo">
                    <img
                      src={sucess}
                      alt="sucess logo"
                      className="sucess_logo_img"
                    />
                  </div>

                  <h3
                    className="main-heading mt-20"
                    style={{ marginTop: "30px", marginBottom: "40px" }}
                  >
                    Password Changed
                  </h3>

                  <Button
                    className="submitBtn w-100"
                    onClick={() => navigate("/login")}
                  >
                    Continue
                    <div className="arrowBtn">
                      <img src={RightArrow} alt="img" />
                    </div>
                  </Button>
                </div>
              </div>
            </div>
          </Col>
          <Col xs={0} md={10} className="loginImgContainer">
            <img src={LoginImg} alt="img" className="loginImg" />
          </Col>
        </Row>
      </div>
    );
  } else {
    return (
      <div className="login_main_container">
        <Row className=" w-100 h-100" justify={"space-between"}>
          <Col xs={24} md={12} xl={15}>
            <div className="login_container">
              <img
                src={Logo}
                className="cursor_pointer"
                onClick={() => navigate("/")}
              />
              <div className="login_form_container">
                <div className="w-100">
                  <div className="forgotHeader">
                    <h3 className="forgotHeading">
                      {otpVerify
                        ? "Change Password"
                        : otpSent
                        ? "Check your mail"
                        : "Forgot Password"}
                    </h3>
                    <p className="desc">
                      {otpVerify ? (
                        "Set your new password below."
                      ) : otpSent ? (
                        <span>
                          We have sent a code on <b>{email}</b>
                        </span>
                      ) : (
                        <span>
                          To reset your password, please enter the <b>email</b>{" "}
                          used to register your account.
                        </span>
                      )}
                    </p>
                  </div>

                  {otpVerify ? (
                    <NewPassword
                      token={token}
                      setSucessScreen={setSucessScreen}
                    />
                  ) : otpSent ? (
                    <Row>
                      <Col span={24}>
                        <OTP
                          setToken={setToken}
                          email={email}
                          setOtpVerified={setOtpVerified}
                        />
                      </Col>
                    </Row>
                  ) : (
                    <>
                      <Form
                        onFinish={ForgotPassword}
                        form={form}
                        className="w-100"
                      >
                        <Row>
                          <Col span={24}>
                            <Form.Item
                              label="Email*"
                              name="email"
                              rules={[
                                {
                                  required: true,
                                  message: "Please input your email!",
                                },
                                {
                                  type: "email",
                                  message: "Please enter correct email!",
                                },
                              ]}
                            >
                              <Input
                                type="email"
                                className="w-100 emailInput"
                                placeholder="abc@gmail.com"
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row>
                          <Col span={24}>
                            <Form.Item>
                              <Button
                                htmlType="submit"
                                style={{ width: "100%" }}
                                className="submitBtn mt-42"
                              >
                                Submit
                                <div className="arrowBtn">
                                  <img src={RightArrow} alt="img" />
                                </div>
                              </Button>
                            </Form.Item>
                          </Col>
                        </Row>
                      </Form>
                    </>
                  )}
                </div>
              </div>
            </div>
          </Col>
          <Col xs={0} md={12} xl={9} className="loginImgContainer">
            <img src={LoginImg} alt="img" className="loginImg" />
            <div className="overlay"></div>
          </Col>
        </Row>
      </div>
    );
  }
}

export default Forgot;
