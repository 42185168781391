import React, { useEffect, useState } from "react";
import { Button, Col, Drawer, Input, Row, message } from "antd";
import userIcon from "../../../Assets/susbcription_mangement/user_icon.svg";
import API_MANAGER from "../../../API";
import { numberWithCommas } from "../../../utils/constant";
import { useNavigate } from "react-router-dom";
import FullScreenLoader from "../FullScreenLoader";

const ByUserDrawer = ({ onCloseDrawer, openDrawer }) => {
  const [standardUserCount, setStandardUserCount] = useState(0);
  const [associateUserCount, setAssociateUserCount] = useState(0);
  const [standardTotal, setStandardTotal] = useState(0);
  const [associateTotal, setAssociateTotal] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [isPlusSub, setIsPlusSub] = useState();
  const [discountCode, setDiscountCode] = useState(null);
  const [discountResponse, setDiscountResponse] = useState(null);
  useEffect(() => {
    setIsPlusSub(JSON.parse(localStorage.getItem("isPlusSubscription")));
  }, []);
  const navigate = useNavigate();
  const handleMinusClick = (type) => {
    if (type === "standard") {
      if (standardUserCount > 0) {
        const newStandardUserCount = standardUserCount - 1;
        setStandardUserCount(newStandardUserCount);
      }
    } else {
      if (associateUserCount > 0) {
        const newAssociateUserCount = associateUserCount - 1;
        setAssociateUserCount(newAssociateUserCount);
      }
    }
  };
  const handlePlusClick = (type) => {
    if (type === "standard") {
      const newStandardUserCount = standardUserCount + 1;
      setStandardUserCount(newStandardUserCount);
    } else {
      const newAssociateUserCount = associateUserCount + 1;
      setAssociateUserCount(newAssociateUserCount);
    }
  };
  useEffect(() => {
    const newStandardTotal = 300 * standardUserCount;
    setStandardTotal(newStandardTotal);
  }, [standardUserCount]);
  useEffect(() => {
    const newAssociateTotal = 200 * associateUserCount;
    setAssociateTotal(newAssociateTotal);
  }, [associateUserCount]);
  useEffect(() => {
    setTotalAmount(standardTotal + associateTotal);
  }, [standardTotal, associateTotal]);

  const handlePay = async () => {
    setLoading(true);
    // if (totalAmount === 0 && standardUserCount === 0 && associateUserCount===0) {
    //   return;
    // }
    if (isPlusSub) {
      if (
        totalAmount === 0 &&
        standardUserCount === 0 &&
        associateUserCount === 0
      ) {
        setLoading(false);
        message.error("Please add users.");
        return;
      }
    } else {
      if (totalAmount === 0 && standardUserCount === 0) {
        setLoading(false);
        message.error("Please add users.");
        return;
      }
    }
    let payloadData = {};
    payloadData.additionalData = [];
    //standard users
    if (standardUserCount > 0) {
      const obj1 = {
        title: "Additional Standard Users",
        price: 300,
        quantity: standardUserCount,
        userType: "standard",
      };
      payloadData.additionalData.push(obj1);
    }
    //associate users
    if (isPlusSub && associateUserCount > 0) {
      const obj2 = {
        title: "Additional Associate Users",
        price: 200,
        quantity: associateUserCount,
        userType: "associate",
      };
      payloadData.additionalData.push(obj2);
    }
    if (discountCode && discountResponse) {
      payloadData["discount"] = discountCode;
    }

    try {
      const response = await API_MANAGER.addNewUser(payloadData);
      window.open(response?.data?.data, "_self");
      // message.success("User(s) successfully added.")
      setLoading(false);
    } catch (error) {
      setLoading(false);
      message.error("Something went wrong. Please try again.");
    }
  };
  const handleDelete = (type) => {
    if (type === "associate") {
      setAssociateTotal(0);
      setAssociateUserCount(0);
    } else {
      setStandardTotal(0);
      setStandardUserCount(0);
    }
  };
  const checkDiscountCode = async () => {
    setTotalAmount(standardTotal + associateTotal);

    setDiscountResponse(null);
    if (discountCode) {
      try {
        const response = await API_MANAGER.discountCode({
          discount: discountCode,
        });

        setDiscountResponse(response?.data?.data);
        if (response?.data?.data) {
          const percentage_off = response?.data?.data?.percent_off;
          const finalPrice =
            standardTotal +
            associateTotal -
            (standardTotal + associateTotal) * (percentage_off / 100);
          setTotalAmount(finalPrice);
        }
      } catch (error) {
        setTotalAmount(standardTotal + associateTotal);
        message.error(
          error?.response?.data?.message?.message ||
            "Something went wrong. Please try again."
        );
      }
    }
  };
  return (
    <>
      <FullScreenLoader isLoading={loading} />
      <Drawer
        getContainer="#modalMount"
        title="Review and Pay"
        placement="right"
        onClose={onCloseDrawer}
        open={openDrawer}
        width={window.innerWidth > 767 ? "456px" : "100%"}
        className="buyuser-main-container"
        contentWrapperStyle={{
          top: "88px",
        }}
      >
        <p className="buydrawer-text">
          "Cinchy.me" makes recruiting for the construction industry easier and
          faster than ever before.
        </p>
        <p className="order-summery">Order Summary</p>
        <Row className="card-one" gutter={[0, 16]}>
          {/* stander user */}
          <Col span={24}>
            <Row justify={"space-between"} className="select-plan">
              <Col span={3} className="align-center-justify ">
                <img src={userIcon} alt="icon" />
              </Col>
              <Col span={11}>
                <Col className="big-card-text">Account User</Col>
                <Col>
                  <div className="price-ctrl-btn">
                    <svg
                      className="cursor-pointer"
                      onClick={() => handleMinusClick("standard")}
                      style={{ height: "100% !important" }}
                      xmlns="http://www.w3.org/2000/svg"
                      width="11"
                      height="100%"
                      viewBox="0 0 11 3"
                      fill="none"
                    >
                      <path
                        d="M4.99937 0.789101L6.45098 0.789062L10.8058 0.789101V2.21015H6.45098L4.99937 2.21025L0.644531 2.21015V0.789101H4.99937Z"
                        fill="#858689"
                      />
                    </svg>
                    <span className="big-card-text">
                      &nbsp;{standardUserCount}&nbsp;
                    </span>
                    <svg
                      className="cursor-pointer"
                      onClick={() => handlePlusClick("standard")}
                      xmlns="http://www.w3.org/2000/svg"
                      width="11"
                      height="100%"
                      viewBox="0 0 11 11"
                      fill="none"
                    >
                      <path
                        d="M4.55015 4.7905V0.527344H6.00176V4.7905H10.3566V6.21155H6.00176V10.4747H4.55015V6.21155H0.195312V4.7905H4.55015Z"
                        fill="#31B948"
                      />
                    </svg>
                  </div>
                </Col>
              </Col>
              <Col className="big-card-text" span={5}>
                ${numberWithCommas(standardTotal.toString()) + ".00"}
              </Col>
              <Col span={2} onClick={() => handleDelete("standard")}>
                <svg
                  className="cursor-pointer"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M15.7148 6V5.2C15.7148 4.0799 15.7148 3.51984 15.4969 3.09202C15.3051 2.71569 14.9991 2.40973 14.6228 2.21799C14.195 2 13.6349 2 12.5148 2H10.9148C9.79474 2 9.23469 2 8.80686 2.21799C8.43054 2.40973 8.12458 2.71569 7.93283 3.09202C7.71484 3.51984 7.71484 4.0799 7.71484 5.2V6M9.71484 11.5V16.5M13.7148 11.5V16.5M2.71484 6H20.7148M18.7148 6V17.2C18.7148 18.8802 18.7148 19.7202 18.3879 20.362C18.1002 20.9265 17.6413 21.3854 17.0768 21.673C16.4351 22 15.595 22 13.9148 22H9.51484C7.83469 22 6.99461 22 6.35287 21.673C5.78839 21.3854 5.32944 20.9265 5.04182 20.362C4.71484 19.7202 4.71484 18.8802 4.71484 17.2V6"
                    stroke="#1638B1"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </Col>
            </Row>
          </Col>
          {/* Association  user */}
          {isPlusSub && (
            <Col span={24}>
              <Row justify={"space-between"} className="select-plan">
                <Col span={3} className="align-center-justify ">
                  <img src={userIcon} alt="icon" />
                </Col>
                <Col span={11}>
                  <Col className="big-card-text">Association User</Col>
                  <Col>
                    <div className="price-ctrl-btn">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="11"
                        onClick={() => handleMinusClick("associate")}
                        height="100%"
                        viewBox="0 0 11 3"
                        fill="none"
                        className="cursor-pointer"
                      >
                        <path
                          d="M4.99937 0.789101L6.45098 0.789062L10.8058 0.789101V2.21015H6.45098L4.99937 2.21025L0.644531 2.21015V0.789101H4.99937Z"
                          fill="#858689"
                        />
                      </svg>
                      <span className="big-card-text">
                        &nbsp;{associateUserCount}&nbsp;
                      </span>
                      <svg
                        className="cursor-pointer"
                        onClick={() => handlePlusClick("associate")}
                        xmlns="http://www.w3.org/2000/svg"
                        width="11"
                        height="100%"
                        viewBox="0 0 11 11"
                        fill="none"
                      >
                        <path
                          d="M4.55015 4.7905V0.527344H6.00176V4.7905H10.3566V6.21155H6.00176V10.4747H4.55015V6.21155H0.195312V4.7905H4.55015Z"
                          fill="#31B948"
                        />
                      </svg>
                    </div>
                  </Col>
                </Col>
                <Col className="big-card-text" span={5}>
                  ${numberWithCommas(associateTotal.toString()) + ".00"}
                </Col>
                <Col span={2} onClick={() => handleDelete("associate")}>
                  <svg
                    className="cursor-pointer"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M15.7148 6V5.2C15.7148 4.0799 15.7148 3.51984 15.4969 3.09202C15.3051 2.71569 14.9991 2.40973 14.6228 2.21799C14.195 2 13.6349 2 12.5148 2H10.9148C9.79474 2 9.23469 2 8.80686 2.21799C8.43054 2.40973 8.12458 2.71569 7.93283 3.09202C7.71484 3.51984 7.71484 4.0799 7.71484 5.2V6M9.71484 11.5V16.5M13.7148 11.5V16.5M2.71484 6H20.7148M18.7148 6V17.2C18.7148 18.8802 18.7148 19.7202 18.3879 20.362C18.1002 20.9265 17.6413 21.3854 17.0768 21.673C16.4351 22 15.595 22 13.9148 22H9.51484C7.83469 22 6.99461 22 6.35287 21.673C5.78839 21.3854 5.32944 20.9265 5.04182 20.362C4.71484 19.7202 4.71484 18.8802 4.71484 17.2V6"
                      stroke="#1638B1"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </Col>
              </Row>
            </Col>
          )}

          <Col span={24} className="note-text">
            <span>Note:</span> The cost of the user is calculated on a prorated
            basis to the next subscription renewal date
          </Col>
          <Col span={24}>
            <Row justify={"space-between"} align={"middle"}>
              <Col xs={16} className="total-amount">
                Total Amount
              </Col>
              <Col xs={8} className="total-amount">
                ${numberWithCommas(parseFloat(totalAmount).toFixed(2))}
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="card-two" gutter={[0, 8]}>
          <p className="order-summery">Discount code</p>
          <Input
            // disabled={totalAmount === 0}
            suffix={
              <div
                className="apply-text cursor-pointer"
                onClick={() => checkDiscountCode()}
              >
                Apply
              </div>
            }
            placeholder="Apply Discount Code"
            className="mb-8 discount-input"
            onChange={(e) => setDiscountCode(e?.target?.value)}
          />
          {discountResponse && (
            <div>
              <p className="discountMsg">
                The discount will apply to the total cost (excluding taxes).
                Please note, the discount does not apply to renewals.
              </p>
            </div>
          )}
          <p className="buydrawer-text">
            Note: You will be redirected to Stripe for payment. We will not
            store your credit card information.
          </p>
          <Button
            className="pay-btn"
            // disabled={totalAmount === 0 ? true : false}
            onClick={handlePay}
          >
            Pay
          </Button>
        </Row>
      </Drawer>
    </>
  );
};

export default ByUserDrawer;
