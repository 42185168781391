import { Row, Col, Modal, Button, Radio, message } from "antd";
import React from "react";
import API_MANAGER from "../../../API";

export function JobActiveDeactivateModal({
  visible,
  loading,
  advertId,
  isActive,
  handleSubmit,
  onCancel,
  jobName,
}) {
  // here calling api

  return (
    <Modal
      className="candidateDeleteModal"
      centered
      onCancel={() => {
        onCancel();
      }}
      open={visible}
      footer={null}
      title={!isActive ? "Activate Job?" : "Deactivate Job?"}
    >
      <Row className="header">
        <Col>
          <p className="desc">
            Are you sure you want to {!isActive ? "activate" : "deactivate"} the
            <span className="span_highlighted_text"> {jobName}</span> job
            advert?
          </p>
        </Col>
      </Row>

      <Row align={"middle"} gutter={16} justify={"end"}>
        <Col>
          <Button
            className="cancelBtn"
            onClick={() => {
              onCancel();
            }}
          >
            Cancel
          </Button>
        </Col>
        <Col>
          <Button
            className="confirmBtn"
            onClick={() => handleSubmit(advertId, isActive)}
            loading={loading}
          >
            {!isActive ? "Activate" : "Deactivate"}
          </Button>
        </Col>
      </Row>
    </Modal>
  );
}
export function UpdateApplicantProfileStatus({ visible, setVisible }) {
  return (
    <Modal
      className="updateStatusModal"
      centered
      onCancel={() => setVisible(false)}
      open={visible}
      footer={null}
      title={"Update Status For:"}
    >
      <Row className="header">
        <Col>
          <p className="desc">Robert Dave, Jonothan Wake and Jake Dave</p>
        </Col>
      </Row>
      <Row>
        <Radio.Group className="radioBtns">
          <p className="radioStage">Current Stage</p>
          <Radio value={"shortlisted"}>Shortlist Offered</Radio>
          <p className="radioStage">Next Stage</p>
          <Radio value={"offered"}>Role Offered</Radio>
        </Radio.Group>
      </Row>
      <Row align={"middle"} gutter={16} justify={"end"} className="btnBox">
        <Col>
          <Button className="cancelBtn" onClick={() => setVisible(false)}>
            Cancel
          </Button>
        </Col>
        <Col>
          <Button className="confirmBtn">Update</Button>
        </Col>
      </Row>
    </Modal>
  );
}

export function DeleteModal({
  visible,
  setVisible,
  text,
  handleSubmit,
  loading,
}) {
  return (
    <Modal
      className="candidateDeleteModal"
      centered
      onCancel={() => {
        setVisible(false);
      }}
      open={visible}
      footer={null}
      title={`Delete ${text}?`}
    >
      <Row className="header">
        <Col>
          <p className="desc">
            Are you sure you want to delete selected {text}?
          </p>
        </Col>
      </Row>

      <Row align={"middle"} gutter={16} justify={"end"}>
        <Col>
          <Button
            className="confirmBtn"
            onClick={() => handleSubmit()}
            loading={loading}
          >
            Delete
          </Button>
        </Col>
        <Col>
          <Button
            className="cancelBtn"
            onClick={() => {
              setVisible(false);
            }}
          >
            Cancel
          </Button>
        </Col>
      </Row>
    </Modal>
  );
}
export function DeleteAdvertModal({
  visible,
  setVisible,
  text,
  handleSubmit,
  loading,
}) {
  return (
    <Modal
      className="candidateDeleteModal"
      centered
      onCancel={() => {
        setVisible(false);
      }}
      open={visible}
      footer={null}
      title={`Delete ${text}?`}
    >
      <Row className="header">
        <Col>
          <p className="desc">
            Are you sure you want to delete selected {text}?
          </p>
        </Col>
      </Row>

      <Row align={"middle"} gutter={16} justify={"end"}>
        <Col>
          <Button
            className="confirmBtn"
            onClick={() => handleSubmit()}
            loading={loading}
          >
            Delete
          </Button>
        </Col>
        <Col>
          <Button
            className="cancelBtn"
            onClick={() => {
              setVisible(false);
            }}
          >
            Cancel
          </Button>
        </Col>
      </Row>
    </Modal>
  );
}
