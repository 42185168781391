import { Col, Row } from "antd";
import React from "react";
import target_image from "../../../Assets/advert_detail/target.svg";
import AdvertSummaryCard from "./AdvertSummaryCard";
import Icon1 from "../../../Assets/campagin/cards/icon1.svg";
import Icon2 from "../../../Assets/campagin/cards/icon2.svg";
function AdvertSummary({ advertDetail }) {
  return (
    <div className="advert_summary_container">
      <Row className="ml-24">
        <h3>Advert Summary</h3>
      </Row>
      <Row gutter={[32, 20]} className="ml-24">
        <Col xs={24} lg={10}>
          <div className="campaignCard campaignDetailCardOne">
            <Row justify={"space-between"} style={{ height: "100%" }}>
              <Col span={15}>
                <Row gutter={{ xs: 8, md: 16 }} align={"middle"}>
                  <Col>
                    <img src={Icon2} alt="img" />
                  </Col>
                  <Col xs={0} md={20} style={{ width: "calc(100% -52px)" }}>
                    <p className="title">Target Headcount</p>
                  </Col>
                  <Col md={0} style={{ width: "calc(100% -52px)" }}>
                    <p className="amount_mobile">
                      {advertDetail?.targetHeadcount
                        ? advertDetail?.targetHeadcount
                        : 0}
                    </p>{" "}
                  </Col>
                </Row>
                <Row>
                  <Col xs={0} md={24}>
                    <p className="amount">
                      {advertDetail?.targetHeadcount
                        ? advertDetail?.targetHeadcount
                        : 0}
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col xs={24} md={0}>
                    <p className="title_mobile">Target Headcount</p>
                  </Col>
                </Row>
              </Col>
              <Col span={9}>
                <div className="detailBox">
                  <Row
                    align={"middle"}
                    justify={"center"}
                    style={{ height: "100%" }}
                  >
                    <div>
                      <p className="data">
                        {advertDetail?.roleAccepted
                          ? advertDetail?.roleAccepted
                          : 0}
                        /
                        {advertDetail?.targetHeadcount
                          ? advertDetail?.targetHeadcount
                          : 0}
                      </p>
                      <p className="detail">Total Accepted</p>
                    </div>
                  </Row>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
        <Col xs={24} lg={14}>
          <div className="campaignCard campaignDetailCardTwo">
            <Row gutter={16}>
              <Col span={8}>
                <div className="detailBox">
                  <Row justify={"center"}>
                    <img src={Icon1} alt="img" />
                  </Row>
                  <Row className="mt-16">
                    <p className="data">
                      {advertDetail?.applied ? advertDetail?.applied : 0}
                    </p>
                    <p className="detail">Applied</p>
                  </Row>
                </div>
              </Col>
              <Col span={8}>
                <div className="detailBox">
                  <Row justify={"center"}>
                    <img src={Icon1} alt="img" />
                  </Row>
                  <Row className="mt-16">
                    <p className="data">
                      {advertDetail?.hardReview ? advertDetail?.hardReview : 0}
                    </p>
                    <p className="detail">Hard Review</p>
                  </Row>
                </div>
              </Col>
              <Col span={8}>
                <div className="detailBox">
                  <Row justify={"center"}>
                    <img src={Icon1} alt="img" />
                  </Row>
                  <Row className="mt-16">
                    <p className="data">
                      {advertDetail?.roleOffered
                        ? advertDetail?.roleOffered
                        : 0}
                    </p>
                    <p className="detail">Role Offered</p>
                  </Row>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default AdvertSummary;
