import { Col, Row } from "antd";
import SuccessIcon from "../../Assets/campagin/success.svg";
import DangerIcon from "../../Assets/danger.svg";
import { useEffect } from "react";
const AlertState = ({ state, message, alertOpen, setAlertOpen }) => {
  //state----------->>>>>>>>>> error/success

  useEffect(() => {
    if (alertOpen) {
      setTimeout(() => {
        setAlertOpen(false);
      }, 5000);
    }
  }, [alertOpen]);
  return (
    <>
      {alertOpen && (
        <div className="AlertStateMaindiv">
          {/* Handling Success state */}
          {state === "success" && (
            <Row className="success_state_row">
              <Col xs={24} className="outerBox_success align-center-justify">
                <img src={SuccessIcon} alt="successIcon" />
                <span className="messageText_success">
                  {message || "Successfully done!"}
                </span>
              </Col>
            </Row>
          )}

          {/* Handling Error State */}
          {state === "error" && (
            <Row className="error_state_row">
              <Col xs={24} className="outerBox_error align-center-justify">
                <img src={DangerIcon} alt="ErrorIcon" />
                <span className="messageText_error">
                  {message || "Facing some error"}
                </span>
              </Col>
            </Row>
          )}
        </div>
      )}
    </>
  );
};
export default AlertState;
