import React from "react";
import { Row, Col, Button } from "antd";
import NoElementImg from "../../Assets/noElement.svg";

function NoElementBox({ title, description, btnText , handleClick}) {
  return (
    <div>
      <div className="noElementBox">
        <Row justify={"center"} align={"middle"}>
          <Col>
            <img src={NoElementImg} alt="img" />
          </Col>
        </Row>
        <Row justify={"center"} align={"middle"}>
          <Col>
            <p className="title">{title}</p>
          </Col>
        </Row>
        {description && (
          <Row justify={"center"} align={"middle"}>
            <Col>
              <p className="desc">{description}</p>
            </Col>
          </Row>
        )}

        {btnText && (
          <Row justify={"center"} align={"middle"}>
            <Col>
              <Button className="btn" onClick={() => handleClick()}>
                {btnText}
              </Button>
            </Col>
          </Row>
        )}
      </div>
    </div>
  );
}

export default NoElementBox;
