import { Button, Col, Modal, Row, message } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import API_MANAGER from "../../API";
import { useState } from "react";
import FullScreenLoader from "../../components/common/FullScreenLoader";

const DeleteUserModal = ({ data, setDeleteModal, deleteModal }) => {
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();
  const handleOk = async () => {
    setLoading(true);
    try {
      const response = await API_MANAGER.deleteUser(id);
      setLoading(false);
      message.success("User successfully deleted.");
      navigate("/user-management");
    } catch (error) {
      setLoading(false);
      message.error(
        error?.response?.data?.message?.message ||
          "Something went wrong. Please try again."
      );
    }
    setDeleteModal(false);
  };
  const handleCancel = () => {
    setDeleteModal(false);
  };
  return (
    <>
      <FullScreenLoader isLoading={loading} />
      <Modal
        title="Delete User?"
        centered
        open={deleteModal}
        className="deleteUserModalMainModal"
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Row>
            <Col span={24} align={"right"} className="justify_items_end">
              <Button
                key="submit"
                className="black_color_button"
                onClick={handleOk}
              >
                Okay
              </Button>
              <Button
                key="submit"
                className="cancel_btn"
                onClick={handleCancel}
              >
                Cancel
              </Button>
              ,
            </Col>
          </Row>,
        ]}
      >
        <Row>
          <Col span={24}>
            <span className="desc">
              Are you sure , you want to delete{" "}
              <span>{data?.firstName + " " + data?.lastName}</span>{" "}
              permanentaly?
            </span>
          </Col>
        </Row>
      </Modal>
    </>
  );
};
export default DeleteUserModal;
