import React, { useEffect, useState } from "react";
import { Avatar, Button, Col, Input, Modal, Row, message } from "antd";
import shareIcon from "../../../Assets/shareIcon.svg";
import checkedSign from "../../../Assets/susbcription_mangement/checkIcon.svg";
import API_MANAGER from "../../../API";
const ShareNews = ({
  showModal,
  handleCancel,
  handleOk,
  isModalOpen,
  shareNews,
}) => {
  const [shareList, setShareList] = useState([]);
  const [userData, setUserData] = useState([]);
  const [search, setSearch] = useState(null);
  const getUserData = async () => {
    const param = {
      search: search ? search : "",
      type: "NEWS",
    };
    try {
      const data = await API_MANAGER.getRecruiterUserList(param);
      setUserData(data?.data?.data);
    } catch (error) {
      message.error("Something went wrong. Please try again.");
    }
  };
  // implimenting debouncing
  useEffect(() => {
    getUserData();
  }, [search]);

  useEffect(() => {
    getUserData();
  }, []);

  const handleRowSelect = (ind, id) => {
    if (shareList.includes(id)) {
      let arr = [...shareList];
      let removeValueIndex = shareList.indexOf(id);
      arr.splice(removeValueIndex, 1);
      setShareList(arr);
    } else {
      setShareList((prev) => [...prev, id]);
    }
  };

  return (
    <>
      <Modal
        centered
        title="Users"
        open={isModalOpen}
        onOk={() => shareNews(shareList)}
        okText={"Share"}
        onCancel={handleCancel}
        wrapClassName="newsModalSearch"
      >
        <Input
          className="modal-search mb-20"
          size="large"
          placeholder="Search user"
          onChange={(e) => setSearch(e?.target?.value)}
          prefix={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="21"
              height="20"
              viewBox="0 0 21 20"
              fill="none"
            >
              <path
                d="M19.8947 19.0002L15.5517 14.6572M15.5517 14.6572C16.2946 13.9143 16.8839 13.0324 17.286 12.0618C17.688 11.0911 17.8949 10.0508 17.8949 9.00021C17.8949 7.9496 17.688 6.90929 17.286 5.93866C16.8839 4.96803 16.2946 4.08609 15.5517 3.34321C14.8088 2.60032 13.9269 2.01103 12.9563 1.60898C11.9857 1.20693 10.9453 1 9.89474 1C8.84414 1 7.80382 1.20693 6.83319 1.60898C5.86256 2.01103 4.98062 2.60032 4.23774 3.34321C2.73741 4.84354 1.89453 6.87842 1.89453 9.00021C1.89453 11.122 2.73741 13.1569 4.23774 14.6572C5.73807 16.1575 7.77295 17.0004 9.89474 17.0004C12.0165 17.0004 14.0514 16.1575 15.5517 14.6572Z"
                stroke="#1638B1"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          }
        />
        <Row>
          <Col span={24} className="modal-card-parent">
            {userData?.map((e, ind) => (
              <Row
                key={e?.user?.id}
                justify={"space-between"}
                align={"middle"}
                className="modal-cards cursor_pointer"
                style={
                  shareList.includes(e?.user?.id)
                    ? { background: "#e8eaec" }
                    : { background: "#ffffff" }
                }
                onClick={() => handleRowSelect(ind, e?.user?.id)}
              >
                <Col xs={3} md={2}>
                  <Avatar
                    style={{
                      backgroundColor: "#f56a00",
                      verticalAlign: "middle",
                    }}
                  >
                    {e?.user?.firstName?.[0]}
                    {e?.user?.lastName?.[0]}
                  </Avatar>
                </Col>

                <Col xs={18} md={19} className="modal-text">
                  {e?.user?.firstName}&nbsp; {e?.user?.lastName}
                </Col>
                <Col xs={2} md={2}>
                  {/* <img src={shareIcon} alt="shareIocn" /> */}
                  {shareList.includes(e?.user?.id) ? (
                    <img src={checkedSign} alt="icons" />
                  ) : (
                    ""
                  )}
                </Col>
              </Row>
            ))}
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default ShareNews;
