import React, { useState } from "react";
import { Row, Col, Divider } from "antd";
import moment from "moment";
import { DownOutlined, UpOutlined } from "@ant-design/icons";

function JobPositionCard({ data, isHidden }) {
  const [refreeDetailsOpen, setRefreeDetailsOpen] = useState(false);
  const handleDownButton = () => {
    if (refreeDetailsOpen) setRefreeDetailsOpen(false);
    else setRefreeDetailsOpen(true);
  };
  const replaceString = (string) => {
    if (typeof string === "number") {
      string = string.toString();
    }
    return string?.replace(/[^\s]/g, "x");
  };
  return (
    <div className="jobPositionCard">
      <Row justify={"space-between"} align={"middle"}>
        <Col>
          <p className="title">{data?.jobTitle?.name || "No data"}</p>
        </Col>
        {data?.currentRole && (
          <Col>
            <div className="currentRole">Current Role</div>
          </Col>
        )}
      </Row>
      <div className="mt-20">
        <Row gutter={8}>
          <Col span={12}>
            <p className="item">
              Start Date:{" "}
              <span>{moment(data?.startDate).format("DD MMM YYYY")}</span>
            </p>
          </Col>
          <Col span={12}>
            <p className="item">
              End Date:{" "}
              <span>
                {!data?.currentRole
                  ? moment(data?.endDate).format("DD MMM YYYY")
                  : "----"}
              </span>
            </p>
          </Col>
        </Row>
      </div>
      <Divider className="horizontalDivider" />
      <div>
        <Row gutter={16} className="itemRow">
          <Col xs={12} md={8}>
            <p className="item">Employer</p>
            <p className="value">{data?.employerName}</p>
          </Col>

          <Col xs={12} md={8}>
            <p className="item">Project Name</p>
            <p className="value">{data?.projectName}</p>
          </Col>
          <Col xs={12} md={8} align={"left"}>
            <p className="item">Industry</p>
            <p className="value">{data?.industry?.name}</p>
          </Col>
        </Row>
        <Row gutter={16} className="itemRow">
          <Col xs={12} md={8}>
            <p className="item">Discipline</p>
            <p className="value">{data?.discipline?.name}</p>
          </Col>
          <Col xs={12} md={8}>
            <p className="item">Inductions</p>
            {data?.inductions.map((item, index) => (
              <p className="value">
                {item?.name}
                {index !== data.inductions.length - 1 ? "," : ""}
              </p>
            ))}
          </Col>
          <Col span={8}>
            <p className="item">Skills</p>
            {data?.skills?.map((item, index) => (
              <p className="value">
                {item?.name}
                {index !== data.skills.length - 1 ? "," : ""}
              </p>
            ))}
          </Col>
        </Row>
      </div>
      <Divider className="horizontalDivider" />
      {/* Refree Details Section */}
      <Row className="cursor_pointer" onClick={handleDownButton}>
        <Col xs={12} className="value">
          Referee Details
        </Col>
        <Col xs={12} align={"right"} className="value">
          {!refreeDetailsOpen ? (
            <DownOutlined className="cursor_pointer" />
          ) : (
            <UpOutlined className="cursor_pointer" />
          )}
        </Col>
        {refreeDetailsOpen && (
          <Col xs={24}>
            <Row className="mt-20 transition-smooth">
              <Col xs={12} md={8}>
                <p className="item">Referee Name</p>
                {!isHidden ? (
                  <p className="value">{data?.refereeName || "-"}</p>
                ) : (
                  <p className="value text_blur">
                    {replaceString(data?.refereeName) || "-"}
                  </p>
                )}
              </Col>
              <Col xs={12} md={8}>
                <p className="item">Referee Position</p>
                {!isHidden ? (
                  <p className="value">{data?.refereePosition || "-"}</p>
                ) : (
                  <p className="value text_blur">
                    {replaceString(data?.refereePosition) || "-"}
                  </p>
                )}
              </Col>
              <Col xs={12} md={8}>
                <p className="item">Referee Email/Phone No.</p>
                {!isHidden ? (
                  <p className="value">{data?.refereeContact || "-"}</p>
                ) : (
                  <p className="value text_blur">
                    {replaceString(data?.refereeContact) || "-"}
                  </p>
                )}
              </Col>
            </Row>
          </Col>
        )}
      </Row>
      {/* <Divider className="horizontalDivider" /> */}
    </div>
  );
}

export default JobPositionCard;
