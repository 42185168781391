export const PcView = ({ color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
  >
    <path
      d="M2.24832 2.25C2.04942 2.25002 1.85866 2.32904 1.71801 2.46969C1.57737 2.61034 1.49834 2.80109 1.49832 3V8.87402C1.48493 8.95502 1.48493 9.03766 1.49832 9.11865V15C1.49834 15.1989 1.57737 15.3897 1.71801 15.5303C1.85866 15.671 2.04942 15.75 2.24832 15.75H15.7483C15.9472 15.75 16.138 15.671 16.2786 15.5303C16.4193 15.3897 16.4983 15.1989 16.4983 15V9.12598C16.5117 9.04498 16.5117 8.96234 16.4983 8.88135V3C16.4983 2.80109 16.4193 2.61034 16.2786 2.46969C16.138 2.32904 15.9472 2.25002 15.7483 2.25H2.24832ZM2.99832 3.75H14.9983V8.25H2.99832V3.75ZM2.99832 9.75H14.9983V14.25H2.99832V9.75Z"
      fill={`${color}`}
    />
  </svg>
);
export const PhoneView = ({ color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
  >
    <path
      d="M14.25 15.375V2.625C14.25 1.59 13.41 0.75 12.375 0.75H5.625C4.59 0.75 3.75 1.59 3.75 2.625V15.375C3.75 16.41 4.59 17.25 5.625 17.25H12.375C13.41 17.25 14.25 16.41 14.25 15.375ZM12.75 15.75H5.25V2.25H6.46875L7.01175 3.33525C7.1385 3.5895 7.398 3.75 7.68225 3.75H10.2863C10.5705 3.75 10.83 3.5895 10.9567 3.33525L11.4998 2.25H12.75V15.75Z"
      fill={`${color}`}
    />
  </svg>
);

export const DownArrowIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M6 9L12 15L18 9"
      stroke="#122D8E"
      strokeWidth="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
export const DragablleIcon = () => (
  <svg
    width="20"
    height="12"
    viewBox="0 0 20 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 2.11198C-4.61233e-09 2.33085 0.0431095 2.54758 0.126867 2.74979C0.210625 2.95199 0.333391 3.13573 0.488155 3.29049C0.64292 3.44525 0.826652 3.56802 1.02886 3.65178C1.23107 3.73554 1.4478 3.77865 1.66667 3.77865C1.88554 3.77865 2.10226 3.73554 2.30447 3.65178C2.50668 3.56802 2.69041 3.44525 2.84518 3.29049C2.99994 3.13573 3.12271 2.95199 3.20647 2.74979C3.29022 2.54758 3.33333 2.33085 3.33333 2.11198C3.33333 1.89311 3.29022 1.67638 3.20647 1.47417C3.12271 1.27196 2.99994 1.08823 2.84518 0.933467C2.69041 0.778703 2.50668 0.655938 2.30447 0.57218C2.10226 0.488422 1.88554 0.445312 1.66667 0.445312C1.4478 0.445312 1.23107 0.488422 1.02886 0.57218C0.826652 0.655938 0.64292 0.778703 0.488155 0.933467C0.333391 1.08823 0.210625 1.27196 0.126867 1.47417C0.0431095 1.67638 -4.61233e-09 1.89311 0 2.11198ZM8.33333 2.11198C8.33333 2.55401 8.50893 2.97793 8.82149 3.29049C9.13405 3.60305 9.55797 3.77865 10 3.77865C10.442 3.77865 10.8659 3.60305 11.1785 3.29049C11.4911 2.97793 11.6667 2.55401 11.6667 2.11198C11.6667 1.66995 11.4911 1.24603 11.1785 0.933467C10.8659 0.620907 10.442 0.445312 10 0.445312C9.55797 0.445312 9.13405 0.620907 8.82149 0.933467C8.50893 1.24603 8.33333 1.66995 8.33333 2.11198ZM16.6667 2.11198C16.6667 2.55401 16.8423 2.97793 17.1548 3.29049C17.4674 3.60305 17.8913 3.77865 18.3333 3.77865C18.7754 3.77865 19.1993 3.60305 19.5118 3.29049C19.8244 2.97793 20 2.55401 20 2.11198C20 1.66995 19.8244 1.24603 19.5118 0.933467C19.1993 0.620907 18.7754 0.445312 18.3333 0.445312C17.8913 0.445312 17.4674 0.620907 17.1548 0.933467C16.8423 1.24603 16.6667 1.66995 16.6667 2.11198Z"
      fill="#1638B1"
    />
    <path
      d="M0 9.89323C-4.61233e-09 10.1121 0.0431095 10.3288 0.126867 10.531C0.210625 10.7332 0.333391 10.917 0.488155 11.0717C0.64292 11.2265 0.826652 11.3493 1.02886 11.433C1.23107 11.5168 1.4478 11.5599 1.66667 11.5599C1.88554 11.5599 2.10226 11.5168 2.30447 11.433C2.50668 11.3493 2.69041 11.2265 2.84518 11.0717C2.99994 10.917 3.12271 10.7332 3.20647 10.531C3.29022 10.3288 3.33333 10.1121 3.33333 9.89323C3.33333 9.67436 3.29022 9.45763 3.20647 9.25542C3.12271 9.05321 2.99994 8.86948 2.84518 8.71472C2.69041 8.55995 2.50668 8.43719 2.30447 8.35343C2.10226 8.26967 1.88554 8.22656 1.66667 8.22656C1.4478 8.22656 1.23107 8.26967 1.02886 8.35343C0.826652 8.43719 0.64292 8.55995 0.488155 8.71472C0.333391 8.86948 0.210625 9.05321 0.126867 9.25542C0.0431095 9.45763 -4.61233e-09 9.67436 0 9.89323ZM8.33333 9.89323C8.33333 10.3353 8.50893 10.7592 8.82149 11.0717C9.13405 11.3843 9.55797 11.5599 10 11.5599C10.442 11.5599 10.8659 11.3843 11.1785 11.0717C11.4911 10.7592 11.6667 10.3353 11.6667 9.89323C11.6667 9.4512 11.4911 9.02728 11.1785 8.71472C10.8659 8.40216 10.442 8.22656 10 8.22656C9.55797 8.22656 9.13405 8.40216 8.82149 8.71472C8.50893 9.02728 8.33333 9.4512 8.33333 9.89323ZM16.6667 9.89323C16.6667 10.3353 16.8423 10.7592 17.1548 11.0717C17.4674 11.3843 17.8913 11.5599 18.3333 11.5599C18.7754 11.5599 19.1993 11.3843 19.5118 11.0717C19.8244 10.7592 20 10.3353 20 9.89323C20 9.4512 19.8244 9.02728 19.5118 8.71472C19.1993 8.40216 18.7754 8.22656 18.3333 8.22656C17.8913 8.22656 17.4674 8.40216 17.1548 8.71472C16.8423 9.02728 16.6667 9.4512 16.6667 9.89323Z"
      fill="#1638B1"
    />
  </svg>
);
export const DropableIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="89"
    height="95"
    viewBox="0 0 89 95"
    fill="none"
  >
    <path
      d="M44.5 89.3906C68.9386 89.3906 88.75 69.5792 88.75 45.1406C88.75 20.702 68.9386 0.890625 44.5 0.890625C20.0614 0.890625 0.25 20.702 0.25 45.1406C0.25 69.5792 20.0614 89.3906 44.5 89.3906Z"
      fill="#D6DDF2"
    />
    <path
      d="M69.8697 26.2578H19.1297C17.5004 26.2578 16.1797 27.5786 16.1797 29.2078V91.1578C16.1797 92.787 17.5004 94.1078 19.1297 94.1078H69.8697C71.4989 94.1078 72.8197 92.787 72.8197 91.1578V29.2078C72.8197 27.5786 71.4989 26.2578 69.8697 26.2578Z"
      fill="white"
    />
    <path
      d="M39.1881 32.1641H23.8481C22.8706 32.1641 22.0781 32.9565 22.0781 33.9341C22.0781 34.9116 22.8706 35.7041 23.8481 35.7041H39.1881C40.1657 35.7041 40.9581 34.9116 40.9581 33.9341C40.9581 32.9565 40.1657 32.1641 39.1881 32.1641Z"
      fill="#E1EBFA"
    />
    <path
      d="M39.1881 56.9375H23.8481C22.8706 56.9375 22.0781 57.73 22.0781 58.7075C22.0781 59.685 22.8706 60.4775 23.8481 60.4775H39.1881C40.1657 60.4775 40.9581 59.685 40.9581 58.7075C40.9581 57.73 40.1657 56.9375 39.1881 56.9375Z"
      fill="#E1EBFA"
    />
    <path
      d="M63.968 41.0156H25.028C23.7246 41.0156 22.668 42.0722 22.668 43.3756V49.2756C22.668 50.579 23.7246 51.6356 25.028 51.6356H63.968C65.2714 51.6356 66.328 50.579 66.328 49.2756V43.3756C66.328 42.0722 65.2714 41.0156 63.968 41.0156Z"
      stroke="#1485FD"
      strokeWidth="1.18"
    />
    <path
      d="M63.9681 65.2031H25.0281C23.3989 65.2031 22.0781 66.5239 22.0781 68.1531V72.8731C22.0781 74.5024 23.3989 75.8231 25.0281 75.8231H63.9681C65.5974 75.8231 66.9181 74.5024 66.9181 72.8731V68.1531C66.9181 66.5239 65.5974 65.2031 63.9681 65.2031Z"
      fill="#DFEAFB"
    />
    <path
      d="M50.993 52.8141C52.6222 52.8141 53.943 51.4933 53.943 49.8641C53.943 48.2348 52.6222 46.9141 50.993 46.9141C49.3637 46.9141 48.043 48.2348 48.043 49.8641C48.043 51.4933 49.3637 52.8141 50.993 52.8141Z"
      fill="#DFEAFB"
    />
    <path
      d="M53.2964 62.4712C52.8244 62.4712 52.3836 62.4712 51.9824 62.4458C51.4883 62.4124 51.0129 62.2433 50.6086 61.9571C50.2043 61.671 49.8867 61.2789 49.6909 60.8239L47.2017 55.9045C47.0191 55.6919 46.9279 55.4158 46.9481 55.1363C46.9682 54.8568 47.0979 54.5966 47.309 54.4124C47.482 54.2733 47.6978 54.1985 47.9197 54.2006C48.0831 54.2053 48.2435 54.2454 48.3898 54.3182C48.5362 54.3909 48.6651 54.4945 48.7675 54.6218L49.898 56.1647L49.9151 56.1847V50.3231C49.9151 50.0323 50.0306 49.7534 50.2362 49.5477C50.4419 49.3421 50.7208 49.2266 51.0116 49.2266C51.3024 49.2266 51.5813 49.3421 51.7869 49.5477C51.9926 49.7534 52.1081 50.0323 52.1081 50.3231V54.1581C52.0954 54.017 52.1122 53.8747 52.1574 53.7405C52.2027 53.6062 52.2755 53.4828 52.371 53.3782C52.4666 53.2736 52.583 53.1901 52.7126 53.1329C52.8423 53.0758 52.9824 53.0463 53.1241 53.0463C53.2658 53.0463 53.4059 53.0758 53.5356 53.1329C53.6652 53.1901 53.7816 53.2736 53.8771 53.3782C53.9727 53.4828 54.0455 53.6062 54.0907 53.7405C54.136 53.8747 54.1528 54.017 54.1401 54.1581V54.9575C54.1273 54.8164 54.1441 54.6742 54.1894 54.5399C54.2347 54.4056 54.3074 54.2823 54.403 54.1777C54.4986 54.0731 54.6149 53.9895 54.7446 53.9324C54.8742 53.8752 55.0144 53.8457 55.1561 53.8457C55.2977 53.8457 55.4379 53.8752 55.5675 53.9324C55.6972 53.9895 55.8135 54.0731 55.9091 54.1777C56.0047 54.2823 56.0774 54.4056 56.1227 54.5399C56.168 54.6742 56.1848 54.8164 56.172 54.9575V55.5735C56.1593 55.4324 56.1761 55.2901 56.2214 55.1559C56.2666 55.0216 56.3394 54.8982 56.435 54.7936C56.5305 54.689 56.6469 54.6055 56.7765 54.5484C56.9062 54.4912 57.0463 54.4617 57.188 54.4617C57.3297 54.4617 57.4698 54.4912 57.5995 54.5484C57.7292 54.6055 57.8455 54.689 57.9411 54.7936C58.0366 54.8982 58.1094 55.0216 58.1547 55.1559C58.1999 55.2901 58.2167 55.4324 58.204 55.5735V59.3123C58.1839 60.4622 57.6641 62.401 55.8357 62.401C55.703 62.4069 54.5784 62.4718 53.2987 62.4718L53.2964 62.4712Z"
      fill="#1485FD"
      stroke="white"
      strokeWidth="0.59"
    />
  </svg>
);

export const InputSearchSuffix = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M19.0002 19.0002L14.6572 14.6572M14.6572 14.6572C15.4001 13.9143 15.9894 13.0324 16.3914 12.0618C16.7935 11.0911 17.0004 10.0508 17.0004 9.00021C17.0004 7.9496 16.7935 6.90929 16.3914 5.93866C15.9894 4.96803 15.4001 4.08609 14.6572 3.34321C13.9143 2.60032 13.0324 2.01103 12.0618 1.60898C11.0911 1.20693 10.0508 1 9.00021 1C7.9496 1 6.90929 1.20693 5.93866 1.60898C4.96803 2.01103 4.08609 2.60032 3.34321 3.34321C1.84288 4.84354 1 6.87842 1 9.00021C1 11.122 1.84288 13.1569 3.34321 14.6572C4.84354 16.1575 6.87842 17.0004 9.00021 17.0004C11.122 17.0004 13.1569 16.1575 14.6572 14.6572Z"
      stroke="#1638B1"
      strokeWidth="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
export const InputSearchCrossIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z"
      fill="#A7AEBB"
    />
  </svg>
);

export const TableStatusImage = ({ color }) => (
  <svg
    width="16"
    height="15"
    viewBox="0 0 16 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8 9.75H4.85C3.87311 9.75 3.38466 9.75 2.9872 9.87057C2.09232 10.142 1.39203 10.8423 1.12057 11.7372C1 12.1347 1 12.6231 1 13.6M10.8 11.5L12.2 12.9L15 10.1M9.75 4.15C9.75 5.8897 8.3397 7.3 6.6 7.3C4.8603 7.3 3.45 5.8897 3.45 4.15C3.45 2.4103 4.8603 1 6.6 1C8.3397 1 9.75 2.4103 9.75 4.15Z"
      stroke={color}
      stroke-width="1.4"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
export const TableDeleteImage = ({ color }) => (
  // <svg
  //   width="24"
  //   height="24"
  //   viewBox="0 0 24 24"
  //   fill="none"
  //   xmlns="http://www.w3.org/2000/svg"
  // >
  //   <mask
  //     id="mask0_1773_56527"
  //     style={{ maskType: "alpha" }} // Fix the style attribute
  //     maskUnits="userSpaceOnUse"
  //     x="0"
  //     y="0"
  //     width="24"
  //     height="24"
  //   >
  //     <rect width="24" height="24" fill="#9F9F9F" />
  //   </mask>
  //   <g mask="url(#mask0_1773_56527)">
  //     <path
  //       d="M7 21C6.45 21 5.97917 20.8042 5.5875 20.4125C5.19583 20.0208 5 19.55 5 19V6H4V4H9V3H15V4H20V6H19V19C19 19.55 18.8042 20.0208 18.4125 20.4125C18.0208 20.8042 17.55 21 17 21H7ZM17 6H7V19H17V6ZM9 17H11V8H9V17ZM13 17H15V8H13V17Z"
  //       fill={color}
  //     />
  //   </g>
  // </svg>
  <svg
    width="31"
    height="30"
    viewBox="0 0 31 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="0.5" width="30" height="30" rx="15" fill="#E3E9FC" />
    <mask
      id="mask0_9889_4112"
      style={{ maskType: "alpha" }}
      maskUnits="userSpaceOnUse"
      x="7"
      y="7"
      width="17"
      height="16"
    >
      <rect x="7.5" y="7" width="16" height="16" fill="#9F9F9F" />
    </mask>
    <g mask="url(#mask0_9889_4112)">
      <path
        d="M12.168 21C11.8013 21 11.4874 20.8694 11.2263 20.6083C10.9652 20.3472 10.8346 20.0333 10.8346 19.6667V11H10.168V9.66667H13.5013V9H17.5013V9.66667H20.8346V11H20.168V19.6667C20.168 20.0333 20.0374 20.3472 19.7763 20.6083C19.5152 20.8694 19.2013 21 18.8346 21H12.168ZM18.8346 11H12.168V19.6667H18.8346V11ZM13.5013 18.3333H14.8346V12.3333H13.5013V18.3333ZM16.168 18.3333H17.5013V12.3333H16.168V18.3333Z"
        fill={color}
      />
    </g>
  </svg>
);

export const TableArchiveImage = ({ color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M3.33341 6.66383C3.19674 6.6603 3.09749 6.65239 3.00826 6.63464C2.34711 6.50313 1.83028 5.9863 1.69877 5.32515C1.66675 5.16415 1.66675 4.97055 1.66675 4.58333C1.66675 4.19612 1.66675 4.00251 1.69877 3.84152C1.83028 3.18037 2.34711 2.66354 3.00826 2.53202C3.16926 2.5 3.36287 2.5 3.75008 2.5H16.2501C16.6373 2.5 16.8309 2.5 16.9919 2.53202C17.653 2.66354 18.1699 3.18037 18.3014 3.84152C18.3334 4.00251 18.3334 4.19612 18.3334 4.58333C18.3334 4.97055 18.3334 5.16415 18.3014 5.32515C18.1699 5.9863 17.653 6.50313 16.9919 6.63464C16.9027 6.65239 16.8034 6.6603 16.6667 6.66383M8.33342 10.8333H11.6667M3.33341 6.66667H16.6667V13.5C16.6667 14.9001 16.6667 15.6002 16.3943 16.135C16.1546 16.6054 15.7721 16.9878 15.3017 17.2275C14.7669 17.5 14.0669 17.5 12.6667 17.5H7.33342C5.93328 17.5 5.23322 17.5 4.69844 17.2275C4.22803 16.9878 3.84558 16.6054 3.6059 16.135C3.33341 15.6002 3.33341 14.9001 3.33341 13.5V6.66667Z"
      stroke={color}
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
export const CommonDeleteSvg = ({ color }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_1773_56527"
      style={{ maskType: "alpha" }} // Fix the style attribute
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="24"
      height="24"
    >
      <rect width="24" height="24" fill="#9F9F9F" />
    </mask>
    <g mask="url(#mask0_1773_56527)">
      <path
        d="M7 21C6.45 21 5.97917 20.8042 5.5875 20.4125C5.19583 20.0208 5 19.55 5 19V6H4V4H9V3H15V4H20V6H19V19C19 19.55 18.8042 20.0208 18.4125 20.4125C18.0208 20.8042 17.55 21 17 21H7ZM17 6H7V19H17V6ZM9 17H11V8H9V17ZM13 17H15V8H13V17Z"
        fill={color}
      />
    </g>
  </svg>
);
export const DownloadIcon = ({ color }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21 15V16.2C21 17.8802 21 18.7202 20.673 19.362C20.3854 19.9265 19.9265 20.3854 19.362 20.673C18.7202 21 17.8802 21 16.2 21H7.8C6.11984 21 5.27976 21 4.63803 20.673C4.07354 20.3854 3.6146 19.9265 3.32698 19.362C3 18.7202 3 17.8802 3 16.2V15M17 10L12 15M12 15L7 10M12 15V3"
      stroke={color}
      stroke-width="1.8"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
