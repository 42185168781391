import {
  Button,
  Col,
  Form,
  Image,
  Input,
  Modal,
  Row,
  Select,
  Switch,
  Upload,
  message,
} from "antd";
import React, { useEffect, useState } from "react";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import uploadIcon from "../../../Assets/uploadIcon.svg";
import { EditFilled } from "@ant-design/icons";
const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
function OnboardingForm({
  handelFinish,
  setprogress,
  setStatus,
  setformProgress,
  formProgress,
  onboardData,
  setOnboardData,
  userData,
  loading,
  setcurrentForm,
  setEditLogo,
  editLogo,
}) {
  const [fileList, setFileList] = useState(
    onboardData?.upload_logo?.length > 0 &&
      typeof onboardData?.upload_logo[0] === "object"
      ? onboardData?.upload_logo
      : []
  );
  const [address, setAddress] = useState(null);
  const [acnNumber, setAcnNumber] = useState(
    onboardData ? onboardData?.companyAbn : ""
  );
  // const [editLogo, setEditLogo] = useState(false);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [marketingPermission, setMarketingPermission] = useState(false);
  const [form] = Form.useForm();
  const props = {
    name: "file",
    multiple: false,
    customRequest({ file, onSuccess }) {
      setTimeout(() => {
        onSuccess("ok");
      }, 0);
    },
  };
  const props2 = {
    name: "file2",
    multiple: false,
    customRequest({ file, onSuccess }) {
      setTimeout(() => {
        onSuccess("ok");
      }, 0);
    },
  };

  /***************** uploaded file data *********************/

  const deductProgress = (field, e) => {
    setprogress((prev) => ({ ...prev, first: prev?.first - 15 }));
  };

  const handelVerify = (field, e) => {
    if (field === "registered_address") {
      setformProgress((prev) => ({
        ...prev,
        [field]: e,
      }));
    } else if (field == "marketingPermission") {
      // setprogress((prev) => ({ ...prev, first: prev?.first + 15 }));
      setMarketingPermission(e);
      if (e === true) {
        setformProgress((prev) => ({
          ...prev,
          [field]: e,
        }));
      } else {
        setformProgress((prev) => ({
          ...prev,
          [field]: false,
        }));
      }
    } else if (formProgress?.[field] != null && formProgress?.[field] != "") {
      setformProgress((prev) => ({ ...prev, [field]: e?.target?.value }));
      if (e?.target?.value == null || e?.target?.value == "") {
        deductProgress(field, e);
      }
    } else {
      setprogress((prev) => ({ ...prev, first: prev?.first + 15 }));
      setformProgress((prev) => ({ ...prev, [field]: e?.target?.value }));
    }
  };
  const handleChange = (file) => {
    /***********  storing file in a state *************/

    if (file?.fileList?.length > 0) {
      setprogress((prev) => ({ ...prev, first: prev?.first + 7.5 }));
    } else {
      setprogress((prev) => ({ ...prev, first: prev?.first - 15 }));
    }
    setFileList(file?.fileList);
    form.setFieldsValue({ upload_logo: file?.fileList });
  };

  // to format abn number
  const numberWithCommas = (inputString) => {
    if (inputString) {
      inputString = inputString.split(" ").join("");
      let result = inputString.slice(0, 2); // Add the first 2 characters as is
      inputString = inputString.slice(2); // Remove the first 2 characters

      while (inputString.length > 0) {
        result += " " + inputString.slice(0, 3); // Add a space and the next 3 characters
        inputString = inputString.slice(3); // Remove the processed characters
      }

      return result;
    }
  };
  const handleAcnNumber = (value) => {
    setAcnNumber(numberWithCommas(value));
  };
  //handle preview function
  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
  };
  useEffect(() => {
    form.setFieldsValue({
      companyName: userData?.companyName,
      tradingName: userData?.tradingName,
      billingName: userData?.billingName,
      companyAbn: userData?.companyAbn,
      billingEmail: userData?.billingEmail,
      registered_address: userData?.registered_address,
      upload_logo: userData?.companyLogo,
      marketingPermission: userData?.marketingPermission,
    });
    let temp = {};
    if (userData?.companyLogo?.length > 0) {
      setEditLogo(true);
    }
    if (userData?.companyAbn) {
      // setformProgress({
      //   ...formProgress,
      //   ["companyAbn"]: userData?.companyAbn,
      // });
      temp = { ...temp, ["companyAbn"]: userData?.companyAbn };
    }
    if (userData?.companyName) {
      // setformProgress({
      //   ...formProgress,
      //   ["companyName"]: userData?.companyName,
      // });
      temp = { ...temp, ["companyName"]: userData?.companyName };
    }
    if (userData?.tradingName) {
      // setformProgress({
      //   ...formProgress,
      //   ["tradingName"]: userData?.tradingName,
      // });
      temp = { ...temp, ["tradingName"]: userData?.tradingName };
    }
    if (userData?.billingName) {
      // setformProgress({
      //   ...formProgress,
      //   ["billingName"]: userData?.billingName,
      // });
      temp = { ...temp, ["billingName"]: userData?.billingName };
    }
    if (userData?.billingEmail) {
      // setformProgress({
      //   ...formProgress,
      //   ["billingEmail"]: userData?.billingEmail,
      // });
      temp = { ...temp, ["billingEmail"]: userData?.billingEmail };
    }
    if (userData?.officeAddress) {
      // setformProgress({
      //   ...formProgress,
      //   ["registered_address"]: userData?.registered_address,
      // });
      temp = { ...temp, ["registered_address"]: userData?.officeAddress };
    }
    if (userData?.upload_logo) {
      // setformProgress({
      //   ...formProgress,
      //   ["upload_logo"]: userData?.upload_logo,
      // });
      temp = {
        ...temp,
        ["upload_logo"]: userData?.upload_logo,
      };
    }
    if (userData?.marketingPermission) {
      // setformProgress({
      //   ...formProgress,
      //   ["marketingPermission"]: userData?.marketingPermission,
      // });
      temp = {
        ...temp,
        ["marketingPermission"]: userData?.marketingPermission,
      };
    }
    setformProgress({ ...formProgress, ...temp });
    // setformProgress({
    //   companyName: userData?.companyName,
    //   tradingName: userData?.tradingName,
    //   billingName: userData?.billingName,
    //   companyAbn: userData?.companyAbn,
    //   billingEmail: userData?.billingEmail,
    //   registered_address: userData?.officeAddress,
    //   upload_logo: userData?.companyLogo,
    //   marketingPermission: userData?.marketingPermission,
    // });
    setMarketingPermission(
      userData?.marketingPermission ? userData?.marketingPermission : false
    );
    setAddress(userData?.officeAddress);
    // if (userData?.subscription?._id) {
    //   setStatus((prev) => ({
    //     ...prev,
    //     company: "success",
    //     plan: "progress",
    //   }));
    //   setprogress((prev) => ({ ...prev, first: 100 }));
    //   setcurrentForm("plan");
    // }
  }, [userData]);
  useEffect(() => {
    form.setFieldsValue({
      companyName: onboardData?.companyName,
      tradingName: onboardData?.tradingName,
      billingName: onboardData?.billingName,
      companyAbn: onboardData?.companyAbn,
      billingEmail: onboardData?.billingEmail,
      registered_address: onboardData?.registered_address,
      upload_logo: onboardData?.upload_logo,
      marketingPermission: onboardData?.marketingPermission,
    });
    setMarketingPermission(onboardData?.marketingPermission);
    setAddress(onboardData?.registered_address);
  }, [onboardData]);

  useEffect(() => {
    form.setFieldsValue({
      companyAbn: acnNumber,
    });
  }, [acnNumber]);

  return (
    <div>
      <Form
        layout="vertical"
        form={form}
        onFinish={(e) => {
          handelFinish(
            {
              ...e,
              ["marketingPermission"]: marketingPermission,
              ["registered_address"]: address,
            },
            "onboard"
          );
        }}
        requiredMark={false}
      >
        <Row className="onboarding_form">
          <Col xs={24} md={11}>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: (
                    <div>
                      <Row gutter={4} align={"middle"}>
                        <Col>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="17"
                            viewBox="0 0 18 17"
                            fill="none"
                            style={{ marginTop: "6px" }}
                          >
                            <path
                              d="M9.0003 6.7531V9.7531M9.0003 12.7531H9.0078M7.96179 2.92189L1.79313 13.5769C1.45097 14.1679 1.2799 14.4633 1.30518 14.7059C1.32724 14.9174 1.43806 15.1096 1.61008 15.2347C1.8073 15.3781 2.14875 15.3781 2.83164 15.3781H15.169C15.8519 15.3781 16.1933 15.3781 16.3905 15.2347C16.5625 15.1096 16.6734 14.9174 16.6954 14.7059C16.7207 14.4633 16.5496 14.1679 16.2075 13.5769L10.0388 2.92189C9.69789 2.33302 9.52743 2.03858 9.30503 1.93969C9.11104 1.85344 8.88957 1.85344 8.69558 1.93969C8.47318 2.03858 8.30272 2.33302 7.96179 2.92189Z"
                              stroke="#D03C28"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </Col>
                        <Col> Company name is required.</Col>
                      </Row>
                    </div>
                  ),
                },
              ]}
              label="Company Name*"
              name="companyName"
            >
              <Input
                onChange={(e) => handelVerify("companyName", e)}
                placeholder="Add company name"
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={11}>
            <Form.Item label="Trading Name" name="tradingName">
              <Input
                placeholder="Add trading name"
                onChange={(e) => handelVerify("tradingName", e)}
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={11}>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: (
                    <div>
                      <Row gutter={4} align={"middle"}>
                        <Col>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="17"
                            viewBox="0 0 18 17"
                            fill="none"
                            style={{ marginTop: "6px" }}
                          >
                            <path
                              d="M9.0003 6.7531V9.7531M9.0003 12.7531H9.0078M7.96179 2.92189L1.79313 13.5769C1.45097 14.1679 1.2799 14.4633 1.30518 14.7059C1.32724 14.9174 1.43806 15.1096 1.61008 15.2347C1.8073 15.3781 2.14875 15.3781 2.83164 15.3781H15.169C15.8519 15.3781 16.1933 15.3781 16.3905 15.2347C16.5625 15.1096 16.6734 14.9174 16.6954 14.7059C16.7207 14.4633 16.5496 14.1679 16.2075 13.5769L10.0388 2.92189C9.69789 2.33302 9.52743 2.03858 9.30503 1.93969C9.11104 1.85344 8.88957 1.85344 8.69558 1.93969C8.47318 2.03858 8.30272 2.33302 7.96179 2.92189Z"
                              stroke="#D03C28"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </Col>
                        <Col>ACN/ABN is required.</Col>
                      </Row>
                    </div>
                  ),
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value) {
                      return Promise.reject("ACN/ABN is required.");
                    }
                    if (/^[\d\s]+$/.test(value)) {
                      return Promise.resolve();
                    } else {
                      return Promise.reject(
                        "ACN/ABN must contain only numeric characters and spaces."
                      );
                    }
                  },
                }),
              ]}
              label="ACN/ABN*"
              name="companyAbn"
            >
              <Input
                value={acnNumber}
                min={0}
                onChange={(e) => {
                  handelVerify("companyAbn", e);
                  handleAcnNumber(e.target.value);
                  // setAcnNumber(e.target.value);
                }}
                placeholder="12 345 678 999"
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={11}>
            <Form.Item
              label="Registered Address*"
              name="registered_address"
              className="googleApiContainer"
            >
              {/* adding google api */}
              <GooglePlacesAutocomplete
                apiKey="AIzaSyBgimhyTUzrdCN2YF7Z81vu0WJbTECDCkA"
                autocompletionRequest={{
                  componentRestrictions: {
                    country: ["au"], //to set the specific country
                  },
                }}
                selectProps={{
                  placeholder: "Add address",
                  value: address,
                  onChange: (e) => {
                    form.setFieldsValue({ registered_address: e });
                    handelVerify("registered_address", e);
                    setAddress(e);
                  },
                }}
                apiOptions={{
                  language: "en",
                  componentRestrictions: { country: "au" },
                  // types: ["regions"],
                }}
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={11}>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: (
                    <div>
                      <Row gutter={4} align={"middle"}>
                        <Col>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="17"
                            viewBox="0 0 18 17"
                            fill="none"
                            style={{ marginTop: "6px" }}
                          >
                            <path
                              d="M9.0003 6.7531V9.7531M9.0003 12.7531H9.0078M7.96179 2.92189L1.79313 13.5769C1.45097 14.1679 1.2799 14.4633 1.30518 14.7059C1.32724 14.9174 1.43806 15.1096 1.61008 15.2347C1.8073 15.3781 2.14875 15.3781 2.83164 15.3781H15.169C15.8519 15.3781 16.1933 15.3781 16.3905 15.2347C16.5625 15.1096 16.6734 14.9174 16.6954 14.7059C16.7207 14.4633 16.5496 14.1679 16.2075 13.5769L10.0388 2.92189C9.69789 2.33302 9.52743 2.03858 9.30503 1.93969C9.11104 1.85344 8.88957 1.85344 8.69558 1.93969C8.47318 2.03858 8.30272 2.33302 7.96179 2.92189Z"
                              stroke="#D03C28"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </Col>
                        <Col>Billing name is required.</Col>
                      </Row>
                    </div>
                  ),
                },
              ]}
              label="Billing Name*"
              name="billingName"
            >
              <Input
                onChange={(e) => handelVerify("billingName", e)}
                placeholder="Add billing name"
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={11}>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: (
                    <div>
                      <Row gutter={4} align={"middle"}>
                        <Col>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="17"
                            viewBox="0 0 18 17"
                            fill="none"
                            style={{ marginTop: "6px" }}
                          >
                            <path
                              d="M9.0003 6.7531V9.7531M9.0003 12.7531H9.0078M7.96179 2.92189L1.79313 13.5769C1.45097 14.1679 1.2799 14.4633 1.30518 14.7059C1.32724 14.9174 1.43806 15.1096 1.61008 15.2347C1.8073 15.3781 2.14875 15.3781 2.83164 15.3781H15.169C15.8519 15.3781 16.1933 15.3781 16.3905 15.2347C16.5625 15.1096 16.6734 14.9174 16.6954 14.7059C16.7207 14.4633 16.5496 14.1679 16.2075 13.5769L10.0388 2.92189C9.69789 2.33302 9.52743 2.03858 9.30503 1.93969C9.11104 1.85344 8.88957 1.85344 8.69558 1.93969C8.47318 2.03858 8.30272 2.33302 7.96179 2.92189Z"
                              stroke="#D03C28"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </Col>
                        <Col>Billing email is required.</Col>
                      </Row>
                    </div>
                  ),
                },
                {
                  type: "email",
                  message: (
                    <div>
                      <Row gutter={4} align={"middle"}>
                        <Col>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="17"
                            viewBox="0 0 18 17"
                            fill="none"
                            style={{ marginTop: "6px" }}
                          >
                            <path
                              d="M9.0003 6.7531V9.7531M9.0003 12.7531H9.0078M7.96179 2.92189L1.79313 13.5769C1.45097 14.1679 1.2799 14.4633 1.30518 14.7059C1.32724 14.9174 1.43806 15.1096 1.61008 15.2347C1.8073 15.3781 2.14875 15.3781 2.83164 15.3781H15.169C15.8519 15.3781 16.1933 15.3781 16.3905 15.2347C16.5625 15.1096 16.6734 14.9174 16.6954 14.7059C16.7207 14.4633 16.5496 14.1679 16.2075 13.5769L10.0388 2.92189C9.69789 2.33302 9.52743 2.03858 9.30503 1.93969C9.11104 1.85344 8.88957 1.85344 8.69558 1.93969C8.47318 2.03858 8.30272 2.33302 7.96179 2.92189Z"
                              stroke="#D03C28"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </Col>
                        <Col>Enter a valid email.</Col>
                      </Row>
                    </div>
                  ),
                },
              ]}
              label="Billing Email*"
              name="billingEmail"
            >
              {/* <Select
                onChange={(e) => handelVerify("billingEmail", e)}
                placeholder="Add Billing Email"
              >
                <Select.Option>billing 1</Select.Option>
              </Select> */}
              <Input
                onChange={(e) => handelVerify("billingEmail", e)}
                placeholder="Add billing email"
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={11}>
            <Form.Item
              rules={[
                {
                  required: false,
                  message: (
                    <div>
                      <Row gutter={4} align={"middle"}>
                        <Col>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="17"
                            viewBox="0 0 18 17"
                            fill="none"
                            style={{ marginTop: "6px" }}
                          >
                            <path
                              d="M9.0003 6.7531V9.7531M9.0003 12.7531H9.0078M7.96179 2.92189L1.79313 13.5769C1.45097 14.1679 1.2799 14.4633 1.30518 14.7059C1.32724 14.9174 1.43806 15.1096 1.61008 15.2347C1.8073 15.3781 2.14875 15.3781 2.83164 15.3781H15.169C15.8519 15.3781 16.1933 15.3781 16.3905 15.2347C16.5625 15.1096 16.6734 14.9174 16.6954 14.7059C16.7207 14.4633 16.5496 14.1679 16.2075 13.5769L10.0388 2.92189C9.69789 2.33302 9.52743 2.03858 9.30503 1.93969C9.11104 1.85344 8.88957 1.85344 8.69558 1.93969C8.47318 2.03858 8.30272 2.33302 7.96179 2.92189Z"
                              stroke="#D03C28"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </Col>
                        <Col>Logo is required.</Col>
                      </Row>
                    </div>
                  ),
                },
              ]}
              label="Upload Logo"
              name="upload_logo"
            >
              {fileList?.length < 1 && editLogo ? (
                <Row gutter={20} align={"top"}>
                  <Col>
                    <Image
                      width={102}
                      height={102}
                      src={userData?.companyLogo}
                    />
                  </Col>
                  <Col>
                    <EditFilled
                      className="editImage"
                      onClick={() => setEditLogo(false)}
                    />
                  </Col>
                  {/* <Upload
                    multiple={false}
                    accept="image/png, image/jpeg"
                    {...props}
                    onPreview={handlePreview}
                    onChange={handleChange}
                    fileList={fileList}
                  >
                    {fileList.length >= 1 ? null : (
                      <EditFilled className="editImage" />
                    )}
                  </Upload> */}
                </Row>
              ) : (
                <Upload
                  multiple={false}
                  accept="image/png, image/jpeg"
                  {...props}
                  onPreview={handlePreview}
                  onChange={handleChange}
                  listType="picture-card"
                  fileList={fileList}
                >
                  {fileList?.length >= 1 ? null : (
                    <div className="dragger-upload-content">
                      <img src={uploadIcon} alt="upload icon" />
                      <span>Upload Image</span>
                    </div>
                  )}
                </Upload>
              )}
            </Form.Item>
          </Col>
          <Col xs={24} md={11} className="marketing_permission">
            <Form.Item label="Marketing Permission" name="marketingPermission">
              <Row>
                <Col span={16}>
                  <span className="marketing_permisssion_text">
                    Receive the latest product and marketing updates from
                    "Cinchy.me" and selected partners.
                  </span>
                </Col>
                <Col span={8} className="justify-end d-flex">
                  <Switch
                    checked={marketingPermission}
                    onChange={(e) => handelVerify("marketingPermission", e)}
                  />
                </Col>
              </Row>
            </Form.Item>
          </Col>
          <Col xs={24} md={0}>
            <Col span={24}>
              <Button
                htmlType="submit"
                className="next-btn-sm w-100 mt-10"
                disabled={
                  formProgress?.billingName &&
                  formProgress?.companyAbn &&
                  formProgress?.registered_address &&
                  formProgress?.billingEmail &&
                  formProgress?.companyName &&
                  formProgress?.tradingName
                    ? false
                    : true
                }
                loading={loading}
              >
                Next
              </Button>
            </Col>
            <Col span={24}>
              <Button
                className="cancle-btn-sm w-100 mt-20 mb-20"
                onClick={() => {
                  setFileList([]);
                  setAddress(null);
                  form.resetFields();
                }}
              >
                Reset
              </Button>
            </Col>
          </Col>
        </Row>
        <Row gutter={12} className="btn_holder dp-none-sm">
          <Col>
            <Button
              className="cancel_btn"
              onClick={() => {
                setFileList([]);
                setAddress(null);
                form.resetFields();
              }}
            >
              Reset
            </Button>
          </Col>
          <Col>
            <Button
              htmlType="submit"
              className="next_btn"
              disabled={
                formProgress?.billingName &&
                formProgress?.companyAbn &&
                formProgress?.registered_address &&
                formProgress?.billingEmail &&
                formProgress?.companyName
                  ? false
                  : true
              }
              loading={loading}
            >
              Next
            </Button>
          </Col>
        </Row>
        <Modal
          open={previewOpen}
          title={null}
          footer={null}
          onCancel={() => setPreviewOpen(false)}
          centered
        >
          <img
            alt="example"
            style={{
              width: "100%",
            }}
            src={previewImage}
          />
        </Modal>
      </Form>
    </div>
  );
}

export default OnboardingForm;
