import { Button, Col, Modal, Row } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";

function UserRegisterdConfirmationModal({
  userregistedSuccessModal,
  setUserregistedSuccessModal,
  data,
  userCount,
}) {
  const navigate = useNavigate();
  const handleOkayButton = () => {
    setUserregistedSuccessModal(false);
    navigate("/user-management");
  };
  return (
    <div>
      <Modal
        title={false}
        open={userregistedSuccessModal}
        centered
        onCancel={() => setUserregistedSuccessModal(false)}
        footer={false}
        className="user_registered_success_modal"
      >
        <Row gutter={[0, 14]}>
          <Col span={24}>
            <h3>User Registered!</h3>
          </Col>
          <Col span={24}>
            <p>
              We have sent an email to{" "}
              <span>
                {data?.firstName}&nbsp;{data?.lastName}
              </span>{" "}
              on <span>{data?.email}</span> to complete the registration.
            </p>
          </Col>
          <Col span={24} className="justify-end d-flex">
            <Button
              className="black_color_button "
              // onClick={() => navigate("/campaign-management")}
              // onClick={() => setUserregistedSuccessModal(false)}
              onClick={() => handleOkayButton()}
            >
              <span className="color_white">Okay</span>
            </Button>
          </Col>
        </Row>
      </Modal>
    </div>
  );
}

export default UserRegisterdConfirmationModal;
