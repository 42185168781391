import { Col, Modal, Row, Spin } from "antd";
import LoaderGif from "../../Assets/loader.gif";
const FullScreenLoader = ({ isLoading }) => {
  return (
    <Modal
      className="loading_state_modal"
      open={isLoading}
      //   onOk={handleOk}
      closable={false}
      footer={false}
      centered
    >
      <img src={LoaderGif} />
    </Modal>
  );
};
export default FullScreenLoader;
