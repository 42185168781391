import { Button, Col, Form, Input, Row, message } from "antd";
import SubLayout from "../../components/layout/SubLayout";
import {
  permissionData,
  permissionDataUser,
  permissionDataUserAssociate,
} from "../../utils/constant";
import PermissionIndividualCard from "../../components/common/UserManagement/PermissionIndividualCard";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useForm } from "antd/es/form/Form";
import PermissionModal from "./permissionModal";
import API_MANAGER from "../../API";
import PhoneInput from "react-phone-number-input";
import AlertState from "../../components/common/AlertState";
import FullScreenLoader from "../../components/common/FullScreenLoader";

const EditUser = () => {
  const [permissionIndex, setPermissionIndex] = useState(0);
  const [defaultPermissionIndex, setDefaultPermissionIndex] = useState(0);
  const [permissionModalOpen, setPermissionModalOpen] = useState(false);
  const [singleUserData, setSingleUserData] = useState();
  const [permissions, setPermissions] = useState([]);
  const [selectedCount, setSelectedCount] = useState(2);
  const [countryCode, setCountryCode] = useState("+61");
  const [alertState, setAlertState] = useState("");
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const { id } = useParams();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    form.setFieldsValue({ firstName: singleUserData?.firstName });
    form.setFieldsValue({ lastName: singleUserData?.lastName });
    form.setFieldsValue({ email: singleUserData?.email });

    form.setFieldsValue({ phoneNumber: singleUserData?.phoneNumber });
    form.setFieldsValue({ countryCode: singleUserData?.countryCode });
    setCountryCode(singleUserData?.countryCode);
  }, [singleUserData]);
  const handlePermissionModal = () => {
    if (permissions.length !== 0) setPermissionModalOpen(true);
    else {
      message.error("Permissions not selected yet!");
    }
  };
  //GET SINGLE USER DETAILS
  const SingleUserData = async () => {
    setLoading(true);
    try {
      const response = await API_MANAGER.getSingleUserData(id);
      setSingleUserData(response?.data?.data);
      // setUsersList(response?.data?.data);
      setPermissions(response?.data?.data?.permissions);
      setLoading(false);
    } catch (error) {
      message.error("Something went wrong. Please try again.");
    }
  };
  useEffect(() => {
    if (singleUserData?.recruiterUserType === "Standard") {
      setPermissionIndex(1);
      setDefaultPermissionIndex(1);
    } else if (
      singleUserData?.recruiterUserType === "Admin" ||
      singleUserData?.recruiterUserType === null
    ) {
      setPermissionIndex(0);
      setDefaultPermissionIndex(0);
    } else {
      setPermissionIndex(2);
      setDefaultPermissionIndex(2);
    }
  }, [singleUserData]);
  useEffect(() => {
    if (permissionIndex === 0) {
      setPermissions([0, 1, 2, 3, 4, 5]);
    } else if (permissionIndex === 1) {
      if (singleUserData?.permissions) {
        setPermissions(singleUserData?.permissions);
      } else {
        setPermissions([0, 1, 3, 4, 5]);
      }
    } else if (permissionIndex === 2) {
      setPermissions([4, 5]);
    } else {
      setPermissions([]);
    }
  }, [permissionIndex]);
  useEffect(() => {
    SingleUserData();
  }, []);

  //change personal details API
  const onFinish = async (values) => {
    values = { ...values, ["countryCode"]: countryCode };
    try {
      const response = await API_MANAGER.updateUserPersonalDetails(id, values);
      message.success("Profile successfully updated.");
      if (singleUserData?.recruiterUserType === "Associate") {
        navigate("/user-management", { state: { profileUpdated: true } });
      }
    } catch (error) {
      message.error("Something went wrong. Please try again.");
    }
  };

  return (
    <div className="edit_user_profile_div">
      <SubLayout page={"User Profile"} whiteBg showBack>
        <FullScreenLoader isLoading={loading} />
        <AlertState
          state={alertState}
          message={alertMessage}
          alertOpen={alertOpen}
          setAlertOpen={setAlertOpen}
        />
        <PermissionModal
          permissionModalOpen={permissionModalOpen}
          setPermissionModalOpen={setPermissionModalOpen}
          singleUserData={singleUserData}
          permissionIndex={permissionIndex}
          editUser={singleUserData?.recruiterUserType}
          permissions={permissions}
        />
        <Row>
          <Col xs={24}>
            <span className="name_title">
              {singleUserData?.firstName + " " + singleUserData?.lastName}
            </span>
          </Col>
          <Col xs={24} className="personal_detail_main_col mt-24">
            <Row>
              <Col xs={24} className="title_col">
                <span>Personal Details</span>
              </Col>
              <Col xs={24} className="first_form_col">
                <Form
                  name="basic"
                  layout="vertical"
                  form={form}
                  requiredMark={false}
                  // initialValues={{
                  //   remember: true,
                  // }}
                  onFinish={onFinish}
                  // onFinishFailed={onFinishFailed}
                  autoComplete="off"
                >
                  <Row justify={"space-between"}>
                    <Col xs={24} md={11}>
                      <Form.Item
                        label="First Name"
                        name="firstName"
                        rules={[
                          {
                            required: true,
                            message: "Please input your first name!",
                          },
                        ]}
                      >
                        <Input size="large" />
                      </Form.Item>
                    </Col>
                    <Col xs={24} md={11}>
                      <Form.Item
                        label="Last Name"
                        name="lastName"
                        rules={[
                          {
                            required: true,
                            message: "Please input your last name!",
                          },
                        ]}
                      >
                        <Input size="large" />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row justify={"space-between"}>
                    <Col xs={24} md={11}>
                      <Form.Item
                        label="Email*"
                        name="email"
                        rules={[
                          {
                            required: true,
                            message: "Please input your first name!",
                          },
                        ]}
                      >
                        <Input size="large" disabled />
                      </Form.Item>
                    </Col>
                    <Col xs={24} md={11}>
                      <label className="custome_label">Contact Number</label>
                      <Row>
                        <Col xs={7} sm={6} lg={5}>
                          <Form.Item
                            name="countryCode"
                            rules={[
                              {
                                required: false,
                                message: "",
                              },
                            ]}
                            initialValue={"+61"}
                          >
                            {/* components from other library */}
                            <PhoneInput
                              international
                              countryCallingCodeEditable={false}
                              className="w-100 emailInput PhoneInput"
                              defaultCountry="AU"
                              value={countryCode}
                              onChange={setCountryCode}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={17} sm={18} lg={19}>
                          <Form.Item
                            name="phoneNumber"
                            rules={[
                              {
                                required: true,
                                message: "",
                              },
                            ]}
                          >
                            <Input
                              placeholder="phone number"
                              type="phone"
                              className="form_input_box border_right_bottom"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row className="p-24">
                    <Col span={24} className="justify_items_end f_col_rev">
                      <Button
                        className="button_no_border button_no_border_sm"
                        onClick={() => navigate(-1)}
                      >
                        Cancel
                      </Button>
                      <Form.Item>
                        <Button
                          className="button_1 button_1_sm"
                          htmlType="submit"
                        >
                          Update
                        </Button>
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
          </Col>

          {/* Permission Section */}
          <Col
            span={24}
            className="personal_detail_main_col  mt-24 border_none"
          >
            <Row>
              <Col xs={24} className="title_col p-0 h-0">
                <span>Permission</span>
              </Col>
              <Col xs={24}>
                <Row justify={"center"} className="mt-24 mt-0 p-24" gutter={12}>
                  {permissionData.map((item, index) => (
                    <Col xs={24} lg={12} xl={8} className="cards_Col">
                      <PermissionIndividualCard
                        data={item}
                        index={index}
                        setPermissionIndex={setPermissionIndex}
                        permissionIndex={permissionIndex}
                        permissions={permissions}
                        setPermissions={setPermissions}
                        selectedCount={selectedCount}
                        setSelectedCount={setSelectedCount}
                        editUser={singleUserData?.recruiterUserType}
                        userEditProfile={"yes"}
                      />
                    </Col>
                  ))}
                </Row>
                <Row className="p-24">
                  <Col xs={24} className="justify_items_end f_col_rev">
                    {singleUserData?.recruiterUserType !== "Associate" && (
                      <Button
                        className="button_no_border button_no_border_sm"
                        onClick={() => navigate(-1)}
                      >
                        Cancel
                      </Button>
                    )}

                    {singleUserData?.recruiterUserType !== "Associate" && (
                      <Button
                        className="button_1 button_1_sm"
                        onClick={handlePermissionModal}
                      >
                        Update
                      </Button>
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </SubLayout>
    </div>
  );
};
export default EditUser;
